import React from 'react';
import PopoverWrapper from '../PopoverWrapper';

export const ProtectionPopoverContent = () => (
  <PopoverWrapper>
    <>
      <p className={'popover-title'}> Protection de la marque </p>
      <p>Pour chaque indicateur est déterminé un niveau de risque :</p>
      <p className={'color-description'}>
        <span className={'color-dot minimal'}></span>
        <span>Vert : toutes les informations sont complètes.</span>
      </p>
      <p className={'color-description'}>
        <span className={'color-dot in-progress'}></span>
        <span>
          Orange : les informations sont incomplètes ou une remédiation est en
          cours.
        </span>
      </p>
      <p className={'color-description'}>
        <span className={'color-dot critical'}></span>
        <span>
          Rouge : les informations sont incomplètes, aucune action est en cours.
        </span>
      </p>
    </>
  </PopoverWrapper>
);
