import React from 'react';
import { get_custom_list_items, set_initial_state_action } from './reducer';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withRouter from '../../../../components/HOC/withRouter';
import DataTable from 'components/DataTable';
import { openModalAction } from '../CustomListItemModal/reducer';
import { onSearchAction } from 'containers/SearchData/reducer';
import withGenericModal from '../../../../containers/GenericModal/component';
import { DocumentsItemForm } from '../../../Documents/containers/DocumentListForm/documents-item-form';
import { ADD_FILE_MODAL_WINDOW } from '../../../../containers/Gallery/containers/gallery/gallery';
import { get_documents } from '../../../Documents/containers/DocumentListTable/reducer';
import {
  closeGenericModalAction,
  openGenericModalAction,
} from '../../../../containers/GenericModal/reducer';
import { handleSubmitAction } from '../../../Documents/containers/DocumentListForm/reducer';
import QrCodePreview from '../../../../containers/QRCodePreview';

const QRCODE_PREVIEW_MODAL = 'QRCODE_PREVIEW_MODAL';

export class CustomListTable extends React.Component {
  componentDidMount() {
    const { get_items, router } = this.props;
    try {
      const list_name = router.location.pathname.split('/')[1];
      get_items({ list_name });
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    const { setInitialState } = this.props;
    setInitialState();
  }

  handleClickOnTableRowAction = ({ record, action }) => {
    const { openModal, openGenericModal, business } = this.props;
    if (typeof action === 'string' && action.includes('edit')) {
      openModal({ itemData: record, isEdit: true, title: record.file_name });
    }
    if (typeof action === 'string' && action.includes('show')) {
      openModal({ itemData: record, isCommonItem: true });
    }

    // TODO replace business name with some logic, for example, data from the schema
    if (business?.name === 'soufflet' && action === 'previewQR') {
      if (record?.EANBiere) {
        // Restitution URL
        const url = `${business?.restitution_url}/${record?.Brasserie}/${record?.EANBiere}`;

        // Restitution image name
        const imageName = `${business?.name}_${record?.Brasserie}_${record?.EANBiere}`;

        // Open QR code preview modal
        openGenericModal({
          name: QRCODE_PREVIEW_MODAL,
          configuration: {
            title: 'QR Code',
          },
          data: {
            url,
            imageName,
          },
        });
      }
    } else if (typeof action === 'string' && action === 'previewQR') {
      const restitutionId =
        typeof record?.EAN === 'string' ? record?.EAN?.slice(-7) : '';
      const url = `${business?.restitution_url}/${restitutionId}`;
      const imageName = `${business?.name}_${restitutionId}`;
      openGenericModal({
        name: QRCODE_PREVIEW_MODAL,
        configuration: {
          title: 'QR Code',
        },
        data: {
          url,
          imageName,
        },
      });
    }
  };

  handleClickOnHeaderButton = (action) => {
    const { openModal } = this.props;
    if (action === 'addItem') openModal({ isEdit: false });
  };

  render() {
    const {
      data,
      table_scheme,
      onSearch,
      loading,
      headerActionsSchema,
      handleSubmitFile,
      get_documents,
      closeGenericModal,
    } = this.props;

    const GalleryModalWindow = withGenericModal(
      DocumentsItemForm,
      ADD_FILE_MODAL_WINDOW,
      {
        handleSubmit: (values) => {
          handleSubmitFile({
            ...values,
            onSuccess: () => {
              get_documents();
              closeGenericModal({ name: ADD_FILE_MODAL_WINDOW });
            },
          });
        },
      }
    );

    const QrPreviewModal = withGenericModal(
      QrCodePreview,
      QRCODE_PREVIEW_MODAL,
      {}
    );

    return (
      <div>
        <DataTable
          {...this.props}
          prefixId="CustomListTable"
          data={data}
          loading={loading}
          columnsSchema={table_scheme}
          headerActionsSchema={headerActionsSchema}
          handleClickOnTableRowAction={this.handleClickOnTableRowAction}
          handleClickOnHeaderButton={this.handleClickOnHeaderButton}
          onSearch={onSearch}
          getRowKey={(item) => item.id}
        />
        <GalleryModalWindow />
        <QrPreviewModal />
      </div>
    );
  }
}

const mapStateToProps = ({
  customListTable,
  searchData,
  auth: {
    currentUser: {
      attributes: { role, company, pages_scheme, business },
      isSignedIn,
    },
  },
}) => ({
  table_scheme: customListTable.table_scheme,
  data:
    searchData.isSearchActive && searchData.table === 'CustomListTable'
      ? searchData.searchResult
      : customListTable.data,
  loading: customListTable.loading,
  schema: pages_scheme,
  business: business,
});

const mapDispatchToProps = {
  get_items: get_custom_list_items,
  openModal: openModalAction,
  onSearch: (q) =>
    onSearchAction(
      q,
      (globalState) => globalState.customListTable.data,
      'CustomListTable'
    ),
  setInitialState: set_initial_state_action,
  get_documents,
  closeGenericModal: closeGenericModalAction,
  handleSubmitFile: handleSubmitAction,
  openGenericModal: openGenericModalAction,
};

const composeResult = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CustomListTable);

export default composeResult;
