class EncodePresenter {
  encode = (data, type) =>
    type ? this[`_encode_${type}`](data) : this._encode(data);

  _encode_filters = (filters = {}) => {
    const filtered = {};
    for (const [k, v] of Object.entries(filters))
      (v !== null && (!!v.length || !!Object.keys(v).length)) && (filtered[k] = v);

    return this._encode(filtered);
  };

  _encode = data => encodeURIComponent(JSON.stringify(data));
}

export default EncodePresenter;
