import React from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { STOCKS_COLORS } from '../../constants';
import { StocksInterface } from '../../types';
import { SIndicatorContainer } from './styled-components';

const Stocks = ({ styles, bordered, data }: StocksInterface) => {
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={'custom-tooltip'}>
          <p className={'label'}>{label}</p>
          <p>{`quantité : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <p className={'indicator-title'}>En stock</p>
      <div className={'chart'}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" vertical={false} />
            <XAxis dataKey="name" tickLine={false} />
            <YAxis
              label={{ value: 'tonnes', position: 'top', offset: 10 }}
              width={55}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="quantity">
              {data?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={STOCKS_COLORS[index]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </SIndicatorContainer>
  );
};

export default Stocks;
