import { BusinessData } from '../types';

const qrParametersGeneration = (
  record: any,
  action: string,
  business: BusinessData
): { url: string; imageName: string } => {
  // Restitution URL
  let url;
  // Restitution image name
  let imageName;

  const { name } = business;
  switch (name) {
    case 'soufflet':
    case 'carlsberg':
      url = `${business?.restitution_url}/${record?.Brasserie || ''}/${
        record?.EANBiere || ''
      }`;
      imageName = `${business?.name}_${record?.Brasserie || ''}_${
        record?.EANBiere || ''
      }`;
      break;
    case 'msfc3':
      url = `${business?.restitution_url}/${record?.ReferenceProduit || ''}`;
      imageName = `${business?.name}_${record?.ReferenceProduit || ''}`;
      break;
    case 'kaviar':
      url = `${business?.restitution_url}/${record?.NomMarque || ''}/homepage`;
      imageName = `${business?.name}_${record?.NomMarque || ''}`;
      break;
    case 'cordier':
      if (['La Cuvée Mythique', 'Mythique Languedoc', 'Maris'].includes(record?.MarqueDomaineChateau)) {
        url = `${business?.restitution_url}/${record?.MarqueDomaineChateau || ''}/homepage`;
        imageName = `${business?.name}_${record?.MarqueDomaineChateau || ''}`;
      } else {
        url = `${business?.restitution_url}/${record?.MarqueDomaineChateau || ''}/${record?.CodeReferenceProduit || ''}`;
        imageName = `${business?.name}_${record?.MarqueDomaineChateau || ''}_${record?.CodeReferenceProduit || ''}`;
      }
      break;
    case 'agec':
      url = `${business?.restitution_url}/company/${record?.Marque || ''}/${record?.ReferenceProduit || ''}`;
      imageName = `${business?.name}_${record?.Marque || ''}/${record?.ReferenceProduit || ''}`;
      break;
    case 'noah':
      url = `${business?.restitution_url}/${record?.CleanCompany|| ''}/${record?.ProjectId || ''}`;
      imageName = `${business?.name}_${record?.CleanCompany|| ''}_${record?.ProjectId || ''}`;
      break;
    case 'adhmais':
    case 'adhsoja':
      url = `${business?.restitution_url}/${record?.Entreprise|| ''}`;
      imageName = `${business?.name}_${record?.Entreprise|| ''}`;
      break;
    case 'meo':
      url = `${business?.restitution_url}/${record?.EAN|| ''}`;
      imageName = `${business?.name}_${record?.EAN|| ''}`;
      break;
    case 'bf':
    case 'cockpit':
    case 'cockpit2':
      url = `${business?.restitution_url}/${record?.Produit|| ''}`;
      imageName = `${business?.name}_${record?.Produit|| ''}`;
      break;
    default:
      const restitutionId =
        typeof record?.EAN === 'string' ? record?.EAN?.slice(-7) : '';
      url = `${business?.restitution_url}/${restitutionId}`;
      imageName = `${business?.name}_${restitutionId}`;
  }

  return {
    url,
    imageName,
  };
};

export default qrParametersGeneration;
