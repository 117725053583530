import React from 'react';
import { SComposition, Wrapper, StyledLine } from './style';
import getContentOrHttpLink from '../check-content-on-http';

const Composition = (composition, businessId) => (
  <SComposition>
    {composition.map((arr, i) => (
      <div key={i}>
        {arr.map(({ title, content }, i) => (
          <Wrapper key={i}>
            <div>{title}:</div>
            <div>{getContentOrHttpLink(content, businessId)}</div>
          </Wrapper>
        ))}
        {i < composition.length - 1 && <StyledLine />}
      </div>
    ))}
  </SComposition>
);

export default Composition;
