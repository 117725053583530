import React, { Component } from 'react';

function withWindowSize(WrappedComponent) {

  class HOC extends Component {
    state = { width: 0 };

    componentDidMount() {
      window.addEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
      this.setState({ width: window.innerWidth });
    }

    UNSAFE_componentWillMount() {
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
      return <WrappedComponent windowSize={{ ...this.state }} {...this.props} />;
    }
  }

  return HOC;
}

export default withWindowSize;
