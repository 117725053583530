import styled from 'styled-components';
import { font } from "../../App/styleVariables";

const StyledWrapper = styled.div`
  background: ${({ theme }) => theme?.dashboard?.pageBackground || '#f5f5f5'};
  height: 100%;
  width: 100%;
  padding: 35px;
  box-sizing: border-box;
  font-family: ${({ theme }) =>
    theme?.dashboard?.fontFamily || font};

  @media (max-width: 1440px) {
    padding: 25px 15px;
  }

  .wrapper {
    display: grid;
    grid-template-columns: ${({ theme }) =>
      theme?.dashboard?.gridColumns || `repeat(10, 1fr)`};
    grid-auto-rows: ${({ theme }) =>
      theme?.dashboard?.gridRows || `minmax(100px, auto)`};
    grid-gap: 15px;
    align-items: start;

    p.indicator-title {
      margin: 0;
      line-height: 1.2;
    }

    & > div.ant-card {
      font-family: inherit;
      box-shadow: 0 3px 4px 3px rgba(0, 0, 0, 0.08);
      width: auto;
      align-self: stretch;

      .ant-card-body {
        padding: 16px;
      }
    }
  }
  .dashboard-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  .dashboard-header-text {
    width: 100%;
  }
  .text-center {
    text-align: center;
  }
  .circle-header-logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 25px;
    margin-bottom: 30px;

    @media (max-width: 1340px) {
      width: 75px;
      height: 75px;
    }
  }
  .dashboard-title {
    text-transform: uppercase;
    color: ${({ theme }) => theme?.dashboard?.title?.color || '#000000'};
    background: ${({ theme }) =>
      theme?.dashboard?.title?.bgColor || 'transparent'};
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
    line-height: 1;
    padding: ${({ theme }) => theme?.dashboard?.title?.padding || '0'};
  }
  .dashboard-subtitle {
    color: ${({ theme }) => theme?.dashboard?.subTitle?.color || '#000000'};
    font-weight: 600;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 30px;
  }
`;

const StyledErrorPage = styled.p`
  grid-column: 1 / 11;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: ${(props) => props.theme.sidebar.itemMenu.color};
`;

export { StyledWrapper, StyledErrorPage };