import styled from 'styled-components';
import { borderRadius, font } from 'App/styleVariables';
import { Flex } from 'antd';

interface TabsCardStyles {
  $bordered?: boolean;
  styles: {
    padding?: string;
    background?: string;
    borderColor?: string;
    title?: {
      textTransform?: string;
      color?: string;
      fontSize?: string;
      fontWeight?: string;
    };
    subtitle?: {
      textTransform?: string;
      color?: string;
      fontSize?: string;
      fontWeight?: string;
      fontStyle?: string;
    };
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

export const STabsCardWrapper = styled.div<TabsCardStyles>`
  grid-column-start: ${({ styles }) => styles?.size?.column?.start};
  grid-column-end: ${({ styles }) => styles?.size?.column?.end};
  grid-row-start: ${({ styles }) => styles?.size?.row?.start};
  grid-row-end: ${({ styles }) => styles?.size?.row?.end};
  box-sizing: border-box;
  background: ${({ styles, theme }) =>
    styles?.background || theme?.dashboard?.block?.background || '#ffffff'};
  box-shadow: ${({ $bordered }) =>
    !$bordered && '0 0 5px 5px rgba(0, 0, 0, 0.03)'};
  border: ${({ $bordered, styles }) => {
    const borderColor = styles?.borderColor || '#EAECF0';
    return $bordered && `1px solid ${borderColor}`;
  }};
  border-radius: ${({ theme }) =>
    theme?.dashboard?.block?.borderRadius || '10px'};
  padding: ${({ styles, theme }) =>
    styles?.padding || theme?.dashboard?.block?.padding || '15px'};
  font-family: ${({ theme }) =>
    theme?.dashboard?.fontFamily || font} !important;
  position: relative;

  .ant-flex {
    font-family: inherit;
  }

  .section-title-wrapper {
    margin-bottom: 15px;
  }

  .title {
    color: ${({ styles }) => styles?.title?.color || '#000000'};
    font-size: ${({ styles }) => styles?.title?.fontSize || '20px'};
    font-weight: ${({ styles }) => styles?.title?.fontWeight || '500'};
    text-transform: ${({ styles }) =>
      styles?.title?.textTransform || 'initial'};
    margin-bottom: 0;
  }

  .title-icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    font-size: 10px;
    font-weight: 400;

    @media (max-width: 1550px) {
      width: 35px;
      height: 35px;
      margin-right: 5px;
    }
  }

  .tabs-wrapper {
    min-height: 250px;

    .ant-tabs-content-holder {
      border: 1px solid #f0f0f0;
      border-top-right-radius: ${borderRadius};
    }

    > .ant-tabs-nav {
      width: 15%;

      @media (max-width: 1600px) {
        width: 20%;
      }
    }

    .ant-tabs-content {
      &.ant-tabs-content-left {
        .ant-tabs-tabpane {
          padding-left: 0px;
        }
      }
    }

    .ant-tabs-tab {
      min-height: 55px;
    }
  }
`;

export const STabLabel = styled(Flex)`
  width: 100%;

  p {
    margin-bottom: 0;
    font-family: ${font};
  }

  .label-title {
    white-space: normal;
    text-align: left;
    font-size: 12px;
    color: #808080;
  }

  .label-number {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
  }
`;
