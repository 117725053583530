import styled from 'styled-components';
import { Button } from 'antd';

export const StyledButton = styled(Button)`
  font-family: ${(props) => props.theme.sidebar.itemMenu.family} !important;
  display: flex;
  align-items: center;
  
  .anticon {
    font-size: 16px;
  }
  .button-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
