import styled from 'styled-components';
import { SIndicator } from '../../styled-components';

export const SIndicatorContainer = styled(SIndicator)`
  .ant-card-body {
    height: 350px;

    .indicator-title {
      margin-bottom: 10px;
    }
  }
  .chart {
    width: 100%;
    height: 285px;

    .recharts-label {
      font-weight: 500;
      fill: #464a53;
      font-size: 16px;
    }
    .custom-tooltip {
      background: #ffffff;
      padding: 10px;
      border: 1px solid #808080;

      .label {
        margin-bottom: 10px;
      }
    }

    @media (max-width: 1600px) {
      .recharts-xAxis .recharts-cartesian-axis-tick-value {
        font-size: 10px;
      }
    }
    @media (max-width: 1320px) {
      .recharts-xAxis .recharts-cartesian-axis-tick-value {
        font-size: 8px;
      }
    }
  }
`;
