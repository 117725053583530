import * as yup from 'yup';

const passwordNotLongEnough = 'password must be at least 12 characters';

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(12, passwordNotLongEnough)
    .max(255)
    .required(),
  password_confirmation: yup
    .string()
    .min(12, passwordNotLongEnough)
    .max(255)
    .oneOf([yup.ref('password'), null], 'does not match')
    .required('does not match')
});