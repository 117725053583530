import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import defaultTheme from './defaultTheme';

class ThemeWrapper extends React.Component {
  render() {
    const { children, currentUser } = this.props;
    const theme =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes &&
      currentUser.attributes.business &&
      currentUser.attributes.business.theme;

    return (
      <ThemeProvider theme={theme ? JSON.parse(theme) : defaultTheme}>
        {children}
      </ThemeProvider>
    );
  }
}

ThemeWrapper.propTypes = {
  /**
   * Component that will be wrapped by *ThemeWrapper* component
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  /**
   * currentUser field from *reduxTokenAuth* object in Redux
   */
  currentUser: PropTypes.object,
};

export default connect((state) => ({
  currentUser: state.auth.currentUser,
}))(ThemeWrapper);
