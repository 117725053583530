import React from 'react';
import { StyledWrapperButton, StyledButton } from './styled-component';

interface Props {
  label: string;
  path: string;
  key: string;
  params?: Array<string>;
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const NavigationButton = ({ key, path, params, label, styles }: Props) => {
  const pathname = `${path}/${params?.join('/')}`;
  return (
    <StyledWrapperButton styles={styles} key={key}>
      <StyledButton to={{ pathname }}>
        {label}
      </StyledButton>
    </StyledWrapperButton>
  )
}

export default NavigationButton;