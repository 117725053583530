import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { CardStyles } from '../../types';

export const SIndicatorContainer = styled(SIndicator)<CardStyles>`
  .indicator-title {
    text-transform: ${(props) => props?.styles?.title?.textTransform};
    margin-bottom: ${(props) => props?.styles?.title?.marginBottom};
  }
  
  .indicator-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
  
  .legend {
   margin-left: 20px;
  }

  .indicator-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-rows: minmax(50px, auto);
    justify-content: flex-start;
  }
  
  .axis-labels {
    .axis-label {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      
      @media (max-width: 1280px) {
        font-size: 12px;
      }
    }
  }
  
  .axis-title-wrapper {
    text-align: center;
  }

  .axis {
    .axis-icon {
      width: 228px;
      
      @media (max-width: 1280px) {
        max-width: 150px;
      }
    }
    
    &.y {
      display: flex;
      flex-flow: row nowrap;

      .axis-labels {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
      }
    }

    &.x {
      display: flex;
      flex-direction: column;

      .axis-labels {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }
      
      .axis-image {
        width: 100%;
      }
      
      .axis-title-wrapper {
        max-width: 60%;
      }
    }
  }
  
  .axis-title {
    color: #06B26B;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;

    @media (max-width: 1280px) {
      font-size: 12px;
    }
  }

  .content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 200px);
    grid-auto-rows: minmax(120px, auto);
    grid-gap: 1px;
    background-color: black;
    
    @media (max-width: 1600px) {
      grid-template-columns: repeat(3, 180px);
    }
  }
  
  .cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6px;
  }
  
  .danger {
    background-color: #e87056;
  }

  .warning {
    background-color: #f59b5f;
  }

  .informative {
    background-color: #f6cf53;
  }

  .minimal {
    background-color: #63e1ad;
  }
  
  .cell-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin-bottom: 5px;
    
    img {
      width: 18px;
      height: auto;
      margin-right: 5px;
    }
    
    .cell-item-title {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 10px;
    }
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const SLegendItem = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .legend-icon {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-right: 4px;
  }
  
  .legend-label {
    color: ${(props) => props.color};
  }

  @media (max-width: 1440px) {
    font-size: 12px;
  }
`;
