import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { reset_search_action } from './reducer';
import { StyledInputSearch } from './style';

class SearchData extends React.Component {
  componentDidMount() {
    const { reset_search } = this.props;
    reset_search();
  }

  render() {
    const { onSearch, queryValue, id } = this.props;

    return (
      <StyledInputSearch
        placeholder=""
        value={queryValue}
        onChange={(e, data) => onSearch(e.target.value, data)}
        id={id}
        suffix={<SearchOutlined />}
        enterButton={false}
        addonAfter={''}
        className="search-data"
      />
    );
  }
}

SearchData.propTypes = {
  /**
   * Function that reset the search
   */
  reset_search: PropTypes.func,
  /**
   * Search function - function should dispatch the search action to update the global searchData state.
   */
  onSearch: PropTypes.func,
  /**
   * The value entered in the search
   */
  queryValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * id for the DOM element
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state) => ({
  queryValue: state.searchData.queryValue,
});

const mapDispatchToProps = {
  reset_search: reset_search_action,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchData);
