import React from "react";
import QualityBoard from "./containers/QualityBoard";

const Quality = () => (
  <React.Fragment>
    <QualityBoard />
  </React.Fragment>
);

export default Quality;
