const FILATEUR_DATA = [
  {
    "Faible": [
      {
        "title": "USA",
        "geometry": { "type": "Point", "coordinates": [-103.722167, 44.369111] },
        "value": 5
      },
      {
        "title": "Spain",
        "geometry": { "type": "Point", "coordinates": [-4.002152, 40.415363] },
        "value": 5
      },
      {
        "title": "Portugal",
        "geometry": { "type": "Point", "coordinates": [-8.148152, 39.656721] },
        "value": 10
      },
      {
        "title": "India",
        "geometry": { "type": "Point", "coordinates": [79.088155, 21.145800] },
        "value": 20
      },
      {
        "title": "China",
        "geometry": { "type": "Point", "coordinates": [113.625328, 34.746611] },
        "value": 20
      }
    ]
  },
  {
    "Modéré": [
      {
        "title": "Vietnam",
        "geometry": { "type": "Point", "coordinates": [18.673373, 105.691266] },
        "value": 5
      },
      {
        "title": "Bulgaria",
        "geometry": { "type": "Point", "coordinates": [24.749535, 42.143411] },
        "value": 5
      },
      {
        "title": "Romania",
        "geometry": { "type": "Point", "coordinates": [24.972609, 45.838624] },
        "value": 5
      },
      {
        "title": "Morocco",
        "geometry": { "type": "Point", "coordinates": [-6.361679, 32.332988] },
        "value": 10
      },
      {
        "title": "Brazil",
        "geometry": { "type": "Point", "coordinates": [-56.099719, -15.593692] },
        "value": 20
      },
      {
        "title": "Thailand",
        "geometry": { "type": "Point", "coordinates": [100.137484, 15.704825] },
        "value": 10
      },
      {
        "title": "Bangladesh",
        "geometry": { "type": "Point", "coordinates": [90.285058, 24.398739] },
        "value": 20
      }
    ]
  },
  {
    "Elevé": [
      {
        "title": "Myanmar",
        "geometry": { "type": "Point", "coordinates": [96.217187, 20.797493] },
        "value": 5
      },
      {
        "title": "Turkey",
        "geometry": { "type": "Point", "coordinates": [34.070981, 38.477565] },
        "value": 20
      },
      {
        "title": "Pakistan",
        "geometry": { "type": "Point", "coordinates": [73.062558, 31.420197] },
        "value": 20
      },
      {
        "title": "Egypt",
        "geometry": { "type": "Point", "coordinates": [30.749761, 28.121878] },
        "value": 10
      }
    ]
  }
];
const TISSEUR_DATA = [
  {
    "Faible": [
      {
        "title": "Spain",
        "geometry": { "type": "Point", "coordinates": [-4.002152, 40.415363] },
        "value": 10
      },
      {
        "title": "Portugal",
        "geometry": { "type": "Point", "coordinates": [-8.148152, 39.656721] },
        "value": 5
      },
      {
        "title": "France",
        "geometry": { "type": "Point", "coordinates": [2.656423, 46.363010] },
        "value": 5
      },
      {
        "title": "India",
        "geometry": { "type": "Point", "coordinates": [79.088155, 21.145800] },
        "value": 20
      },
      {
        "title": "China",
        "geometry": { "type": "Point", "coordinates": [113.625328, 34.746611] },
        "value": 20
      },
    ]
  },
  {
    "Modéré": [
      {
        "title": "Vietnam",
        "geometry": { "type": "Point", "coordinates": [18.673373, 105.691266] },
        "value": 10
      },
      {
        "title": "Bulgaria",
        "geometry": { "type": "Point", "coordinates": [24.749535, 42.143411] },
        "value": 10
      },
      {
        "title": "Romania",
        "geometry": { "type": "Point", "coordinates": [24.972609, 45.838624] },
        "value": 10
      },
      {
        "title": "Morocco",
        "geometry": { "type": "Point", "coordinates": [-6.361679, 32.332988] },
        "value": 10
      },
      {
        "title": "Brazil",
        "geometry": { "type": "Point", "coordinates": [-56.099719, -15.593692] },
        "value": 5
      },
      {
        "title": "Thailand",
        "geometry": { "type": "Point", "coordinates": [100.137484, 15.704825] },
        "value": 10
      },
      {
        "title": "Bangladesh",
        "geometry": { "type": "Point", "coordinates": [90.285058, 24.398739] },
        "value": 20
      }
    ]
  },
  {
    "Elevé": [
      {
        "title": "Myanmar",
        "geometry": { "type": "Point", "coordinates": [96.217187, 20.797493] },
        "value": 5
      },
      {
        "title": "Turkey",
        "geometry": { "type": "Point", "coordinates": [34.070981, 38.477565] },
        "value": 20
      },
      {
        "title": "Pakistan",
        "geometry": { "type": "Point", "coordinates": [73.062558, 31.420197] },
        "value": 20
      },
      {
        "title": "Egypt",
        "geometry": { "type": "Point", "coordinates": [30.749761, 28.121878] },
        "value": 20
      }
    ]
  }
];
const FABRICANT_DATA = [
  {
    "Faible": [
      {
        "title": "USA",
        "geometry": { "type": "Point", "coordinates": [-103.722167, 44.369111] },
        "value": 5
      },
      {
        "title": "Spain",
        "geometry": { "type": "Point", "coordinates": [-4.002152, 40.415363] },
        "value": 10
      },
      {
        "title": "Portugal",
        "geometry": { "type": "Point", "coordinates": [-8.148152, 39.656721] },
        "value": 5
      },
      {
        "title": "France",
        "geometry": { "type": "Point", "coordinates": [2.656423, 46.363010] },
        "value": 5
      },
      {
        "title": "India",
        "geometry": { "type": "Point", "coordinates": [79.088155, 21.145800] },
        "value": 20
      },
      {
        "title": "China",
        "geometry": { "type": "Point", "coordinates": [113.625328, 34.746611] },
        "value": 20
      },
    ]
  },
  {
    "Modéré": [
      {
        "title": "Vietnam",
        "geometry": { "type": "Point", "coordinates": [18.673373, 105.691266] },
        "value": 5
      },
      {
        "title": "Bulgaria",
        "geometry": { "type": "Point", "coordinates": [24.749535, 42.143411] },
        "value": 5
      },
      {
        "title": "Romania",
        "geometry": { "type": "Point", "coordinates": [24.972609, 45.838624] },
        "value": 10
      },
      {
        "title": "Morocco",
        "geometry": { "type": "Point", "coordinates": [-6.361679, 32.332988] },
        "value": 10
      },
      {
        "title": "Brazil",
        "geometry": { "type": "Point", "coordinates": [-56.099719, -15.593692] },
        "value": 10
      },
      {
        "title": "Thailand",
        "geometry": { "type": "Point", "coordinates": [100.137484, 15.704825] },
        "value": 10
      },
      {
        "title": "Bangladesh",
        "geometry": { "type": "Point", "coordinates": [90.285058, 24.398739] },
        "value": 20
      }
    ]
  },
  {
    "Elevé": [
      {
        "title": "Myanmar",
        "geometry": { "type": "Point", "coordinates": [96.217187, 20.797493] },
        "value": 5
      },
      {
        "title": "Turkey",
        "geometry": { "type": "Point", "coordinates": [34.070981, 38.477565] },
        "value": 20
      },
      {
        "title": "Pakistan",
        "geometry": { "type": "Point", "coordinates": [73.062558, 31.420197] },
        "value": 10
      },
      {
        "title": "Egypt",
        "geometry": { "type": "Point", "coordinates": [30.749761, 28.121878] },
        "value": 10
      }
    ]
  }
];

class FinancialCockpit {
  getDataByEntityType = (type?: string) => {
    switch (type) {
      case 'Filateur':
        return FILATEUR_DATA;
      case 'Tisseur':
        return TISSEUR_DATA;
      case 'Fabricant':
        return FABRICANT_DATA;
      default:
        return [];
    }
  }

  render = (type?: string) => {
    return {
      "dash_header": {
        "component": "SectionHeader",
        "title": "Tableau de bord financier OCTOBRE 2023",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 1,
              "end": 2
            }
          },
          "title": {
            "textAlign": "center"
          }
        }
      },
      "environment_header": {
        "component": "SectionHeader",
        "title": "ENVIRONNEMENT",
        "subtitle": "sur le mois",
        "questionMark": true,
        "questionMarkPopover": "EnvironmentPopoverContent",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 2,
              "end": 3
            }
          },
          "display": "flex",
          "alignItems": "flex-end",
          "subtitle": {
            "fontSize": "20px",
            "textTransform": "uppercase",
            "marginLeft": "20px"
          },
          "title": {
            "marginBottom": "0px",
            "lineHeight": "1",
          }
        }
      },
      "climate_change": {
        "component": "Environment",
        "title": "Changement climatique",
        "withDescription": true,
        "impact": 2748,
        "units": "tCO₂e",
        "total": "220 500 €",
        "impactDescription": "(1 tCO2 = 90 €)",
        "percentage": {
          "number": 20,
          "growth": "positive",
          "negativeColor": "#EB2C02",
          "positiveColor": "#00CEAA"
        },
        "graph": {
          "growth": "positive",
          "data": [
            { "x": 1 },
            { "x": 6 },
            { "x": 8 },
            { "x": 3 },
            { "x": 10 },
            { "x": 12 }
          ]
        },
        "graphDescription": "Trajectoire 6 derniers mois",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 5
            },
            "row": {
              "start": 3,
              "end": 4
            }
          },
          "header": {
            "marginBottom": "5px"
          }
        }
      },
      "resource_use": {
        "component": "Environment",
        "title": "Utilisation de ressources fossiles",
        "withDescription": true,
        "impact": 3160,
        "units": "GJ",
        "total": "101 120 €",
        "impactDescription": "(1 GJ = 32 €)",
        "percentage": {
          "number": 20,
          "growth": "positive",
          "negativeColor": "#EB2C02",
          "positiveColor": "#00CEAA"
        },
        "graph": {
          "growth": "positive",
          "data": [
            { "x": 6961291.5 },
            { "x": 7231898.3 },
            { "x": 7161898.6 },
            { "x": 7308059.873 }
          ]
        },
        "graphDescription": "Trajectoire 6 derniers mois",
        "styles": {
          "size": {
            "column": {
              "start": 5,
              "end": 9
            },
            "row": {
              "start": 3,
              "end": 4
            }
          },
          "header": {
            "marginBottom": "5px"
          }
        }
      },
      "water_use": {
        "component": "Environment",
        "title": "Utilisation de ressources en eau",
        "withDescription": true,
        "impact": 123450,
        "units": "m³",
        "total": "370 350 €",
        "impactDescription": "(1 m3 = 3 €)",
        "percentage": {
          "number": 10,
          "growth": "negative",
          "negativeColor": "#EB2C02",
          "positiveColor": "#00CEAA"
        },
        "graph": {
          "growth": "negative",
          "data": [
            { "x": 14 },
            { "x": 8 },
            { "x": 5 },
            { "x": 8 },
            { "x": 4 },
            { "x": 2 },
          ]
        },
        "graphDescription": "Trajectoire 6 derniers mois",
        "styles": {
          "size": {
            "column": {
              "start": 9,
              "end": 13
            },
            "row": {
              "start": 3,
              "end": 4
            }
          },
          "header": {
            "marginBottom": "5px"
          }
        }
      },
      "traceability_header": {
        "component": "SectionHeader",
        "title": "Traçabilité",
        "subtitle": "sur le mois",
        "questionMark": true,
        "questionMarkPopover": "TraceabilityPopoverContent",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 4,
              "end": 5
            }
          },
          "display": "flex",
          "alignItems": "flex-end",
          "subtitle": {
            "fontSize": "20px",
            "textTransform": "uppercase",
            "marginLeft": "20px"
          },
          "title": {
            "marginBottom": "0px",
            "lineHeight": "1",
          }
        }
      },
      "entity": {
        "component": "Entities",
        "title": "Couverture de traçabilité",
        "tracedVersion": true,
        "list": [
          {
            "type": "FILATEURS",
            "traced_nontraced_qty": "111000 €",
            "traced_qty": "100000 €"
          },
          {
            "type": "TISSEURS",
            "traced_nontraced_qty": "250000 €",
            "traced_qty": "200000 €"
          },
          {
            "type": "FABRICANTS",
            "traced_nontraced_qty": "500000 €",
            "traced_qty": "350000 €"
          }
        ],
        "order": [
          "FILATEURS",
          "TISSEURS",
          "FABRICANTS"
        ],
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 5
            },
            "row": {
              "start": 5,
              "end": 6
            }
          }
        }
      },
      "traces": {
        "component": "CertificateManager",
        "isSearchEnabled": false,
        "pagination": false,
        "title": "Chiffre d’affaires tracé",
        "data": [
          {
            "Category": "Vêtement du dessus",
            "Turnover": "1 467 000 €",
            "DontTraces": "1 173 600 €",
            "DontSurvaleur": "58 680 €",
            "NonTrace": "293 400 €",
            "PercentageNonTrace": "32%"
          },
          {
            "Category": "Vêtement du dessous",
            "Turnover": "250 000 € ",
            "DontTraces": "154 400 € ",
            "DontSurvaleur": "17 720 €",
            "NonTrace": "95 600 €",
            "PercentageNonTrace": "11%"
          },
          {
            "Category": "Accessoires",
            "Turnover": "178 000 €",
            "DontTraces": "142 400 €",
            "DontSurvaleur": "12 121 €",
            "NonTrace": "35 600 €",
            "PercentageNonTrace": "4%"
          },
          {
            "Category": "Nuit",
            "Turnover": "289 000 €",
            "DontTraces": "206 400 €",
            "DontSurvaleur": "10 320 €",
            "NonTrace": "82 800 €",
            "PercentageNonTrace": "9%"
          },
          {
            "Category": "Linge de maison",
            "Turnover": "468 560 €",
            "DontTraces": "67 000 €",
            "DontSurvaleur": "15 800 €",
            "NonTrace": "401 560 €",
            "PercentageNonTrace": "44%"
          },
          {
            "Category": "Total",
            "Turnover": "2 652 560 €",
            "DontTraces": "1 743 800 €",
            "DontSurvaleur": "114 641 €",
            "NonTrace": "908 960 €",
            "PercentageNonTrace": "100%"
          }
        ],
        "table_scheme": [
          {
            "label": "Catégorie",
            "fieldName": "Category",
            "width": 200,
            "isWidthFixed": false,
            "sortAs": "string"
          },
          {
            "label": "Chiffre d’affaires",
            "fieldName": "Turnover",
            "width": 200,
            "isWidthFixed": false,
            "sortAs": "string"
          },
          {
            "label": "Dont tracés",
            "fieldName": "DontTraces",
            "width": 200,
            "isWidthFixed": false,
            "sortAs": "string"
          },
          {
            "label": "Dont survaleur",
            "fieldName": "DontSurvaleur",
            "width": 200,
            "isWidthFixed": false,
            "sortAs": "string"
          },
          {
            "label": "Non tracé (à risque)",
            "fieldName": "NonTrace",
            "width": 200,
            "isWidthFixed": false,
            "sortAs": "string"
          },
          {
            "label": "% non tracé (à risque)",
            "fieldName": "PercentageNonTrace",
            "width": 200,
            "isWidthFixed": false,
            "sortAs": "string"
          }
        ],
        "styles": {
          "size": {
            "column": {
              "start": 5,
              "end": 13
            },
            "row": {
              "start": 5,
              "end": 6
            }
          },
          "minHeight": "200px",
          "table": {
            "marginTop": "30px",
            "header": {
              "fontSize": "12px",
              "paddingRight": "0px",
              "sorterRight": "0px"
            },
            "content": {
              "fontSize": "12px",
              "textAlign": "right"
            },
            "lastRow": {
              "borderTop": "2px solid #808080",
              "fontWeight": 600
            },
            "firstRow": {
              "textAlign": "left"
            }
          }
        }
      },
      "certification_header": {
        "component": "SectionHeader",
        "title": "CERTIFICATION",
        "subtitle": "sur le mois",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 6,
              "end": 7
            }
          },
          "display": "flex",
          "alignItems": "flex-end",
          "subtitle": {
            "fontSize": "20px",
            "textTransform": "uppercase",
            "marginLeft": "20px"
          },
          "title": {
            "marginBottom": "0px",
            "lineHeight": "1",
          }
        }
      },
      "certificates": {
        "component": "CertificateManager",
        "isSearchEnabled": false,
        "pagination": false,
        "displayTitle": false,
        "data": [
          {
            "Entities": "Filateurs",
            "Volume": "211 000 €",
            "AvecCertification": "16 100 €",
            "SansCertification": "20 000 €",
            "PercentageSansCertification": "28%",
            "EndCertification": "174 900 €"
          },
          {
            "Entities": "Tisseurs",
            "Volume": "450 000 €",
            "AvecCertification": "300 000 €",
            "SansCertification": "0 €",
            "PercentageSansCertification": "0%",
            "EndCertification": "150 000 €"
          },
          {
            "Entities": "Fabricants",
            "Volume": "850 000 €",
            "AvecCertification": "200 000 €",
            "SansCertification": "50 000 €",
            "PercentageSansCertification": "72%",
            "EndCertification": "60 000 €"
          }
        ],
        "table_scheme": [
          {
            "label": "Entités",
            "fieldName": "Entities",
            "width": 200,
            "isWidthFixed": false,
            "sortAs": "string"
          },
          {
            "label": "Volume d’achat",
            "fieldName": "Volume",
            "width": 200,
            "isWidthFixed": false,
            "sortAs": "string"
          },
          {
            "label": "Avec certification",
            "fieldName": "AvecCertification",
            "width": 200,
            "isWidthFixed": false,
            "sortAs": "string"
          },
          {
            "label": "Sans certification",
            "fieldName": "SansCertification",
            "width": 200,
            "isWidthFixed": false,
            "sortAs": "string"
          },
          {
            "label": "% sans certification",
            "fieldName": "PercentageSansCertification",
            "width": 200,
            "isWidthFixed": false,
            "sortAs": "string"
          },
          {
            "label": "Avec certification arrivant à terme",
            "fieldName": "EndCertification",
            "width": 250,
            "isWidthFixed": true,
            "sortAs": "string"
          }
        ],
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 7,
              "end": 8
            }
          },
          "minHeight": "100px",
          "table": {
            "header": {
              "fontSize": "12px",
              "paddingRight": "0px",
              "sorterRight": "0px"
            },
            "content": {
              "fontSize": "12px",
              "textAlign": "right"
            },
            "firstRow": {
              "textAlign": "left"
            }
          }
        }
      },
      "purchase_header": {
        "component": "SectionHeader",
        "title": "Prix d’achat des matieres",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 8,
              "end": 9
            }
          },
          "display": "flex",
          "alignItems": "flex-end",
          "subtitle": {
            "fontSize": "20px",
            "textTransform": "uppercase",
            "marginLeft": "20px"
          },
          "title": {
            "marginBottom": "0px",
            "lineHeight": "1",
          }
        }
      },
      "costs_evaluation": {
        "component": "QuantityProduced",
        "title": "Evolution des coûts sur une année",
        "yAxisLeftValue": "Prix € au kg",
        "yAxisRightValue": "Prix € à l’unité",
        "questionMark": false,
        "questionMarkPopover": "CostsEvolutionPopoverContent",
        "areas": [
          {
            "key": "finished",
            "name": "PRODUITS FINIS (prix/unité)",
            "yAxisId": "right",
            "color" : "#06B26B"
          },
          {
            "key": "tissus",
            "name": "TISSUS (prix/kg)",
            "yAxisId": "left",
            "color": "#534DCA"
          },
          {
            "key": "fils",
            "name": "FILS (prix/kg)",
            "yAxisId": "left",
            "color": "#FFCA22"
          }
        ],
        "data": [
          {
            "name": "Sept",
            "fils": 3.406,
            "tissus": 4.4278,
            "finished": 10.2
          },
          {
            "name": "Oct",
            "fils": 2.912,
            "tissus": 3.7856,
            "finished": 10.2
          },
          {
            "name": "Nov",
            "fils": 2.847,
            "tissus": 3.7011,
            "finished": 9.9
          },
          {
            "name": "Déc",
            "fils": 2.73,
            "tissus": 3.549,
            "finished": 9.7
          },
          {
            "name": "Janv",
            "fils": 2.66,
            "tissus": 3.458,
            "finished": 9.4
          },
          {
            "name": "Févr",
            "fils": 2.66,
            "tissus": 3.458,
            "finished": 9.2
          },
          {
            "name": "Mars",
            "fils": 2.548,
            "tissus": 3.3124,
            "finished": 9
          },
          {
            "name": "Avr",
            "fils": 2.483,
            "tissus": 3.2279,
            "finished": 8.8
          },
          {
            "name": "Mai",
            "fils": 2.47,
            "tissus": 3.211,
            "finished": 8.6
          },
          {
            "name": "Juin",
            "fils": 2.444,
            "tissus": 3.1772,
            "finished": 8.5
          },
          {
            "name": "Juil",
            "fils": 2.405,
            "tissus": 3.1265,
            "finished": 8.4
          },
          {
            "name": "Août",
            "fils": 2.509,
            "tissus": 3.2617,
            "finished": 10
          },
          {
            "name": "Sept",
            "fils": 2.626,
            "tissus": 3.4138,
            "finished": 10.5
          }
        ],
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 9,
              "end": 10
            }
          }
        }
      },
      "map": {
        "component": "FlowMap",
        "title": "Cartographie",
        "questionMark": true,
        "questionMarkPopover": "MapPopoverContent",
        "legendTitle": "Niveau de risque (politique, économique, social)",
        "showArrowInLegend": false,
        "withBubbles": true,
        "zoomLevel": 2,
        "selectPlaceholderText": "Sélectionnez une entité",
        "data": {
          "filter": [
            { "value": "Filateur", "label": "Filateur" },
            { "value": "Tisseur", "label": "Tisseur" },
            { "value": "Fabricant", "label": "Fabricant" }
          ],
          "data": this.getDataByEntityType(type),
          "colors": {
            "Faible": "#06B26B",
            "Modéré": "#F19B1A",
            "Elevé": "#EB2C02"
          },
          "arrows": []
        },
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 10,
              "end": 11
            }
          }
        }
      },
      "protection_header": {
        "component": "SectionHeader",
        "title": "PROTECTION DE LA MARQUE",
        "subtitle": "sur le mois",
        "questionMark": true,
        "questionMarkPopover": "ProtectionPopoverContent",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 11,
              "end": 12
            }
          },
          "display": "flex",
          "alignItems": "flex-end",
          "subtitle": {
            "fontSize": "20px",
            "textTransform": "uppercase",
            "marginLeft": "20px"
          },
          "title": {
            "marginBottom": "0px",
            "lineHeight": "1",
          }
        }
      },
      "company_risks": {
        "component": "CompanyRisks",
        "brandValue": "1 200 M€",
        "categories": [
          {
            "title": "Qualité",
            "icon": "quality",
            "risk": "minimal",
            "riskTitle": "Minimal"
          },
          {
            "title": "Transparence",
            "icon": "transparency",
            "risk": "critical",
            "riskTitle": "Critique"
          },
          {
            "title": "Responsabilité sociale",
            "icon": "social_responsibility",
            "risk": "in_progress",
            "riskTitle": "En cours",
            "customColors": {
              "background": "#F19B1A",
              "text": "white"
            },
            "displayBadgeIcon": false
          },
          {
            "title": "Equité",
            "icon": "equity",
            "risk": "in_progress",
            "riskTitle": "En cours",
            "customColors": {
              "background": "#F19B1A",
              "text": "white"
            },
            "displayBadgeIcon": false
          },
          {
            "title": "Impact Environnemental",
            "icon": "environmental_protection",
            "risk": "minimal",
            "riskTitle": "Minimal"
          }
        ],
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 9
            },
            "row": {
              "start": 12,
              "end": 13
            }
          }
        }
      },
      "csrd_header": {
        "component": "SectionHeader",
        "title": "Corporate Sustainability Reporting Directive (CSRD)",
        "subtitle": "sur le mois",
        "questionMark": true,
        "questionMarkPopover": "CsrdPopoverContent",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 13,
              "end": 14
            }
          },
          "display": "flex",
          "alignItems": "flex-end",
          "subtitle": {
            "fontSize": "20px",
            "textTransform": "uppercase",
            "marginLeft": "20px"
          },
          "title": {
            "marginBottom": "0px",
            "lineHeight": "1",
          }
        }
      },
      "csrd": {
        "component": "CSRDirective",
        "categories": [
          {
            "title": "Environment",
            "icon": "csrd_environment",
            "subcategories": [
              {
                "name": "E1 Changement climatique",
                "impact_risk": {
                  "title": "Critique",
                  "type": "critical"
                },
                "financial_risk": {
                  "title": "Informatif",
                  "type": "informative"
                }
              },
              {
                "name": "E2 Pollution",
                "impact_risk": {
                  "title": "Significatif",
                  "type": "significant"
                },
                "financial_risk": {
                  "title": "Important",
                  "type": "important"
                }
              },
              {
                "name": "E3 Ressources marines et en eau",
                "impact_risk": {
                  "title": "Significatif",
                  "type": "significant"
                },
                "financial_risk": {
                  "title": "Minimal",
                  "type": "minimal"
                }
              },
              {
                "name": "E4 Biodiversité et écosystèmes",
                "impact_risk": {
                  "title": "Important",
                  "type": "important"
                },
                "financial_risk": {
                  "title": "Minimal",
                  "type": "minimal"
                }
              },
              {
                "name": "E5 Utilisation des ressources et économie circulaire",
                "impact_risk": {
                  "title": "Important",
                  "type": "important"
                },
                "financial_risk": {
                  "title": "Significatif",
                  "type": "significant"
                }
              }
            ]
          },
          {
            "title": "Social",
            "icon": "csrd_social",
            "subcategories": [
              {
                "name": "S1 Main d’oeuvre de l’entreprise",
                "impact_risk": {
                  "title": "Minimal",
                  "type": "minimal"
                },
                "financial_risk": {
                  "title": "Minimal",
                  "type": "minimal"
                }
              },
              {
                "name": "S2 Employés de la chaîne de valeur",
                "impact_risk": {
                  "title": "Significatif",
                  "type": "significant"
                },
                "financial_risk": {
                  "title": "Critique",
                  "type": "critical"
                }
              },
              {
                "name": "S3 Communautés concernées",
                "impact_risk": {
                  "title": "Critique",
                  "type": "critical"
                },
                "financial_risk": {
                  "title": "Important",
                  "type": "important"
                }
              },
              {
                "name": "S4 Consommateurs et utilisations",
                "impact_risk": {
                  "title": "Important",
                  "type": "important"
                },
                "financial_risk": {
                  "title": "Critique",
                  "type": "critical"
                }
              }
            ]
          },
          {
            "title": "Gouvernance",
            "icon": "csrd_governance",
            "subcategories": [
              {
                "name": "G1 Conduite commerciale",
                "impact_risk": {
                  "title": "Informatif",
                  "type": "informative"
                },
                "financial_risk": {
                  "title": "Significatif",
                  "type": "significant"
                }
              }
            ]
          }
        ],
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 14,
              "end": 15
            }
          }
        }
      }
    }
  }
}

export default new FinancialCockpit();