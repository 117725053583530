import type { ColumnsType } from 'antd/es/table';
import { toHHMMSS } from './utils';
import { DeviceData, EventData, PageData } from './type';

const deviceColumns: ColumnsType<DeviceData> = [
    {
        title: 'Type',
        dataIndex: 'label',
        key: 'label',
    },
    {
        title: 'Visiteurs uniques',
        dataIndex: 'nb_visits',
        key: 'nb_visits',
        width: '20%',
    },
];

const eventColumns: ColumnsType<EventData> = [
    {
        title: 'Action',
        dataIndex: 'label',
        key: 'label',
    },
    {
        title: 'Evenements',
        dataIndex: 'nb_events',
        key: 'nb_events',
        width: '12%',
    },
];

const pageColumns: ColumnsType<PageData> = [
    {
        title: 'URL',
        dataIndex: 'label',
        key: 'label',
        render: (_, record) => {
            const href = decodeURI(record.segment.substring(9));
            const true_href = decodeURIComponent(href);
            if (record.hasOwnProperty('subtable')) {
                return <span>{record.label} </span>
            } else {
                return (<a href={true_href} > {record.label} </a>)
            }

        }
    },
    {
        title: 'Vues de page',
        dataIndex: 'nb_hits',
        key: 'nb_hits',
        width: '20%',
    },
    {
        title: 'Vues de page uniques',
        dataIndex: 'nb_visits',
        width: '20%',
        key: 'nb_visits',
    },
    {
        title: 'Temps moyen sur la page',
        dataIndex: 'avg_time_on_page',
        width: '20%',
        key: 'avg_time_on_page',
        render: (sec) => toHHMMSS(sec),
    },
];

export {
    deviceColumns,
    eventColumns,
    pageColumns
}