import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker as ADatePicker } from 'antd';
import dayjs from 'dayjs';

const DatePicker = (props) => {
  const {
    name,
    value,
    disabled,
    placeholder,
    setFieldTouched,
    setFieldValue,
    isError,
  } = props;

  const dateValue = value && typeof value !== 'object' ? dayjs(value) : value;

  return (
    <ADatePicker
      placeholder={placeholder}
      name={name}
      style={{ width: '100%' }}
      value={!dateValue ? null : dateValue}
      onChange={(date) =>
        setFieldValue(name, date ? dayjs(date).format('YYYY/MM/DD') : date)
      }
      onBlur={() => setFieldTouched(name, true)}
      disabled={disabled}
      className={isError ? 'has-error' : ''}
      format="YYYY-MM-DD"
      data-name={name}
    />
  );
};

DatePicker.propTypes = {
  /**
   * The name for the current DatePicker. Must be unique in the form.
   */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * DatePicker selected value.
   */
  value: PropTypes.string,
  /**
   * Determine whether the DatePicker is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The placeholder of date input.
   */
  placeholder: PropTypes.string,
  /**
   * Touch state monitoring function.
   */
  setFieldTouched: PropTypes.func,
  /**
   * Value setting function.
   */
  setFieldValue: PropTypes.func,
  /**
   * Shows whether the DatePicker has an error. If '' then there is no errors.
   */
  isError: PropTypes.string,
};

export default DatePicker;
