import styled from 'styled-components';
import { Button, Form } from 'antd';
import { colors, font, transition } from "../../App/styleVariables";

export const SResult = styled.div`
  max-width: 474px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  margin-bottom: 50px;
  margin-top: 15vh;

  p {
    margin: 0;
    text-align: justify;
  }
  .anticon {
    color: ${colors.primary};
    margin-right: 15px;

    svg {
      width: 45px;
      height: 45px;
    }
  }
`;

export const SFormItem = styled(Form.Item)`
  .ant-form-item-row {
    display: flex;
    flex-direction: column;

    .ant-form-item-label {
      text-align: start;
      font-family: ${font};

      label {
        color: #1d1d1b;
        font-size: 16px;
        font-weight: 500;
        height: auto;

        &:after {
          content: none;
        }
      }
    }
  }
`;

export const SSubmitButton = styled(Button)`
  min-width: 148px;
  padding: 0 42px !important;
  height: 40px;
  font-size: ${(props) => props.theme.form.submitButton.size} !important;
  font-weight: 600 !important;
  font-family: ${(props) => props.theme.form.submitButton.family} !important;
  color: ${(props) => props.theme.form.submitButton.color} !important;
  background: ${(props) => props.theme.form.submitButton.passive} !important;
  border-color: ${(props) => props.theme.form.submitButton.passive} !important;
  margin-top: 16px;
  align-self: center;

  &:hover {
    color: ${(props) => props.theme.form.submitButton.color} !important;
    background: ${(props) => props.theme.form.submitButton.active} !important;
    border-color: ${(props) => props.theme.form.submitButton.active} !important;
  }
`;

export const SBackLink = styled.div`
  margin-top: 25px;
  align-self: flex-start;

  a {
    color: #1d1d1b;
    font-weight: 500;
    transition: ${transition};
    text-decoration: underline;

    &:hover {
      color: ${colors.secondary};
    }
  }
`;
