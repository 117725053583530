import * as yup from 'yup';
import { EMAIL_NOT_LONG_ENOUGH_MESSAGE, INVALID_EMAIL_MESSAGE } from '../constants';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .min(3, EMAIL_NOT_LONG_ENOUGH_MESSAGE)
    .max(255)
    .email(INVALID_EMAIL_MESSAGE)
    .required()
});

export { validationSchema };