// @ts-nocheck
import { store, resetAllDataAction } from 'store/nextStore';
import { verifyTokenAuth } from '../../sections/Login/reducer';

export const verifyUser = () => {
  if (
    sessionStorage.getItem('uid') &&
    sessionStorage.getItem('access-token') &&
    sessionStorage.getItem('client')
  ) {
    store.dispatch(verifyTokenAuth());
  } else {
    store.dispatch(resetAllDataAction());
  }
};