export const font = '"Rubik", sans-serif';

export const colors = {
  primary: '#1F487E',
  secondary: '#3b83e3',
  secondary10: '#ebf2fc',
  success: '#07AB6C',
  success10: '#e6f6f0',
  error: '#B42318',
  error10: '#f7e9e7',
  checking: '#BBB417',
  checking10: '#f8f7e7',
  inProgress: '#667085',
  inProgress10: '#eff0f2',
  interrupted: '#C07300',
  interrupted10: '#f8f1e5',
  pending: '#4DB4BB',
  pending10: '#edf7f8',
  informative: '#FFCA22',
  important: '#F37A09',
  significant: '#F84949',
  critical: '#BB0707',
  lightGray: '#d8d8d8',
  gray: '#777E8C',
};

export const blockShadow = 'rgba(0, 0, 0, 0.15) 0 3px 10px';
export const inputShadow = '0 0 0 2px rgba(59, 131, 227, 0.1)';

export const transition = 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)';

export const borderRadius = '10px';
