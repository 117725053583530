import React from "react";
import SourcingBoard from "./containers/SourcingBoard";

const Sourcing = () => (
  <React.Fragment>
    <SourcingBoard />
  </React.Fragment>
);

export default Sourcing;
