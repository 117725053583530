import { MutationFunction, useMutation } from 'react-query';

import { MutationArguments } from '../../types';
import errorHandler from '../../../../lib/errorHandler';

const useValidateOneMutation = ({
  factory,
  client,
  name,
  page,
  searchField,
  filter,
}: MutationArguments) => {
  return useMutation(factory?.validateOne as MutationFunction, {
    onSuccess: () => {
      client.refetchQueries([name, page, searchField, filter]);
    },
    onError: (error) => {
      if (error) {
        errorHandler(error);
      }
    },
  });
};

export default useValidateOneMutation;

