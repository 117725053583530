import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import withRouter from '../../components/HOC/withRouter';
import UniqIdsGenerator from '../../lib/uniqIdsGenerator';
import { resetAllDataAction } from '../../store/nextStore';
import DynamicTime from './components/DynamicTime';
import { StyledTopBar } from './style';
import Notifications from '../Notifications';
import { signOut } from '../Login/reducer';
import { openGenericModalAction } from '../../containers/GenericModal/reducer';
import { SUPPORT_MODAL, supportModal } from '../../components/Modal/constants';

const id_generator = new UniqIdsGenerator('topbar');

class MenuAppBar extends React.PureComponent {
  state = { isLogo: true };

  componentDidUpdate() {
    const { user } = this.props;
    const auth = user.currentUser.isSignedIn;
    const {
      currentUser: { attributes },
    } = user;
    const { logo_url } =
      auth && attributes && attributes.business && attributes.business;
    const image = new Image();
    image.src = logo_url;
    setTimeout(() => {
      if (auth && !image.width) {
        this.setState({ isLogo: false });
      } else if (auth && image.width) {
        this.setState({ isLogo: true });
      }
    }, 3000);
  }

  handleLogout = async (e) => {
    const { signOut, resetAllData, dashboardLogoutUrl, router } = this.props;
    e.preventDefault();

    try {
      const params = {
        'access-token': sessionStorage.getItem('access-token'),
        client: sessionStorage.client,
        uid: sessionStorage.uid,
      };

      await signOut(params);
    } catch (e) {
      console.error(e);
    } finally {
      // TODO reset all states to initial state to avoid displaying incorrect data after switching between businesses
      resetAllData();

      if (dashboardLogoutUrl) {
        // window.location.href = dashboardLogoutUrl;
        const logoutIframe = document.createElement('iframe');
        logoutIframe.style.display = 'none';
        logoutIframe.src = dashboardLogoutUrl;
        logoutIframe.setAttribute('id', 'logoutIframe');
        logoutIframe.onload = () => {
          logoutIframe.parentNode.removeChild(logoutIframe);
        };
        document.body.appendChild(logoutIframe);
      }

      router.navigate('/sign_in');
    }
  };

  handleLogo = (auth, attributes) => {
    const { isLogo } = this.state;
    const { name, logo_url } =
      auth && attributes && attributes.business && attributes.business;

    return auth && logo_url && isLogo ? (
      <img src={logo_url} className={'logo-icon'} alt="Business Logo" />
    ) : (
      <h1 className="company-name">{name && name.toUpperCase()}</h1>
    );
  };

  handleSupport = () => {
    const { openGenericModal } = this.props;
    openGenericModal({
      name: supportModal,
      configuration: {
        title: '',
        width: 650,
        prefixId: SUPPORT_MODAL,
      },
    });
  };

  render() {
    const { user } = this.props;
    const auth = user.currentUser.isSignedIn;
    const {
      currentUser: { attributes },
    } = user;

    const loggedUser = `${attributes.first_name} ${attributes.last_name}`;
    const logoContent = auth ? this.handleLogo(auth, attributes) : null;

    return (
      <StyledTopBar>
        <div className="topbar-wrapper">
          <div className="logo-wrapper">
            <div className="logo-inner">
              <div>
                <span className="platform-type">Traceability platform by </span>
                <span className="platform-name">Crystalchain</span>
              </div>
              {auth && (
                <div className="support-wrapper">
                  <Button
                    type={'link'}
                    onClick={this.handleSupport}
                    className={'support-btn'}
                  >
                    Support
                  </Button>
                </div>
              )}
            </div>
          </div>
          {auth && (
            <React.Fragment>
              {logoContent}
              <div className="right-box-wrapper">
                <DynamicTime />
                <div className="profile-wrapper">
                  <Notifications />
                  <div>
                    <UserOutlined style={{ paddingRight: 5 }} />
                    {loggedUser} &nbsp;
                  </div>
                  <div
                    id={id_generator.get_id('btn-logout')}
                    className="logout-btn"
                    onClick={this.handleLogout}
                  >
                    logout
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </StyledTopBar>
    );
  }
}

MenuAppBar.propTypes = {
  user: PropTypes.object,
  dashboardLogoutUrl: PropTypes.string,
  signOut: PropTypes.func,
  router: PropTypes.object,
  resetAllData: PropTypes.func,
  openGenericModal: PropTypes.func,
};

const mapDispatchToProps = {
  logout: () => ({ type: 'LOGOUT' }),
  resetAllData: resetAllDataAction,
  signOut: signOut,
  openGenericModal: openGenericModalAction,
};

export default withRouter(
  connect(
    (state) => ({
      user: state.auth,
      state,
      dashboardLogoutUrl: state.commonIframe?.dashboardLogoutUrl,
    }),
    mapDispatchToProps
  )(MenuAppBar)
);
