import styled from 'styled-components';
import { font } from '../../../../App/styleVariables';

interface Block {
  styles: {
    background?: string;
    padding?: string;
    borderRadius?: string;
    boxShadow?: string;
    title?: {
      color: string;
      fontSize: string;
      fontWeight: string | number;
      textTransform: string;
      textAlign: string;
    };
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

interface LegendItemStyles {
  styles?: {
    textTransform?: string;
    textColor?: string;
    fontSize?: string;
  };
  icon?: string;
  color?: string;
}

const StyledMapWrapper = styled.div<Block>`
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  box-sizing: border-box;
  width: 100%;
  font-family: ${({ theme }) =>
    theme?.dashboard?.fontFamily || font} !important;
  background: ${({ styles }) => styles?.background};
  padding: ${({ styles }) => styles?.padding};
  border-radius: ${({ styles }) => styles?.borderRadius};
  box-shadow: ${({ styles }) => styles?.boxShadow};

  .title {
    color: ${({ styles }) => styles?.title?.color || '#000000'};
    font-size: ${({ styles }) => styles?.title?.fontSize || '18px'};
    font-weight: ${({ styles }) => styles?.title?.fontWeight || '600'};
    text-align: ${({ styles }) => styles?.title?.textAlign || 'center'};
    text-transform: ${({ styles }) => styles?.title?.textTransform || 'none'};
    margin-bottom: 15px;
  }
  .map-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .map-container {
    width: 74% !important;
    height: 410px;
    position: relative;

    .loader {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
  #mapdiv {
    width: 100%;
    height: 100%;
  }
  .legend-container {
    width: 24%;
    align-self: flex-end;
  }
`;

const SLegendItem = styled.div<LegendItemStyles>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 10px;
  text-transform: ${({ styles }) => styles?.textTransform || 'uppercase'};
  color: ${({ styles }) => styles?.textColor || '#808080'};
  font-size: ${({ styles }) => styles?.fontSize};

  &:before {
    content: ${({ icon }) => `url(${icon})` || ''};
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${({ color }) => color};
    margin-right: 15px;
  }
`;

export { StyledMapWrapper, SLegendItem };