import styled from 'styled-components';
import { font } from '../../App/styleVariables';

interface Block {
  styles: {
    background?: string;
    padding?: string;
    wrapperMarginTop?: string | number;
    not_shadow?: boolean;
    minHeight?: string;
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledWrapper = styled.div<Block>`
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  box-shadow: ${(props) =>
    props.styles?.not_shadow ? 'none' : '0 0 5px 5px rgba(0, 0, 0, 0.03)'};
  box-sizing: border-box;
  font-family: ${font} !important;
  background: ${({ theme, styles }) =>
    styles?.background || theme?.dashboard?.block?.background || '#ffffff'};
  border-radius: ${({ theme }) =>
    theme?.dashboard?.block?.borderRadius || '10px'};
  padding: ${({ theme, styles }) =>
    styles?.padding || theme?.dashboard?.block?.padding || '20px 15px'};
  min-height: ${({ styles }) => styles?.minHeight || 'auto'};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .plot-title {
    color: ${({ theme }) => theme?.dashboard?.block?.titleColor || '#000000'};
    text-transform: ${({ theme }) =>
      theme?.dashboard?.block?.titleTextTransform || 'initial'};
    font-size: 20px;
    text-align: center;
    margin-bottom: 5px;

    @media (max-width: 1440px) {
      font-size: 18px;
    }
  }
  .plot-subtitle {
    color: ${({ theme }) => theme?.dashboard?.block?.titleColor || '#000000'};
    text-transform: ${({ theme }) =>
      theme?.dashboard?.block?.titleTextTransform || 'initial'};
    font-size: 20px;
    text-align: center;
    margin-bottom: 0;

    @media (max-width: 1440px) {
      font-size: 18px;
    }
  }
  .plot-wrapper {
    margin-top: ${({ styles }) => styles?.wrapperMarginTop || '15px'};
  }
`;

export default StyledWrapper;
