import client from 'lib/apiClient';
import errorHandler from '../../lib/errorHandler';

const getChainDetailsAction = async ({ traceId, traceType, name }) => {
  try {
    const chainData = await client.getChainDetails({
      id: traceId,
      traceType,
      name,
    });

    return chainData;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Err on getChainDetailsAction', err);
    errorHandler(err);
  }
};

export { getChainDetailsAction };
