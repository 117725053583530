import styled from 'styled-components';

export const SLegendItem = styled.div<any>`
  display: flex;
  align-items: center;
  .legend-item-dot {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
    margin-right: 4px;
  }
`;
