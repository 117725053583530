import styled from 'styled-components';
import { Modal } from 'antd';
import { font } from '../../../../App/styleVariables';

interface Block {
  modal?: boolean;
  styles: {
    modal?: {
      button?: {
        color?: string;
      };
    };
    labelWrapper?: {
      display?: string;
      alignItems?: string;
      justifyContent?: string;
    };
    label?: {
      fontSize?: string;
      color?: string;
      textTransform?: string;
      fontWeight?: string | number;
      textAlign?: string;
    };
    value?: {
      fontSize?: string;
      color?: string;
      margin?: string;
      lineHeight?: string;
    };
    container?: {
      justifyContent?: string;
      flexDirection?: string;
    };
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledWrapper = styled.div<Block>`
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  align-self: stretch;
  box-sizing: border-box;
  font-family: ${({ theme }) =>
    theme?.dashboard?.fontFamily || font} !important;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  background-color: ${({ theme }) =>
    theme?.dashboard?.block?.background || '#ffffff'};
  color: ${({ theme }) => theme?.dashboard?.block?.color || '#000000'};
  text-align: center;
  padding: 20px 15px;
  border-radius: ${({ theme }) =>
    theme?.dashboard?.block?.borderRadius || '10px'};
  position: relative;

  p {
    line-height: 1.2;
    margin: 0;
  }

  .statistic-container {
    display: flex;
    flex-direction: ${({ styles }) =>
      styles?.container?.flexDirection || 'column'};
    justify-content: ${({ styles }) =>
      styles?.container?.justifyContent || 'space-evenly'};
    height: 100%;
  }

  .statistic-text-wrapper {
    display: ${({ styles }) => styles?.labelWrapper?.display};
    align-items: ${({ styles }) => styles?.labelWrapper?.alignItems};
    justify-content: ${({ styles }) => styles?.labelWrapper?.justifyContent};
  }

  .statistic-text {
    font-size: ${({ styles }) => styles?.label?.fontSize || '18px'};
    color: ${({ styles }) => styles?.label?.color};
    font-weight: ${({ styles }) => styles?.label?.fontWeight};
    text-transform: ${({ styles }) => styles?.label?.textTransform};
    text-align: ${({ styles }) => styles?.label?.textAlign};
    padding-right: ${({ modal }) => (modal ? '20px' : 0)};
  }

  .statistic-text-icon {
    margin-right: 8px;
    font-size: 20px;

    &:not(.anticon) {
      width: 24px;
      height: 24px;
    }
  }

  .statistic-value {
    font-weight: bold;
    font-size: ${({ styles }) => styles?.value?.fontSize || '24px'};
    line-height: ${({ styles }) => styles?.value?.lineHeight};
    color: ${({ styles }) => styles?.value?.color};
    margin: ${({ styles }) => styles?.value?.margin || '10px 0'};
  }

  .more-modal-btn {
    position: absolute;
    top: 16px;
    right: 10px;
    border: none;
    padding: 0 !important;
    font-size: 26px;
    line-height: 26px;
    color: ${({ styles, theme }) =>
      styles?.modal?.button?.color ||
      theme?.dashboard?.block?.color ||
      '#000000'};
    box-shadow: none;
    height: initial;
  }
`;

const StyledStatisticModal = styled(Modal)`
  width: 1050px !important;

  .ant-modal-content {
    min-height: 350px;
    padding: 40px 24px !important;
  }
`;

export { StyledWrapper, StyledStatisticModal };