import searchData from 'containers/SearchData/reducer';
import traceDetails from '../containers/TraceDetails/reducers';
import networkEvents from './network_events';
import customLists from '../sections/CustomList/reducers';
import documents from 'sections/Documents/reducers';

import trackProduct from '../sections/business_organica/TrackProduct/reducers';

import bactMudAnalysis from '../sections/business_sbnm/BactMudAnalysis/reducers';
import stocking from '../sections/business_sbnm/Stocking/reducers';

import traceup_board from '../sections/business_bk/Traceup/reducers';
import quality_board from '../sections/business_bk/Quality/reducers';

import genericModal from '../containers/GenericModal/reducer';

import auth from '../sections/Login/reducer';
import homePage from '../sections/Home/reducer';

import notifications from '../sections/Notifications/reducers';

import commonIframe from '../sections/CommonIframe/reducer'

export default {
  auth,
  networkEvents,
  searchData,
  ...trackProduct,
  ...bactMudAnalysis,
  ...stocking,
  ...traceDetails,
  ...traceup_board,
  ...quality_board,
  ...customLists,
  ...documents,
  ...notifications,
  homePage,
  genericModal,
  commonIframe,
};
