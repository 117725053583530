import styled from 'styled-components';
import { colors, font } from 'App/styleVariables';

export const SFormWrapper = styled.div`
  margin-top: 20px;
  font-family: ${font};

  .product-image {
    width: 350px;
    border-radius: 10px;
  }

  .ant-image .ant-image-mask {
    border-radius: 10px;
  }

  .status-section {
    margin-bottom: 24px;
    font-family: ${font};
    background-color: #ffffff;

    .ant-collapse-header {
      padding: 0;
    }

    .ant-collapse-content .ant-collapse-content-box {
      padding: 15px 0 0;
    }
  }

  .status-section-label {
    font-family: ${font};

    p {
      margin-bottom: 0;
      margin-right: 10px;
      font-weight: 500;
      font-size: 15px;
    }
  }

  .ant-form-item-label {
    color: #101010;
    font-weight: 500;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    &:hover,
    &:focus {
        border: ${({ theme }) => theme.form.item?.input?.borderActive} !important;
    }
  }

  .ant-descriptions {
    font-family: ${font};
  }

  .ant-descriptions-item-label {
    font-weight: 500;
    color: #101010;
  }

  .cancel-button {
    font-size: ${(props) => props.theme.form.backButton.size} !important;
    font-weight: ${(props) => props.theme.form.backButton.weight} !important;
    font-family: ${(props) => props.theme.form.backButton.family} !important;
    color: ${({ theme }) => theme.form.backButton.colorPassive} !important;
    border: ${({ theme }) => theme.form.backButton.borderPassive} !important;

    &:hover {
      color: ${({ theme }) =>
        theme.form.backButton.colorActive || colors.secondary} !important;
      border: ${({ theme }) =>
        theme.form.backButton.borderActive ||
        `1px solid ${colors.secondary}`} !important;
    }
  }

  .submit-button {
    font-size: ${(props) => props.theme.form.submitButton?.size} !important;
    font-weight: ${(props) => props.theme.form.submitButton?.weight} !important;
    font-family: ${(props) => props.theme.form.submitButton?.family} !important;
    color: ${(props) => props.theme.form.submitButton?.color} !important;
    border: none !important;
    background: ${(props) => props.theme.form.submitButton?.passive} !important;

    &:hover {
      color: ${(props) => props.theme.form.submitButton?.color} !important;
      background: ${(props) =>
        props.theme.form.submitButton?.active} !important;
    }
  }

  .ant-list-empty-text {
    .ant-empty {
      margin: 0;
    }
  }
`;