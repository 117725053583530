import React from 'react';
import { Statistic } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Area, AreaChart, ResponsiveContainer, YAxis } from 'recharts';
import { EnvironmentInterface } from '../../types';
import { SIndicatorContainer } from './styled-components';
import { ENVIRONMENT_ICONS } from '../../constants';

const Environment = ({
  bordered = false,
  title,
  styles,
  impact,
  units,
  percentage,
  graph,
  indicatorName,
  total,
  totalUnits,
  withDescription,
  impactDescription,
  graphDescription,
  percentageDescription,
}: EnvironmentInterface) => {
  const icon = ENVIRONMENT_ICONS[indicatorName];

  return (
    <SIndicatorContainer
      styles={styles}
      bordered={bordered}
      withDescription={withDescription}
    >
      <div className={'header'}>
        <img src={icon} className={'indicator-icon'} alt="Indicator icon" />
        <p className={'indicator-title'}>{title}</p>
      </div>
      {total && <p className={'total'}>{total}<span>{totalUnits}</span></p>}
      <div className={'content'}>
        <div className={'impact-wrapper'}>
          {withDescription ? (
            <p className={'impact-row with-description'}>
              Soit <span>{impact}</span> {units} {impactDescription}
            </p>
          ) : (
            <p className={'impact-row'}>
              <span>{impact}</span> {units}
            </p>
          )}
          <div className={'growth-row'}>
            <Statistic
              value={percentage?.number}
              valueStyle={{
                color:
                  percentage?.growth === 'positive'
                    ? percentage?.positiveColor || '#EB2C02'
                    : percentage?.negativeColor || '#00CEAA',
              }}
              prefix={
                percentage?.growth === 'positive' ? (
                  <ArrowUpOutlined />
                ) : (
                  <ArrowDownOutlined />
                )
              }
              suffix="%"
              className={'percentage'}
            />
            <span className={'text'}> {percentageDescription || 'sur le mois'} </span>
          </div>
        </div>
        <div className={'graph-container'}>
          <div className={'area-chart'}>
            <ResponsiveContainer>
              <AreaChart data={graph?.data}>
                <defs>
                  <linearGradient id="negative" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={percentage?.negativeColor || '#00CEAA'}
                      stopOpacity={0.5}
                    />
                    <stop
                      offset="95%"
                      stopColor={percentage?.negativeColor || '#00CEAA'}
                      stopOpacity={0}
                    />
                  </linearGradient>
                  <linearGradient id="positive" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={percentage?.positiveColor || '#EB2C02'}
                      stopOpacity={0.5}
                    />
                    <stop
                      offset="95%"
                      stopColor={percentage?.positiveColor || '#EB2C02'}
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <YAxis type="number" domain={['auto', 'auto']} hide={true} />
                <Area
                  type="monotone"
                  dataKey="x"
                  stroke={
                    graph?.growth === 'positive'
                      ? percentage?.positiveColor || '#EB2C02'
                      : percentage?.negativeColor || '#00CEAA'
                  }
                  strokeWidth={2}
                  fill={`url(#${graph?.growth})`}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          {graphDescription && (
            <p className={'graph-description'}> {graphDescription} </p>
          )}
        </div>
      </div>
    </SIndicatorContainer>
  );
};

export default Environment;
