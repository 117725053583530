import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { CardStyles } from '../../types';

export const SIndicatorContainer = styled(SIndicator)<CardStyles>`
  .ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    
    .header {
      margin-bottom: 10px;
    }
  }
  .content {
    padding-left: 20%;
    
    .wrapper {
      direction: rtl;
      padding-left: 15px;
      overflow-y: scroll;
      max-height: 155px;

      p {
        direction: ltr;
        font-size: 15px;
        margin-bottom: 10px;
        color: #808080;
      }
    }
  }
`;