import React from 'react';
import { StyledErrorPage } from './styled-components';

type Error = {
  message: string | null;
}

const ERROR_MESSAGE = 'Something went wrong. Please try later again.';

const ErrorPage = ({ message }: Error) => {
  return (
    <StyledErrorPage>
      {message || ERROR_MESSAGE}
    </StyledErrorPage>
  )
}

export default ErrorPage;