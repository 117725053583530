import { useMutation } from 'react-query';

import { MutationArguments } from '../../types';
import errorHandler from '../../../../lib/errorHandler';

const useValidateAllMutation = ({
  factory,
  client,
  name,
  page,
  searchField,
  filter,
}: MutationArguments) => {
  return useMutation(factory?.validateAll, {
    onSuccess: () => {
      client.refetchQueries([name, page, searchField, filter]);
    },
    onError: (error) => {
      if (error) {
        errorHandler(error);
      }
    },
  })
};

export default useValidateAllMutation;