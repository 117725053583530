import React, { Component } from 'react';
import { json2csv } from 'json-2-csv';
import Hotkeys from 'react-hot-keys';
import CustomModal from 'components/Modal';
import CustomListItemForm from '../CustomListItemForm';
import { connect } from 'react-redux';
import { closeModalAction } from './reducer';
import { message } from 'antd';
import ShowTraceList from '../../../../components/ShowTraceList';
import { downloadFile, prepareTraceForCsv } from '../../../../lib/util';

export const CUSTOM_MODAL_WIDTH = 900;
export const EDIT_TITLE = `Edit Item:`;
export const ADD_TITLE = `Add Item:`;
export const VIEW_TITLE = `View Item`;

export class CustomListItemModal extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  handleOnClose = () => {
    const { closeModal } = this.props;

    closeModal();
  };

  onKeyDown(keyName) {
    const { itemData } = this.props;

    if (keyName === 'shift+s') {
      const filename = `${itemData.trace_id}.csv` || 'export.csv';

      json2csv(prepareTraceForCsv(itemData), {
        delimiter: { field: ';' },
        emptyFieldValue: '',
        expandArrayObjects: true,
        excludeKeys: ['trace_id', 'id', 'updated_at', 'created_at'],
      })
        .then((csv) => {
          downloadFile(csv, filename);
          message.success('File downloaded');
        })
        .catch((err) => message.error(`${err.name}: ${err.message}`));
    }
  }

  render() {
    const { isEdit, isModalOpen, isCommonItem, itemData } = this.props;

    const CustomListModalRef = React.forwardRef((props, ref) => (
      <CustomModal
        title={isCommonItem ? VIEW_TITLE : isEdit ? EDIT_TITLE : ADD_TITLE}
        closeModal={this.handleOnClose}
        modalVisible={isModalOpen}
        width={CUSTOM_MODAL_WIDTH}
      >
        {isModalOpen ? (
          isCommonItem ? (
            <Hotkeys keyName="shift+s" onKeyDown={this.onKeyDown.bind(this)}>
              <ShowTraceList data={itemData} modalWidth={CUSTOM_MODAL_WIDTH} />
            </Hotkeys>
          ) : (
            <CustomListItemForm {...this.props} />
          )
        ) : null}
      </CustomModal>
    ));

    return <CustomListModalRef ref={this.modalRef} />;
  }
}

const ConnectedModal = connect(
  ({ customListModal }) => ({
    isModalOpen: customListModal.isModalOpen,
    isEdit: customListModal.isEdit,
    isCommonItem: customListModal.isCommonItem,
    itemData: customListModal.itemData,
    title: customListModal.title,
  }),
  {
    closeModal: closeModalAction,
  }
)(CustomListItemModal);

export default ConnectedModal;
