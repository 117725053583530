import { FC } from 'react';

interface InfoBadgeProps {
  color: string;
}

const InfoBadge: FC<InfoBadgeProps> = ({ color = '#000000' }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="info-badge"
    >
      <circle cx="7.5" cy="7.5" r="5.625" stroke={color} strokeWidth="1" />
      <path
        d="M7.8125 4.6875C7.8125 4.86009 7.67259 5 7.5 5C7.32741 5 7.1875 4.86009 7.1875 4.6875C7.1875 4.51491 7.32741 4.375 7.5 4.375C7.67259 4.375 7.8125 4.51491 7.8125 4.6875Z"
        fill={color}
        stroke={color}
      />
      <path d="M7.5 10.625V6.25" stroke={color} />
    </svg>
  );
};

export default InfoBadge;
