import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { cloneDeep } from 'lodash';
import StyledWrapper from './styled-component';

type PlotData = {
  data: Array<any>;
  layout: any;
  frames: Array<any>;
};
interface Props {
  key?: number;
  data: PlotData;
  name?: string;
  subname?: string;
  responsive?: boolean
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const CommonPlot = ({ styles, name, subname, data, responsive = true }: Props) => {
  const [plotData, setPlotData] = useState(null);
  const [layout, setLayout] = useState(null);
  const [revision, setRevision] = useState(0);

  useEffect(() => {
    const cloneData = cloneDeep(data?.data);
    const cloneLayout = cloneDeep(data?.layout);
    setPlotData(cloneData);
    setLayout({ ...cloneLayout, datarevision: revision + 1 });
    setRevision(revision + 1);
    // eslint-disable-next-line
  }, [data]);

  return (
    <StyledWrapper styles={styles}>
      {name && <p className={'plot-title'}> {name} </p>}
      {subname && <p className={'plot-subtitle'}> {subname} </p>}
      {plotData && (
        <Plot
          data={plotData}
          revision={revision}
          layout={layout}
          // frames={frames}
          className={'plot-wrapper'}
          style={{ width: '100%' }}
          config={{
            displayModeBar: false,
            responsive: responsive,
          }}
        />
      )}
    </StyledWrapper>
  );
};

export default CommonPlot;
