import styled from 'styled-components';
import { Row } from 'antd';

export const SSplitScreen = styled(Row)`
  height: 100vh;
  
  .content-container {
    height: 100%;
    background-color: #ffffff;
  }
`;

export const SBackgroundBlock = styled.div<{ image: string }>`
  width: 100%;
  height: 100%;
  background-image: ${({ image }) => `url(${image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  
  .logo {
    align-self: flex-start;
    display: block;
    margin-top: 10vh;
    max-width: 490px;
    width: 80%;
  }
`;
