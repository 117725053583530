import React, { useCallback, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import { EntityRow } from './components';
import { COLORS } from '../../constants';
import { ComplianceInterface } from '../../types';
import { SIndicatorContainer } from './styled-components';

const Compliance = ({ styles, bordered, data }: ComplianceInterface) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const onClick = useCallback((data, index) => {
    setActiveIndex(index);
  }, []);

  const { name, total, ...entities } = data[activeIndex];

  const renderActiveShape = (props: {
    cx: number;
    cy: number;
    innerRadius: number;
    outerRadius: number;
    startAngle: number;
    endAngle: number;
    fill: string;
    payload: any;
  }) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
    } = props;
    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={fill}
          className={'active-label'}
        >
          {payload.total}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          className={'active'}
        />
      </g>
    );
  };

  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <p className={'indicator-title'}>Certification des entités</p>
      <div className={'content-wrapper'}>
        <div className={'chart'}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                activeIndex={activeIndex}
                data={data}
                dataKey="total"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius="97%"
                innerRadius="60%"
                fill="#8884d8"
                onClick={onClick}
                activeShape={renderActiveShape}
              >
                {data?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    style={{ cursor: 'pointer' }}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className={'info'}>
          <p className={'sector-title'}>{data[activeIndex]?.name}</p>
          {Object.keys(entities)?.map((name: string) => (
            <EntityRow
              key={name}
              name={name}
              value={entities[name]}
              activeIndex={activeIndex}
              entities={entities}
            />
          ))}
        </div>
      </div>
    </SIndicatorContainer>
  );
};

export default Compliance;
