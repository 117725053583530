import AbstractResourceFactory from './abstract-resource-factory';
import client from '../../../../../lib/apiClient';
import { Table } from '../../../types';

class SmsResourceFactory implements AbstractResourceFactory {
  async getAll(...args: any[]): Promise<Table> {
    const [sms, table_scheme] = await Promise.all([
      client.getSms(),
      client.getSmsTable(),
    ]);

    return {
      data: sms,
      schema: table_scheme,
      total: sms.length,
    };
  }
}

export default SmsResourceFactory;
