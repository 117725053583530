import styled from 'styled-components';
import { font } from '../../../../App/styleVariables';

interface Block {
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledStatistic = styled.div<Block>`
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  box-sizing: border-box;
  border-left: 15px solid;
  font-family: ${font};
  border-image: linear-gradient(to bottom, #85b725, #317c15) 1;
  background-color: #ffffff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  padding: 20px 30px 20px 50px;
  line-height: 1.2;
  min-height: 140px;

  @media (max-width: 1440px) {
    min-height: 130px;
    padding: 20px 20px 20px 30px;
  }

  p {
    margin: 0;
    padding: 0;
    line-height: inherit;
  }
  .circle-star {
    background-image: linear-gradient(to right, #85b725, #317c15);
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #ffffff;
    font-size: 40px;
  }
  .content {
    margin-left: 30px;
  }
  .statistic {
    line-height: 1;

    .ant-statistic-content {
      font-size: 42px;
      color: #85b725;
    }
    .ant-statistic-content-value {
      font-weight: bold;
    }
  }
  .subtitle {
    color: ${({ theme }) =>
      theme?.dashboard?.block?.sectionTextColor || '#8c8c8c'};
    font-size: 22px;

    @media (max-width: 1440px) {
      font-size: 20px;
    }
  }
`;

export default StyledStatistic;
