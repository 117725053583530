import React from 'react';
import { connect } from 'react-redux';
import {
  get_board_data_action,
  set_initial_state_action,
  set_no_errors_action
} from './reducer';
import ControlPanel from '../../components/ControlPanel';
const src = process.env.REACT_APP_BK_TRACEUP_URL;

class TraceUpBoard extends React.PureComponent {
  type = 'json';

  componentWillUnmount() {
    const { set_initial_state } = this.props;
    set_initial_state();
  }

  handleData = ({ numerolot }) => {
    const { get_board_data } = this.props;
    const { type } = this;
    get_board_data({ numerolot, type });
  };

  handleType = type => () => (this.type = type);

  handleErrors = () => {
    const { set_no_errors } = this.props;
    set_no_errors();
  };

  render() {
    const { data, loading, error } = this.props;

    return (
      <div style={{ background: 'white' }}>
        <ControlPanel
          handleData={this.handleData}
          handleType={this.handleType}
          handleErrors={this.handleErrors}
          error={error}
        />
        {!loading && !error.is_error && data && src && (
          <iframe
            title="Trace Up"
            src={src}
            width="100%"
            height="1000"
            frameBorder="0"
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  get_board_data: get_board_data_action,
  set_initial_state: set_initial_state_action,
  set_no_errors: set_no_errors_action
};

const mapStateToProps = ({ traceup_board }) => ({
  data: traceup_board.data,
  loading: traceup_board.loading,
  error: traceup_board.error
});

export default connect(mapStateToProps, mapDispatchToProps)(TraceUpBoard);
