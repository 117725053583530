import { FC } from 'react';
import { Button, Flex, Form, List, Select } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import Badge from 'components/Badge';
import { SListItem } from './styled-components';

const FilesList: FC<any> = ({ list, fieldName, isEdit }) => {
  const form = Form.useFormInstance();

  const viewFile = (url) => {
    window.open(url, '_blank');
  };

  const getBadgeColors = (status) => {
    switch (status) {
      case 'Validated':
        return {
          background: '#e6faf2',
          text: '#09cf7d',
        };
      case 'Refused':
        return {
          background: '#ffeded',
          text: '#FF4D4D',
        };
      default:
        return;
    }
  };

  return (
    <List
      className="files-list"
      itemLayout="horizontal"
      dataSource={list}
      split={false}
      renderItem={(item: any, index: number) => {
        const defaultActions = [
          <Button
            key="list-view-file"
            onClick={() => viewFile(item.url)}
            className="list-view-file"
          >
            View
          </Button>,
        ];

        const actions = isEdit
          ? [
              ...defaultActions,
              <Form.Item name={[fieldName, index, 'status']}>
                <Select
                  key="list-select-status"
                  options={[
                    { value: 'Validated', label: 'Validate' },
                    {
                      value: 'Validation pending',
                      label: 'Validation pending',
                    },
                    { value: 'Refused', label: 'Refuse' },
                  ]}
                  className="list-select-status"
                />
              </Form.Item>,
            ]
          : defaultActions;
          
        return (
          <SListItem actions={actions}>
            <List.Item.Meta
              avatar={<FileOutlined />}
              title={
                <Flex align="center">
                  <p className="list-item-title">{item.name}</p>
                  <Badge
                    title={form.getFieldValue(fieldName)[index].status}
                    customColor={getBadgeColors(
                      form.getFieldValue(fieldName)[index].status
                    )}
                  />
                </Flex>
              }
            />
          </SListItem>
        );
      }}
    />
  );
};

export default FilesList;