import React from 'react';
import { Statistic } from 'antd';
import { StarOutlined } from '@ant-design/icons';
import StyledStatistic from './styled-component';


interface Props {
  value: number | string;
  label: string;
  total?: number | string | undefined;
  key: number;
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const UnmergedStatistic = ({ value, label, total = 100, styles, key }: Props) => {
  return (
    <StyledStatistic styles={styles} key={key}>
      <div className={'circle-star'}>
        <StarOutlined />
      </div>
      <div className={'content'}>
        <Statistic value={value} suffix={`/${total}`} className={'statistic'} />
        <p className={'subtitle'}> { label } </p>
      </div>
    </StyledStatistic>
  );
};

export default UnmergedStatistic;
