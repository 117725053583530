const MODAL_ACTION = 'TRACK_PRODUCT::ACTION';

export const openModalAction = data =>
  ({ type: MODAL_ACTION, payload: { isModalOpen: true, ...data } });


export const closeModalAction = () =>
  ({ type: MODAL_ACTION, payload: { isModalOpen: false } });

const handlers = {
  [MODAL_ACTION]: (state, action) =>
    ({ ...action.payload }),
};

export default (state = { isModalOpen: false }, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};
