const LIGHT_ORANGE = '#fe9800';
const DARK_ORANGE = '#fe5722';
const YELLOW = '#e3d912';
const LIGHT_YELLOW = '#ffc163';
const DARK_YELLOW = '#fec007';
const GREEN = '#2eda36';
const LIGHT_GREEN = '#74f279';
const DARK_GREEN = '#08400a';
const MARSH = '#5b785c';
const BLUE = '#2196f3';
const LIGHT_BLUE = '#3fddf2';
const TURQUOISE = '#3FE0D0';
const TEAL = '#018081';
const PINK = '#de5b8d';
const DARK_PINK = '#e81e63';
const PURPLE = '#b21cc9';
const VIOLET = '#673ab7';
const LIGHT_VIOLET = '#ab7bff';
const RED = '#f34336';
const BROWN = '#a14822';
const GRAY = '#607d8b';
const LIGHT_GRAY = '#ccdce3';
const BLACK = '#171517';

const DEFAULT_COLORS = [
  LIGHT_ORANGE,
  DARK_ORANGE,
  GREEN,
  BLUE,
  GRAY,
  VIOLET,
  DARK_YELLOW,
  DARK_PINK,
  LIGHT_GRAY,
  PURPLE,
  BLACK,
  YELLOW,
  TEAL,
  RED,
  LIGHT_GREEN,
  MARSH,
  DARK_GREEN,
  TURQUOISE,
  LIGHT_VIOLET,
  LIGHT_YELLOW,
  BROWN,
  PINK,
  LIGHT_BLUE,
];

export const getTraceTypeColors = (graphScheme: {
  [key: string]: { color?: string };
}): { [key: string]: string } => {
  let result = {};
  const colors: string[] = DEFAULT_COLORS.concat();

  const types: string[] = Object.keys(graphScheme).filter(
    (type: string) => type !== 'graph_options'
  );

  types.forEach((type: string): void => {
    result[type] =
      graphScheme[type].color || colors?.splice(0, 1)[0] || '#808080';
  });

  return result;
};
