import { FC, ReactNode, useState } from 'react';
import { Popover } from 'antd';
import PopoverContent from './components/PopoverContent';

interface InfoPopoverProps {
  title?: string;
  triggerElement: ReactNode;
  triggerAction?: 'click' | 'hover' | 'focus';
  content: any;
  type?: 'dot_legend';
}

const InfoPopover: FC<InfoPopoverProps> = ({
  title,
  triggerElement,
  triggerAction = 'click',
  content,
  type,
}) => {
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={
        <PopoverContent
          content={content}
          type={type}
          closeFn={close}
          displayClose={triggerAction !== 'hover'}
        />
      }
      title={title}
      trigger={triggerAction}
      open={open}
      onOpenChange={handleOpenChange}
    >
      {triggerElement}
    </Popover>
  );
};

export default InfoPopover;
