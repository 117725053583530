// Modal types
export const USERS_MODAL = 'users';
export const COMPANIES_MODAL = 'companies';
export const CUSTOMLIST_MODAL = 'custom_list';
export const TRACES_MODAL = 'traces';
export const CHAINS_MODAL = 'chains';
export const SMS_MODAL = 'sms_modal';
export const PRODUCTS_MODAL = 'products';
export const GALLERY_MODAL = 'gallery';
export const DOCUMENTS_MODAL = 'documents';
export const QR_CODE_MODAL = 'qr_code';
export const UPLOAD_MODAL = 'upload';
export const FILE_UPLOAD_MODAL = 'file_upload';
export const HISTORY_MODAL = 'history';
export const HISTORY_VERSION_MODAL = 'history_version';
export const BUSINESS_PICKER_MODAL = 'business_picker';
export const SUPPORT_MODAL = 'support';

export const supportModal = 'SUPPORT_MODAL';
export const businessPickerModal = 'BUSINESS_PICKER_MODAL';
export const sendEmailFormModal = 'SEND_EMAIL_FORM_MODAL';
export const conformityFormModal = 'CONFORMITY_FORM_MODAL';
export const contactSupplierModal = 'CONTACT_SUPPLIER_MODAL';
export const industrialProcessModal = 'INDUSTRIAL_PROCESS_MODAL';
