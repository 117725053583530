import dayjs from 'dayjs';

class FilterHandler {
  constructor() {
    this._sorter = new Map();
    this._opposite = { ASC: 'DESC', DESC: 'ASC' };
  }
  get_sorting = field => {
    if (!field) return;
    if (this._sorter.has(field)) this._toggle_sorting(field);
    else this._handle_adding(field);

    return this._present_filter();
  };

  get_range = ({ from, to, format = 'DD-MM-YYYY' }) => ({
    gte: dayjs(from).format(format),
    lte: dayjs(to).format(format)
  });

  _toggle_sorting = field => {
    const current = this._sorter.get(field);
    this._sorter.set(field, this._opposite[current]);
    const sorting = Array.from(this._sorter.entries());
    sorting.unshift([field, this._sorter[field]]);
    this._sorter = new Map(sorting);
  };

  _handle_adding = field => {
    const sorting = Array.from(this._sorter.entries());
    sorting.unshift([field, 'ASC']);
    this._sorter = new Map(sorting);
  };

  _present_filter = () =>
    Array.from(this._sorter.entries()).map(([field_name, direction]) => ({
      field_name,
      direction
    }));
}

export default FilterHandler;
