import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App/App';
import { is_forbidden } from './lib/util';
import './index.css';
import registerServiceWorker from './registerServiceWorker';

const app = is_forbidden() ? <div /> : <App />;
const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(app);
registerServiceWorker();

// if (module.hot) {
//   module.hot.accept('./index', () => {
//     render(root, document.getElementById('root'));
//   });
// }
