import React from 'react';
import GenericTablePage from "../../sections/GenericTablePage";
import { HISTORY_DETAILS_TABLE } from "../../sections/GenericTablePage/constants";
import {Spin} from "antd";

type Props = {
  traceId: string;
};

const HistoryDetails: React.FC<Props> = ({ traceId }: Props) => {
  return traceId ? (
    <GenericTablePage
      pageName={HISTORY_DETAILS_TABLE}
      traceId={traceId}
      withWrapper={false}
    />
  ) : (
    <Spin style={{ display: 'block' }} size="large" />
  );
};

export default HistoryDetails;
