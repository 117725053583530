import React, { useRef } from 'react';
import { DatePicker } from 'antd';
import { Wrapper } from './style';
import { Dayjs } from 'dayjs';

const { RangePicker } = DatePicker;

type Props = {
  setSelectedKeys: Function;
  selectedKeys: [Dayjs, Dayjs] | null;
  confirm: Function;
  name: string;
};

const Range = ({ setSelectedKeys, selectedKeys, confirm, name }: Props) => {
  const rangePickerRef = useRef(null);

  return (
    <Wrapper>
      <RangePicker
        name={name}
        size="large"
        value={selectedKeys}
        onChange={(dates) => {
          setSelectedKeys(dates);
          confirm();
        }}
        ref={(el) => {
          rangePickerRef.current = el;
          if (rangePickerRef.current) {
            rangePickerRef.current.focus();
          }
        }}
      />
    </Wrapper>
  );
}


export default Range;
