import styled from 'styled-components';
import { SIndicator } from '../../styled-components';

export const SIndicatorContainer = styled(SIndicator)`
  .ant-card-body {
    width: 100%;
    height: 100%;
    color: #101828;
  }

  .content-wrapper {
    display: flex;
    flex-flow: row nowrap;
  }

  .chart {
    width: 40%;
    height: 180px;
  }

  .active-label {
    font-weight: 600;
    font-size: 30px;
  }

  .recharts-sector.active {
    filter: drop-shadow(0px 0px 7px #7d7d7d);
  }

  .info {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1440px) {
      width: 70%;
    }

    .entity-item {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
    }

    .entity-label {
      display: flex;
      justify-content: flex-end;
      width: 60%;

      .traces-info {
        font-size: 14px;
        text-align: right;

        @media (max-width: 1550px) {
          font-size: 12px;
        }
      }

      img {
        margin: 0 10px;

        @media (max-width: 1550px) {
          margin: 0 5px;
          width: 25px;
        }
      }
    }

    .graph-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 40%;

      @media (max-width: 1440px) {
        width: 30%;
      }

      .ant-progress {
        width: 85%;
        margin-bottom: 0;
      }

      .value {
        font-size: 20px;
        font-weight: 600;

        @media (max-width: 1550px) {
          font-size: 16px;
        }
      }
    }
  }

  .sector-title {
    margin: 8px 0;
    text-align: center;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 600;

    @media (max-width: 1550px) {
      font-size: 14px;
    }
  }
`;
