import styled from 'styled-components';
import { getBadgeColor } from './utils';
import { BadgeType } from './badge';
import { font } from 'App/styleVariables';

interface BadgeStyles {
  type?: BadgeType;
  $star?: boolean;
  $customColor?: {
    background: string;
    text?: string;
  };
}

export const SBadge = styled.div<BadgeStyles>`
  background-color: ${({ type, $customColor }) =>
    $customColor?.background || getBadgeColor(type).background};
  border-radius: 10px;
  color: ${({ type, $customColor }) =>
    $customColor?.text || getBadgeColor(type).text};

  font-size: 12px;
  padding: 4px 12px;
  white-space: nowrap;
  width: fit-content;
  height: 24px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${font};
  font-weight: 400;
  position: relative;
  overflow: ${({ $star }) => $star && 'visible !important'};

  .badge-icon {
    margin-right: 4px;

    &:not(.anticon) {
      width: 16px;
      height: 16px;
    }

    path {
      fill: ${({ type, $customColor }) =>
        $customColor?.text || getBadgeColor(type).text};
    }
  }
  
  .badge-star {
    position: absolute;
    right: -5px;
    top: 0;
  }
`;
