import * as yup from 'yup';
import { InitialValuesInterface } from '../../types';

const emailNotLongEnough = 'the field must contain at least 1 character';
const passwordNotLongEnough = 'password must be at least 8 characters';
const emailIsRequired = 'this is a required field';
// const invalidEmail = 'email must be valid';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .min(1, emailNotLongEnough)
    .max(255)
    .required(emailIsRequired),
});

const validationSchemaWithPassword = yup.object().shape({
  email: yup
    .string()
    .min(1, emailNotLongEnough)
    .max(255)
    .required(emailIsRequired),
  password: yup.string().min(8, passwordNotLongEnough).max(255).required(),
});

const initialValues: InitialValuesInterface = {
  email: '',
  password: '',
};

const handleLogin = async (values, signInUser): Promise<void> => {
  try {
    await signInUser(values);
  } catch (err) {
    if (err.message === 'Request failed with status code 401') {
      alert(JSON.stringify('Invalid Credentials', null, 2));
    } else if (err.message === 'Network Error') {
      alert(JSON.stringify('Network error', null, 2));
    } else {
      alert(JSON.stringify('Unknown error', null, 2));
    }
  }
};

export {
  validationSchema,
  validationSchemaWithPassword,
  handleLogin,
  initialValues,
};
