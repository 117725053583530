import styled from 'styled-components';

const SComposition = styled.div`
  max-height: 140px;
  padding: 1px 0px 5px 0px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  overflow: hidden;
  margin-bottom: 0px !important;

  div:first-child {
    display: inline-block;
    justify-content: flex-start;
    margin-right: 27px;
    margin-bottom: 0px !important;
  }

  div:last-child {
    display: block;
    justify-content: flex-start;
    width: 100%;
    margin-right: 20px;
    margin-bottom: 5px !important;
  }
`;

const StyledLine = styled.div`
  background-color: rgb(237, 233, 228);
  margin: 10px 20px 10px 0;
  height: 1px;
`;

export { SComposition, Wrapper, StyledLine };

export default {};
