import { FC } from 'react';
import GenericTablePage from 'sections/GenericTablePage';
import { DASHBOARD_BACKEND_TABLE } from 'sections/GenericTablePage/constants';
import StyledTableWrapper from './styled-component';

interface TableProps {
  table_name: string;
  scroll?: object;
  isSearchEnabled?: boolean;
  searchButtonText?: boolean;
  searchPlaceholder?: string;
  title?: string;
  subtitle?: string;
  image?: string;
  indicators?: string[];
  download?: boolean;
  downloadButtonPosition?: 'top' | 'bottom' | undefined;
  bordered?: boolean;
  exportButtonText?: string;
  exportFilename?: string;
  exportFullData?: boolean;
  businessName?: string;
  options: {
    expandArrayObjects: boolean;
    unwindArrays: boolean;
    excludeKeys: string[];
  };
  footerDescription?: string;
  backendPaginated?: boolean;
  pageSchema: any;
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const BackendPaginatedTable: FC<TableProps> = ({
  styles,
  isSearchEnabled = false,
  searchButtonText = true,
  searchPlaceholder,
  title,
  subtitle,
  image,
  indicators,
  bordered = false,
  businessName,
  footerDescription,
  pageSchema,
  table_name,
}) => {
  const imageSrc = `${process.env.REACT_APP_BUCKET_NAME}/${businessName}/public/images/${image}`;

  return (
    <StyledTableWrapper
      styles={styles}
      $bordered={bordered}
    >
      {title && (
        <div className={'header'}>
          {title && (
            <div>
              <div className={'title-wrapper'}>
                {image && (
                  <img
                    src={imageSrc}
                    className={'title-icon'}
                    alt="Table icon"
                  />
                )}
                <p className={'title'}>{title}</p>
              </div>
              {subtitle && <p className={'subtitle'}>{subtitle}</p>}
            </div>
          )}
          {indicators && (
            <div className={'indicators-wrapper'}>
              {indicators.map((item, index) => (
                <p key={`indicator-${index}`} className={'indicator'}>
                  {item}
                </p>
              ))}
            </div>
          )}
        </div>
      )}
      <GenericTablePage
        pageName={`${DASHBOARD_BACKEND_TABLE}_${table_name}`}
        withWrapper={false}
        pageSchema={{
          path: pageSchema.path,
          options: {
            table_name,
          },
        }}
        isSearchBarEnabled={isSearchEnabled}
        searchPlaceholder={searchPlaceholder}
        searchButtonText={searchButtonText}
      />
      <div className={'footer'}>
        {footerDescription && (
          <p className="footer-description">{footerDescription}</p>
        )}
      </div>
    </StyledTableWrapper>
  );
};

export default BackendPaginatedTable;
