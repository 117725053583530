import Home from './Home';
import { LoginPage, LoginCallbackPage } from './Login';
import SideBar from './SideBar';
import TopBar from './TopBar';
import Documents from './Documents';
import CommonIframe from './CommonIframe';
import ResetPassword from './ResetPassword';
import CommonDashboard from './CommonDashboard';
import Analytics from './Analytics';
import ForgotPassword from './ForgotPassword';
import ConfirmEmail from './ConfirmEmail';
// import CustomList from './CustomList';
import Cockpit from './Cockpit';

import business_organica from './business_organica';
import business_sbnm from './business_sbnm';
import business_bk from './business_bk';
import business_chanel from './business_chanel';

import GenericTablePage from './GenericTablePage';

export default {
  Home,
  LoginPage,
  LoginCallbackPage,
  SideBar,
  TopBar,
  Documents,
  CommonIframe,
  ...business_organica,
  ...business_sbnm,
  ...business_bk,
  ...business_chanel,
  GenericTablePage,
  ResetPassword,
  CommonDashboard,
  Analytics,
  ForgotPassword,
  ConfirmEmail,
  Cockpit,
};
