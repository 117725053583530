import styled from 'styled-components';
import { Button } from 'antd';
import { borderRadius, colors, transition } from 'App/styleVariables';
import image from './squares_bw.png';

export const StyledButton = styled(Button)`
  margin-right: 32px;
  margin-top: 16px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const GridSize = styled.div`
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;

  span.filter-label {
    font-weight: bold;
    margin-right: 8px;
    font-size: 14px;
    font-family: ${({ theme }) => theme.gallery?.family};
    color: ${({ theme }) => theme.gallery?.label?.color || colors.primary};
  }

  .ant-select:not(.ant-select-disabled) {
    transition: all 0.2s;

    .ant-select-selector {
      height: 36px;
      border-radius: ${borderRadius};
      border: ${({ theme }) =>
        theme.gallery?.filter?.borderPassive || '1px solid #d9d9d9'};
      padding-inline-end: 15px;
      font-family: ${({ theme }) => theme.gallery?.family};
      transition: ${transition};
    }

    &:hover {
      .ant-select-selector {
        border: ${({ theme }) =>
          theme.gallery?.filter?.borderActive ||
          `1px solid ${colors.secondary}`} !important;
      }
    }

    &.ant-select-focused {
      .ant-select-selector {
        border: ${({ theme }) =>
          theme.gallery?.filter?.borderActive ||
          `1px solid ${colors.secondary}`} !important;
        box-shadow: ${({ theme }) => `0 0 0 2px ${theme.gallery?.filter?.boxShadowActiveColor}`} !important;
      }
    }

    .ant-select-selection-overflow-item {
      max-width: 55%;
    }

    .ant-select-selection-item {
      font-size: 12px;
    }
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    ).ant-select-open
    .ant-select-selector {
    box-shadow: none;
    border: ${({ theme }) =>
      theme.gallery?.filter?.borderPassive || '1px solid #d9d9d9'};

    &:hover {
      border: ${({ theme }) =>
        theme.gallery?.filter?.borderActive ||
        `1px solid ${colors.secondary}`} !important;
    }
  }

  .size-filter {
    height: 36px;

    .ant-select-selector {
      font-family: ${({ theme }) => theme.gallery?.family};
      border-radius: ${borderRadius};
      border: ${({ theme }) =>
        theme.gallery?.filter?.borderPassive || '1px solid #d9d9d9'};
      transition: ${transition};

      &:hover {
        border: ${({ theme }) =>
          theme.gallery?.filter?.borderActive ||
          `1px solid ${colors.secondary}`} !important;
      }
    }
  }

  .size-filter .ant-select-selection__clear,
  .size-filter .ant-select-arrow {
    right: 0;
    margin-right: 4px;
  }

  .ant-select-selection-selected-value,
  .ant-select-selection-item {
    padding-inline-end: 12px !important;
    font-size: 13px;
  }

  .company-filter {
    width: 280px;
  }

  @media (max-width: 1350px) {
    .ant-select-selection--multiple
      .ant-select-selection__choice:not(
        .ant-select-selection__choice__disabled
      ) {
      padding: 0 15px 0 5px;
    }
    .ant-select-selection__choice__disabled {
      padding: 0 5px;
    }
    .ant-select-selection__choice__remove {
      margin-right: 5px;
    }
    .company-filter {
      width: 252px;
    }
  }
`;

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const FilesViewWrapper = styled.div`
  background-image: url(${image});
  background-position: bottom left;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  width: 70%;
`;

export const Miniatures = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: space-around;

  .file-preview-name {
    font-family: ${(props) => props.theme.gallery?.family} !important;
  }
`;

export const FileInfoContainer = styled.div`
  height: 93%;
  overflow-y: scroll;
  padding-right: 4px;
`;
