import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getFileExtensionFromUrl, getImageUrl } from 'containers/Gallery/util';

import paper from 'containers/Gallery/resource/paper.png';

export const getPreview = (url, size = 'medium', truncated = true) => {
  const fileExtension = getFileExtensionFromUrl(url);
  const src = getImageUrl(fileExtension, url);

  return (
    <div className={'file-preview-wrapper'}>
      <LazyLoadImage
        placeholderSrc={paper}
        alt={'File preview'}
        src={src} // use normal <img> attributes as props
        className={`file-preview-img file-preview-img-${
          truncated ? size : size + '_nontruncated'
        }`}
      />
    </div>
  );
};
