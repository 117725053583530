import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from '../../store/nextStore';
import { Config } from 'config.js';
import client from 'lib/apiClient';
import { setDashboardLogoutUrlAction } from './reducer';
import errorHandler from '../../lib/errorHandler';

const WIDTH = '100%';
const HEIGHT = '100%';
const TITLE = 'Common Frame';

const CommonIframe = ({
  frame: { src, width, height, title, fetchUrl, authentication },
}) => {
  const [iframeSrc, setIframeSrc] = useState(null);

  useEffect(() => {
    if (fetchUrl) {
      const { API_URL } = Config;
      const DASHBOARD_URL = `${API_URL}${fetchUrl}`;
      fetch(DASHBOARD_URL).then((response) => response.json());
    }
  }, [fetchUrl]);

  useEffect(() => {
    if (authentication && src) {
      client
        .doDashboardAuth(encodeURIComponent(src))
        .then(({ url, url_logout }) => {
          setIframeSrc(url);
          url_logout && store.dispatch(setDashboardLogoutUrlAction(url_logout));
        })
        .catch((err) => {
          //TODO define error management
          console.log('err', err);
          errorHandler(err);
        });
    } else {
      setIframeSrc(src);
    }
  }, [authentication, src]);

  return (
    <div style={{ background: '#ffffff', height: 'calc(100vh - 83px)' }}>
      {iframeSrc ? (
        <iframe
          title={title || TITLE}
          src={iframeSrc}
          width={width || WIDTH}
          height={height || HEIGHT}
          frameBorder="0"
        />
      ) : null}
    </div>
  );
};

CommonIframe.propTypes = {
  /**
   * An object that contains all the settings for the iframe. The `src` field is required. Component will return 'null' without `src`.
   */
  frame: PropTypes.shape({
    src: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    title: PropTypes.string,
    fetchUrl: PropTypes.string,
  }).isRequired,
};

export default CommonIframe;
