import styled from 'styled-components';

const SWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .trace-logo {
    max-width: 265px;
    margin: 10vh 0 20vh;
  }
`;

export { SWrapper };
