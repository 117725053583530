import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { CardStyles } from '../../types';

interface EntityStyles extends CardStyles {
  tracedVersion?: boolean;
}

export const SIndicatorContainer = styled(SIndicator)`
  .ant-card-body {
    height: 100%;
  }
  .indicator-title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    span {
      font-size: 36px;
      margin-right: 10px;
      cursor: pointer;
      font-weight: 600;

      @media (max-width: 1600px) {
        font-size: 30px;
      }
    }
  }
  .entities-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    row-gap: 20px;
    width: 100%;
  }
`;

export const SEntity = styled.div<EntityStyles>`
  display: flex;
  flex-flow: row nowrap;
  width: ${(props) => props?.tracedVersion ? '100%' : '45%'};
  overflow: hidden;

  &:nth-child(2n + 1) {
    width: ${(props) => props?.tracedVersion ? '100%' : '54%'};
  }

  .total-container {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1600px) {
      margin-right: 8px;
    }
    @media (max-width: 1440px) {
      margin-right: 4px;
    }
  }
  .entity-icon {
    margin-bottom: 6px;

    @media (max-width: 1600px) {
      width: 25px;
    }
  }
  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title {
    font-size: 16px;
    color: #808080;
    text-transform: uppercase;
    font-weight: 400;
    padding-top: 6px;
    margin-bottom: 10px;

    @media (max-width: 1600px) {
      font-size: 14px;
    }
    @media (max-width: 1440px) {
      font-size: 13px;
    }
  }
  .total-number {
    font-size: 22px;
    font-weight: 500;
    text-align: center;

    @media (max-width: 1600px) {
      font-size: 18px;
    }
  }
  .entities-numbers {
    display: flex;
    flex-flow: row nowrap;
    
    .devider {
      margin: 0 3px;
    }
  }
  .qty {
    font-size: 22px;
    cursor: pointer;
    margin-right: 14px;
    display: flex;
    align-items: center;
    color: #00ceaa;
    line-height: 1.2;

    @media (max-width: 1600px) {
      font-size: 18px;
    }
    &.traced {
      font-size: 18px;
      font-weight: 500;
      margin-right: 0;
      
      &.total {
        color: #314BAA;
      }
      
      @media (max-width: 1400px) {
        font-size: 16px;
      }
    }

    img {
      margin-right: 2px;
    }
  }
`;
