import styled from 'styled-components';
import { Result } from 'antd';
import { colors, font } from 'App/styleVariables';

export const SResult = styled(Result)`
  font-family: ${font};

  .ant-result-title {
    font-size: 18px;
  }

  .ant-result-icon > .anticon {
    font-size: 50px;
    color: ${colors.significant};
  }
`;

export const SWrapper = styled.div`
  .ant-spin {
    font-family: ${font};

    .ant-spin-text {
      padding-top: 40px !important;
      font-size: 14px;
      color: #101828;
    }
  }

  .chains-wrapper {
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
