import _ from 'lodash';

class WidthHandler {
  constructor(default_settings) {
    this.default_settings = default_settings;
  }

  get_widths() {
    throw new Error('get_widths method should be defined');
  }

  get_max(arr) {
    return _.maxBy(arr, str => str && str.length).length;
  }

  to_percent(n, total) {
    return `${Math.floor((n * 100) / total)}%`;
  }
}

export default WidthHandler;
