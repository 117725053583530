import AbstractResourceFactory from './abstract-resource-factory';
import client from '../../../../../lib/apiClient';
import { Table, TableFilters } from '../../../types';
import formErrorHandler from '../../formErrorHandler';

class UploadResourceFactory implements AbstractResourceFactory {
  defaultFilterState = {
    sorting: '%5B%5D',
    lines_per_page: 10,
  };
  async getAll(
    page = 1,
    searchField = '',
    filters: TableFilters,
    path,
    filter_params
  ): Promise<Table> {
    const uploads = await client.getTraceUploadAPI({
      query: searchField,
      ...filters,
      page_number: page,
      filters:
        filters?.filters ||
        encodeURIComponent(
          JSON.stringify({ upload_type: filter_params?.upload_type })
        ),
    });

    // Stubbed patch for the table badges
    const table_schema = await client.getTraceUploadTableWithFilter({
      upload_type: filter_params?.upload_type || 'trace',
    });

    const status_column_index = table_schema.findIndex(
      (item) => item.fieldName === 'status'
    );

    if (status_column_index !== -1) {
      table_schema[status_column_index] = {
        ...table_schema[status_column_index],
        badges: [
          {
            key: 'SUCCESS',
            title: 'SUCCESS',
            type: 'success',
          },
          {
            key: 'REJECTED',
            title: 'REJECTED',
            type: 'rejected',
          },
          {
            key: 'ERROR',
            title: 'ERROR',
            type: 'error',
          },
          {
            key: 'QUEUED',
            title: 'QUEUED',
            type: 'queued',
          },
          {
            key: 'CHECKING',
            title: 'CHECKING',
            type: 'checking',
          },
          {
            key: 'PENDING',
            title: 'PENDING',
            type: 'pending',
          },
          {
            key: 'IN PROGRESS',
            title: 'IN PROGRESS',
            type: 'in_progress',
          },
          {
            key: 'INTERRUPTED',
            title: 'INTERRUPTED',
            type: 'interrupted',
          },
        ],
      };
    }

    return {
      data: uploads?.content,
      schema: table_schema,
      total: uploads.numberOfElements,
    };
  }

  async edit({ data, traceId, actions }: any) {
    try {
      await client.putTraceUpload({
        upload: data,
        id: traceId,
      });
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }

  async add({ data, filter_params, actions }: any) {
    try {
      await client.postTraceUpload({
        upload: { ...data, upload_type: filter_params?.upload_type || 'trace' },
      });
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }
}

export default UploadResourceFactory;
