export const NODE_WIDTH = 220;
export const NODE_HEIGHT = 110;
export const MIN_SCALE_FACTOR = 0.03;
export const MAX_SCALE_FACTOR = 4;
export const INIT_SCALE_FACTOR = 1;
export const OFFSET_SCALE_PARAM = 0.9;

export const EXCLUDED_FIELDS = [
  'id',
  'hash_for_duplicates',
  'searchable_index',
];
