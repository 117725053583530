import AbstractResourceFactory from './abstract-resource-factory';
import client from '../../../../../lib/apiClient';
import { PageSchema, Table } from '../../../types';
import { getDashboardName } from 'sections/CommonDashboard/util';

class DashboardTableResourceFactory implements AbstractResourceFactory {
  defaultFilterState = {
    query: '',
    sorting: '%5B%5D',
    lines_per_page: 10,
    filters: '%7B%7D',
  };
  toggleArrow = 2560;
  async getAll(
    page = 1,
    searchField = '',
    filters: object,
    path: string,
    filter_params: any,
    pageSchema: PageSchema,
    traceId,
    business,
  ): Promise<Table> {
    const table_name: string = pageSchema?.options?.table_name || '';
    const dashboard_name = getDashboardName(business.name, pageSchema);

    const { data, numberOfElements, table_scheme } = await client.getDashboardTableData({
      business: business.name,
      dashboard_name,
      table_name,
      ...filters,
      page_number: page,
      query: searchField,
    });

    return {
      data,
      schema: table_scheme,
      total: numberOfElements,
    };
  }
}

export default DashboardTableResourceFactory;
