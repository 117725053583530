import React, { useState, useEffect } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Config } from 'config.js';
import { SWarning } from './styled-comopnent';

const { API_URL } = Config;

const BuildWarning = () => {
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);
  const origin = window.location.origin;

  useEffect(() => {
    setIsDisplayed(origin !== API_URL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const message = (
    <p>
      The platform code is built for{' '}
      <span className={'platform'}>{API_URL}</span>, but installed on{' '}
      <span className={'platform'}>{origin}</span>.
    </p>
  );

  return isDisplayed ? (
    <SWarning
      type="error"
      message="Warning"
      description={message}
      closable
      showIcon
      icon={<ExclamationCircleOutlined />}
    />
  ) : null;
};

export default BuildWarning;
