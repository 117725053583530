import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, Select } from 'antd';
import SegmentedGraph from '../../../Analytics/components/SegmentedGraph';
import {
  ANALYTICS_COLORS,
  ANALYTICS_DATE,
  DATE_INPUT,
  METRICS,
} from '../../constants';
import { AnalyticsInterface } from '../../types';
import { SIndicatorContainer } from './styled-components';

const MatomoAnalytics = ({
  styles,
  bordered,
  data,
}: AnalyticsInterface) => {
  const [period, setPeriod] = useState('day');
  const [date, setDate] = useState<Dayjs | string>(
    dayjs(ANALYTICS_DATE, 'YYYY-MM-DD')
  );
  const { date_format } = DATE_INPUT;

  const handlePeriodChange = (value: string) => {
    setPeriod(value);
  };
  const handleDateChange = (useless: any, dateString: any) => {
    if (dateString === '') {
      setDate(dayjs(ANALYTICS_DATE, 'YYYY-MM-DD'));
    } else {
      setDate(dayjs(dateString, date_format).format('YYYY-MM-DD'));
    }
  };

  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <p className={'indicator-title'}>Date et période</p>
      <DatePicker
        size={'large'}
        format={date_format}
        value={dayjs(date, 'YYYY-MM-DD')}
        onChange={handleDateChange}
      />
      <Select
        size={'large'}
        dropdownMatchSelectWidth={false}
        value={period}
        onChange={handlePeriodChange}
        options={[
          { value: 'day', label: DATE_INPUT.period_labels[0] },
          { value: 'week', label: DATE_INPUT.period_labels[1] },
          { value: 'month', label: DATE_INPUT.period_labels[2] },
          { value: 'year', label: DATE_INPUT.period_labels[3] },
        ]}
      />
      <SegmentedGraph
        name={'Dashboard'}
        stubbed={true}
        colors={ANALYTICS_COLORS}
        title={''}
        date={date}
        period={period}
        metrics={METRICS}
        stubbedData={data}
        tabPosition={'left'}
      />
    </SIndicatorContainer>
  );
};

export default MatomoAnalytics;
