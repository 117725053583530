import React from 'react';
import { Tooltip } from 'antd';
import { EntityElement } from './components';
import { ENTITY_ICONS } from '../../constants';
import { EntitiesInterface } from '../../types';
import { SIndicatorContainer } from './styled-components';

const Entities = ({
  styles,
  bordered,
  total,
  list,
  order,
  title,
  tracedVersion,
}: EntitiesInterface) => {
  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <p className={'indicator-title'}>
        {total && (
          <Tooltip
            id="indicatorTooltip"
            title="Nombre total d’entités enregistrées sur la plateforme de traçabilité."
            color={'#ffffff'}
          >
            <span> {total} </span>
          </Tooltip>
        )}
        {title || 'Entités enregistrées'}
      </p>
      <div className={'entities-wrapper'}>
        {list
          .sort(
            (a, b) => order && order.indexOf(a.type) - order.indexOf(b.type)
          )
          .map((item, index) => {
            return (
              <EntityElement
                key={`entity-${index}`}
                src={ENTITY_ICONS[item?.type]}
                tracedVersion={tracedVersion}
                {...item}
              />
            );
          })}
      </div>
    </SIndicatorContainer>
  );
};

export default Entities;
