import React from 'react';
import { Carousel } from 'antd';
import { StyledCarouselWrapper, StyledItem } from './styled-components';

interface Props {
  key?: number;
  data: { img: string }[];
  styles?: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const CustomCarousel = ({ key, styles, data }: Props) => {
  return data && Array.isArray(data) && data.length !== 0 ? (
    <StyledCarouselWrapper key={key} styles={styles}>
      <Carousel autoplay>
        {data.map((item, index) => (
          <StyledItem key={index} background={item?.img} />
        ))}
      </Carousel>
    </StyledCarouselWrapper>
  ) : null;
};

export default CustomCarousel;
