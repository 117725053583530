import React from 'react';
import Notification from '../notification';
import { Message } from '../../containers/bell/reducer';
import { StyledRoot, StyledNoMessages } from './style';

type Props = {
  notifications: Array<Message>;
  markAsRead: Function;
};

const NotificationList = ({ notifications, markAsRead }: Props) => {
  return (
    <StyledRoot>
      {Array.isArray(notifications) &&
        notifications
          .sort(function (a, b) {
            return +b.id - +a.id || b.name.localeCompare(a.name);
          })
          .map((notification) => (
            <Notification
              key={notification.id.toString()}
              notification={notification}
              markAsRead={markAsRead}
            />
          ))}
      {(!Array.isArray(notifications) || notifications.length === 0) && (
        <StyledNoMessages>
          Il n'y a pas de nouvelles notifications
        </StyledNoMessages>
      )}
    </StyledRoot>
  );
};

export default NotificationList;
