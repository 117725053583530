// @ts-nocheck
import { message } from 'antd';

import { resetAllDataAction, store } from '../store/nextStore';
import { actionGoTo } from '../sections/SideBar/reducer';

const errorHandler = (err: any, pageName?: string) => {
  const error = err instanceof Array ? err[0] : err;
  const errorMessage = error?.message || error;

  // Page names for which do not display a popup error message
  const excludedPageNames = ['CommonDashboard'];

  switch (errorMessage) {
    case 'You need to sign in or sign up before continuing.':
      store.dispatch(resetAllDataAction());
      store.dispatch(actionGoTo('/sign_in'));
      break;
    default:
      return !excludedPageNames.includes(pageName)
        ? message.error(`${error.name ? error.name : ''} ${errorMessage}`)
        : null;
  }
};

export default errorHandler;
