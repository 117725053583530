import styled from 'styled-components';
import { Table } from 'antd';
import { borderRadius, blockShadow, colors } from 'App/styleVariables';

const StyledTable = styled(Table)`
  background: ${({ theme }) => theme.table.background} !important;
  border-radius: ${borderRadius};
  box-shadow: ${blockShadow};

  .ant-table {
    border-radius: ${borderRadius} !important;

    table {
      table-layout: fixed !important;
      border-radius: inherit !important;
    }
  }

  tr {
    white-space: ${(props) =>
      props.isBrokenTitle && props.theme.table.brokenTitle
        ? props.theme.table.brokenTitle.whiteSpace
        : 'nowrap'};

    div:not(.ant-table-filter-column) {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.ant-table-column-sort {
      background-color: transparent;
    }
  }

  th {
    padding: 10px 10px !important;
    /* height: 56px !important; */
    word-break: normal;
    font-size: ${({ isBrokenTitle, theme }) =>
      isBrokenTitle
        ? theme.table.brokenTitle && theme.table.brokenTitle.size
        : theme.table.th.size};
    font-weight: ${({ theme }) => theme.table.th.weight} !important;
    font-family: ${({ theme }) => theme.table.th.family} !important;
    color: ${({ theme }) => theme.table.th.color} !important;
    border-bottom: ${({ theme }) =>
      theme.table.th.border || '1px solid #EAECF0'} !important;
    background-color: ${({ theme }) =>
      theme.table.th.backgroundColor || '#F9FAFB'} !important;
  }

  th:hover .ant-table-column-sorter {
    color: ${(props) => props.theme.table.sorterArrow.active};
  }

  .ant-table-thead > tr > th::before {
    display: none !important;
  }

  .ant-table-thead > tr > th .ant-table-column-sorters {
    padding-right: 30px;
  }

  .ant-table-thead > tr > th .ant-table-filter-column {
    padding-right: ${({ toggleArrow }) => (toggleArrow ? '30px' : '54px')};

    .ant-table-column-sorters {
      padding-right: 0px;
    }
  }

  .ant-table-filter-column {
    display: ${({ toggleArrow }) =>
      toggleArrow ? 'block' : 'flex'} !important;
    justify-content: flex-start;
    align-items: center;
  }

  .ant-table-column-sorters {
    justify-content: flex-start;
  }

  .ant-table-column-title {
    flex: none;
    width: ${({ toggleArrow }) => (toggleArrow ? '100%;' : 'auto')};
    position: ${({ toggleArrow }) => (toggleArrow ? 'static' : 'relative')};
    display: block;
  }

  .ant-table-column-sorter {
    position: ${(props) => {
      if (props.isBrokenTitle) return `absolute !important`;
      if (!props.toggleArrow) return `static !important`;
      return `absolute !important`;
    }};
    top: 20% !important;
    display: inline-block !important;
    width: 25px !important;
    right: 16px !important;
    text-align: center;
    margin-left: 0;

    i,
    span[role='img'] {
      svg {
        width: 1.3em !important;
        height: 1.3em !important;
      }
    }
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: 10px;
  }

  .ant-table-column-sorter-up + .ant-table-column-sorter-down {
    margin-top: -0.4em;
  }

  .ant-table-thead > tr > th .ant-table-column-sorter {
    height: 19px;
  }

  .ant-table-thead > tr > th .ant-table-column-sorters > *:not(.ant-table-column-sorter) {
    display: ${({ toggleArrow }) =>
      toggleArrow ? 'block' : 'inline'} !important;
    margin-right: ${({ toggleArrow, isBrokenTitle }) =>
      toggleArrow && !isBrokenTitle ? '6px' : '0'} !important;
  }

  .ant-table-thead > tr > th .ant-table-filter-trigger {
    position: ${(props) => {
      if (!props.toggleArrow) return `relative !important`;
      return `absolute !important`;
    }};
    width: ${({ toggleArrow }) => (toggleArrow ? '12px' : 'auto')};
    height: ${({ toggleArrow }) => (toggleArrow ? '12px' : 'auto')};
    display: ${({ toggleArrow }) => (toggleArrow ? 'block' : 'flex')};
    margin: 0;
    padding: 0;
    top: ${({ toggleArrow }) => (toggleArrow ? '30%' : 'auto')};
    right: ${({ toggleArrow }) => (toggleArrow ? '8px' : 'auto')};

    &:hover {
      background: transparent;
    }
  }

  .ant-table-thead > tr > th .anticon-filter {
    position: ${(props) => {
      if (!props.toggleArrow) return `relative !important`;
      return `absolute !important`;
    }};
    display: inline !important;
    line-height: 1;
    margin-top: ${({ toggleArrow }) => (toggleArrow ? '0px' : '7px')};
  }

  .ant-table-tbody {
    tr.ant-table-placeholder {
      td {
        border-radius: ${borderRadius} !important;
      }
    }
  }

  tbody tr:not(.ant-table-measure-row) td {
    padding: 14px 10px !important;
    font-size: ${(props) => props.theme.table.tableElement.size} !important;
    font-weight: ${(props) => props.theme.table.tableElement.weight} !important;
    font-family: ${(props) => props.theme.table.tableElement.family} !important;
    color: ${(props) => props.theme.table.tableElement.color} !important;

    &.ant-table-cell:first-child {
      font-weight: 600 !important;
    }
  }

  .ant-pagination {
    margin: 20px 0 10px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    float: none !important;
    background: transparent !important;

    li {
      margin: 0 5px !important;
    }
  }

  .ant-pagination-item {
    margin: 0 4px !important;
    height: 32px !important;
    min-width: 32px !important;
    border-radius: ${({ theme }) =>
      theme.table.pagination.passiveItem.borderRadius || borderRadius};
    background: ${({ theme }) =>
      theme.table.pagination.passiveItem.background} !important;

    a {
      font-size: ${({ theme }) =>
        theme.table.pagination.passiveItem.size} !important;
      font-weight: ${({ theme }) =>
        theme.table.pagination.passiveItem.weight} !important;
      font-family: ${({ theme }) =>
        theme.table.pagination.passiveItem.family} !important;
      color: ${({ theme }) =>
        theme.table.pagination.passiveItem.color} !important;
      line-height: 32px !important;
    }
  }
  .ant-pagination-item-active {
    margin: 0px 4px !important;
    height: 32px !important;
    min-width: 32px !important;
    border: none !important;
    border-radius: ${({ theme }) =>
      theme.table.pagination.passiveItem.borderRadius || borderRadius};
    background: ${({ theme }) =>
      theme.table.pagination.activeItem.background} !important;

    a {
      font-size: ${({ theme }) =>
        theme.table.pagination.activeItem.size} !important;
      font-weight: ${({ theme }) =>
        theme.table.pagination.activeItem.weight} !important;
      font-family: ${({ theme }) =>
        theme.table.pagination.activeItem.family} !important;
      color: ${({ theme }) =>
        theme.table.pagination.activeItem.color} !important;
      line-height: 32px !important;
    }
  }
  .ant-pagination-item-link {
    font-size: ${({ theme }) => theme.table.pagination.arrow.size} !important;
    font-weight: ${({ theme }) =>
      theme.table.pagination.arrow.weight} !important;
    font-family: ${({ theme }) =>
      theme.table.pagination.arrow.family} !important;
    color: ${({ theme }) => theme.table.pagination.arrow.passive} !important;

    &:hover {
      color: ${({ theme }) => theme.table.pagination.arrow.active} !important;
    }
  }
`;

const LikeLink = styled.span`
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin: 0 8px 0 0;
  color: ${({ theme }) => theme.table.tableLink.passive} !important;
  font-size: ${({ theme }) => theme.table.tableLink.size} !important;
  font-weight: ${({ theme }) => theme.table.tableLink.weight} !important;
  font-family: ${({ theme }) => theme.table.tableLink.family} !important;

  &:hover {
    color: ${({ theme }) => theme.table.tableLink.active} !important;
  }

  a {
    color: ${({ theme }) => theme.table.tableLink.passive} !important;
    font-size: ${({ theme }) => theme.table.tableLink.size} !important;
    font-weight: ${({ theme }) => theme.table.tableLink.weight} !important;
    font-family: ${({ theme }) => theme.table.tableLink.family} !important;

    &:hover {
      color: ${({ theme }) => theme.table.tableLink.active} !important;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 30px;
  height: 40px;

  button {
    height: 40px;
    padding: 0 28px;
    font-size: ${({ theme }) => theme.buttonToAdd.size} !important;
    font-weight: ${({ theme }) => theme.buttonToAdd.weight} !important;
    font-family: ${({ theme }) => theme.buttonToAdd.family} !important;
    color: ${({ theme }) => theme.buttonToAdd.passive} !important;
    background: ${({ theme }) => theme.buttonToAdd.backgroundPassive || colors.primary};
    outline: none !important;
    border: none !important;
    border-radius: ${({ theme }) =>
      theme.buttonToAdd.borderRadius || borderRadius} !important;
    box-shadow: ${({ theme }) => theme.buttonToAdd.boxShadow || 'rgba(0, 0, 0, 0.25) 0 4px 4px 0'};

    &:focus {
      outline: none !important;
      border: none !important;
    }

    &:hover {
      color: ${({ theme }) => theme.buttonToAdd.active} !important;
      background: ${({ theme }) => theme.buttonToAdd.backgroundActive || colors.secondary};
      outline: none !important;
      border: none !important;
      margin-top: ${({ theme }) => theme.buttonToAdd.marginTopActive};
    }
  }
`;

export { StyledTable, LikeLink, Header };
