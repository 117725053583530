import client from '../../../../lib/apiClient';

const moduleName = 'DETAILS_TABLE';

const GET_DETAILS_SUCCESS = `${moduleName}::GET_DETAILS_SUCCESS`;
const CHANGE_LOADING_STATUS = `${moduleName}::CHANGE_LOADING_STATUS`;
const SET_FILTER = `${moduleName}::SET_FILTER`;
const SET_INITIAL_STATE = `${moduleName}::SET_INITIAL_STATE`;

const initial_state = {
  data: [],
  table_scheme: [],
  loading: false,
};

export const get_data_action = (traceId) => async (dispatch) => {
  try {
    dispatch({ type: CHANGE_LOADING_STATUS });

    const { data, table_scheme } = await client.getStockingDetails(traceId);

    dispatch({ type: GET_DETAILS_SUCCESS, payload: { data, table_scheme } });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const get_current_action = ({ traceId }) => async () => {
  try {
    const trace = await client.getTraceById(traceId);
    if (trace) return trace;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const set_filter_action = (data) => async (dispatch) =>
  dispatch({ type: SET_FILTER, payload: data });

export const set_initial_state_action = () => ({ type: SET_INITIAL_STATE });

const handle_data_success = (state, payload) => ({
  ...payload,
  loading: false,
});

const handle_loading = (state) => ({ ...state, loading: true });

const handle_filter = (state, payload) => ({ ...state, data: payload });

const handle_initial_state = () => initial_state;

const handlers = {
  [GET_DETAILS_SUCCESS]: handle_data_success,
  [CHANGE_LOADING_STATUS]: handle_loading,
  [SET_FILTER]: handle_filter,
  [SET_INITIAL_STATE]: handle_initial_state,
};

export default (state = initial_state, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action.payload) : state;
};
