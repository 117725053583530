// import axios from 'axios';

function add_keys_to_json(obj: any, key: string): void {
    for (let index = 0; index < obj.length; index++) {
        const element = obj[index];
        const new_key = key + (index + 1).toString();
        element['key'] = new_key;
        if (element.hasOwnProperty('subtable')) {
            add_keys_to_json(element['subtable'], new_key)
        }
    }
}

function toHHMMSS(sec: string): string {
    var sec_num: number = parseInt(sec, 10); // don't forget the second param
    var hours: number = Math.floor(sec_num / 3600);
    var minutes: number = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds: number = sec_num - (hours * 3600) - (minutes * 60);

    var hours_str: string = (hours < 10) ? '0' + hours : hours.toString();
    var minutes_str: string = (minutes < 10) ? '0' + minutes : minutes.toString();
    var seconds_str: string = (seconds < 10) ? '0' + seconds : seconds.toString();

    return hours_str + ':' + minutes_str + ':' + seconds_str;
}

// function matomo_fetch(host: string, method: string, idSite: string, date: string, period: string, format: string, token_auth: string): Promise<any> {
//     return axios.get('https://' + host + '/?module=API&method=' + method + '&idSite=' + idSite + '&date=' + date + '&period=' + period + '&format=' + format + '&token_auth=' + token_auth + '&expanded=1');
//     return axios.get('https://api.od.crystalchain.io/api/v2/analytics?method=' + method + '&date=' + date + '&period=' + period);
// }

export {
    add_keys_to_json,
    toHHMMSS
}