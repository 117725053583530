import styled from 'styled-components';
import { font } from '../../../../App/styleVariables';

const BlocksComponentWrapper = styled.div`
  display: block;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 50px;
  //min-height: 420px;

  h1 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 60px;
    line-height: 1.2;
    color: #112530;
    font-family: ${font} !important;

    @media (max-width: 1360px) {
      margin-bottom: 30px;
    }

    @media (max-width: 1000px) {
      text-align: center;
      font-size: 32px;
    }
  }

  @media (max-width: 1360px) {
    padding: 20px 25px;
    min-height: auto;
  }
`;
const BlocksWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
  pagging-bottom: 24px;
  margin-bottom: 30px;
  font-family: ${font} !important;
`;

const BlockItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px;
  text-align: center;
  color: #112530;

  .block-icon {
    width: 81px;
    height: 81px;
    display: flex;
    justify-content: center;
    margin-bottom: 17px;

    img {
      width: 100%;
      height: auto;
    }

    @media (max-width: 1300px) {
      width: 70px;
      height: 70px;
    }
  }

  .block-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 17px;
  }

  .block-count {
    font-size: 44px;
    font-weight: 600;
    line-height: 1.2;

    @media (max-width: 1000px) {
      font-size: 32px;
    }
  }
`;

export { BlocksComponentWrapper, BlocksWrapper, BlockItem };

export default {};
