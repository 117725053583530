import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { Form, Field, Formik, FormikProps } from 'formik';
import BusinessCard from './components';
import { BUSINESS_PICKER_MODAL } from '../../components/Modal/constants';
import { verifyBusiness } from '../../sections/Login/reducer';
import { resetAllDataAction } from '../../store/nextStore';
import { BusinessItemInterface } from '../../sections/Login/types';
import { FormContent, PickerSpin } from './styled-components';
import UniqIdsGenerator from '../../lib/uniqIdsGenerator';

const id_generator = new UniqIdsGenerator('business-picker');
const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;

interface Props {
  data: any;
  verifyBusiness: Function;
  resetAllData: Function;
}
const BusinessPicker = ({ data, verifyBusiness, resetAllData }: Props) => {
  const [focusedValue, setFocusedValue] = useState(null);
  const navigate: NavigateFunction = useNavigate();
  const { state }: any = useLocation();

  return (
    <Formik
      initialValues={{ businessId: null }}
      onSubmit={(values, actions) => {
        verifyBusiness(values)
          .then(({ forceReset = false }) => {
            sessionStorage.removeItem(BUSINESS_PICKER_MODAL);
            navigate(forceReset ? '/reset_password' : state?.path || '/');
          })
          .catch(() => {
            actions.setSubmitting(false);
            resetAllData();
            navigate('/');
          });
      }}
    >
      {({ isSubmitting, setFieldValue }: FormikProps<any>) => (
        <Form id={id_generator.get_id('formik')}>
          <Field name={'businessId'}>
            {({
              field, // { name, value, onChange, onBlur }
              form: { submitForm },
            }) => (
              <PickerSpin spinning={isSubmitting}>
                <FormContent
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    if (
                      e.nativeEvent.type === 'click' &&
                      e.nativeEvent.clientX !== 0 &&
                      e.nativeEvent.clientY !== 0
                    ) {
                      submitForm();
                    }
                  }}
                >
                  <Row gutter={[16, 16]}>
                    {data.map((item: BusinessItemInterface, index: number) => {
                      return (
                        <Col md={12} sm={24} key={index} className={'item'}>
                          <BusinessCard
                            {...item}
                            focusedValue={focusedValue}
                            onKeyDown={(e) => {
                              if (
                                e.keyCode === ENTER_KEY_CODE ||
                                e.keyCode === SPACE_KEY_CODE
                              ) {
                                e.preventDefault();
                                setFieldValue('businessId', +e.target.value);
                                submitForm();
                              }
                            }}
                            onFocus={(e) => setFocusedValue(+e.target.value)}
                            onBlur={() => setFocusedValue(null)}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </FormContent>
              </PickerSpin>
            )}
          </Field>
        </Form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = {
  verifyBusiness: verifyBusiness,
  resetAllData: resetAllDataAction,
};

export default connect(null, mapDispatchToProps)(BusinessPicker);
