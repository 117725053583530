import styled from 'styled-components';

interface Block {
  styles?: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledLinkContainer = styled.div<Block>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .link-label {
    margin-top: 5px;
    margin-bottom: 0;
  }
  .link-button {
    background: transparent;
    padding: 0;
    height: inherit;
    margin-top: 5px;
  }
  .link-icon {
    display: block;
    width: 30px;
    height: auto;

    @media (max-width: 1440px) {
      width: 26px;
    }
  }
`;

export default StyledLinkContainer;
