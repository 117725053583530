import React from 'react';
import PropTypes from 'prop-types';
import { openLink, openProtectedLink } from '../../../../lib/util';
import { PROTECTED_BUCKET } from '../../../../lib/constants';

const PlatformLink = ({ content, businessId }) => {
  const isProtected = content.startsWith(`${PROTECTED_BUCKET}`);

  return (
    <a
      // target={'_blank'}
      // rel="noreferrer"
      onClick={(e) => {
        e.preventDefault();
        if(isProtected) {
          openProtectedLink(content, businessId)
        } else {
          openLink(content);
        }
      }}
      href={content}
    >
      {content}
    </a>
  );
};

PlatformLink.propTypes = {
  /**
   * Content that will be wrapped in the link
   */
  content: PropTypes.string,
  /**
   * Business ID
   */
  businessId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default PlatformLink;
