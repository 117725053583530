import React from 'react';
import ProgressBar from '../../../../components/ProgressBar';
import * as constants from '../../constants';
import { StatusInterface } from '../../types';
import { SIndicatorContainer, SStatusLegendItem } from './styled-components';

const Status = ({
  styles,
  bordered,
  icon,
  title,
  barData,
  legend,
}: StatusInterface) => {
  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <div className={'header'}>
        <img
          src={constants[icon]}
          className={'indicator-icon'}
          alt="Indicator icon"
        />
        <p className={'indicator-title'}>{title}</p>
      </div>
      <div className={'content'}>
        <ProgressBar {...barData} />
        {legend && (
          <div className={'legend'}>
            {legend.map(({ color, title }, index) => (
              <SStatusLegendItem color={color} key={`legent-item-${index}`}>
                <span>{title}</span>
              </SStatusLegendItem>
            ))}
          </div>
        )}
      </div>
    </SIndicatorContainer>
  );
};

export default Status;
