import BarIndicator from './BarIndicator';
import CertificateManager from './CertificateManager';
import CompanyRisks from './CompanyRisks';
import Compliance from './Compliance';
import CSRDirective from './CSRDirective';
import DoubleMaterialityAnalysis from './DoubleMaterialityAnalysis';
import Entities from './Entities';
import Environment from './Environment';
import FlowMap from './FlowMap';
import InfoCard from './InfoCard';
import MatomoAnalytics from './MatomoAnalytics';
import QualityControl from './QualityControl';
import QuantityProduced from './QuantityProduced';
import Recency from './Recency';
import SectionHeader from './SectionHeader';
import Stocks from './Stocks';
import TraceabilityQuality from './TraceabilityQuality';
import ScrollableList from './ScrollableList';
import Status from './Status';

export default {
  BarIndicator,
  CertificateManager,
  CompanyRisks,
  Compliance,
  CSRDirective,
  DoubleMaterialityAnalysis,
  Entities,
  Environment,
  FlowMap,
  InfoCard,
  MatomoAnalytics,
  QualityControl,
  QuantityProduced,
  Recency,
  Stocks,
  SectionHeader,
  TraceabilityQuality,
  ScrollableList,
  Status,
};
