import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import ScreenManager from '../../../../components/ScreenManager';

import * as screens from './Screens';

class ScreensWrapper extends Component {
  state = {
    recordData: {},
    pagination: {},
    sorter: {},
  };

  handleSetRecordData = (recordData) => {
    this.setState({ recordData });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ pagination, sorter, filters });
  };

  render() {
    const { data } = this.props;
    const { recordData, pagination, sorter, filters } = this.state;

    return data ? (
      <ScreenManager
        mainScreen={'Table'}
        mainScreenName={'Chain Traces'}
        screens={screens}
        data={data}
        recordData={recordData}
        setRecordData={this.handleSetRecordData}
        pagination={pagination}
        sorter={sorter}
        filters={filters}
        handleTableChange={this.handleTableChange}
      />
    ) : (
      <Spin style={{ display: 'block' }} size="large" />
    );
  }
}

ScreensWrapper.propTypes = {
  data: PropTypes.any,
};

export default React.createFactory(ScreensWrapper);
