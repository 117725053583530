class CarboneCockpit {
  render = () => {
    return {
      "progress_header": {
        "component": "SectionHeader",
        "title": "Avancée de la collecte de données",
        "subtitle": "EN COURS",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 1,
              "end": 2
            }
          },
          "display": "flex",
          "alignItems": "flex-end",
          "subtitle": {
            "fontSize": "20px",
            "textTransform": "uppercase",
            "marginLeft": "20px"
          },
          "title": {
            "marginBottom": "0px",
            "lineHeight": "1",
          }
        }
      },
      "status": {
        "component": "Status",
        "title": "Statut de la collecte des données",
        "icon": "collection",
        "barData": {
          "completed": "65",
          "progress": "12",
          "balance": "23",
          "showInfo": true,
          "showBalance": true,
          "strokeHeight": "30px",
          "completedColor": "#00CEAA",
          "progressColor": "#F19B1A",
          "trailColor": "#EB2C02",
          "labelColor": "#272626"
        },
        "legend": [
          {
            "color": "#00CEAA",
            "title": "% de fournisseurs qui ont terminé la collecte"
          },
          {
            "color": "#F19B1A",
            "title": "% de fournisseurs pour lesquels la collecte est en cours"
          },
          {
            "color": "#EB2C02",
            "title": "% de fournisseurs pour lesquels la collecte n’est pas commencée"
          }
        ],
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 5
            },
            "row": {
              "start": 2,
              "end": 4
            }
          }
        }
      },
      "collection_deadline": {
        "component": "InfoCard",
        "icon": "calendar",
        "title": "Date butoir de la collecte",
        "info": "01/12/2023",
        "styles": {
          "size": {
            "column": {
              "start": 5,
              "end": 9
            },
            "row": {
              "start": 2,
              "end": 3
            }
          }
        }
      },
      "remaining_days": {
        "component": "InfoCard",
        "icon": "date_waiting",
        "title": "Nombre de jours restants",
        "info": "160 jours",
        "badge": {
          "type": "in_progress",
          "title": "Risque modéré",
          "customColors": {
            "background": "#F19B1A",
            "text": "white"
          },
          "displayBadgeIcon": false
        },
        "styles": {
          "size": {
            "column": {
              "start": 5,
              "end": 9
            },
            "row": {
              "start": 3,
              "end": 4
            }
          }
        }
      },
      "suppliers": {
        "component": "ScrollableList",
        "icon": "warn",
        "title": "Fournisseurs sans réponse",
        "list": [
          "Tisseur 4",
          "Fabricant 1",
          "Filateur 15",
          "Fournisseur MP 2",
          "Fabricant 18",
          "Tisseur 1",
          "Fournisseur MP 6",
          "Fabricant 9",
          "Tisseur 6",
          "Fabricant 2",
          "Fournisseur MP 9"
        ],
        "styles": {
          "size": {
            "column": {
              "start": 9,
              "end": 13
            },
            "row": {
              "start": 2,
              "end": 4
            }
          }
        }
      },
      "dash_header": {
        "component": "SectionHeader",
        "title": "BILAN CARBONE 2022",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 4,
              "end": 5
            }
          },
          "title": {
            "textAlign": "center"
          }
        }
      },
      "company_header": {
        "component": "SectionHeader",
        "title": "Bilan carbone de l’entreprise",
        "subtitle": "sur l’année",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 5,
              "end": 6
            }
          },
          "display": "flex",
          "alignItems": "flex-end",
          "subtitle": {
            "fontSize": "20px",
            "textTransform": "uppercase",
            "marginLeft": "20px"
          },
          "title": {
            "marginBottom": "0px",
            "lineHeight": "1",
          }
        }
      },
      "total_emissions": {
        "component": "Environment",
        "title": "Total des émissions",
        "total": "345 431",
        "totalUnits": " tonnes de CO2 éq",
        "percentage": {
          "number": 5,
          "growth": "negative"
        },
        "percentageDescription": "par rapport à l’année précédente",
        "graph": {
          "growth": "negative",
          "data": [
            { "x": 12 },
            { "x": 13 },
            { "x": 11 },
            { "x": 5 },
            { "x": 8 },
            { "x": 4 },
            { "x": 2 },
          ]
        },
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 5
            },
            "row": {
              "start": 6,
              "end": 7
            }
          },
          "header": {
            "marginBottom": "5px"
          },
          "chart": {
            "height": "70px",
            "marginBottom": "-10px"
          },
          "percentage": {
            "display": "inline-flex"
          },
          "growthRow": {
            "alignItems": "center"
          }
        }
      },
      "total_emissions_turnover": {
        "component": "Environment",
        "title": "Total des émissions ramené au chiffre d’affaires",
        "total": "1191",
        "totalUnits": " kg de CO2 éq / k€ de C.A.",
        "percentage": {
          "number": 7,
          "growth": "negative"
        },
        "percentageDescription": "par rapport à l’année précédente",
        "graph": {
          "growth": "negative",
          "data": [
            { "x": 12 },
            { "x": 14 },
            { "x": 8 },
            { "x": 5 },
            { "x": 8 },
            { "x": 4 },
          ]
        },
        "styles": {
          "size": {
            "column": {
              "start": 5,
              "end": 9
            },
            "row": {
              "start": 6,
              "end": 7
            }
          },
          "header": {
            "marginBottom": "5px"
          },
          "chart": {
            "height": "70px",
            "marginBottom": "-10px"
          },
          "percentage": {
            "display": "inline-flex"
          },
          "growthRow": {
            "alignItems": "center"
          }
        }
      },
      "total_emissions_employee": {
        "component": "Environment",
        "title": "Total des émissions par collaborateur",
        "total": "4",
        "totalUnits": " tonnes de CO2 éq / collaborateur",
        "percentage": {
          "number": 10,
          "growth": "positive"
        },
        "percentageDescription": "par rapport à l’année précédente",
        "graph": {
          "growth": "positive",
          "data": [
            { "x": 2 },
            { "x": 8 },
            { "x": 3 },
            { "x": 9 },
            { "x": 12 }
          ]
        },
        "styles": {
          "size": {
            "column": {
              "start": 9,
              "end": 13
            },
            "row": {
              "start": 6,
              "end": 7
            }
          },
          "header": {
            "marginBottom": "5px"
          },
          "chart": {
            "height": "70px",
            "marginBottom": "-10px"
          },
          "percentage": {
            "display": "inline-flex"
          },
          "growthRow": {
            "alignItems": "center"
          }
        }
      },
      "distribution_header": {
        "component": "SectionHeader",
        "title": "Repartition des émissions",
        "subtitle": "sur l’année",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 7,
              "end": 8
            }
          },
          "display": "flex",
          "alignItems": "flex-end",
          "subtitle": {
            "fontSize": "20px",
            "textTransform": "uppercase",
            "marginLeft": "20px"
          },
          "title": {
            "marginBottom": "0px",
            "lineHeight": "1",
          }
        }
      },
      "distribution": {
        "component": "Recency",
        "title": "Par scope 1, 2 et 3",
        "data": [
          {
            "name": "Scope 1",
            "value": 0.5,
            "unit": "%"
          },
          {
            "name": "Scope 2",
            "value": 0.5,
            "unit": "%"
          },
          {
            "name": "Scope 3",
            "value": 99,
            "unit": "%"
          }
        ],
        "legendLayout": "horizontal",
        "legendAlign": "center",
        "legendVerticalAlign": "bottom",
        "legendHeight": 40,
        "legendIconSize": 16,
        "displayTitleTooltip": false,
        "displayChartTooltip": true,
        "customColors": [
          "#288DEB",
          "#F37A09",
          "#D05261"
        ],
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 6
            },
            "row": {
              "start": 8,
              "end": 9
            }
          },
          "title": {
            "textTransform": "uppercase",
            "marginBottom": "10px"
          },
          "chart": {
            "heightXl": "300px",
            "heightL" : "300px"
          },
          "legend": {
            "paddingTop": "15px",
            "item": {
              "marginLeft": "16px",
              "display": "inline-flex",
              "justifyContent": "center",
              "alignItems": "center"
            }
          }
        }
      },
      "details": {
        "component": "BarIndicator",
        "title": "Détail des émissions SCOPE 3",
        "chartType": "multipleCell",
        "data": [
          {
            "name": 'Production de matière première',
            "value": 12.8,
            "color": "#4472C4",
            "unit": "%"
          },
          {
            "name": 'Traitement du textile',
            "value": 20.4,
            "color": "#ED7D31",
            "unit": "%"
          },
          {
            "name": 'Extraction des matières premières',
            "value": 13.4,
            "color": "#A5A5A5",
            "unit": "%"
          },
          {
            "name": 'Transformation des matières premières',
            "value": 12.6,
            "color": "#FFC000",
            "unit": "%"
          },
          {
            "name": 'Transport et distribution (en amont)',
            "value": 8.4,
            "color": "#5B9BD5",
            "unit": "%"
          },
          {
            "name": 'Autres',
            "value": 5.7,
            "color": "#70AD47",
            "unit": "%"
          },
          {
            "name": 'Confection du produits finis',
            "value": 4.4,
            "color": "#264478",
            "unit": "%"
          },
          {
            "name": 'Traitement en fin de vie des produits vendus',
            "value": 1.3,
            "color": "#9E480E",
            "unit": "%"
          },
          {
            "name": 'Utilisation des produits vendus',
            "value": 20.1,
            "color": "#636363",
            "unit": "%"
          },
          {
            "name": 'Franchise',
            "value": 0.7,
            "color": "#997300",
            "unit": "%"
          },
          {
            "name": 'Voyages d\'affaires',
            "value": 0.2,
            "color": "#255E91",
            "unit": "%"
          }
        ],
        "xaxis": {
          "unit": "%",
        },
        "customLegend": true,
        "chartLayout": "vertical",
        "hideYAxis": true,
        "horizontalGrid": false,
        "customTooltip": true,
        "styles": {
          "size": {
            "column": {
              "start": 6,
              "end": 13
            },
            "row": {
              "start": 8,
              "end": 9
            }
          },
          "title": {
            "textTransform": "uppercase",
            "marginBottom": "10px"
          }
        }
      },
      "monitoring_header": {
        "component": "SectionHeader",
        "title": "SUIVI DES FOURNISSEURS",
        "subtitle": "sur l’année",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 9,
              "end": 10
            }
          },
          "display": "flex",
          "alignItems": "flex-end",
          "subtitle": {
            "fontSize": "20px",
            "textTransform": "uppercase",
            "marginLeft": "20px"
          },
          "title": {
            "marginBottom": "0px",
            "lineHeight": "1",
          }
        }
      },
      "distribution_time": {
        "component": "Recency",
        "title": "Répartition des fournisseurs",
        "displayHeader": true,
        "icon": "distribution",
        "total": 450,
        "headerTitle": "Fournisseurs enregistrés",
        "legendIconSize": 16,
        "data": [
          {
            "name": "Fournisseurs de matière première",
            "value": 60
          },
          {
            "name": "Filateurs",
            "value": 100
          },
          {
            "name": "Tisseurs",
            "value": 40
          },
          {
            "name": "Fabricants",
            "value": 250
          }
        ],
        "displayTitleTooltip": false,
        "displayChartTooltip": true,
        "customColors": [
          "#00CEAA",
          "#FFCA22",
          "#EB6402",
          "#534DCA"
        ],
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 8
            },
            "row": {
              "start": 10,
              "end": 11
            }
          },
          "title": {
            "textTransform": "uppercase",
            "marginBottom": "10px"
          },
          "chart": {
            "widthXl": "75%",
            "widthM": "100%"
          }
        }
      },
      "response_time": {
        "component": "Recency",
        "title": "Délai de réponse des fournisseurs",
        "data": [
          {
            "name": "\u003c 14 jours",
            "value": 150
          },
          {
            "name": "15-30 jours",
            "value": 120
          },
          {
            "name": "\u003e 30 jours",
            "value": 90
          },
          {
            "name": "n/a",
            "value": 90
          }
        ],
        "legendIconSize": 16,
        "displayTitleTooltip": false,
        "styles": {
          "size": {
            "column": {
              "start": 8,
              "end": 13
            },
            "row": {
              "start": 10,
              "end": 11
            }
          },
          "title": {
            "textTransform": "uppercase",
            "marginBottom": "25px"
          },
          "chart": {
            "widthXl": "75%",
            "widthM": "100%",
            "heightXl": "190px",
            "heightL" : "160px"
          }
        }
      },
      "distribution_by_entity_type": {
        "component": "BarIndicator",
        "title": "Répartition des émissions par type d’entité",
        "barsConfig": [
          {
            "dataKey": "Scope 1",
            "color": "#D05261",
            "stackId": "a",
            "barSize": 40,
            "unit": "tonnes de CO2"
          },
          {
            "dataKey": "Scope 2",
            "color": "#F37A09",
            "stackId": "a",
            "barSize": 40,
            "unit": "tonnes de CO2"
          },
          {
            "dataKey": "Scope 3",
            "color": "#288DEB",
            "stackId": "a",
            "barSize": 40,
            "unit": "tonnes de CO2"
          }
        ],
        "data": [
          {
            "name": 'Fournisseurs',
            "Scope 1": 10500
          },
          {
            "name": 'Filateur',
            "Scope 1": 6000
          },
          {
            "name": 'Tisseur',
            "Scope 1": 7000,
            "Scope 2": 2000,
            "Scope 3": 5000
          },
          {
            "name": 'Fabricant',
            "Scope 1": 8500,
            "Scope 2": 4000,
            "Scope 3": 6000
          }
        ],
        "chartLayout": "horizontal",
        "verticalGrid": false,
        "yaxis": {
          "label": "tonnes de CO2 éq",
          "labelPosition": "insideTopLeft",
          "labelOffset": -30,
          "domain": [0, "dataMax + 500"],
          "tickCount": 20
        },
        "margin": {
          "top": 35,
          "right": 0,
          "left": 35,
          "bottom": 0,
        },
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 7
            },
            "row": {
              "start": 11,
              "end": 12
            }
          },
          "title": {
            "textTransform": "uppercase",
            "marginBottom": "10px"
          },
          "chart": {
            "label": {
              "color": "#464A53",
              "fontSize": "16px",
              "fontWeight": "500"
            },
            "tooltip": {
              "display": "flex",
              "flexDirection": "column-reverse"
            }
          },
          "legend": {
            "display": "flex",
            "flexDirection": "column-reverse"
          }
        }
      },
      "suppliers_ranking": {
        "component": "BarIndicator",
        "title": "CLASSEMENT DES FOURNISSEURS les plus émetteurs",
        "chartType": "multipleCell",
        "barSize": 25,
        "data": [
          {
            "name": 'Fournisseur MP 1',
            "value": 1150,
            "color": "#6D0744",
            "unit": "tonnes de CO2 éq"
          },
          {
            "name": 'Tisseur 15',
            "value": 1000,
            "color": "#BB0707",
            "unit": "tonnes de CO2 éq"
          },
          {
            "name": 'Filateur 7',
            "value": 945,
            "color": "#EB2C02",
            "unit": "tonnes de CO2 éq"
          },
          {
            "name": 'Fournisseur MP 9',
            "value": 895,
            "color": "#F84949",
            "unit": "tonnes de CO2 éq"
          },
          {
            "name": 'Fabricant 6',
            "value": 810,
            "color": "#ED7D31",
            "unit": "tonnes de CO2 éq"
          },
          {
            "name": 'Fournisseur MP 3',
            "value": 760,
            "color": "#FDB140",
            "unit": "tonnes de CO2 éq"
          },
          {
            "name": 'Tisseur 4',
            "value": 690,
            "color": "#FFDF78",
            "unit": "tonnes de CO2 éq"
          }
        ],
        "chartLayout": "vertical",
        "customTooltip": true,
        "displayLegend": false,
        "xaxis": {
          "label": "tonnes de CO2 éq",
          "labelPosition": "insideBottomRight",
          "labelOffset": -15
        },
        "yaxis": {
          "width": 130,
        },
        "margin": {
          "top": 10,
          "right": 20,
          "left": 0,
          "bottom": 20,
        },
        "styles": {
          "size": {
            "column": {
              "start": 7,
              "end": 13
            },
            "row": {
              "start": 11,
              "end": 12
            }
          },
          "title": {
            "textTransform": "uppercase",
            "marginBottom": "10px"
          },
          "chart": {
            "label": {
              "color": "#464A53",
              "fontSize": "16px",
              "fontWeight": "500"
            }
          }
        }
      },
      "emissions_breakdown": {
        "component": "BarIndicator",
        "title": "RéPARTITION DES éMISSIONS PAR type de matière première achetée",
        "barsConfig": [
          {
            "dataKey": "Quantité de MP achetée",
            "color": "#06B26B",
            "barSize": 40,
            "stackId": "a",
            "unit": "%"
          },
          {
            "dataKey": "Quantité de CO2 libéré",
            "color": "#534DCA",
            "barSize": 40,
            "stackId": "b",
            "unit": "%"
          }
        ],
        "data": [
          {
            "name": "Coton",
            "Quantité de MP achetée": 50,
            "Quantité de CO2 libéré": 60
          },
          {
            "name": "Polyester",
            "Quantité de MP achetée": 16,
            "Quantité de CO2 libéré": 20
          },
          {
            "name": "Laine",
            "Quantité de MP achetée": 6,
            "Quantité de CO2 libéré": 10
          },
          {
            "name": "Viscose",
            "Quantité de MP achetée": 5,
            "Quantité de CO2 libéré": 5
          },
          {
            "name": "Acrylique",
            "Quantité de MP achetée": 3,
            "Quantité de CO2 libéré": 5
          }
        ],
        "chartLayout": "horizontal",
        "yaxis": {
          "unit": "%"
        },
        "margin": {
          "top": 10,
          "right": 0,
          "left": 0,
          "bottom": 0,
        },
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 12,
              "end": 13
            }
          },
          "title": {
            "textTransform": "uppercase",
            "marginBottom": "10px"
          },
          "chart": {
            "label": {
              "color": "#464A53",
              "fontSize": "16px",
              "fontWeight": "500"
            }
          }
        }
      },
      "objectives_header": {
        "component": "SectionHeader",
        "title": "objectifs",
        "subtitle": "sur l’année",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 13,
              "end": 14
            }
          },
          "display": "flex",
          "alignItems": "flex-end",
          "subtitle": {
            "fontSize": "20px",
            "textTransform": "uppercase",
            "marginLeft": "20px"
          },
          "title": {
            "marginBottom": "0px",
            "lineHeight": "1",
          }
        }
      },
      "objectives": {
        "component": "BarIndicator",
        "chartType": "composed",
        "barsConfig": [
          {
            "dataKey": "Emissions réalisées",
            "color": "#06B26B",
            "barSize": 40,
            "stackId": "a",
            "unit": "tonnes de CO2 éq"
          },
          {
            "dataKey": "Emissions cibles sur l’année passée",
            "color": "#DDDFE1",
            "barSize": 40,
            "stackId": "b",
            "unit": "tonnes de CO2 éq"
          },
          {
            "dataKey": "Objectifs des émissions définies",
            "color": "#8FDCBC",
            "barSize": 40,
            "stackId": "a",
            "unit": "tonnes de CO2 éq"
          }
        ],
        "data": [
          {
            "name": "2021",
            "Emissions réalisées": 395000,
            "Emissions cibles sur l’année passée": 370000
          },
          {
            "name": "2022",
            "Emissions réalisées": 345431,
            "Emissions cibles sur l’année passée": 320000
          },
          {
            "name": "2023",
            "Objectifs des émissions définies": 324704
          },
          {
            "name": "2024",
            "Objectifs des émissions définies": 305221
          },
          {
            "name": "2025",
            "Objectifs des émissions définies": 286907
          },
          {
            "name": "2026",
            "Objectifs des émissions définies": 269692
          },
          {
            "name": "2027",
            "Objectifs des émissions définies": 250000
          },
          {
            "name": "2028",
            "Objectifs des émissions définies": 235000
          },
          {
            "name": "2029",
            "Objectifs des émissions définies": 220900
          },
          {
            "name": "2030",
            "Objectifs des émissions définies": 200000
          },
          {
            "name": "2031",
            "Objectifs des émissions définies": 188000
          }
        ],
        "chartLayout": "horizontal",
        "yaxis": {
          "label": "tonnes de CO2 éq",
          "labelPosition": "insideTopLeft",
          "labelOffset": -30
        },
        "legendAlign": "center",
        "legendVerticalAlign": "top",
        "legendLayout": "horizontal",
        "legendHeight": 35,
        "legendIconSize": 16,
        "margin": {
          "top": 10,
          "right": 0,
          "left": 35,
          "bottom": 0,
        },
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 14,
              "end": 15
            }
          },
          "chart": {
            "label": {
              "color": "#464A53",
              "fontSize": "16px",
              "fontWeight": "500"
            }
          },
          "legend": {
            "item": {
              "marginLeft": "16px"
            }
          }
        }
      },
      "csrd_header": {
        "component": "SectionHeader",
        "title": "DOUBLE matérialité - csrd",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 15,
              "end": 16
            }
          },
          "display": "flex",
          "alignItems": "flex-end",
          "title": {
            "marginBottom": "0px",
            "lineHeight": "1",
          }
        }
      },
      "analysis_materiality": {
        "component": "DoubleMaterialityAnalysis",
        "title": "ANALYSE DE DOUBLE MATérialité - E1 CHANGEMENT CLIMATIQUE",
        "styles": {
          "size": {
            "column": {
              "start": 1,
              "end": 13
            },
            "row": {
              "start": 16,
              "end": 17
            }
          },
          "title": {
            "textTransform": "uppercase",
            "marginBottom": "20px"
          },
        }
      }
    }
  }
}

export default new CarboneCockpit();