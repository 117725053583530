import styled from 'styled-components';
import { Button, Form } from 'antd';
import { font } from '../../App/styleVariables';

export const SFormItem = styled(Form.Item)`
  .ant-form-item-row {
    display: flex;
    flex-direction: column;

    .ant-form-item-label {
      text-align: start;
      font-family: ${font};

      label {
        color: #1d1d1b;
        font-size: 16px;
        font-weight: 500;
        height: auto;

        &:after {
          content: none;
        }
      }
    }
    
    .form-error {
      color: #ff4d4f;
      font-family: ${font};
    }
    
    .has-error {
      border-color: #ff4d4f;
    }
  }
`;

export const SButton = styled(Button)`
  height: 40px;
  min-width: 148px;
  padding: 0 42px !important;
  font-size: ${(props) => props.theme.form.submitButton.size} !important;
  font-weight: 600 !important;
  font-family: ${(props) => props.theme.form.submitButton.family} !important;
  color: ${(props) => props.theme.form.submitButton.color} !important;
  background: ${(props) => props.theme.form.submitButton.passive} !important;
  border-color: ${(props) => props.theme.form.submitButton.passive} !important;
  margin-top: 16px;
  align-self: center;

  &:hover {
    color: ${(props) => props.theme.form.submitButton.color} !important;
    background: ${(props) => props.theme.form.submitButton.active} !important;
    border-color: ${(props) => props.theme.form.submitButton.active} !important;
  }
`;
