import styled from 'styled-components';
import { colors } from 'App/styleVariables';

const ViewsFilter = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 36px;

  .table-view-icon {
    font-size: 24px;
    cursor: pointer;
    margin: 0 6px 0 0;
    color: #a7c0cd;

    &:last-child {
      margin-right: 12px;
    }

    &.selected {
      color: ${({ theme }) =>
        theme.gallery?.viewFilter?.selectedColor || colors.secondary};
    }
  }
`;

export { ViewsFilter };
