import React from 'react';
import { Input, Spin } from 'antd';
import { Field, Form as FForm, Formik, FormikProps } from 'formik';
import { validationSchema } from '../forgot-password-control';
import UniqIdsGenerator from '../../../../lib/uniqIdsGenerator';
import { SFormItem, SSubmitButton } from "../../styled-components";

interface ForgotFormInterface {
  initialValues: {
    email: string;
  };
  handleSubmit: Function;
}

const id_generator = new UniqIdsGenerator('forgot-form');

const ForgotForm = ({ initialValues, handleSubmit }: ForgotFormInterface) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        handleSubmit(values).then(() => {
          actions.setSubmitting(false);
        });
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }: FormikProps<any>) => (
        <FForm id={id_generator.get_id('formik')}>
          <Field name={'email'}>
            {({
              field, // { name, value, onChange, onBlur }
              form: { touched, errors },
            }) => {
              return (
                <SFormItem
                  label={'Email'}
                  help={touched.email && errors.email ? errors.email : ''}
                  validateStatus={
                    touched.email && errors.email ? 'error' : undefined
                  }
                >
                  <Input autoComplete="off" {...field} />
                </SFormItem>
              );
            }}
          </Field>
          {isSubmitting && <Spin size="default" />}
          {!isSubmitting && (
            <SSubmitButton htmlType={'submit'}>
              Send link to email
            </SSubmitButton>
          )}
        </FForm>
      )}
    </Formik>
  );
};

export default ForgotForm;
