import React, { useEffect } from 'react';
import { Progress, Tooltip } from 'antd';
import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { TraceabilityQualityInterface } from '../../types';
import { SIndicatorContainer } from './styled-components';

const TraceabilityQuality = ({
  bordered,
  styles,
  title,
  gauge_chart,
  finished_products_traced,
  finished_products_traced_title,
  complete_chains,
  complete_chains_title,
  complete_progress,
  all_traces_qty,
  all_traces_qty_title,
  all_traces_qty_tooltip = 'Nombre de traces ajoutées depuis le 1 août 2023',
  missing_relations_qty,
  missing_relations_percentage,
  perfect_duplicates_qty,
  perfect_duplicates_percentage,
  logic_duplicates_qty,
  logic_duplicates_percentage,
  redStart = 0.2,
  redEnd = 1,
  orangeStart = 0.1,
  orangeEnd = 0.2,
  greenStart = 0,
  greenEnd = 0.1,
}: TraceabilityQualityInterface) => {
  useEffect(() => {
    let root = am5.Root.new('gaugeChart');
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        startAngle: -180,
        endAngle: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
      })
    );
    // Create axis and its renderer
    let axisRenderer = am5radar.AxisRendererCircular.new(root, {
      strokeOpacity: 0,
      minGridDistance: 1000,
      innerRadius: -30,
      inversed: true,
    });
    axisRenderer.ticks.template.setAll({
      visible: false,
      strokeOpacity: 0,
    });
    axisRenderer.grid.template.setAll({
      visible: false,
      strokeOpacity: 0,
    });
    axisRenderer.labels.template.setAll({
      minPosition: 0.1,
      maxPosition: 0.9,
    });
    let axis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 1,
        strictMinMax: true,
        renderer: axisRenderer,
      })
    );

    // Create ranges
    const createRange = (start, end, color) => {
      let rangeDataItem = axis.makeDataItem({
        value: start,
        endValue: end,
      });
      axis.createAxisRange(rangeDataItem);

      rangeDataItem.get('axisFill').setAll({
        visible: true,
        fill: color,
        fillOpacity: 0.8,
      });
      rangeDataItem.get('tick').setAll({
        visible: false,
      });
      rangeDataItem.get('grid').setAll({
        visible: false,
      });
    };
    createRange(redStart, redEnd, am5.color('#EB2C02'));
    createRange(orangeStart, orangeEnd, am5.color('#FFB800'));
    createRange(greenStart, greenEnd, am5.color('#00CEAA'));

    // Add clock hand
    let axisDataItem = axis.makeDataItem({
      value: 0,
    });
    axisDataItem.set(
      'bullet',
      am5xy.AxisBullet.new(root, {
        sprite: am5radar.ClockHand.new(root, {
          radius: am5.percent(99),
          pinRadius: 5,
        }),
      })
    );
    axis.createAxisRange(axisDataItem);
    axisDataItem.animate({
      key: 'value',
      to: gauge_chart,
      duration: 800,
      easing: am5.ease.out(am5.ease.cubic),
    });
    axisDataItem.get('grid').set('visible', false);
    axisDataItem.get('tick').set('visible', false);

    // Make stuff animate on load
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SIndicatorContainer bordered={bordered} styles={styles}>
      <div className={'chart-wrapper'}>
        <p className={'indicator-title'}>
          {title || (
            <>
              Qualité de la <br /> traçabilité
            </>
          )}
        </p>
        <div id="gaugeChart"></div>
      </div>
      <div className={'traces-wrapper'}>
        <div className={'traces-title'}>
          <span>{finished_products_traced}</span>
          {finished_products_traced_title || 'lots de produits finis tracés'}
        </div>
        <div className={'traces-info'}>
          <span>{complete_chains}</span>
          {complete_chains_title || 'chaines complètes'}
        </div>
        <Progress
          percent={complete_progress}
          showInfo={false}
          strokeColor={'#00CEAA'}
          trailColor={'#EB2C02'}
          className={'complete-progress'}
        />
        <div className={'traces-title'}>
          <Tooltip
            id="indicatorTooltip"
            title={all_traces_qty_tooltip}
            color={'#ffffff'}
          >
            <span className={'tooltip'}>{all_traces_qty}</span>
          </Tooltip>
          {all_traces_qty_title || 'évènements tracés'}
        </div>
        <div className={'monitoring-container'}>
          {missing_relations_qty && (
            <div className={'monitoring-item'}>
              <div className={'traces-info'}>
                <Tooltip
                  id="indicatorTooltip"
                  title={'Nombre de traces avec des relations manquantes'}
                  color={'#ffffff'}
                >
                  <span>{missing_relations_qty}</span>
                </Tooltip>
                relations manquantes
              </div>
              <Progress
                percent={missing_relations_percentage}
                showInfo={false}
                strokeColor={'#EB2C02'}
                trailColor={'#00CEAA'}
              />
            </div>
          )}
          {perfect_duplicates_qty && (
            <div className={'monitoring-item'}>
              <div className={'traces-info'}>
                <Tooltip
                  id="indicatorTooltip"
                  title={'Nombre de traces parfaitement similaires'}
                  color={'#ffffff'}
                >
                  <span>{perfect_duplicates_qty}</span>
                </Tooltip>
                doublons parfaits
              </div>
              <Progress
                percent={perfect_duplicates_percentage}
                showInfo={false}
                strokeColor={'#EB2C02'}
                trailColor={'#00CEAA'}
              />
            </div>
          )}
          {logic_duplicates_qty && (
            <div className={'monitoring-item'}>
              <div className={'traces-info'}>
                <Tooltip
                  id="indicatorTooltip"
                  title={'Nombre de défaut d’unicité de numéros de lot'}
                  color={'#ffffff'}
                >
                  <span>{logic_duplicates_qty}</span>
                </Tooltip>
                doublons logiques
              </div>
              <Progress
                percent={logic_duplicates_percentage}
                showInfo={false}
                strokeColor={'#EB2C02'}
                trailColor={'#00CEAA'}
              />
            </div>
          )}
        </div>
      </div>
    </SIndicatorContainer>
  );
};

export default TraceabilityQuality;
