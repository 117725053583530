import React from 'react';
import TraceupBoard from './containers/TraceupBoard';

const Traceup = () => (
  <React.Fragment>
    <TraceupBoard />
  </React.Fragment>
);

export default Traceup;
