import styled from 'styled-components';
import { colors, font } from '../../../../../../App/styleVariables';

export const SPopoverWrapper = styled.div`
  max-width: 350px;
  font-family: ${({ theme }) =>
    `${theme?.dashboard?.fontFamily}, sans-serif` || font};
  p {
    margin-bottom: 5px;
  }
  .popover-title {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .color-description {
    display: flex;
    margin-bottom: 10px;

    span:not(.color-dot) {
      width: calc(100% - 20px);
    }
  }
  .color-dot {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;

    &.minimal {
      background-color: ${colors.success};
    }
    &.in-progress {
      background-color: ${colors.inProgress};
    }
    &.critical {
      background-color: ${colors.critical};
    }
    &.significant {
      background-color: ${colors.significant};
    }
    &.important {
      background-color: ${colors.important};
    }
    &.informative {
      background-color: ${colors.informative};
    }
  }
`;
