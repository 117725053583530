import { FC, useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Form,
  Input,
  Image,
  Spin,
  Descriptions,
  Collapse,
} from 'antd';
import { closeGenericModalAction } from 'containers/GenericModal/reducer';
import { SFormWrapper } from './styled-components';
import { conformityFormModal } from 'components/Modal/constants';
import { store } from 'store/nextStore';
import { CaretRightOutlined } from '@ant-design/icons';
import Badge from 'components/Badge';
import FilesList from './components/FilesList';

const ConformityForm: FC<any> = ({ userValues, isEdit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [commonConformityStatus, setCommonConformityStatus] = useState<any>();
  const [commonQualityStatus, setCommonQualityStatus] = useState<any>();
  const [form] = Form.useForm();

  const {
    ProductImageUrl,
    ProductName,
    Reference,
    GTIN,
    Batch,
    ConformityFiles,
    QualityFiles,
    Industry,
  } = userValues;

  const onFinish = (values) => {
    setIsSubmitting(true);
    setTimeout(() => {
      store.dispatch(closeGenericModalAction({ name: conformityFormModal }));
    }, 1500);
  };

  const onFieldsChange = (_changed, all) => {
    const allConformityFiles = all.filter((item) =>
      item.name.includes('ConformityFiles')
    );
    let conformityStatus = getSectionStatus(
      allConformityFiles,
      'value',
      'Conformity'
    );

    const allQualityFiles = all.filter((item) =>
      item.name.includes('QualityFiles')
    );
    let qualityStatus = getSectionStatus(allQualityFiles, 'value', 'Quality');

    setCommonConformityStatus(conformityStatus);
    setCommonQualityStatus(qualityStatus);
  };

  const getSectionStatus = (files, fieldName, statusType) => {
    if (!files) return null;

    const allStatuses = files?.map((item) => item[fieldName]);

    if (
      allStatuses?.some(
        (status) => status === 'Refused' || status === 'Validation pending'
      ) &&
      statusType === 'Conformity'
    ) {
      return {
        title: 'Missing elements',
        customColor: {
          background: '#fff7e7',
          text: '#d88f00',
        },
      };
    }

    if (
      allStatuses?.some((status) => status === 'Refused') &&
      statusType === 'Quality'
    ) {
      return {
        title: 'Refused',
        customColor: {
          "background": "#ffeded",
          "text": "#FF4D4D"
        },
      };
    }

    if (
      allStatuses?.some((status) => status === 'Validation pending') &&
      statusType === 'Quality'
    ) {
      return {
        title: 'Validation pending',
      };
    }

    return {
      title: 'Complete & Validated',
      customColor: {
        background: '#e6faf2',
        text: '#09cf7d',
      },
    };
  };

  useEffect(() => {
    setCommonConformityStatus(
      getSectionStatus(ConformityFiles, 'status', 'Conformity')
    );
  }, [ConformityFiles]);

  useEffect(() => {
    setCommonQualityStatus(getSectionStatus(QualityFiles, 'status', 'Quality'));
  }, [QualityFiles]);

  return (
    <SFormWrapper>
      <Form
        name="edit_statuses"
        layout="vertical"
        initialValues={userValues}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        form={form}
      >
        {ProductImageUrl && (
          <Flex justify="center" style={{ marginBottom: '24px' }}>
            <Image src={ProductImageUrl} className="product-image" />
          </Flex>
        )}

        <Descriptions column={1}>
          <Descriptions.Item label="Product name">
            {ProductName}
          </Descriptions.Item>
          <Descriptions.Item label="Product reference">
            {Reference}
          </Descriptions.Item>
          <Descriptions.Item label="GTIN">{GTIN}</Descriptions.Item>
          <Descriptions.Item label="Batch number">{Batch}</Descriptions.Item>
          <Descriptions.Item label="Industrial process">{Industry}</Descriptions.Item>
        </Descriptions>

        <Collapse
          items={[
            {
              key: '1',
              label: (
                <Flex align="center" className="status-section-label">
                  <p> Conformity status </p>
                  {commonConformityStatus && (
                    <Badge
                      title={commonConformityStatus.title}
                      customColor={commonConformityStatus.customColor}
                    />
                  )}
                </Flex>
              ),
              children: (
                <FilesList list={ConformityFiles} fieldName="ConformityFiles" isEdit={isEdit} />
              ),
            },
          ]}
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          bordered={false}
          className="status-section"
        />

        <Collapse
          items={[
            {
              key: '1',
              label: (
                <Flex align="center" className="status-section-label">
                  <p> Quality status </p>
                  {commonQualityStatus && (
                    <Badge
                      title={commonQualityStatus.title}
                      customColor={commonQualityStatus.customColor}
                    />
                  )}
                </Flex>
              ),
              children: (
                <FilesList list={QualityFiles} fieldName="QualityFiles" isEdit={isEdit} />
              ),
            },
          ]}
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          bordered={false}
          className="status-section"
        />

        {isEdit && (
          <Form.Item name="Comment" label="Comment">
            <Input.TextArea allowClear />
          </Form.Item>
        )}

        <Form.Item style={{ marginBottom: 0 }}>
          <Flex gap="small" justify="flex-end">
            <Button
              onClick={() =>
                store.dispatch(
                  closeGenericModalAction({ name: conformityFormModal })
                )
              }
              className="cancel-button"
            >
              Cancel
            </Button>
            {isEdit && (
              <Spin size="small" spinning={isSubmitting}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                >
                  Save
                </Button>
              </Spin>
            )}
          </Flex>
        </Form.Item>
      </Form>
    </SFormWrapper>
  );
};

export default ConformityForm;