import React from 'react';
import StyledCard from './styled-component';
import Parser from 'html-react-parser';

interface CardProps {
  image?: string;
  title?: string;
  businessName?: string;
  content?: any;
  styles?: {
    main?: any;
    title?: any;
    content?: any;
    size?: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const Card = ({
  image,
  title,
  content,
  businessName,
  styles,
}: CardProps) => {
  const imageSrc = `${process.env.REACT_APP_BUCKET_NAME}/${businessName}/public/images/${image}`;

  const cardContent =
    Array.isArray(content) &&
    content.map(({ title, text }) => {
      const textContent =
        Array.isArray(text) &&
        text.map((string, index) => (
          <p
            key={`text_${index}`}
            className={`content-item-text ${styles?.content?.text?.dot ? 'dot-item' : ''}`}
          >
            {Parser(string)}
          </p>
        ));
      return (
        <div key={title} className={'content-item'}>
          {title && <p className={'content-item-title'}>{title}</p>}
          {textContent}
        </div>
      );
    });

  return (
    <StyledCard styles={styles}>
      {title && (
        <p className={'card-title'}>
          {title}
        </p>
      )}
      <div className={'content-wrapper'}>
        {image && (
          <div className={'card-icon'}>
            <img src={imageSrc} alt="Card icon" />
          </div>
        )}
        {cardContent && (
          <div className={'card-items-wrapper'}>{cardContent}</div>
        )}
      </div>
    </StyledCard>
  );
};

export default Card;
