import React from 'react';
import { CustomSelect } from './components';
import Card from '../Card';
import StyledBlock from './styled-component';
import components from '../../components';

type SelectOption = {
  value: string;
  label: string;
}

interface Props {
  key: string;
  selectList: [
    {
      label: string;
      name: string;
      options: SelectOption[];
    }
  ];
  topSectionCard?: {
    display: boolean;
    image: string;
    content?: any;
    styles?: any;
  };
  mainSection: any;
  filters: object | null;
  setFilters: Function;
  businessName?: string;
  styles?: {
    mainContent: any;
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const FiltersBlock = ({
  selectList,
  topSectionCard,
  mainSection,
  styles,
  filters,
  setFilters,
  businessName,
}: Props) => {
  const handleChange = (value: SelectOption, name: string) => {
    setFilters(value ? {
      [name]: value,
    } : null);
  }

  const selectListContent =
    Array.isArray(selectList) &&
    selectList?.map(({ label, name , options}, index) => {
      return (
        <div className={'select-wrapper'} key={`select_${name}`}>
          <p className={'select-label'}> {label} </p>
          <CustomSelect
            name={name}
            value={filters ? filters[name] : null}
            style={{ width: 130 }}
            allowClear
            options={options}
            onChange={(filter) => handleChange(filter, name)}
            showSearch={true}
            filterOption={(input, option: SelectOption) =>
              option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              || option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            className={'select-field'}
          />
        </div>
      );
    });

  const mainSectionContent = Array.isArray(mainSection) && mainSection?.map((item, index) => {
    const { component, styles, data } = item;
    const Component = components[component];

    return Component ? (
      <Component
        {...data}
        styles={styles}
        key={`filters_block_main_${index}`}
        businessName={businessName}
      />
    ) : null;
  });

  return (
    <StyledBlock styles={styles}>
      <div className={'select-list-wrapper'}>{selectListContent}</div>
      {topSectionCard?.display && (
        <div className={'top-section-wrapper'}>
          <Card
            image={topSectionCard?.image}
            content={topSectionCard?.content}
            businessName={businessName}
            styles={topSectionCard?.styles}
          />
        </div>
      )}
      <div className={'main-section-wrapper'}>
        {mainSectionContent}
      </div>
    </StyledBlock>
  );
};

export default FiltersBlock;
