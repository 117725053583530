import React from 'react';
import { connect } from 'react-redux';
import { Button, Spin } from 'antd';
import PropTypes from 'prop-types';
import { ReloadOutlined, RollbackOutlined } from '@ant-design/icons';
import UniqIdsGenerator from '../../lib/uniqIdsGenerator';
import { SCard } from './style';

const id_generator = new UniqIdsGenerator('cardwrapper');

const checkForReloadVisibility = ({ isBackActive, userCompany }) =>
  !isBackActive && userCompany.kind !== 'FabricBrand'; // hard code

class CardWrapper extends React.Component {
  state = {
    isReload: false,
  };

  toggleReload = () => {
    this.setState((prevState) => ({
      isReload: !prevState.isReload,
    }));
  };

  reloadChildren = (e) => {
    e.preventDefault();
    const { refreshFunc } = this.props;
    if (refreshFunc) {
      refreshFunc();
    } else {
      this.toggleReload();
      setTimeout(this.toggleReload, 1500);
    }
  };

  handleOnBack = () => {
    const { onBack } = this.props;
    onBack();
  };

  render() {
    const { title, children, isBackActive, pageName, schema } = this.props;
    const { isReload } = this.state;

    let defaultTitleValue;
    switch (pageName) {
      case 'SludgeAnalysis':
        defaultTitleValue = 'Validation des analyses de boues';
        break;
      case 'CompAnalysis':
        defaultTitleValue = 'Alertes analyses (compost)';
        break;
      case 'SoilAnalysis':
        defaultTitleValue = 'Validation des analyses de sols';
        break;
      case 'Stocking':
        defaultTitleValue = 'Validation des déstockages';
        break;
      case 'BactMudAnalysis':
        defaultTitleValue = 'Validation des analyses bactériologiques de boues';
        break;
      case 'GreasyWoolLot':
        defaultTitleValue = 'Greasy Wool Lot Stock List';
        break;
      case 'SalesStock':
        defaultTitleValue = 'Sales Stock List';
        break;
      case 'WoolTopLots':
        defaultTitleValue = 'Wool Top Lots List';
        break;
      case 'SpinningSales':
        defaultTitleValue = 'Spinning Sales List';
        break;
      default:
        defaultTitleValue = `${schema?.[pageName]?.label}`;
        break;
    }
    let currentTitle = schema?.[pageName]?.title || title || defaultTitleValue;

    return (
      <SCard>
        {isBackActive && (
          <h3>
            <Button icon={<RollbackOutlined />} onClick={this.handleOnBack} />
            <span> Back </span>
          </h3>
        )}
        {checkForReloadVisibility(this.props) && (
          <div className="title-wrapper">
            <div className="title">{currentTitle}</div>
            <div
              id={id_generator.get_id('refresh')}
              className="refresh"
              onClick={this.reloadChildren}
            >
              <Button
                className="refresh-icon"
                icon={<ReloadOutlined />}
                disabled={isReload}
                loading={isReload}
              />
              <span className="refresh-text">Refresh</span>
            </div>
          </div>
        )}
        {isReload ? (
          <Spin style={{ display: 'block' }} size="large" />
        ) : (
          children
        )}
      </SCard>
    );
  }
}

CardWrapper.propTypes = {
  /**
   * Callback function for _Back_ button.
   */
  onBack: PropTypes.func,
  /**
   * Hardcoded title.
   */
  title: PropTypes.string,
  /**
   * The Table Сomponent that will be wrapped by _CardWrapper_ component. It can be one React Element or several. Then they should be in an array.
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  /**
   * Flag indicating whether the _Back_ button will be displayed.
   */
  isBackActive: PropTypes.bool,
  /**
   * Name for page - Key from the _page_schema_ object.
   */
  pageName: PropTypes.string.isRequired,
  /**
   * _page_schema_ from API response for current business.
   */
  schema: PropTypes.object.isRequired,
  /**
   * Function that will be called by Refresh button
   */
  refreshFunc: PropTypes.func,
};

export default connect(
  ({
    auth: {
      currentUser: {
        attributes: { company, pages_scheme },
      },
    },
  }) => ({
    userCompany: company,
    schema: pages_scheme,
  }),
  {}
)(CardWrapper);
