import React from 'react';
import PropTypes from 'prop-types';
import ShowTraceList from 'components/ShowTraceList';

const ShowTrace = (props) => {
  const { recordData } = props;
  return <ShowTraceList data={recordData} />;
};

ShowTrace.propTypes = {
  recordData: PropTypes.any,
};

export default ShowTrace;
