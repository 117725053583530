import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Cell,
} from 'recharts';
import { SIndicatorContainer, SLegendItem } from './styled-components';

const BarIndicator = ({
  styles,
  bordered,
  title,
  data,
  chartType,
  customLegend = false,
  xaxis,
  yaxis,
  chartLayout,
  hideYAxis,
  horizontalGrid,
  verticalGrid,
  barsConfig,
  customTooltip = false,
  margin,
  displayLegend = true,
  barSize,
  barRadius,
  legendLayout = 'vertical',
  legendAlign = 'right',
  legendVerticalAlign = 'middle',
  legendHeight,
  legendIconSize,
}: any) => {
  const getContentByType = () => {
    switch (chartType) {
      case 'multipleCell':
        return (
          <Bar
            dataKey="value"
            barSize={barSize}
            radius={barRadius}
            unit={data[0]?.unit}
          >
            {data?.map((entry, index) => {
              return <Cell key={`cell-${index}`} fill={entry.color} />;
            })}
          </Bar>
        );
      default:
        return barsConfig?.map((bar, index) => {
          return (
            <Bar
              key={`bar-${bar.dataKey}-${index}`}
              dataKey={bar.dataKey}
              stackId={bar.stackId}
              fill={bar.color}
              barSize={bar.barSize}
              minPointSize={bar.minPointSize}
              unit={bar.unit}
            />
          );
        });
    }
  };

  const renderLegend = () => {
    return (
      <ul className={'custom-legend'}>
        {data?.map((entry, index) => (
          <SLegendItem key={`item-${index}`} color={entry.color}>
            <span className={'legend-circle'}></span>
            {entry.name}
          </SLegendItem>
        ))}
      </ul>
    );
  };

  const CustomChartTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={'custom-bar-tooltip'}>
          <p
            className={'label'}
          >{`${label} : ${payload[0].value} ${payload[0].payload?.unit}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <p className={'indicator-title'}>{title}</p>
      <div className={'bar-chart'}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data} layout={chartLayout} margin={margin}>
            <CartesianGrid
              stroke="#f5f5f5"
              horizontal={horizontalGrid}
              vertical={verticalGrid}
            />
            {chartLayout === 'vertical' ? (
              <>
                <XAxis
                  type="number"
                  unit={xaxis?.unit}
                  label={{
                    value: xaxis?.label,
                    position: xaxis?.labelPosition,
                    offset: xaxis?.labelOffset,
                  }}
                />
                <YAxis type="category" dataKey="name" hide={hideYAxis} width={yaxis?.width} />
              </>
            ) : (
              <>
                <XAxis dataKey="name" />
                <YAxis
                  unit={yaxis?.unit}
                  tickCount={yaxis?.tickCount}
                  label={{
                    value: yaxis?.label,
                    position: yaxis?.labelPosition,
                    offset: yaxis?.labelOffset,
                  }}
                  width={yaxis?.width}
                  domain={yaxis?.domain}
                />
              </>
            )}
            <Tooltip content={customTooltip && <CustomChartTooltip />} />
            {displayLegend && (
              <Legend
                layout={legendLayout}
                verticalAlign={legendVerticalAlign}
                align={legendAlign}
                iconType="circle"
                iconSize={legendIconSize}
                height={legendHeight}
                content={customLegend && renderLegend}
              />
            )}
            {getContentByType()}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </SIndicatorContainer>
  );
};

export default BarIndicator;
