import * as d3 from 'd3';
import { getCoordinatesFromTransform } from './getCoordinatesFromTransform';
import { NODE_HEIGHT, NODE_WIDTH, OFFSET_SCALE_PARAM } from '../constants';

export const handleZoomAll = (svg, zoom) => {
  const svgSizes = svg.node().getBoundingClientRect();
  const svgWidth = svgSizes.width;
  const svgHeight = svgSizes.height;
  const nodes = svg.selectAll('.nodes').nodes();

  const firstNodeCoordinates = getCoordinatesFromTransform(nodes[0]);
  let minX = firstNodeCoordinates.x;
  let minY = firstNodeCoordinates.y;
  let maxX = firstNodeCoordinates.x;
  let maxY = firstNodeCoordinates.y;

  nodes.forEach((node) => {
    const nodeCoordinates = getCoordinatesFromTransform(node);

    minX = Math.min(minX, nodeCoordinates.x - NODE_WIDTH / 2);
    minY = Math.min(minY, nodeCoordinates.y - NODE_HEIGHT / 2);
    maxX = Math.max(maxX, nodeCoordinates.x + NODE_WIDTH / 2);
    maxY = Math.max(maxY, nodeCoordinates.y + NODE_HEIGHT / 2);
  });

  const contentWidth = maxX - minX;
  const contentHeight = maxY - minY;

  const initialScale =
    Math.min(svgWidth, svgHeight) / Math.max(contentWidth, contentHeight);
  const offsettedScaleValue = initialScale * OFFSET_SCALE_PARAM;
  const scaleValue = offsettedScaleValue > 1 ? 1 : offsettedScaleValue;

  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;
  const scaledCenterX = svgWidth / 2 - centerX * scaleValue;
  const scaledCenterY = svgHeight / 2 - centerY * scaleValue;

  const zoomTransition = d3.transition().delay(100).duration(700);

  svg
    .transition(zoomTransition)
    .call(
      zoom.transform,
      d3.zoomIdentity.translate(scaledCenterX, scaledCenterY).scale(scaleValue)
    );
};
