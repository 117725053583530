import styled from 'styled-components';
import { colors, font } from 'App/styleVariables';

export const SFormWrapper = styled.div`
  margin-top: 20px;
  font-family: ${font};

  .email {
    color: #424242;
  }

  textarea {
    min-height: 300px;
    max-height: 700px;
  }

  .ant-form-item-label {
    color: #424242;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    &:hover,
    &:focus {
        border: ${({ theme }) => theme.form.item?.input?.borderActive} !important;
    }
  }

  .cancel-button {
    font-size: ${(props) => props.theme.form.backButton.size} !important;
    font-weight: ${(props) => props.theme.form.backButton.weight} !important;
    font-family: ${(props) => props.theme.form.backButton.family} !important;
    color: ${({ theme }) => theme.form.backButton.colorPassive} !important;
    border: ${({ theme }) => theme.form.backButton.borderPassive} !important;

    &:hover {
      color: ${({ theme }) =>
        theme.form.backButton.colorActive || colors.secondary} !important;
      border: ${({ theme }) =>
        theme.form.backButton.borderActive ||
        `1px solid ${colors.secondary}`} !important;
    }
  }

  .submit-button {
    font-size: ${(props) => props.theme.form.submitButton?.size} !important;
    font-weight: ${(props) => props.theme.form.submitButton?.weight} !important;
    font-family: ${(props) => props.theme.form.submitButton?.family} !important;
    color: ${(props) => props.theme.form.submitButton?.color} !important;
    border: none !important;
    background: ${(props) => props.theme.form.submitButton?.passive} !important;

    &:hover {
      color: ${(props) => props.theme.form.submitButton?.color} !important;
      background: ${(props) =>
        props.theme.form.submitButton?.active} !important;
    }
  }
`;