import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { CardStyles } from '../../types';

export const SIndicatorContainer = styled(SIndicator)<CardStyles>`
  .ant-card-body {
    .indicator-title {
      text-transform: ${(props) => props?.styles?.title?.textTransform};
      margin-bottom: ${(props) => props?.styles?.title?.marginBottom};
    }
  }

  .bar-chart {
    width: 100%;
    height: ${(props) => props?.styles?.chart?.height || '330px'};
    margin: 0 auto;

    .recharts-default-legend {
      display: ${(props) => props?.styles?.legend?.display};
      flex-direction: ${(props) => props?.styles?.legend?.flexDirection};
      
      li {
        margin-right: 0 !important;
        margin-left: ${(props) => props?.styles?.legend?.item?.marginLeft || '8px'};
        display: ${(props) => props?.styles?.legend?.item?.display} !important;
        justify-content: ${(props) => props?.styles?.legend?.item?.justifyContent};
        align-items: ${(props) => props?.styles?.legend?.item?.alignItems}
      }
    }

    .recharts-label {
      color: ${(props) => props?.styles?.chart?.label?.color};
      font-size: ${(props) => props?.styles?.chart?.label?.fontSize};
      font-weight: ${(props) => props?.styles?.chart?.label?.fontWeight};
    }

    .recharts-tooltip-item-list {
      display: ${(props) => props?.styles?.chart?.tooltip?.display};
      flex-direction: ${(props) => props?.styles?.chart?.tooltip?.flexDirection};
    }
  }
  .recharts-responsive-container {
    margin: 0 auto;
  }
  
  .recharts-default-tooltip {
    .recharts-tooltip-item-unit {
      margin-left: 4px;
    }
  }

  .custom-legend {
    padding-left: 20px;

    @media (max-width: 1440px) {
      padding-left: 8px;
    }
  }

  .custom-bar-tooltip {
    background-color: #ffffff;
    padding: 15px 10px;
    border: 1px solid #ccccccff;
  }
`;

export const SLegendItem = styled.li<{ color: string }>`
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .legend-circle {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-right: 4px;
  }

  @media (max-width: 1440px) {
    font-size: 12px;
  }
`;
