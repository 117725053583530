export const getValidationFieldName = (business: String) => {
  const name = business.toLowerCase();
  if (name.includes('sb-') && name !== 'sb-nm') {
    return 'confirmee';
  }
  return 'Validated';
};


export const getValidationValue = (business: String) => {
  const name = business.toLowerCase();
  if (name.includes('sb-') && name !== 'sb-nm') {
    return 'Confirmee';
  }
  return 'validee';
};


export const getInvalidationValue = (business: String) => {
  const name = business.toLowerCase();
  if (name.includes('sb-') && name !== 'sb-nm') {
    return 'Infirmee';
  }
  return 'invalidee';
};


export const getValidateAllButtonLabel = (business: String) => {
  const name = business.toLowerCase();
  if (name.includes('sb-') && name !== 'sb-nm') {
    return 'Confirmer cohérentes';
  }
  return 'Valider cohérentes';
};

//true for sb-nm and false for sb-gene ( sb-xx )
export const getCoherence = (business: String) => {
  const name = business.toLowerCase();
  if (name.includes('sb-') && name !== 'sb-nm') {
    return { non: true};
  }
  return { oui: true};
};
