import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { store, history } from 'store/nextStore';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

import AppRoutes from '../routes/AppRoutes';
import { DocumentsItemForm } from '../sections/Documents/containers/DocumentListForm/documents-item-form';
import ErrorCatcher from './components/ErrorCatcher';
import ShowTraceList from '../components/ShowTraceList';
import DownloadTraceHotkey from '../components/DownloadTraceHotkey';
import SupportForm from '../components/SupportForm';
import BuildWarning from '../components/BuildWarning';
import SendEmailForm from '../components/SendEmailForm';
import ConformityForm from '../components/ConformityForm';
import ContactSupplier from '../components/ContactSupplier';
import IndustrialProcess from '../components/IndustrialProcess';
import FormWorker from '../containers/FormWorker';
import ChainDetails from '../containers/ChainDetails';
import QrCodePreview from '../containers/QRCodePreview';
import ThemeWrapper from '../containers/ThemeWrapper';
import withGenericModal from '../containers/GenericModal/component';
import FileDetails from '../containers/FileDetails';
import SmsDetails from '../containers/SmsDetails';
import Gallery from '../containers/Gallery';
import HistoryDetails from '../containers/HistoryDetails';
import BusinessPicker from '../containers/BusinessPicker';

import { verifyUser } from '../lib/auth/verifyUser';
import { FILE_SELECT_MODAL_NAME } from '../lib/modal-names';
import {
  addFileGalleryModal,
  chainsModal,
  formWorkerModal,
  showModal,
  qrCodeModal,
  fileUploadModal,
  smsDetailsModal,
  downloadTraceModal,
  historyTableModal,
} from '../sections/GenericTablePage/constants';
import {
  supportModal,
  businessPickerModal,
  sendEmailFormModal,
  conformityFormModal,
  contactSupplierModal,
  industrialProcessModal,
} from '../components/Modal/constants';
import { clearSessionStorage } from '../lib/util';

import 'antd/dist/reset.css';
import GlobalStyle from './globalStyles';
import FontFamily from './font-family';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

verifyUser();
let persistor = persistStore(store);

// Create a client
const queryClient = new QueryClient();

const ShowTraceListModal = withGenericModal(ShowTraceList, showModal, {});
const DownloadTraceHotkeyModal = withGenericModal(
  DownloadTraceHotkey,
  downloadTraceModal,
  {
    children: ShowTraceList,
  }
);
const FormWorkerModal = withGenericModal(FormWorker, formWorkerModal, {});
const ChainsModal = withGenericModal(ChainDetails, chainsModal, {});
const QrCodeModal = withGenericModal(QrCodePreview, qrCodeModal, {});
const FileUploadModal = withGenericModal(FileDetails, fileUploadModal, {});
const SmsDetailsModal = withGenericModal(SmsDetails, smsDetailsModal, {});
const AddGalleryFileModalWindow = withGenericModal(
  DocumentsItemForm,
  addFileGalleryModal,
  {}
);
const ViewGalleryModal = withGenericModal(Gallery, FILE_SELECT_MODAL_NAME, {});
const HistoryTraceModal = withGenericModal(
  HistoryDetails,
  historyTableModal,
  {}
);
const SupportModal = withGenericModal(SupportForm, supportModal, {});
const BusinessPickerModal = withGenericModal(
  BusinessPicker,
  businessPickerModal,
  {},
  clearSessionStorage
);
const SendEmailFormModal = withGenericModal(SendEmailForm,sendEmailFormModal,{});
const ConformityFormModal = withGenericModal(ConformityForm,conformityFormModal,{});
const ContactSupplierModal = withGenericModal(ContactSupplier,contactSupplierModal,{});
const IndustrialProcessModal = withGenericModal(IndustrialProcess,industrialProcessModal,{});

class App extends Component {
  async componentDidMount() {
    const jimoProjectId = process.env.REACT_APP_JIMO_PROJECT_ID;

    if (!jimoProjectId || window.jimo != null) {
      return;
    }
    window.jimo = [];

    const s = document.createElement('script');

    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://undercity.usejimo.com/jimo-invader.js';
    window['JIMO_PROJECT_ID'] = jimoProjectId;

    document.getElementsByTagName('head')[0].appendChild(s);
  }
  
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <FontFamily />
            <GlobalStyle />
            <ErrorCatcher>
              <QueryClientProvider client={queryClient}>
                <AppRoutes />
                <ThemeWrapper>
                  <BuildWarning />
                  <ShowTraceListModal />
                  <FormWorkerModal />
                  <ChainsModal />
                  <QrCodeModal />
                  <FileUploadModal />
                  <SmsDetailsModal />
                  <DownloadTraceHotkeyModal />
                  <AddGalleryFileModalWindow />
                  <ViewGalleryModal />
                  <HistoryTraceModal />
                  <SupportModal />
                  <BusinessPickerModal />
                  <SendEmailFormModal />
                  <ConformityFormModal />
                  <ContactSupplierModal />
                  <IndustrialProcessModal />
                </ThemeWrapper>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </ErrorCatcher>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
