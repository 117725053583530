import React from 'react';
import {
  ResponsiveContainer,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  Legend,
} from 'recharts';
import QuestionMarkPopover from '../QuestionMarkPopover';
import { STOCKS_COLORS } from '../../constants';
import { QuantityProducedInterface } from '../../types';
import { SIndicatorContainer } from './styled-components';

const QuantityProduced = ({
  styles,
  bordered,
  data,
  areas,
  yAxisRightValue,
  yAxisLeftValue,
  title,
  questionMark = false,
  questionMarkPopover,
}: QuantityProducedInterface) => {
  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <p className={'indicator-title'}>
        {title || 'Quantités produites'}
        {questionMark && (
          <QuestionMarkPopover popoverName={questionMarkPopover} />
        )}
      </p>
      <div className={'chart'}>
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{
              top: 30,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <Legend
              verticalAlign="top"
              layout={'horizontal'}
              iconType={'circle'}
              iconSize={16}
            />
            <CartesianGrid stroke="#f5f5f5" vertical={false} />
            <XAxis dataKey="name" />
            <YAxis
              yAxisId="left"
              label={{
                value: yAxisLeftValue || 'tonnes',
                position: 'insideTopLeft',
                offset: 5,
                dy: -27,
              }}
              width={55}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              label={{
                value: yAxisRightValue || 'tonnes',
                position: 'insideTopRight',
                offset: 5,
                dy: -27,
              }}
              width={55}
            />
            <Tooltip />
            {areas.map(({ key, name, yAxisId, color }, index) => (
              <Area
                type="monotone"
                dataKey={key}
                stackId={index + 1}
                stroke={color || STOCKS_COLORS[index]}
                fill={color || STOCKS_COLORS[index]}
                yAxisId={yAxisId}
                name={name}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </SIndicatorContainer>
  );
};

export default QuantityProduced;
