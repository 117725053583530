import styled from 'styled-components';

const SIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #525659;
  z-index: 100;
`;

const SH1 = styled.h1`
  padding: 10px;
  color: ${props => props.theme.cardwrapper.title.color};
  font-size: ${props => props.theme.cardwrapper.title.size};
  font-family: ${props => props.theme.cardwrapper.title.family};
  font-weight: ${props => props.theme.cardwrapper.title.weight};
`;

export { SIframe, SH1 };

export default {};
