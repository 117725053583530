import api_client from '../../../../../lib/apiClient';

const GET_BOARD_DATA = 'TRACEUP::GET_BOARD_DATA';
const SET_LOADING = 'TRACEUP::SET_LOADING';
const SET_INITIAL_STATE = 'TRACEUP::SET_INITIAL_STATE';
const SET_NO_ERRORS = 'TRACEUP::SET_NO_ERRORS';

const trace_up_error = ({ is_error = false, msg = '' }) => ({ is_error, msg });

const initial_state = {
  data: null,
  loading: false,
  error: trace_up_error({}),
};

export const get_board_data_action = (params) => async (dispatch) => {
  try {
    const { type, numerolot } = params;
    if (type === 'json') dispatch({ type: SET_LOADING });

    const response = await api_client.get_traceup(params);

    if (type === 'pdf') {
      localStorage.setItem('details', `${numerolot}.${type}`);
      window.open('/details');
    }

    dispatch({ type: GET_BOARD_DATA, payload: { data: response || [] } });
  } catch (err) {
    dispatch({
      type: GET_BOARD_DATA,
      payload: {
        data: null,
        error: trace_up_error({ is_error: true, msg: err.message }),
      },
    });
  }
};

export const set_initial_state_action = () => ({ type: SET_INITIAL_STATE });

export const set_no_errors_action = () => ({ type: SET_NO_ERRORS });

const handle_loading = (state) => ({
  ...state,
  error: trace_up_error({}),
  loading: true,
});

const handle_board_data_success = (state, payload) => ({
  ...state,
  loading: false,
  ...payload,
});

const handle_initial_state = () => initial_state;

const handle_no_errors = (state) => ({
  ...state,
  error: trace_up_error({}),
});

const handlers = {
  [SET_LOADING]: handle_loading,
  [GET_BOARD_DATA]: handle_board_data_success,
  [SET_INITIAL_STATE]: handle_initial_state,
  [SET_NO_ERRORS]: handle_no_errors,
};

export default (state = initial_state, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action.payload) : state;
};
