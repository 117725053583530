import React from 'react';
import { Progress, Tooltip } from 'antd';
import { QualityControlInterface } from '../../types';
import { SIndicatorContainer } from './styled-components';

const QualityControl = ({
  styles,
  bordered,
  quality_control_qty,
  uncompliant_traces_qty,
  percentage,
}: QualityControlInterface) => {
  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <p className={'indicator-title'}>
        <Tooltip
          id="indicatorTooltip"
          title="Nombre de contrôle qualité réalisés depuis le 01 août 2023"
          color={'#ffffff'}
        >
          <span> {quality_control_qty} </span>
        </Tooltip>
        Contrôles qualité
      </p>
      <div className={'progress-wrapper'}>
        <div className={'traces-info'}>
          <Tooltip
            id="indicatorTooltip"
            title={'Nombre de contrôles qualité non conformes'}
            color={'#ffffff'}
          >
            <span>{uncompliant_traces_qty}</span>
          </Tooltip>
          Non conformes
        </div>
        <Progress
          percent={percentage}
          showInfo={false}
          strokeColor={'#EB2C02'}
          trailColor={'#00CEAA'}
        />
      </div>
    </SIndicatorContainer>
  );
};

export default QualityControl;
