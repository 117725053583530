import { openGenericModalAction } from 'containers/GenericModal/reducer';
import { store } from '../../../store/nextStore';
import dayjs from 'dayjs';
import {
  chainsModal,
  formWorkerModal,
  showModal,
  qrCodeModal,
  fileUploadModal,
  smsDetailsModal,
  downloadTraceModal,
  historyTableModal,
  CUSTOM_MODAL_WIDTH,
  PRODUCTS_ADD_TITLE,
  PRODUCTS_EDIT_TITLE,
  CHAIN_DETAILS_TITLE,
  QR_CODE_TITLE,
  USERS_EDIT_TITLE,
  COMPANIES_EDIT_TITLE,
  TRACES_SHOW_TITLE,
  TRACES_EDIT_TITLE,
  TRACES_HISTORY_TITLE,
  TRACES_SHOW_VERSION_TITLE,
  UPLOADS_SHOW_TITLE,
  UPLOADS_EDIT_TITLE,
  CUSTOMLIST_SHOW_TITLE,
  CUSTOMLIST_EDIT_TITLE,
  CUSTOMLIST_ADD_TITLE,
  SMSCOMFIRMATION_SHOW_TITLE,
} from '../constants';
import {
  USERS_MODAL,
  CUSTOMLIST_MODAL,
  COMPANIES_MODAL,
  TRACES_MODAL,
  CHAINS_MODAL,
  PRODUCTS_MODAL,
  QR_CODE_MODAL,
  UPLOAD_MODAL,
  FILE_UPLOAD_MODAL,
  SMS_MODAL,
  HISTORY_MODAL,
  HISTORY_VERSION_MODAL,
} from '../../../components/Modal/constants';
import show_width_handler from '../../../lib/helpers/width_handlers/ShowWidthHandler';
import {
  ADD_ITEM_ACTION,
  ADD_ORGANIZATION_ACTION,
  ADD_PRODUCT_ACTION,
  ADD_TRACE_ACTION,
  ADD_UPLOAD_ACTION,
  ADD_USER_ACTION,
  CHAIN_GRAPH_ACTION,
  CHAIN_TABLE_ACTION,
  EDIT_COMPANY_ACTION,
  EDIT_ITEM_ACTION,
  EDIT_OBJECT_ACTION,
  EDIT_PRODUCT_ACTION,
  EDIT_TRACE_ACTION,
  EDIT_UPLOAD_ACTION,
  EDIT_USER_ACTION,
  SHOW_ITEM_ACTION,
  SHOW_OBJECT_ACTION,
  SHOW_LOG_UPLOAD_ACTION,
  SHOW_SMS_ACTION,
  SHOW_TRACE_ACTION,
  SHOW_FILE_UPLOAD_ACTION,
  SHOW_UPLOAD_ACTION,
  QR_CODE_PREVIEW,
  RESEND_ALL_SMS_CONFIRMATION_ACTION,
  SHOW_SMS_CONFIRMATION_ACTION,
  SHOW_USER_ACTION,
  RESEND_SMS_CONFIRMATION_ACTION,
  HISTORY_TRACE_ACTION,
  SHOW_TRACE_VERSION_ACTION,
  SHOW_ANALYSIS_ACTION,
  VALIDATE_DATA_ACTION,
  INVALIDATE_DATA_ACTION,
  VALIDATE_ALL_ACTION,
} from '../actions';
import { define_width } from '../../../lib/util';
import { getCoherence } from '../../../lib/validations/util';
import {
  USERS_ADD_TITLE,
  COMPANIES_ADD_TITLE,
  UPLOADS_ADD_TITLE,
  TRACES_ADD_TITLE,
  ANALYSIS_SHOW_TITLE,
} from '../constants';
import client from '../../../lib/apiClient';
import validationStatusHandler from './validationStatusHandler';

async function actionHandler(
  action: string,
  handleSubmit: Function,
  record: Object | any | undefined,
  args: any
) {
  const {
    data,
    traceId,
    traceType,
    blockNumber,
    txHash,
    id,
    company_id,
    company,
    name,
    kind,
    trace_type,
    created_at,
    displayTraceType,
    object_type,
  } = record;
  const { handleSubTitle, objectType, chainTableName } = args;
  const trace_data = { ...data, traceId, blockNumber, txHash };
  const businessName = args?.user?.business?.name;

  switch (action) {
    // USERS
    case ADD_USER_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: USERS_ADD_TITLE,
            prefixId: USERS_MODAL,
          },
          data: {
            type: 'user',
            user: args?.user,
            isEdit: false,
            formStep: 'formSelection',
            extraKeyName: 'company_id',
            selectLabel: 'Please select the organization of the new user:',
            handleSubTitle: (title: string) => handleSubTitle(title, action),
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            handleSubmit: (data: any, actions: any) =>
              handleSubmit({ data, actions }),
          },
        })
      );
      break;
    case EDIT_USER_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: `${USERS_EDIT_TITLE} ${company?.name}`,
            prefixId: USERS_MODAL,
          },
          data: {
            type: 'user',
            isEdit: true,
            userCompany: company?.name,
            userValues: record,
            formStep: 'formFilling',
            formType: company_id,
            extraKeyName: 'company_id',
            selectLabel: 'Please select the organization of the new user:',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            handleSubmit: (data: any, actions: any) =>
              handleSubmit({
                data: { ...data, id },
                actions,
                user: args?.user,
              }),
          },
        })
      );
      break;
    // CUSTOM LISTS
    case SHOW_OBJECT_ACTION:
    case SHOW_ITEM_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: downloadTraceModal,
          configuration: {
            title: `${CUSTOMLIST_SHOW_TITLE} ${object_type || ''}`,
            width: CUSTOM_MODAL_WIDTH,
            prefixId: CUSTOMLIST_MODAL,
          },
          data: {
            data: record,
            isEdit: false,
          },
        })
      );
      break;
    case ADD_ITEM_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: `${CUSTOMLIST_ADD_TITLE} ${objectType || ''}`,
            width: CUSTOM_MODAL_WIDTH,
            prefixId: CUSTOMLIST_MODAL,
          },
          data: {
            type: 'custom_list',
            listName: args?.path,
            isEdit: false,
            formStep: 'formFilling',
            formType: '',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            selectLabel: 'TO BE DEFINED LABEL',
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({
                data: { ...data },
                path: args?.path,
                actions,
              });
            },
          },
        })
      );
      break;
    case EDIT_OBJECT_ACTION:
    case EDIT_ITEM_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: `${CUSTOMLIST_EDIT_TITLE} ${object_type || ''}`,
            width: CUSTOM_MODAL_WIDTH,
            prefixId: CUSTOMLIST_MODAL,
          },
          data: {
            type: 'custom_list',
            listName: args?.path,
            isEdit: true,
            userValues: record,
            formStep: 'formFilling',
            formType: '',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            selectLabel: 'TO BE DEFINED LABEL',
            title: record.file_name,
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({
                data: { ...data, id },
                path: args?.path,
                actions,
              });
            },
          },
        })
      );
      break;
    // TRACES
    case SHOW_TRACE_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: downloadTraceModal,
          configuration: {
            title: `${TRACES_SHOW_TITLE} ${displayTraceType || ''}`,
            width: define_width({
              target: show_width_handler.get_widths({ data: trace_data }),
            }),
            prefixId: TRACES_MODAL,
          },
          data: {
            data: trace_data,
            traceId,
            isEdit: false,
            isShowTrace: true,
            displayTraceType: displayTraceType,
          },
        })
      );
      break;
    case EDIT_TRACE_ACTION:
      const { traceType: formType } = data;
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: `${TRACES_EDIT_TITLE} ${displayTraceType || ''}`,
            width: 1200,
            prefixId: TRACES_MODAL,
          },
          data: {
            user: args?.user,
            userValues: record.data,
            isEdit: true,
            isShowTrace: false,
            formStep: 'formFilling',
            type: 'trace',
            extraKeyName: 'traceType',
            traceId,
            displayTraceType: record.displayTraceType,
            created_at: record.displayDate,
            formType,
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({
                data,
                traceId,
                created_at: record.displayDate,
                actions,
              });
            },
          },
        })
      );
      break;
    case ADD_TRACE_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: TRACES_ADD_TITLE,
            width: 1200,
            prefixId: TRACES_MODAL,
          },
          data: {
            user: args?.user,
            isEdit: false,
            formStep: 'formSelection',
            type: 'trace',
            extraKeyName: 'traceType',
            traceId,
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 1,
              },
            },
            handleSubTitle: (title: string) => handleSubTitle(title, action),
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({ data, actions });
            },
          },
        })
      );
      break;
    case HISTORY_TRACE_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: historyTableModal,
          configuration: {
            title: `${TRACES_HISTORY_TITLE} ${displayTraceType || ''}`,
            prefixId: HISTORY_MODAL,
          },
          data: {
            traceId,
            displayTraceType: displayTraceType,
          },
        })
      );
      break;
    case SHOW_TRACE_VERSION_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: downloadTraceModal,
          configuration: {
            title: `${TRACES_SHOW_VERSION_TITLE} ${displayTraceType || ''}`,
            width: define_width({
              target: show_width_handler.get_widths({ data: trace_data }),
            }),
            prefixId: HISTORY_VERSION_MODAL,
          },
          data: {
            data: trace_data,
            traceId,
            isEdit: false,
            isShowTrace: true,
          },
        })
      );
      break;
    // CHAINS
    case CHAIN_GRAPH_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: chainsModal,
          configuration: {
            title: `${CHAIN_DETAILS_TITLE} ${traceType} ${traceId}`,
            prefixId: CHAINS_MODAL,
          },
          data: {
            mode: action,
            traceId,
            traceType,
            name: chainTableName,
          },
        })
      );
      break;
    case CHAIN_TABLE_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: chainsModal,
          configuration: {
            title: `${CHAIN_DETAILS_TITLE} ${traceType} ${traceId}`,
            prefixId: CHAINS_MODAL,
          },
          data: {
            mode: action,
            traceId,
            traceType,
            name: chainTableName,
          },
        })
      );
      break;
    // COMPANY
    case EDIT_COMPANY_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: `${COMPANIES_EDIT_TITLE} ${kind || ''}`,
            prefixId: COMPANIES_MODAL,
          },
          data: {
            isEdit: true,
            type: 'company',
            userValues: record,
            userCompany: name || '',
            formStep: 'formFilling',
            formType: kind,
            selectLabel: 'Select Organization Kind:',
            extraKeyName: 'kind',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            handleSubmit: (data: any, actions: any) =>
              handleSubmit({
                data: { ...data, id },
                actions,
              }),
          },
        })
      );
      break;
    case ADD_ORGANIZATION_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: COMPANIES_ADD_TITLE,
            prefixId: COMPANIES_MODAL,
          },
          data: {
            isEdit: false,
            type: 'company',
            formStep: 'formSelection',
            formType: kind,
            selectLabel: 'Select Organization Kind:',
            extraKeyName: 'kind',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            handleSubTitle: (title: string) => handleSubTitle(title, action),
            handleSubmit: (data: any, actions: any) =>
              handleSubmit({ data, actions }),
          },
        })
      );
      break;
    // PRODUCTS
    case ADD_PRODUCT_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: PRODUCTS_ADD_TITLE,
            prefixId: PRODUCTS_MODAL,
          },
          data: {
            type: 'product',
            isEdit: false,
            formStep: 'formFilling',
            formType: '',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            selectLabel: 'Choose Organization for new Product:',
            handleSubmit: (data: any, actions: any) =>
              handleSubmit({ data, actions }),
          },
        })
      );
      break;
    case EDIT_PRODUCT_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: PRODUCTS_EDIT_TITLE,
            prefixId: PRODUCTS_MODAL,
          },
          data: {
            type: 'product',
            isEdit: true,
            userValues: record,
            formStep: 'formFilling',
            formType: '',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            selectLabel: 'Choose Organization for new Product:',
            handleSubmit: (data: any, actions: any) =>
              handleSubmit({
                data: {
                  ...data,
                  id: record.id,
                },
                actions,
              }),
          },
        })
      );
      break;
    // QR CODE
    case QR_CODE_PREVIEW:
      store.dispatch(
        openGenericModalAction({
          name: qrCodeModal,
          configuration: {
            title: QR_CODE_TITLE,
            prefixId: QR_CODE_MODAL,
          },
          data: {
            url: args?.url,
            imageName: args?.imageName,
          },
        })
      );
      break;
    // UPLOADS
    case SHOW_UPLOAD_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: showModal,
          configuration: {
            title: `${UPLOADS_SHOW_TITLE} ${trace_type || ''}`,
            width: 1200,
            prefixId: UPLOAD_MODAL,
          },
          data: {
            data: record,
            formType: record.trace_type,
          },
        })
      );
      break;
    case EDIT_UPLOAD_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: `${UPLOADS_EDIT_TITLE} ${trace_type || ''}`,
            width: 700,
            prefixId: UPLOAD_MODAL,
          },
          data: {
            userValues: record,
            type: 'upload',
            formType: trace_type,
            isEdit: true,
            filter_params: args?.filter_params || { upload_type: 'trace' },
            user: args?.user,
            formStep: 'formFilling',
            extraKeyName: 'trace_type',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 1,
              },
            },
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({
                data,
                traceId: record && record.id,
                actions,
              });
            },
          },
        })
      );
      break;
    case SHOW_FILE_UPLOAD_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: fileUploadModal,
          configuration: {
            title: `Upload File for Upload id ${id || ''}`,
            width: 1200,
            prefixId: FILE_UPLOAD_MODAL,
          },
          data: {
            data: record,
            formType: trace_type,
            isShowFile: true,
            date: dayjs(created_at).format('DD MMM YYYY HH:mm'),
          },
        })
      );
      break;
    case SHOW_LOG_UPLOAD_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: fileUploadModal,
          configuration: {
            title: `Log File for Upload id ${id || ''}`,
            width: 1200,
            prefixId: FILE_UPLOAD_MODAL,
          },
          data: {
            data: record,
            formType: trace_type,
            isShowLog: true,
            date: dayjs(created_at).format('DD MMM YYYY HH:mm'),
          },
        })
      );
      break;
    case ADD_UPLOAD_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: UPLOADS_ADD_TITLE,
            width: 700,
            prefixId: UPLOAD_MODAL,
          },
          data: {
            type: 'upload',
            user: args?.user,
            formStep: 'formSelection',
            selectLabel: 'Select Type :',
            isEdit: false,
            extraKeyName: 'trace_type',
            filter_params: args?.filter_params || { upload_type: 'trace' },
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 1,
              },
            },
            handleSubTitle: (title: string) => handleSubTitle(title, action),
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({
                data,
                filter_params: args?.filter_params,
                actions,
              });
            },
          },
        })
      );
      break;
    // SMS
    case SHOW_SMS_CONFIRMATION_ACTION:
    case SHOW_SMS_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: smsDetailsModal,
          configuration: {
            title: `${id?.toString() || ''}`,
            prefixId: SMS_MODAL,
          },
          data: {
            data: record,
          },
        })
      );
      break;
    // SMS CONFIRMATION
    case RESEND_ALL_SMS_CONFIRMATION_ACTION:
      handleSubmit({
        filters: args?.filters,
        query: args?.searchField,
      });
      break;
    case SHOW_USER_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: showModal,
          configuration: {
            title: `${SMSCOMFIRMATION_SHOW_TITLE}`,
            width: 900,
          },
          data: {
            isEdit: false,
            fetchTrace: true,
            traceId: record?.user_trace_id,
          },
        })
      );
      break;
    case RESEND_SMS_CONFIRMATION_ACTION:
      handleSubmit(record?.related_trace_id);
      break;
    // ANALYSIS
    case SHOW_ANALYSIS_ACTION:
      const res = await client.getTraceById(traceId);
      res &&
        store.dispatch(
          openGenericModalAction({
            name: showModal,
            configuration: {
              title: `${ANALYSIS_SHOW_TITLE} ${res?.displayTraceType}`,
              width: 1100,
            },
            data: {
              isEdit: false,
              traceId,
              data: {
                ...JSON.parse(res?.data),
                traceId: res?.traceId,
                blockNumber: res?.blockNumber,
                txHash: res?.txHash,
              },
            },
          })
        );
      break;
    case VALIDATE_DATA_ACTION:
    case INVALIDATE_DATA_ACTION:
      handleSubmit({
        traceId,
        businessName,
        status: validationStatusHandler(action, businessName),
        validation: args?.user?.company?.kind === 'Admin',
      });
      break;
    case VALIDATE_ALL_ACTION:
      handleSubmit({
        data: args?.allData,
        status: validationStatusHandler(action, businessName),
        coherence: getCoherence(businessName),
        businessName,
      });
      break;
    default:
      throw new Error(`Action ${action} not found`);
  }
}

export default actionHandler;
