export const getText = (text: string): string => {
  let resultText = '';

  resultText += splitByWords(text);

  return resultText;
};

const splitByWords = (string: string) => {
  const maxWidth = 200;
  const maxLinesPerField = 2;
  const words = string.split(' ');
  let line = '';
  let lines = 0;
  let result = '';

  words.forEach((word, index) => {
    const tmpLine = line.length > 0 ? line + ' ' + word : word;
    const lineWidth = getTextWidth(tmpLine);

    if (lineWidth <= maxWidth) {
      line = tmpLine;
    } else {
      if (lines < maxLinesPerField - 1) {
        result += getHtmlText(line);
        line = word;
        lines++;
      } else if (lines === maxLinesPerField - 1) {
        const remainingWidth = maxWidth - getTextWidth(line + ' ') - 15;
        const truncatedWord = truncateWordToFitWidth(word, remainingWidth);
        line += ' ' + truncatedWord + '...';
        lines++;
      }
    }

    if (index === words.length - 1 && line.length > 0) {
      result += getHtmlText(line, lines > 0 ? 1.2 : 1.5);
    }
  });

  return result;
};

export const getTextWidth = (text: string): number => {
  const letters = text.split('');
  let sum = 0;

  letters.forEach((letter) => {
    if (letter === letter.toUpperCase()) {
      sum += 6.7;
    } else {
      sum += 5;
    }
  });

  return sum;
};

const truncateWordToFitWidth = (word: string, width: number) => {
  const truncatedWordArr = word
    .split('')
    .filter((_letter, index) => {
      const tmpWord = word.slice(0, index + 1);
      const wordWidth = getTextWidth(tmpWord);

      return wordWidth <= width;
    });

  return truncatedWordArr.join('');
}

const getHtmlText = (text: string, offset?: number): string => {
  const dy = `${offset || 1.5}em`;

  return `<tspan x='0' dy=${dy}>${text}</tspan>`;
};

export const cutLongText = (longText: string, qty: number = 24): string => {
  let returnText = longText;
  if (returnText.length > qty) {
    returnText = `${returnText.slice(0, qty)}...`;
  }

  return returnText;
};
