import React from 'react';
import * as yup from 'yup';

import { Form, Input, Button } from 'antd';
import { Formik, Form as FForm } from 'formik';

const validationSchema = yup.object().shape({
  code: yup
    .string()
    .required('required field'),
});

const initialValues = {
  code: '',
};

const FormItem = Form.Item;

const CodeFormContainer = ({ onCodeSubmit, error, loading }) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(values) => onCodeSubmit(values)}
    validationSchema={validationSchema}
    render={props => CodeForm({ ...props, error, loading })}
  />
);

const CodeForm = ({
  values,
  touched,
  error,
  errors,
  loading,
  handleChange,
  handleBlur,
}) =>
  <FForm style={{ display: 'flex', justifyContent: 'flexStart', margin: '20px 0' }} >
    <FormItem
      help={touched.code && errors.code
        ? errors.code
        : error
          ? error
          : ''
      }
      validateStatus={(touched.code && errors.code) || error ? 'error' : undefined}
    >
      <Input
        name='code'
        placeholder='Please input your Certificate Number'
        value={values.code}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ width: '265px' }}
      />
    </FormItem>
    <FormItem>
      <Button
        type='primary'
        htmlType='submit'
        loading={loading}
        style={{ margin: '0 10px' }}
      >
        Find
      </Button>
    </FormItem>
  </FForm>

export default CodeFormContainer;
