import styled from 'styled-components';
import { font } from '../../../../App/styleVariables';

interface CardStyles {
  styles?: {
    main?: any;
    content?: any;
    title?: any;
    size?: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledCard = styled.div<CardStyles>`
  background: ${({ theme }) =>
    theme?.dashboard?.block?.background || '#ffffff'};
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  font-family: ${({ theme }) => theme?.dashboard?.fontFamily || font};
  box-sizing: border-box;
  padding: ${({ theme, styles }) =>
    styles?.main?.padding || theme?.dashboard?.block?.padding || '20px 15px'};
  height: ${({ styles }) => styles?.main?.height || 'auto'};
  width: ${({ styles }) => styles?.main?.width || 'auto'};
  overflow: ${({ styles }) => styles?.main?.overflow || 'inherit'};
  border: ${({ styles }) => styles?.main?.border || 'none'};
  border-radius: ${({ styles }) => styles?.main?.borderRadius || '0'};
  margin-bottom: ${({ styles }) => styles?.main?.marginBottom || '0'};

  p {
    margin-bottom: 0;
  }
  .card-title {
    font-size: ${({ styles }) => styles?.title?.fontSize || '18px'};
    font-weight: ${({ styles }) => styles?.title?.fontWeight || '700'};
    text-transform: ${({ styles }) =>
      styles?.title?.textTransform || 'initial'};
    margin-bottom: 5px;
  }
  .content-wrapper {
    display: flex;
    flex-flow: ${({ styles }) =>
      styles?.content?.main?.flexFlow || 'row nowrap'};
    flex-flow: row nowrap;
    align-items: flex-start;
  }
  .card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ styles }) => styles?.content?.icon?.width || 'auto'};
    @media (max-width: 1550px) {
      width: auto;
    }

    img {
      width: 70px;
      @media (max-width: 1550px) {
        width: 55px;
      }
    }
  }
  .card-items-wrapper {
    width: ${({ styles }) => styles?.content?.itemsWrapper?.width || 'auto'};
    height: ${({ styles }) => styles?.content?.itemsWrapper?.height || 'auto'};
    margin-top: ${({ styles }) =>
      styles?.content?.itemsWrapper?.marginTop || '0'};
    overflow-y: ${({ styles }) =>
      styles?.content?.itemsWrapper?.overflowY || 'inherit'};
    overflow-x: hidden;
    padding-left: ${({ styles }) =>
      styles?.content?.itemsWrapper?.paddingLeft || '35px'};
    font-style: ${({ styles }) =>
      styles?.content?.itemsWrapper?.fontStyle || 'normal'};

    @media (max-width: 1550px) {
      width: 100%;
      padding-left: 10px;
    }
  }
  .content-item {
    font-size: ${({ styles }) =>
      styles?.content?.itemsWrapper?.fontSize || '16px'};
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .content-item-title {
    font-size: ${({ styles }) => styles?.content?.title?.fontSize || 'inherit'};
    text-transform: ${({ styles }) =>
      styles?.content?.title?.textTransform || 'none'};
    line-height: ${({ styles }) => styles?.content?.title?.lineHeight || '1.5'};
    font-style: ${({ styles }) =>
      styles?.content?.title?.fontStyle || 'normal'};
    margin-bottom: ${({ styles }) =>
      styles?.content?.title?.marginBottom || '0'};
  }
  .content-item-text {
    font-size: ${({ styles }) => styles?.content?.text?.fontSize || 'inherit'};
  }

  .dot-item {
    padding-left: 15px;
    position: relative;
    margin-left: 15px;
  }
  .dot-item::before {
    content: '•';
    color: #000000;
    font-size: ${({ styles }) => styles?.content?.text?.fontSize || 'inherit'};
    position: absolute;
    left: 0;
  }
`;

export default StyledCard;
