import { Tooltip } from 'antd';
import styled from 'styled-components';

const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    color: yellow !important;
    background-color: green !important;
  }
  .ant-tooltip-placement-bottom .ant-tooltip-arrow,
  .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    border-bottom-color: green !important;
  }
`;

export { StyledTooltip };

export default {};
