import { createGlobalStyle } from 'styled-components';

const FontFamily = createGlobalStyle`
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/open-sans/open-sans-cyrillic-ext_latin-ext_vietnamese-regular.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/open-sans/open-sans-cyrillic-ext_latin-ext_vietnamese-600.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/open-sans/open-sans-cyrillic-ext_latin-ext_vietnamese-700.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/source-sans-pro/source-sans-pro-cyrillic-ext_latin-ext_vietnamese-regular.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/source-sans-pro/source-sans-pro-cyrillic-ext_latin-ext_vietnamese-600.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/source-sans-pro/source-sans-pro-cyrillic-ext_latin-ext_vietnamese-700.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Rubik';
    src: url("/fonts/rubik/rubik-300.woff2") format('woff2');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Rubik';
    src: url("/fonts/rubik/rubik-300italic.woff2") format('woff2');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'Rubik';
    src: url("/fonts/rubik/rubik-regular.woff2") format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Rubik';
    src: url("/fonts/rubik/rubik-italic.woff2") format('woff2');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'Rubik';
    src: url("/fonts/rubik/rubik-500.woff2") format('woff2');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Rubik';
    src: url("/fonts/rubik/rubik-500italic.woff2") format('woff2');
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: 'Rubik';
    src: url("/fonts/rubik/rubik-600.woff2") format('woff2');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Rubik';
    src: url("/fonts/rubik/rubik-600italic.woff2") format('woff2');
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: 'Rubik';
    src: url("/fonts/rubik/rubik-700.woff2") format('woff2');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Rubik';
    src: url("/fonts/rubik/rubik-700italic.woff2") format('woff2');
    font-weight: 700;
    font-style: italic;
  }
`;

export default FontFamily;