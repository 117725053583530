import client from '../../../../../lib/apiClient';
import { prepareValidationData } from '../../../../../lib/util';
import { actionSetCommonNetworkFailure } from '../../../../../reducers/network_events';
import { getValidationFieldName } from "../../../../../lib/validations/util";
import { updateData } from '../../../../../containers/SearchData/reducer';

const moduleName = 'STOCKING_TABLE';

const GET_STOCKING_SUCCESS = `${moduleName}::GET_STOCKING_SUCCESS`;
const CHANGE_LOADING_STATUS = `${moduleName}::CHANGE_LOADING_STATUS`;
const SET_FILTER = `${moduleName}::SET_FILTER`;
const SET_INITIAL_STATE = `${moduleName}::SET_INITIAL_STATE`;

const initial_state = {
  data: [],
  table_scheme: [],
  loading: false,
};

export const get_data_action = () => async (dispatch) => {
  try {
    dispatch({ type: CHANGE_LOADING_STATUS });

    const { data, table_scheme } = await client.getStocking();

    dispatch({ type: GET_STOCKING_SUCCESS, payload: { data, table_scheme } });

    dispatch(updateData({ data, table: 'StockingTable' }));
  } catch (e) {
    console.log(e);
    dispatch(actionSetCommonNetworkFailure({ error: e }));
  }
};

export const validate_data_action = ({
  traceId,
  status,
  trace,
  validation,
}) => async (dispatch, getState) => {
  try {
    const data = prepareValidationData({
      trace,
      traceId,
      status,
      validation,
      statusField: getValidationFieldName(
        getState().auth.currentUser.attributes.business.name
      ),
    });

    if (data) {
      await client.putTraces(data);
    }

    dispatch(get_data_action());
  } catch (e) {
    console.log(e);
  }
};

export const get_current_action = ({ traceId }) => async () => {
  try {
    const trace = await client.getTraceById(traceId);
    if (trace) return trace;
  } catch (e) {
    console.log(e);
  }
};

export const validate_all_action = ({ status, data, coherence }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: CHANGE_LOADING_STATUS });
    await Promise.all(
      data.map(async (item) => {
        const { traceId, CalcCoherentTrace } = item;
        if (coherence[CalcCoherentTrace]) {
          const trace = await client.getTraceById(traceId);
          await client.putTraces(
            prepareValidationData({
              trace,
              traceId,
              status,
              statusField: getValidationFieldName(
                getState().auth.currentUser.attributes.business.name
              ),
            })
          );
        }
      })
    );
    dispatch(get_data_action());
  } catch (e) {
    console.log(e);
  }
};

export const set_filter_action = (data) => async (dispatch) =>
  dispatch({ type: SET_FILTER, payload: data });

export const set_initial_state_action = () => ({ type: SET_INITIAL_STATE });

const handle_data_success = (state, payload) => ({
  ...payload,
  loading: false,
});

const handle_loading = (state) => ({ ...state, loading: true });

const handle_filter = (state, payload) => ({ ...state, data: payload });

const handle_initial_state = () => initial_state;

const handlers = {
  [GET_STOCKING_SUCCESS]: handle_data_success,
  [CHANGE_LOADING_STATUS]: handle_loading,
  [SET_FILTER]: handle_filter,
  [SET_INITIAL_STATE]: handle_initial_state,
};

export default (state = initial_state, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action.payload) : state;
};
