import { FC } from 'react';
import SImage from './styled-components';

const Image: FC = ({ imageName, businessName, styles }: any) => {
  if (!imageName) {
    return null;
  }

  const imageSrc = `${process.env.REACT_APP_BUCKET_NAME}/${businessName}/public/images/${imageName}`;

  return <SImage src={imageSrc} styles={styles} />;
};

export default Image;
