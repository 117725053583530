import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import withRouter from '../components/HOC/withRouter';
import routeGenerator from './routeGenerator';
import sections from '../sections';
import ThemeWrapper from '../containers/ThemeWrapper';
import ScrollToTop from '../components/ScrollToTop';
import PageWrapper from '../containers/PageWrapper';

const {
  LoginPage,
  LoginCallbackPage,
  ResetPassword,
  ForgotPassword,
  ConfirmEmail,
} = sections;

class AppRoutes extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(nextProps.pagesSchema, this.props.pagesSchema);
  }

  render() {
    const { pagesSchema, router, isAdmin, kind, isSignedIn } = this.props;

    const routes = routeGenerator({
      pagesSchema,
      isAdmin,
      kind,
      pathname: router?.location?.pathname,
    });

    return (
      <ThemeWrapper>
        <ScrollToTop>
          <Routes>
            <Route
              exact
              path="/sign_in"
              element={isSignedIn ? <Navigate to="/" /> : <LoginPage />}
            />
            <Route
              path="/sign_in/callback"
              element={isSignedIn ? <Navigate to="/" /> : <LoginCallbackPage />}
            />
            <Route
              exact
              path="/reset_password"
              element={isSignedIn ? <Navigate to="/" /> : <ResetPassword />}
            />
            <Route
              exact
              path="/forgot_password"
              element={isSignedIn ? <Navigate to="/" /> : <ForgotPassword />}
            />
            <Route
              exact
              path="/confirm_email"
              element={isSignedIn ? <Navigate to="/" /> : <ConfirmEmail />}
            />
            <Route element={<PageWrapper />}>
              {routes}
              <Route path="*" element={<h1>404 Not Found</h1>} />
            </Route>
          </Routes>
        </ScrollToTop>
      </ThemeWrapper>
    );
  }
}

AppRoutes.propTypes = {
  pagesSchema: PropTypes.object,
  isAdmin: PropTypes.bool,
  kind: PropTypes.string,
};

const mapStateToProps = ({
  auth: {
    currentUser: {
      attributes: { role, company, pages_scheme },
      isSignedIn,
    },
  },
}) => ({
  isAdmin:
    (role === 'Admin' || role === 'SuperAdmin') &&
    company &&
    company.kind === 'Admin',
  kind: company && company.kind,
  pagesSchema: pages_scheme,
  isSignedIn,
});

export default withRouter(connect(mapStateToProps)(AppRoutes));
