import React from 'react';
import { SIndicatorContainer, SLegendItem } from './styled-components';
import xaxis from 'images/cockpit/xaxis.svg';
import yaxis from 'images/cockpit/yaxis.svg';

import digital_carbon_footprint from 'images/cockpit/digital_carbon_footprint.svg';
import public_health from 'images/cockpit/public_health.svg';
import regulations_in_force from 'images/cockpit/regulations_in_force.svg';
import transportation from 'images/cockpit/transportation.svg';
import packaging from 'images/cockpit/packaging.svg';
import cotton from 'images/cockpit/cotton.svg';
import extinction_of_species from 'images/cockpit/extinction_of_species.svg';
import deforestation from 'images/cockpit/deforestation.svg';
import biodiversite from 'images/cockpit/biodiversite.svg';
import extreme_weather from 'images/cockpit/extreme_weather.svg';
import temperature from 'images/cockpit/temperature.svg';
import air_pollution from 'images/cockpit/air_pollution.svg';
import chemical_pollution from 'images/cockpit/chemical_pollution.svg';
import water_intensive_use from 'images/cockpit/water_intensive_use.svg';
import energy_intensive_use from 'images/cockpit/energy_intensive_use.svg';
import animal_wellbeing from 'images/cockpit/animal_wellbeing.svg';
import yicon from 'images/cockpit/y-icon.png';
import xicon from 'images/cockpit/x-icon.png';

const DoubleMaterialityAnalysis = ({ styles, bordered, title }: any) => {
  const CellItem = ({ icon, title }: { icon: string; title: string }) => {
    return (
      <div className={'cell-item'}>
        <img src={icon} alt="cell icon" />
        <p className={'cell-item-title'}>{title}</p>
      </div>
    );
  };

  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <p className={'indicator-title'}>{title}</p>
      <div className={'indicator-container'}>
        <div className={'indicator-wrapper'}>
          <div className={'axis y'}>
            <div className={'axis-labels'}>
              <p className={'axis-label'}>ÉLEVÉE</p>
              <div className={'axis-title-wrapper'}>
                <p className={'axis-title'}>Matérialité financière</p>
                <img src={yicon} alt="Y icon" className={'axis-icon'} />
              </div>
              <p className={'axis-label'}>Faible</p>
            </div>
            <img src={yaxis} alt="Y axis" className={'axis-image'} />
          </div>
          <div className={'content'}>
            <div className={'danger cell'}></div>
            <div className={'danger cell'}></div>
            <div className={'danger cell'}></div>
            <div className={'warning cell'}></div>
            <div className={'warning cell'}>
              <CellItem icon={cotton} title={'Approvisionnement matière'} />
              <CellItem icon={packaging} title={'Emballages & déchets'} />
            </div>
            <div className={'danger cell'}>
              <CellItem icon={biodiversite} title={'Biodiversité'} />
              <CellItem icon={deforestation} title={'Déforestation'} />
              <CellItem
                icon={extinction_of_species}
                title={'Extinction des espèces'}
              />
            </div>
            <div className={'informative cell'}></div>
            <div className={'informative cell'}>
              <CellItem icon={public_health} title={'Santé publique'} />
              <CellItem icon={transportation} title={'Transportation'} />
              <CellItem
                icon={regulations_in_force}
                title={'Réglementations en vigueur'}
              />
            </div>
            <div className={'danger cell'}>
              <CellItem icon={extreme_weather} title={'Intempéries extrêmes'} />
              <CellItem
                icon={temperature}
                title={'Augmentation des températures & niveau des mers'}
              />
              <CellItem icon={air_pollution} title={'Pollution chimique'} />
              <CellItem
                icon={chemical_pollution}
                title={'Pollution de l’air'}
              />
            </div>
            <div className={'minimal cell'}></div>
            <div className={'informative cell'}>
              <CellItem
                icon={digital_carbon_footprint}
                title={'Empreinte carbone du numérique'}
              />
            </div>
            <div className={'danger cell'}>
              <CellItem
                icon={water_intensive_use}
                title={'Utilisation intensive de l’eau'}
              />
              <CellItem
                icon={energy_intensive_use}
                title={'Utilisation intensive de l’énergie'}
              />
              <CellItem icon={animal_wellbeing} title={'Bien-être animal'} />
            </div>
          </div>
          <div className={'zero'}></div>
          <div className={'axis x'}>
            <img src={xaxis} alt="X axis" className={'axis-image'} />
            <div className={'axis-labels'}>
              <p className={'axis-label'}>Faible</p>
              <div className={'axis-title-wrapper'}>
                <p className={'axis-title'}>
                  Matérialité de l’impact (social, économique, naturel)
                </p>
                <img src={xicon} alt="X icon" className={'axis-icon'} />
              </div>
              <p className={'axis-label'}>ÉLEVÉE</p>
            </div>
          </div>
        </div>
        <div className={'legend'}>
          <SLegendItem color={'#e87056'}>
            <span className={'legend-icon'}></span>
            <p className={'legend-label'}>Critique et prioritaire</p>
          </SLegendItem>
          <SLegendItem color={'#f59b5f'}>
            <span className={'legend-icon'}></span>
            <p className={'legend-label'}>Majeur</p>
          </SLegendItem>
          <SLegendItem color={'#f6cf53'}>
            <span className={'legend-icon'}></span>
            <p className={'legend-label'}>Important</p>
          </SLegendItem>
          <SLegendItem color={'#63e1ad'}>
            <span className={'legend-icon'}></span>
            <p className={'legend-label'}>Modéré</p>
          </SLegendItem>
        </div>
      </div>
    </SIndicatorContainer>
  );
};

export default DoubleMaterialityAnalysis;
