import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { MailOutlined } from '@ant-design/icons';

const EmailInput = ({
  name,
  value,
  disabled,
  placeholder,
  handleChange,
  handleBlur,
  isError,
}) => (
  <Input
    name={name}
    value={value}
    disabled={disabled}
    onBlur={handleBlur}
    allowClear={!disabled}
    onChange={handleChange}
    placeholder={placeholder}
    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
    className={isError ? 'has-error' : ''}
    data-name={name}
  />
);

EmailInput.propTypes = {
  /**
   * The name for the current EmailInput. Must be unique in the form.
   */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Input value.
   */
  value: PropTypes.string,
  /**
   * Determine whether the EmailInput is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The placeholder of email input.
   */
  placeholder: PropTypes.string,
  /**
   * General input change event handler.
   */
  handleChange: PropTypes.func,
  /**
   * *onBlur* event handler.
   */
  handleBlur: PropTypes.func,
  /**
   * Shows whether the EmailInput has an error. If '' then there is no errors.
   */
  isError: PropTypes.string,
};

export default EmailInput;
