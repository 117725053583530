import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
const FrontEndTableComponent = React.lazy(() =>
  import(
    '../../../../../sections/GenericTablePage/table/front-end-paginated-table'
  )
);

const ChainDetailsTable = ({
  data: { nodes = [], table_scheme = [] },
  onScreenChange,
  setRecordData,
  pagination,
  sorter,
  filters,
  handleTableChange,
}) => {
  const handlePresentData = (data) =>
    data.map((trace) => {
      const [label, trace_values] = Object.entries(trace).flat();
      return { label, ...trace_values };
    });

  const chainData = handlePresentData(nodes);

  const handleClickOnTableRowAction = ({
    record,
    action,
    onScreenChange,
    setRecordData,
  }) => {
    if (action === 'ShowTrace') {
      onScreenChange({ screenName: action });
      setRecordData(record);
    }
  };

  return (
    <React.Suspense
      fallback={<Spin style={{ display: 'block' }} size="large" />}
    >
      <FrontEndTableComponent
        dataSource={chainData}
        columns={table_scheme}
        pagination={{ ...pagination, showSizeChanger: false }}
        isLoading={!chainData.length}
        getRowKey={(record) => record.traceId}
        toggleArrow={2560}
        onRowAction={(data) =>
          handleClickOnTableRowAction({
            ...data,
            onScreenChange,
            setRecordData,
          })
        }
        isSearchEnabled={false}
        isFrontTableControlled={true}
        handleTableChange={handleTableChange}
        sortedInfo={sorter}
        filteredInfo={filters}
      />
    </React.Suspense>
  );
};

ChainDetailsTable.propTypes = {
  data: PropTypes.object,
  onScreenChange: PropTypes.func,
  setRecordData: PropTypes.func,
};

export default ChainDetailsTable;
