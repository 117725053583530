import React from 'react';
import PlatformLink from './PlatformLink';

/**
 * Returns content or anchor
 * @param {string|boolean} content
 * @param {number} businessId
 * @returns {JSX.Element|string}
 */
const getContentOrHttpLink = (content, businessId) => {
  return typeof content === 'string' && content.startsWith('http') ? (
    <PlatformLink
      content={content}
      businessId={businessId}
    />
  ) : typeof content === 'boolean' ? (
    content.toString()
  ) : (
    content
  );
};

export default getContentOrHttpLink;
