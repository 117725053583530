import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { store } from 'store/nextStore';
import { FILE_SELECT_MODAL_NAME } from 'lib/modal-names';
import {
  closeGenericModalAction,
  openGenericModalAction,
} from '../../GenericModal/reducer';

class FileSelect extends React.Component {
  constructor(props) {
    const { name } = props;
    super();
    this.state = { MODAL_NAME: `${FILE_SELECT_MODAL_NAME}_${name}` };
    this.onSelect = this.onSelect.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handlerFieldValue(replaceValue) {
    const { setFieldValue, name } = this.props;

    if (name.indexOf('DocumentFilename') > -1) {
      setFieldValue(
        name.replace('DocumentFilename', 'DocumentHash'),
        replaceValue.file_hash || replaceValue
      );
      setFieldValue(
        name.replace('DocumentFilename', 'DocumentUrl'),
        replaceValue.file_url || replaceValue
      );
    } else {
      setFieldValue(
        name.replace('Filename', 'Hash'),
        replaceValue.file_hash || replaceValue
      );
      setFieldValue(
        name.replace('Filename', 'Url'),
        replaceValue.file_url || replaceValue
      );
    }
  }

  onChange(event) {
    const { handleChange } = this.props;
    if (event.target.value === '') {
      this.handlerFieldValue('');
      handleChange(event);
    }
  }

  onSelect(file) {
    const { setFieldValue, name } = this.props;

    // TODO replace field values with values from the schema (should be developed on back-end side)
    setFieldValue(name, file.file_name);
    this.handlerFieldValue(file);

    store.dispatch(closeGenericModalAction({ name: FILE_SELECT_MODAL_NAME }));
  }

  render() {
    const {
      value: filename,
      name,
      placeholder,
      handleBlur,
      disabled,
      isError,
    } = this.props;

    // Test for value coming from parent
    if (typeof filename !== 'string') {
      return null;
    }

    return (
      <div>
        <Input
          name={name}
          placeholder={placeholder}
          value={filename}
          onChange={this.onChange}
          onBlur={handleBlur}
          disabled={disabled}
          allowClear={!disabled}
          className={isError ? 'has-error' : ''}
          onClick={() =>
            store.dispatch(
              openGenericModalAction({
                name: FILE_SELECT_MODAL_NAME,
                configuration: {
                  title: 'Choose file',
                  width: '80vw',
                  prefixId: 'gallery',
                },
                data: {
                  parent: 'FileSelect',
                  item: filename,
                  modalName: FILE_SELECT_MODAL_NAME,
                  onSelect: this.onSelect,
                },
              })
            )
          }
          data-name={name}
        />
      </div>
    );
  }
}

FileSelect.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setFieldValue: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  isError: PropTypes.string,
};

const FileSelectWrapper = (props) => <FileSelect {...props} />;

export default FileSelectWrapper;
