import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { notification } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import ForgotForm from './components/ForgotForm';
import SplitScreenWrapper from '../../containers/SplitScreenWrapper';
import client from '../../lib/apiClient';
import { RESULT_TEXT } from './constants';
import { SAuthPageWrapper } from '../../App/global-styled-components';
import { SResult, SBackLink } from './styled-components';

import crystaltrace from 'images/crystaltrace_logo.png';

const ForgotPassword: React.FC = () => {
  const [isSent, setIsSent] = useState<boolean>(false);
  const { state }: any = useLocation();

  const handleSubmit = async (value) => {
    try {
      await client.sendForgotPasswordEmail({
        ...value,
        redirect_url: '',
      });

      setIsSent(true);
    } catch (e) {
      // If there is an error that the user with the current email cannot be found,
      // then we do not need to display this error on the page for security reasons
      if (e instanceof Array) {
        setIsSent(true);
      } else {
        console.log('err', e);
        notification.error({
          placement: 'top',
          message: 'Error',
          description: "An error occured when trying to send the email. Please contact your Administrator.",
          duration: 10,
        });
      }
    }
  };

  return (
    <SplitScreenWrapper>
      <SAuthPageWrapper vertical align={'center'}>
        {!isSent ? (
          <>
            <img
              src={crystaltrace}
              className={'trace-logo'}
              alt="CrystalTrace"
            />
            <h1> Forgot password </h1>
            <p className={'form-description'}>
              Enter your email address account to receive a reset link
            </p>
            <ForgotForm
              initialValues={{ email: state?.email || '' }}
              handleSubmit={handleSubmit}
            />
          </>
        ) : (
          <>
            <img
              src={crystaltrace}
              className={'trace-logo'}
              alt="CrystalTrace"
            />
            <SResult>
              <ExclamationCircleFilled />
              <div>
                <p> {RESULT_TEXT} </p>
                <SBackLink>
                  <Link to={'/sign_in'}>Back to login</Link>
                </SBackLink>
              </div>
            </SResult>
          </>
        )}
      </SAuthPageWrapper>
    </SplitScreenWrapper>
  );
};

export default ForgotPassword;
