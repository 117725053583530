import styled from 'styled-components';
import { Flex } from 'antd';
import { font } from './styleVariables';

export const SAuthPageWrapper = styled(Flex)`
  font-family: ${font};
  width: 100%;
  padding: 15px;

  .trace-logo {
    max-width: 265px;
    margin-top: 10vh;
  }

  h1 {
    font-size: 26px;
    font-weight: 600;
    margin: 10vh 0 30px;

    &.picker-title {
      margin: 5vh 0 30px;
    }
  }
  
  form {
    max-width: 474px;
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
  }

  #business-picker-formik {
    max-width: 650px;
  }
`;
