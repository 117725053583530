import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FieldArray } from 'formik';
import * as inputTypes from './index';
import s from '../FormSteps/formFilling/style';
import InputWrapper from '../../../components/HOC/InputWrapper';
import UniqIdsGenerator from '../../../lib/uniqIdsGenerator';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const idsGenerator = new UniqIdsGenerator('field_array');

const FieldWrapper = ({ children, wrapItem }) => (
  <div style={s.wrapTitle}>
    <div style={wrapItem}>
      <div style={s.labelWrapper}>
        <span style={s.label} />
      </div>
    </div>
    <div style={s.inputCompos}>{children && children}</div>
  </div>
);

const getObjToPush = (protoObj) => {
  let obj = {};
  Object.keys(protoObj).forEach((fieldName) => {
    obj[fieldName] = '';
  });
  return obj;
};

class FieldArrayComponent extends React.PureComponent {
  render() {
    const {
      name,
      value,
      handleChange,
      handleBlur,
      formSchema,
      touched,
      errors,
      setFieldTouched,
      setFieldValue,
      compositionName,
      wrapItem,
      isEdit,
    } = this.props;

    const objToPush = getObjToPush(value[0]);

    return (
      <FieldArray
        name={name}
        render={(arrayHelpers) => {
          return (
            <div>
              {value.map((fields, index) => (
                <div key={index}>
                  {index > 0 && (
                    <div style={s.wrap}>
                      <div style={wrapItem} />
                      <div style={s.inputCompos}>
                        <div style={s.seperatorLine} />
                      </div>
                    </div>
                  )}
                  {index === 0 && (
                    <div style={s.wrapTitle}>
                      <div style={wrapItem}>
                        <div style={s.labelWrapper}>
                          <span style={s.label}>
                            <span style={{ textDecoration: 'underline' }}>
                              {compositionName}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div style={s.inputCompos}>
                        <div style={s.seperatorLine} />
                      </div>
                    </div>
                  )}
                  {Object.keys(fields).map((fieldName, fieldIndex) => {
                    return (
                      <div style={s.wrap} key={fieldIndex}>
                        <div style={wrapItem}>
                          {/* <div style={{ display: 'inline-block', marginRight: 10, position: 'relative'}}> */}
                          <div style={s.labelWrapper}>
                            <span style={s.label}>
                              {formSchema[name].fields[fieldName].label}
                              {formSchema[name].fields[fieldName].rules
                                .required ? (
                                <span style={s.star}>*</span>
                              ) : null}
                            </span>
                          </div>
                        </div>
                        <div
                          style={s.inputCompos}
                          key={`${index}.${fieldIndex}`}
                        >
                          <span style={s.composError}>
                            {touched[name] &&
                            touched[name][index] &&
                            touched[name][index][fieldName] &&
                            errors[name] &&
                            errors[name][index] &&
                            errors[name][index][fieldName]
                              ? errors[name][index][fieldName]
                              : ''}
                          </span>

                          {/* Tip: if you need use DatePicker, Select or other input types into FieldArray then provide more properties. You can find example in formFilling.js  */}

                          {inputTypes[formSchema[name].fields[fieldName].type]({
                            name: `${name}[${index}][${fieldName}]`,
                            value:
                              value[index][fieldName] ||
                              formSchema[name].fields[fieldName].defaultValue ||
                              '',
                            placeholder:
                              formSchema[name].fields[fieldName].label,
                            disabled:
                              (isEdit &&
                                (formSchema[name].fields[fieldName]
                                  .isEditingDisabled ||
                                  formSchema[name].fields[fieldName]
                                    .disabled)) ||
                              formSchema[name].fields[fieldName].disabled,
                            handleChange,
                            handleBlur,
                            setFieldTouched,
                            setFieldValue,
                            isError:
                              touched[name] &&
                              touched[name][index] &&
                              touched[name][index][fieldName] &&
                              errors[name] &&
                              errors[name][index] &&
                              errors[name][index][fieldName]
                                ? 'error'
                                : undefined,

                            // defaultValue: formSchema[name].fields[fieldName].defaultValue,
                            options: formSchema[name].fields[fieldName].options,
                          })}
                        </div>
                      </div>
                    );
                  })}

                  {value.length > 1 && (
                    <FieldWrapper wrapItem={wrapItem}>
                      <Button
                        style={{ marginBottom: '4px' }}
                        type="dashed"
                        icon={<DeleteOutlined />}
                        onClick={() => arrayHelpers.remove(index)}
                        id={idsGenerator.getComposition(
                          `delete_${formSchema[name].label}_${index}`
                        )}
                      />
                    </FieldWrapper>
                  )}
                </div>
              ))}
              <FieldWrapper wrapItem={wrapItem}>
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => arrayHelpers.push(objToPush)}
                  id={idsGenerator.getComposition(
                    `${formSchema[name].label}_${formSchema[name].buttonName}`
                  )}
                >
                  {formSchema[name].buttonName}
                </Button>
              </FieldWrapper>

              <div style={s.wrapTitle}>
                <div style={wrapItem}>
                  <div style={s.labelWrapper} />
                </div>
                <div style={s.inputCompos}>
                  <div style={s.seperatorLine} />
                </div>
              </div>
            </div>
          );
        }}
        data-name={name}
      />
    );
  }
}

FieldWrapper.propTypes = {
  children: PropTypes.any,
  wrapItem: PropTypes.object,
};

FieldArrayComponent.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  formSchema: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  compositionName: PropTypes.string,
  wrapItem: PropTypes.object,
};

export default (props) => InputWrapper(props, FieldArrayComponent);
