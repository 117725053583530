import { useState } from 'react';
import { DatePicker, Input, Select, Space, Typography } from 'antd';
import locale from 'antd/lib/date-picker/locale/fr_FR';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import Style from './styled-component';
import { AnalyticsInterface } from './utils/type';
import { deviceColumns, eventColumns, pageColumns } from './utils/columns';
import SegmentedGraph from './components/SegmentedGraph';
import TableView from './components/TableView';
import { colors } from '../../App/styleVariables';

const { Title } = Typography;
const { StyledTableWrapper } = Style;

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const props: AnalyticsInterface = {
  date_input: {
    title: 'DATE ET PERIODE',
    date_format: 'MMM D YYYY',
    period_labels: ['JOUR', 'SEMAINE', 'MOIS', 'AN'],
  },
  widgets: [
    {
      name: 'Dashboard',
      title: 'TABLEAU DE BORD',
      colors: {
        success: '#3f8600',
        info: colors.secondary,
      },
      metrics: [
        {
          name: 'nb_visits',
          label: 'Nombre de visiteurs',
          yaxis_title: 'Nombre de visiteurs',
        },
        {
          name: 'avg_time_spent',
          label: "Durée moy. d'une visite(en secondes)",
          yaxis_title: 'Durée (en secondes)',
        },
        {
          name: 'nb_pageviews',
          label: 'Pages vues',
          yaxis_title: 'Nombre de pages',
        },
        {
          name: 'nb_bottom_reached',
          label: 'Bas de page atteint',
          yaxis_title: "Nombre d'évènements",
        },
        {
          name: 'penetration_rate',
          label: 'Taux de pénetration',
          yaxis_title: 'Taux (en %)',
        },
      ],
    },
    {
      name: 'Pageviews',
      title: 'DETAIL PAR PAGE',
    },
    {
      name: 'Eventviews',
      title: 'DETAIL PAR ACTION (PERSONNALISABLE)',
    },
    {
      name: 'Deviceviews',
      title: 'DETAIL PAR APPAREIL',
    },
  ],
  styles: {
    size: {
      column: {
        start: 1,
        end: 11,
      },
    },
  },
};

const { date_input, widgets, styles } = props;

const Analytics = () => {
  const [period, setPeriod] = useState('day');
  const [date, setDate] = useState(
    dayjs().subtract(1, 'day').format('YYYY-MM-DD')
  );
  const { date_format } = date_input;
  const handlePeriodChange = (value: string) => {
    setPeriod(value);
  };

  const handleDateChange = (useless: any, dateString: any) => {
    if (dateString === '') {
      setDate(dayjs().subtract(1, 'day').format('YYYY-MM-DD'));
    } else {
      setDate(dayjs(dateString, date_format).format('YYYY-MM-DD'));
    }
  };

  const widget_components = widgets.map((widget) => {
    const props = {
      date: date,
      period: period,
      ...widget,
    };
    switch (widget.name) {
      case 'Dashboard':
        return <SegmentedGraph {...props} />;
      case 'Pageviews':
        return (
          <TableView
            {...props}
            columns={pageColumns}
            method={'Actions.getPageUrls'}
          />
        );
      case 'Eventviews':
        return (
          <TableView
            {...props}
            columns={eventColumns}
            method={'Events.getAction'}
          />
        );
      case 'Deviceviews':
        return (
          <TableView
            {...props}
            columns={deviceColumns}
            method={'DevicesDetection.getType'}
            filter={(record: any) => record.nb_visits > 0}
          />
        );
      default:
        return <></>;
    }
  });

  return (
    <StyledTableWrapper styles={styles}>
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <Input.Group size="large">
          <Title level={3}>{date_input.title}</Title>
          <Space>
            <DatePicker
              locale={locale}
              size={'large'}
              format={date_format}
              value={dayjs(date, 'YYYY-MM-DD')}
              onChange={handleDateChange}
            />
            <Select
              size={'large'}
              dropdownMatchSelectWidth={false}
              value={period}
              onChange={handlePeriodChange}
              options={[
                { value: 'day', label: date_input.period_labels[0] },
                { value: 'week', label: date_input.period_labels[1] },
                { value: 'month', label: date_input.period_labels[2] },
                { value: 'year', label: date_input.period_labels[3] },
              ]}
            />
          </Space>
        </Input.Group>

        {widget_components}
      </Space>
    </StyledTableWrapper>
  );
};

export default Analytics;
