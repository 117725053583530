import React from 'react';
import Badge from '../../../../components/Badge';
import { SIndicatorContainer } from './styled-components';
import { RISK_ICONS } from '../../constants';

const CompanyRisks = ({ styles, bordered, brandValue, categories }: any) => {
  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <p className={'brand-value-row'}>
        Valeur de la marque
        <span className={'brand-value'}>{brandValue}</span>
      </p>
      <div className={'categories-container'}>
        {categories?.map(
          (
            { icon, title, risk, riskTitle, customColors, displayBadgeIcon },
            index
          ) => {
            return (
              <div key={`${risk}-${index}`} className={'category-element'}>
                <div className={'category-name-wrapper'}>
                  <img
                    src={RISK_ICONS[icon]}
                    className={'category-icon'}
                    alt={risk}
                  />
                  <p className={'category-name'}>{title}</p>
                </div>
                <div className={'category-badge'}>
                  <Badge
                    title={riskTitle}
                    type={risk}
                    customColor={customColors}
                    displayIcon={displayBadgeIcon}
                  />
                </div>
              </div>
            );
          }
        )}
      </div>
    </SIndicatorContainer>
  );
};

export default CompanyRisks;