import React from 'react';
import PropTypes from 'prop-types';
import { BlocksComponentWrapper, BlocksWrapper, BlockItem } from './style';
import { connect } from 'react-redux';

const BlocksComponent = ({ data, businessName }) => {
  const blocks = data.map(({ title, count, icon }) => {
    return (
      <BlockItem key={title}>
        {businessName && icon &&
          <div className="block-icon">
              <img
                src={`${process.env.REACT_APP_BUCKET_NAME}/${businessName}/public/images/${icon}.svg`}
                alt={title}
              />
          </div>
        }
        <div className="block-title"> {title} </div>
        <div className="block-count"> {count} </div>
      </BlockItem>
    );
  });
  return (
    <BlocksComponentWrapper>
      <h1> Tableau de bord </h1>
      <BlocksWrapper>{blocks}</BlocksWrapper>
    </BlocksComponentWrapper>
  );
};

const mapStateToProps = (state) => ({
  businessName: state.auth.currentUser.attributes.business.name,
});

BlocksComponent.propTypes = {
  /**
   * Data to be displayed.
   */
  data: PropTypes.array.isRequired,
  /**
   * Busines name.
   */
  businessName: PropTypes.string,
}

export default connect(mapStateToProps)(BlocksComponent);
