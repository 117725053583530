import React from 'react';
import { Select as ASelect } from 'antd';
import { SelectOption } from '../../../../../Cockpit/types';

const Select = ({ placeholder, options, disabled }: any) => {
  return (
    <ASelect
      showSearch
      allowClear
      placeholder={placeholder}
      filterOption={(input, option: SelectOption) =>
        option?.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
        option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
      }
      options={options}
      disabled={disabled}
    />
  );
};

export default Select;
