import React, { FunctionComponent } from 'react';
import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';

export interface ExtendedSelectProps {
  name?: string;
}
export type SelectProps = AntSelectProps & ExtendedSelectProps;

const CustomSelect: FunctionComponent<SelectProps> = (
  props
) => {
  return (
    <AntSelect
      {...props}
    />
  );
};

export default CustomSelect;