import { useMutation } from 'react-query';

import { MutationArguments } from "../../types";
import { store } from '../../../../store/nextStore';
import { formWorkerModal } from '../../constants';
import { closeGenericModalAction } from '../../../../containers/GenericModal/reducer';
import errorHandler from '../../../../lib/errorHandler';

const useEditMutation = ({
  factory,
  apiPaginatedTable,
  client,
  name,
  page,
  searchField,
  filter,
}: MutationArguments) => {
  return useMutation(factory?.edit, {
    onSuccess: (successData: any) => {
      if (!apiPaginatedTable && !successData?.force_relogin) {
        client.setQueryData(
          [name, page, searchField, filter],
          (oldData: any) => {
            const nextData = [...oldData?.data];
            const index = nextData?.findIndex((el) => el.id === successData.id);
            nextData[index] = successData;
            return {
              ...oldData,
              data: nextData,
            };
          }
        );
      } else if (apiPaginatedTable) {
        client.refetchQueries([name, page, searchField, filter]);
      }

      store.dispatch(closeGenericModalAction({ name: formWorkerModal }));
    },
    onError: (error) => {
      if (error) {
        errorHandler(error);
        client.refetchQueries([name, page, searchField, filter]);
        store.dispatch(closeGenericModalAction({ name: formWorkerModal }));
      }
    },
  });
};

export default useEditMutation;
