import React from 'react';
import ResetForm from './components/ResetForm';
import SplitScreenWrapper from '../../containers/SplitScreenWrapper';
import { SAuthPageWrapper } from '../../App/global-styled-components';

import crystaltrace from 'images/crystaltrace_logo.png';

const ResetPassword: React.FC = () => {
  return (
    <SplitScreenWrapper>
      <SAuthPageWrapper vertical align={'center'}>
        <img src={crystaltrace} className={'trace-logo'} alt="CrystalTrace" />
        <h1> Set new password </h1>
        <ResetForm />
      </SAuthPageWrapper>
    </SplitScreenWrapper>
  );
};

export default ResetPassword;
