const MODAL_ACTION = 'STOCKING::ACTION';

export const openModalAction = data => ({
  type: MODAL_ACTION,
  payload: { ...data, isModalOpen: true }
});

export const closeModalAction = () => ({
  type: MODAL_ACTION,
  payload: { isModalOpen: false, userData: {} }
});

const handlers = {
  [MODAL_ACTION]: (state, action) => ({ ...action.payload })
};

export default (state = { isModalOpen: false }, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};
