import { FC } from 'react';
import { STabLabel } from '../../styled-components';

interface TabLabelProps {
  title: string;
  number?: number | string;
}

const TabLabel: FC<TabLabelProps> = ({ number, title }) => {
  return (
    <STabLabel gap="small" justify="space-between" align="center">
      {number && <p className="label-number">{number}</p>}
      <p className="label-title">{title}</p>
    </STabLabel>
  );
};

export default TabLabel;
