import React from 'react';
import { PlayCircleOutlined } from '@ant-design/icons';
import { StyledVideo, StyledVideoWrapper } from './styled-components';

interface Props {
  key: number;
  data: { url: string }[];
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const CircleVideoLink = ({ key, styles, data }: Props) => {
  return data && Array.isArray(data) && data.length !== 0 ? (
    <StyledVideoWrapper key={key} styles={styles}>
      {data?.map((item, index) => {
        return (
          <StyledVideo
            href={item?.url}
            target={'_blank'}
            rel="noopener noreferrer"
            className={'link-text'}
            key={index}
          >
            <PlayCircleOutlined className={'play'} />
          </StyledVideo>
        );
      })}
    </StyledVideoWrapper>
  ) : null;
};

export default CircleVideoLink;
