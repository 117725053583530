import { FC, useState } from 'react';
import { Button, Flex, Form, Input, Spin } from 'antd';
import { closeGenericModalAction } from 'containers/GenericModal/reducer';
import { SFormWrapper } from './styled-components';
import { sendEmailFormModal } from 'components/Modal/constants';
import { store } from 'store/nextStore';

const onboardInitials = (business) => {
  const businessName = (business === 'decathlon' || business === 'dkt') ? 'Decathlon' : 'Anna Rose';

  return {
    subject: `First connection on ${businessName}’s traceability platform`,
  message:
    `Hello, \n\nJust a reminder we sent you login information in order to start participating in the traceability effort for ${businessName}. \nIf you are having issues for  logging in, please let us know and we will assist you as soon as possible. \n\nBest regards, \n\n The Crystalchain Team`,

  }
};

const onboardingInitials = (business) => {
  const businessName = (business === 'decathlon' || business === 'dkt') ? 'Decathlon' : 'Anna Rose';

  return {
    subject: `Get started on the traceability platform`,
    message:
      `Hello, \n\nYou have successfully accessed ${businessName}’s traceability platform but we noticed you haven’t started entering data. \nThe tour of the platform and explanatory documentation can be accessed from the home page. \nIf you are encountering any issue you can create a support ticket, we will get back to you as soon as possible.  \n\nBest regards, \n\n The Crystalchain Team`,
  }
};

const SendEmailForm: FC = ({ data, businessName }: any) => {
  const { email, Status } = data;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFinish = () => {
    setIsSubmitting(true);
    setTimeout(() => {
      store.dispatch(closeGenericModalAction({ name: sendEmailFormModal }));
    }, 1500);
  };

  return (
    <SFormWrapper>
      <p className="email"> Email to: {email}</p>
      <Form
        name="send_email"
        initialValues={
          Status === 'TO ONBOARD'
            ? onboardInitials(businessName)
            : Status === 'ONBOARDING'
            ? onboardingInitials(businessName)
            : {}
        }
        onFinish={onFinish}
      >
        <Form.Item label="CC" name="cc">
          <Input allowClear />
        </Form.Item>

        <Form.Item
          label="Subject"
          name="subject"
          rules={[{ required: true, message: 'Please input Subject.' }]}
        >
          <Input allowClear />
        </Form.Item>

        <Form.Item
          name="message"
          rules={[{ required: true, message: 'Please input message' }]}
        >
          <Input.TextArea allowClear />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Flex gap="small" justify="flex-end">
            <Button
              onClick={() =>
                store.dispatch(
                  closeGenericModalAction({ name: sendEmailFormModal })
                )
              }
              className="cancel-button"
            >
              Cancel
            </Button>
            <Spin size="small" spinning={isSubmitting}>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-button"
              >
                Submit
              </Button>
            </Spin>
          </Flex>
        </Form.Item>
      </Form>
    </SFormWrapper>
  );
};

export default SendEmailForm;