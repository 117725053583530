import styled from 'styled-components';
import { colors, font } from 'App/styleVariables';

export const StyledGraph = styled.div`
  cursor: move;
  text-align: center;
  width: 100%;
  
  .ant-spin-nested-loading {
    .ant-spin {
      max-height: initial;
      font-family: ${font};
    }
    
    .ant-spin-dot {
      margin-top: -100px !important;
    }
    
    .ant-spin-text {
      margin-top: -60px !important;
      font-size: 14px;
    }
  }

  .ant-divider {
    font-size: 28px;
    border-inline-start: 1px solid #d9d9d9;
  }

  .settings {
    font-family: ${font};
    cursor: initial;

    .ant-switch {
      font-family: inherit;

      &.ant-switch-checked {
        background-color: ${colors.secondary};
      }
    }
  }

  .setting-label {
    margin: 0;
  }
`;