class FormPresenter {
  present_data = (data, form_step, ...rest) =>
    this[`_present_${form_step}`](data, rest);

  _present_selection = (data, [is_sorted]) => {
    const cache = {};
    const kinds = new Map();
    const values = Object.values(data);
    const fields =
      (is_sorted && values.sort((a, b) => a.localeCompare(b))) || values;

    for (const [k, v] of Object.entries(data)) cache[v] = k;
    for (const field of fields) kinds.set(cache[field], field);

    const [formType] = Array.from(kinds.keys());

    return { kinds, formType };
  };
}

export default new FormPresenter();
