import styled from 'styled-components';
import { colors, transition } from 'App/styleVariables';

interface ProgressBarStyles {
  completed: string | number;
  progress?: string | number;
  balance?: number;
  trailColor?: string;
  completedColor?: string;
  progressColor?: string;
  strokeHeight?: string;
  labelColor?: string;
  borderRadius?: string;
}

const SProgressBar = styled.div<ProgressBarStyles>`
  height: ${(props) => props.strokeHeight || '15px'};
  width: 100%;
  background-color: ${(props) => props.trailColor || colors.lightGray};
  border-radius: ${(props) => props.borderRadius || '50px'};
  display: flex;

  .filler {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ${transition};

    &.hoverable:hover {
      box-shadow: 0 0 5px 0.5px rgba(0, 0, 0, 0.25) !important;
      z-index: 2;
    }

    &.completed {
      width: ${(props) => `${props.completed}%`};
      background-color: ${(props) => props.completedColor || colors.primary};
      border-top-left-radius: ${(props) => props.borderRadius || '50px'};
      border-bottom-left-radius: ${(props) => props.borderRadius || '50px'};
      border-top-right-radius: ${(props) =>
        props.completed === 100 ? props.borderRadius || '50px' : '0px'};
      border-bottom-right-radius: ${(props) =>
        props.completed === 100 ? props.borderRadius || '50px' : '0px'};
    }

    &.progress {
      width: ${(props) => `${props.progress}%`};
      background-color: ${(props) => props.progressColor || colors.secondary};
      border-top-left-radius: ${(props) => +props.completed > 0 ? '0px' : props.borderRadius || '50px'};
      border-bottom-left-radius: ${(props) => +props.completed > 0 ? '0px' : props.borderRadius || '50px'};
      border-top-right-radius: ${(props) => +props.balance > 0 ? '0px' : props.borderRadius || '50px'};
      border-bottom-right-radius: ${(props) => +props.balance > 0 ? '0px' : props.borderRadius || '50px'};
    }

    &.balance {
      width: ${(props) => `${props.balance}%`};
      background-color: ${(props) => props.trailColor || colors.lightGray};
      border-top-right-radius: ${(props) => props.borderRadius || '50px'};
      border-bottom-right-radius: ${(props) => props.borderRadius || '50px'};
      border-top-left-radius: ${(props) =>
        props.balance === 100 ? props.borderRadius || '50px' : '0px'};
      border-bottom-left-radius: ${(props) =>
        props.balance === 100 ? props.borderRadius || '50px' : '0px'};
    }
  }

  .label {
    color: ${(props) => props.labelColor || 'white'};
    font-size: 10px;
    line-height: 1;
    white-space: nowrap;
    font-weight: 500;
  }
`;

export default SProgressBar;
