import styled from 'styled-components';

export const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: 0 4%;
`;

export const QRWrapper = styled.div`
  margin: 16px 0;
`;

export const DownloadButton = styled.a`
  font-size: 1.4rem;
`;

export const URLRoot = styled.footer`
  //border: 1px solid grey;
  border-radius: 5px;
  padding: 4px 16px;
  margin-top: 64px;
`;

export const URLPreview = styled.a`
  font-size: 0.8rem;
`;

export const MockupWrapper = styled.div`
  width: 500px;
  display: flex;
  justify-content: center;
`;

export const QRCodeRootBlock = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const QRCodeNotation = styled.div`
  text-align: center;
  width: 196px;
`;
