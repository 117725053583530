import React, { Component } from 'react';
import { CustomListTable } from 'sections/CustomList/containers/CustomListTable/custom-list-table';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from '../../../../components/HOC/withRouter';
import { get_documents, set_initial_state_action } from './reducer';
import { openModalAction } from 'sections/CustomList/containers/CustomListItemModal/reducer';
import { onSearchAction } from 'containers/SearchData/reducer';
import { closeGenericModalAction } from '../../../../containers/GenericModal/reducer';
import { handleSubmitAction } from '../DocumentListForm/reducer';

class DocumentListTable extends Component {
  render() {
    return (
      <CustomListTable
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({ documentsTable, searchData, auth }) => ({
  data:
    searchData.isSearchActive && searchData.table === 'DocumentsTable'
      ? searchData.searchResult
      : documentsTable.data,
  table_scheme: documentsTable.table_scheme,
  business: auth.currentUser.attributes.business
});

const mapDispatchToProps = {
  setInitialState: set_initial_state_action,
  get_items: get_documents,
  openModal: openModalAction,
  onSearch: q =>
    onSearchAction(
      q,
      globalState => globalState.documentsTable.data,
      'DocumentsTable'
    ),
  get_documents,
  closeGenericModal: closeGenericModalAction,
  handleSubmitFile: handleSubmitAction
};

const composeResult = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(DocumentListTable);

export default composeResult;
