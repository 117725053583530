import React from 'react';
import { connect } from 'react-redux';
import CustomModal from '../../../../../components/Modal';
import TraceDetails from '../../../../../containers/TraceDetails';
import StockingForm from '../StockingForm';
import { closeModalAction } from './reducer';
import { get_data_action } from '../StockingTable/reducer';
import ShowTraceList from '../../../../../components/ShowTraceList';

const actions = { edit: 'Editer', show: 'Voir' };

class StockingModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  handleOnClose = () => {
    const { closeModal, get_data } = this.props;
    closeModal();
    get_data();
  };

  render() {
    const {
      edit,
      traceId,
      isModalOpen,
      formType,
      data,
      show,
      trace_pb,
      displayTraceType,
    } = this.props;
    let title;
    if (edit) title = `${actions.edit} : ${formType}`;
    else if (show) title = `${actions.show}: ${displayTraceType}`;
    else title = 'Recherche de traces PB pour une trace de déstockage Kizéo';

    const StockingModalRef = React.forwardRef((props, ref) => (
      <CustomModal
        title={title}
        closeModal={this.handleOnClose}
        modalVisible={isModalOpen}
        width={edit ? 1300 : 1300}
      >
        {isModalOpen && edit ? (
          show ? (
            <ShowTraceList data={data} />
          ) : (
            <StockingForm {...this.props} />
          )
        ) : null}
        {isModalOpen && !edit && trace_pb && (
          <TraceDetails parentTraceId={traceId} />
        )}
      </CustomModal>
    ));

    return <StockingModalRef ref={this.modalRef} />;
  }
}

const ConnectedModal = connect(
  ({ stockingModal }) => ({
    isModalOpen: stockingModal.isModalOpen,
    edit: stockingModal.edit,
    traceId: stockingModal.traceId,
    formType: stockingModal.formType,
    data: stockingModal.data,
    show: stockingModal.show,
    trace_pb: stockingModal.trace_pb,
    displayTraceType: stockingModal.displayTraceType,
  }),
  {
    closeModal: closeModalAction,
    get_data: get_data_action,
  }
)(StockingModal);

export default ConnectedModal;
