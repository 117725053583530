import React from 'react';

import QuestionMarkPopover from '../QuestionMarkPopover';
import { SIndicatorContainer } from './styled-component';
import { SectionHeaderInterface } from '../../types';

const SectionHeader = ({
  title,
  subtitle,
  questionMark,
  questionMarkPopover,
  styles,
}: SectionHeaderInterface) => {
  if (!title) {
    return null;
  }
  return (
    <SIndicatorContainer bordered={false} styles={styles}>
      <p className={'title'}>
        {title}
        {questionMark && (
          <QuestionMarkPopover popoverName={questionMarkPopover} />
        )}
      </p>
      {subtitle && <p className={'sub-title'}>{subtitle}</p>}
    </SIndicatorContainer>
  );
};

export default SectionHeader;
