import React from 'react';
import { Progress } from 'antd';
import { COLORS, ENTITY_ICONS } from '../../../../constants';
import { calculateEntityRow } from '../../../../util';
import { EntityRowInterface } from '../../../../types';

const EntityRow = ({
  name,
  value,
  activeIndex,
  entities,
}: EntityRowInterface) => {
  return (
    <div className={'entity-item'}>
      <div className={'entity-label'}>
        <p className={'traces-info'}>{name}</p>
        <img src={ENTITY_ICONS[name]} alt={name} />
      </div>
      <div className={'graph-wrapper'}>
        <Progress
          percent={calculateEntityRow(entities, name)}
          showInfo={false}
          strokeColor={COLORS[activeIndex % COLORS.length]}
          trailColor={'#FFFFFF'}
          className={'progress'}
        />
        <p className={'value'}>{value}</p>
      </div>
    </div>
  );
};

export default EntityRow;
