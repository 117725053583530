import styled from 'styled-components';
import { colors, font } from 'App/styleVariables';

interface Block {
  styles: {
    minHeight?: string;
    content?: {
      gridColumns?: string;
      gridRows?: string;
    };
    title?: {
      color?: string;
      fontSize?: string;
      fontWeight?: string;
      textAlign?: string;
      textTransform?: string;
    };
    subtitle?: {
      color?: string;
      fontSize?: string;
      fontWeight?: string;
      textAlign?: string;
      textTransform?: string;
      fontStyle?: string;
    };
    submitBtn?: {
      backgroundPassive: string;
      backgroundActive: string;
      colorPassive: string;
      colorActive: string;
      borderPassive: string;
      borderActive: string;
      fontSize: string;
      fontWeight: string | number;
      borderRadius: string;
    };
    filters?: {
      width?: string;
      gap?: string;
      item?: {
        width: string;
        fontSize: string;
        color: string;
        borderRadius: string;
        borderColorPassive: string;
        borderColorActive: string;
      };
    };
    extra?: {
      width?: string;
      margin?: string;
      item?: {
        marginBottom: string;
        labelFontSize: string;
        labelColor: string;
        labelFontWeight: string;
        textColor: string;
        textFontSize: string;
        textFontWeight: string;
      };
    };
    infoModal?: {
      okBtn?: {
        backgroundColor?: string;
        color?: string;
      };
    };
    downloadBtn?: {
      fontSize?: string;
      fontWeight?: string;
      textTransform?: string;
      colorPassive?: string;
      backgroundPassive?: string;
      borderPassive?: string;
      colorActive?: string;
      backgroundActive?: string;
      borderActive?: string;
    };
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledWrapper = styled.div<Block>`
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  background: ${({ theme }) =>
    theme?.dashboard?.block?.background || '#ffffff'};
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: ${({ theme }) =>
    theme?.dashboard?.block?.borderRadius || '10px'};
  padding: ${({ theme }) => theme?.dashboard?.block?.padding || '20px 15px'};
  box-sizing: border-box;
  font-family: ${({ theme }) =>
    theme?.dashboard?.fontFamily || font} !important;
  position: relative;
  min-height: ${({ styles }) => styles?.minHeight};

  .ant-empty {
    font-family: ${({ theme }) =>
      theme?.dashboard?.fontFamily || font} !important;
  }

  .title-wrapper {
    margin-bottom: 15px;
  }

  .title {
    color: ${({ styles }) => styles?.title?.color || '#000000'};
    font-size: ${({ styles }) => styles?.title?.fontSize || '18px'};
    font-weight: ${({ styles }) => styles?.title?.fontWeight || '600'};
    text-align: ${({ styles }) => styles?.title?.textAlign || 'center'};
    text-transform: ${({ styles }) => styles?.title?.textTransform || 'none'};
    margin-bottom: 5px;
  }

  .subtitle {
    color: ${({ styles }) => styles?.subtitle?.color || '#000000'};
    font-size: ${({ styles }) => styles?.subtitle?.fontSize || '14px'};
    font-weight: ${({ styles }) => styles?.subtitle?.fontWeight || '300'};
    text-align: ${({ styles }) => styles?.subtitle?.textAlign || 'center'};
    text-transform: ${({ styles }) =>
      styles?.subtitle?.textTransform || 'none'};
    font-style: ${({ styles }) => styles?.subtitle?.fontStyle};
  }

  .info-button {
    margin-left: 5px;
    border: none;
    padding: 0 !important;
    height: auto;
  }

  .export-button {
    display: flex;
    align-items: center;
    min-width: 32px;
    width: auto;
    height: 32px;
    font-size: ${({ styles }) => styles?.downloadBtn?.fontSize || '14px'};
    font-weight: ${({ styles }) => styles?.downloadBtn?.fontWeight || '600'};
    text-transform: ${({ styles }) =>
      styles?.downloadBtn?.textTransform || 'none'};
    border-radius: 10px !important;
    padding: 5px 10px !important;
    color: ${({ styles }) => styles?.downloadBtn?.colorPassive || '#000000'};
    background: ${({ styles }) =>
      styles?.downloadBtn?.backgroundPassive || '#ffffff'};
    border-color: ${({ styles }) =>
      styles?.downloadBtn?.borderPassive || '#d9d9d9'};

    &:hover {
      color: ${({ styles }) =>
        styles?.downloadBtn?.colorActive || colors.secondary} !important;
      background: ${({ styles }) =>
        styles?.downloadBtn?.backgroundActive || '#ffffff'} !important;
      border-color: ${({ styles }) =>
        styles?.downloadBtn?.borderActive || colors.secondary} !important;
    }

    .anticon-download {
      font-size: 20px;
    }
  }

  .filter-section {
    display: flex;
    flex-flow: row nowrap;
    gap: 15px;
    font-family: inherit;

    .ant-form-item {
      margin-bottom: 0;
    }
    .filters {
      display: flex;
      flex-flow: row wrap;
      gap: ${({ styles }) => styles?.filters?.gap || '15px'};
      width: ${({ styles }) => styles?.filters?.width};
    }
    .filter {
      width: ${({ styles }) => styles?.filters?.item?.width || '250px'};

      .ant-select-selector,
      .ant-input-affix-wrapper,
      .ant-picker {
        font-family: ${({ theme }) =>
          theme?.dashboard?.fontFamily || font} !important;
        font-size: ${({ styles }) => styles?.filters?.item?.fontSize || '14px'};
        color: ${({ styles }) => styles?.filters?.item?.color || '#000000'};
        border-radius: ${({ styles }) =>
          styles?.filters?.item?.borderRadius || '8px'} !important;
        border-color: ${({ styles }) =>
          styles?.filters?.item?.borderColorPassive || '#d9d9d9'};
        height: 32px;
        width: 100%;

        &[class*='status-error'] {
          border-color: #ff7875;
        }

        &:hover,
        &:focus {
          border-color: ${({ styles }) =>
            styles?.filters?.item?.borderColorActive ||
            colors.secondary} !important;
          box-shadow: none !important;
        }
        &.ant-input-affix-wrapper-focused {
          box-shadow: none !important;
        }
        .ant-select-selection-placeholder {
          color: #808080;
        }
      }

      .ant-select:hover .ant-select-selector,
      .ant-select.ant-select-focused .ant-select-selector {
        border-color: ${({ styles }) =>
          styles?.filters?.item?.borderColorActive ||
          colors.secondary} !important;
        box-shadow: none !important;
      }

      .ant-input,
      .ant-picker-input > input {
        font-family: ${({ theme }) =>
          theme?.dashboard?.fontFamily || font} !important;
        font-size: ${({ styles }) => styles?.filters?.item?.fontSize || '14px'};
        color: ${({ styles }) => styles?.filters?.item?.color || '#000000'};

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          font-weight: 400 !important;
          color: #808080;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          font-weight: 400 !important;
          color: #808080;
        }
        &::-ms-input-placeholder {
          /* Microsoft Edge */
          font-weight: 400 !important;
          color: #808080;
        }
      }
    }
    .submit-btn {
      border-radius: ${({ styles }) =>
        styles?.submitBtn?.borderRadius || '8px'} !important;
      text-transform: uppercase;
      box-shadow: none;
      font-family: ${({ theme }) =>
        theme?.dashboard?.fontFamily || font} !important;
      font-size: ${({ styles }) => styles?.submitBtn?.fontSize || '12px'};
      font-weight: ${({ styles }) => styles?.submitBtn?.fontWeight || 400};
      background: ${({ styles }) =>
        styles?.submitBtn?.backgroundPassive || '#ffffff'};
      color: ${({ styles }) => styles?.submitBtn?.colorPassive || '#000000'};
      border: ${({ styles }) =>
        styles?.submitBtn?.borderPassive || '1px solid #d9d9d9'};

      &:hover {
        background: ${({ styles }) =>
          styles?.submitBtn?.backgroundActive || '#ffffff'};
        color: ${({ styles }) =>
          styles?.submitBtn?.colorActive || colors.secondary};
        border: ${({ styles }) =>
          styles?.submitBtn?.borderActive || `1px solid ${colors.secondary}`};
      }

      .anticon-search {
        margin-left: 0;
      }
    }
    .extra-info {
      width: ${({ styles }) => styles?.extra?.width};
      margin: ${({ styles }) => styles?.extra?.margin};

      p {
        margin-bottom: ${({ styles }) =>
          styles?.extra?.item?.marginBottom || '8px'};
        white-space: pre-wrap;

        .label {
          font-size: ${({ styles }) =>
            styles?.extra?.item?.labelFontSize || '14px'};
          color: ${({ styles }) =>
            styles?.extra?.item?.labelColor || 'inherit'};
          font-size: ${({ styles }) => styles?.extra?.item?.labelFontWeight};
        }
        .text {
          font-size: ${({ styles }) =>
            styles?.extra?.item?.textFontSize || '14px'};
          color: ${({ styles }) => styles?.extra?.item?.textColor || 'inherit'};
          font-weight: ${({ styles }) => styles?.extra?.item?.textFontWeight};
        }
      }
    }
  }
  .content {
    display: grid;
    grid-template-columns: ${({ styles, theme }) =>
      styles?.content?.gridColumns ||
      theme?.dashboard?.gridColumns ||
      'repeat(10, 1fr)'};
    grid-auto-rows: ${({ theme, styles }) =>
      styles?.content?.gridRows ||
      theme?.dashboard?.gridRows ||
      `minmax(100px, auto)`};
    grid-gap: 20px;
    align-items: start;
    margin-top: 20px;
  }
`;

export default StyledWrapper;
