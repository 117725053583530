import AbstractResourceFactory from '../abstract-resource-factory';
import client from '../../../../../../lib/apiClient';
import { prepareValidationData} from '../../../../../../lib/util';
import { getValidationFieldName} from '../../../../../../lib/validations/util';

class AnalysisResourceFactory implements AbstractResourceFactory {
  async validateOne({ traceId, status, validation, businessName }: any) {
    try {
      const trace = await client.getTraceById(traceId);

      const data = prepareValidationData({
        trace,
        traceId,
        status,
        statusField: getValidationFieldName(businessName),
        validation,
      });

      await client.putTraces(data);
    } catch (e) {
      console.log('err', e);
      throw e;
    }
  }

  async validateAll({ data, status, coherence, businessName }: any) {
    try {
      await Promise.all(
        data.map(async (item: any) => {
          const { traceId, CalcCoherentTrace } = item;
          if (coherence[CalcCoherentTrace]) {
            const trace = await client.getTraceById(traceId);
            await client.putTraces(
              prepareValidationData({
                trace,
                traceId,
                status,
                statusField: getValidationFieldName(businessName),
              })
            );
          }
        })
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}

export default AnalysisResourceFactory;
