import { EntityType } from './types';

const isExist = (data: object): boolean => {
  if (!data) {
    return false;
  }

  return Object.keys(data).length !== 0;
};

const getTooltipTitle = (type: EntityType) => {
  let titlesSprite;
  switch (type) {
    case 'TRANSFORMATEURS':
    case 'CONTROLEURS':
    case 'FILATEURS':
    case 'TISSEURS':
    case 'FABRICANTS':
      titlesSprite = {
        total: `Nombre total de ${type} enregistrés sur la plateforme`,
        onboarded: `Nombre total de ${type} enregistrés depuis le 1 août 2023`,
        active: `Nombre total de ${type} actifs depuis le 1 août 2023`,
        traced: 'Total d’achat tracé',
        tracedTotal: 'Total d’achat tracé et non tracé',
      };
      break;
    case 'USINES D’ALIMENTS':
      titlesSprite = {
        total: `Nombre total d'${type} enregistrées sur la plateforme`,
        onboarded: `Nombre total d'${type} enregistrées depuis le 1 août 2023`,
        active: `Nombre total d'${type} actives depuis le 1 août 2023`,
      };
      break;
    default:
      titlesSprite = {
        total: `Nombre total d'${type} enregistrés sur la plateforme`,
        onboarded: `Nombre total d'${type} enregistrés depuis le 1 août 2023`,
        active: `Nombre total d'${type} actifs depuis le 1 août 2023`,
      };
  }
  return titlesSprite;
};

const calculateEntityRow = (entities: { [key: string]: number }, type: string) => {
  const max = Math.max(...Object.values(entities));
  return (entities[type] * 100) / max;
};

export {
  isExist,
  getTooltipTitle,
  calculateEntityRow,
}