// import { message } from "antd";
import client from "../../../../../lib/apiClient";
import table_presenter from "../../../../../lib/presenters/TablePresenter";
// import { actionSetCommonNetworkFailure } from "reducers/network_events";
import errorHandler from "../../../../../lib/errorHandler";

const moduleName = "TRACK_PRODUCT_TABLE";

const GET_CHAINS_SUCCESS = `${moduleName}::GET_CHAINS_SUCCESS`;
const GET_LAYOUT_SUCCESS = `${moduleName}::GET_LAYOUT_SUCCESS`;
const CHANGE_LOADING_STATUS = `${moduleName}::CHANGE_LOADING_STATUS`;
const SET_FILTER = `${moduleName}::SET_FILTER`;
const SET_INITIAL_STATE = `${moduleName}::SET_INITIAL_STATE`;

const url_layouts = [
  "/Track_Product_Header.html",
  "/Track_Product_Footer.html"
];

const initial_state = {
  data: [],
  table_scheme: [],
  loading: false,
  error: "",
  layout: {
    header: "",
    footer: ""
  }
};

export const get_chains_action = ({ code = "" }) => async dispatch => {
  try {
    dispatch({ type: CHANGE_LOADING_STATUS });

    const { traces, table_scheme } = await client.getChainsByCode({ code });

    dispatch({
      type: GET_CHAINS_SUCCESS,
      payload: {
        data: table_presenter.present_data(traces, "track_product", code),
        error: traces.length ? "" : "Certificate not found",
        table_scheme
      }
    });
  } catch (err) {
    console.log(err);
    // message.error(`Cannot get chains: ${err.name} ${err.message}`);
    // dispatch(actionSetCommonNetworkFailure({ error: err }));
    errorHandler(err);
  }
};

export const get_layout_action = () => async dispatch => {
  try {
    const promises = await Promise.all(url_layouts.map(url => fetch(url)));
    const [header, footer] = await Promise.all(
      promises.map(promise => promise.text())
    );
    dispatch({
      type: GET_LAYOUT_SUCCESS,
      payload: {
        layout: { header, footer }
      }
    });
  } catch (e) {
    console.log(e);
    errorHandler(e);
  }
};

export const set_filter_action = data => async dispatch =>
  dispatch({ type: SET_FILTER, payload: data });

export const set_initial_state_action = () => ({ type: SET_INITIAL_STATE });

const handle_chains_success = (state, payload) => ({
  ...state,
  ...payload,
  loading: false
});

const handle_layout_success = (state, payload) => ({
  ...payload,
  loading: false
});

const handle_loading = state => ({ ...state, loading: true });

const handle_filter = (state, payload) => ({ ...state, data: payload });

const handle_initial_state = () => initial_state;

const handlers = {
  [GET_CHAINS_SUCCESS]: handle_chains_success,
  [GET_LAYOUT_SUCCESS]: handle_layout_success,
  [CHANGE_LOADING_STATUS]: handle_loading,
  [SET_FILTER]: handle_filter,
  [SET_INITIAL_STATE]: handle_initial_state
};

export default (state = initial_state, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action.payload) : state;
};
