import { BarsOutlined, AppstoreOutlined } from '@ant-design/icons';
import './styles.css';

const ICONS = {
  bars: BarsOutlined,
  appstore: AppstoreOutlined,
};

const TableViewIcon = ({ type, onClick, isSelected }) => {
  const Icon = ICONS[type];

  if (Icon) {
    return (
      <Icon
        onClick={onClick}
        className={`table-view-icon ${isSelected ? 'selected' : ''}`}
      />
    );
  } else {
    return null;
  }
};

export default TableViewIcon;
