import { message as antdShowMessage } from 'antd';

const showSuccessMessage = (customMessage) => {
  antdShowMessage.success(customMessage);
};

const showErrorMessage = (customMessage) => {
  antdShowMessage.error(customMessage);
};

const handleError = (message = 'An error ocurred') => (event, state) => {
  showErrorMessage(message);
  return { ...state, error: { ...event.payload } };
};

const handleSuccess = (message = 'Success!') => (event, state) => {
  showSuccessMessage(message);
  return state;
};

export const POST_TRACE_SUCCESS = 'NETWORK_EVENTS::POST_TRACE_SUCCESS';
export const POST_TRACE_FAILURE = 'NETWORK_EVENTS::POST_TRACE_FAILURE';

export const PUT_TRACE_SUCCESS = 'NETWORK_EVENTS::PUT_TRACE_SUCCESS';
export const PUT_TRACE_FAILURE = 'NETWORK_EVENTS::PUT_TRACE_FAILURE';

export const GET_TRACES_SUCCESS = 'NETWORK_EVENTS::GET_TRACES_SUCCESS';
export const GET_TRACES_FAILURE = 'NETWORK_EVENTS::GET_TRACES_FAILURE';

export const POST_EXPORT_SUCCESS = 'NETWORK_EVENTS::POST_EXPORT_SUCCESS';
export const POST_EXPORT_FAILURE = 'NETWORK_EVENTS::POST_EXPORT_FAILURE';

export const POST_REPORT_SUCCESS = 'NETWORK_EVENTS::POST_REPORT_SUCCESS';
export const POST_REPORT_FAILURE = 'NETWORK_EVENTS::POST_REPORT_FAILURE';

export const GET_EXPORTS_SUCCESS = 'NETWORK_EVENTS::GET_EXPORTS_SUCCESS';
export const GET_EXPORTS_FAILURE = 'NETWORK_EVENTS::GET_EXPORTS_FAILURE';

export const GET_REPORTS_SUCCESS = 'NETWORK_EVENTS::GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAILURE = 'NETWORK_EVENTS::GET_REPORTS_FAILURE';

export const SET_CURRENT_TRACE_FILTER = 'SET_CURRENT_TRACE_FILTER';

export const SET_COMMON_NETWORK_FAILURE =
  'NETWORK_EVENTS::SET_COMMON_NETWORK_FAILURE';
export const UNSET_COMMON_NETWORK_FAILURE = 'UNSET_COMMON_NETWORK_FAILURE';

export const actionGetTracesFinished = (payload) => ({
  type: payload.status === 'OK' ? GET_TRACES_SUCCESS : GET_TRACES_FAILURE,
  payload,
});

export const actionPostTraceFinished = (payload) => ({
  type: payload.status === 'OK' ? POST_TRACE_SUCCESS : POST_TRACE_FAILURE,
  payload,
});

export const actionPutTraceFinished = (payload) => ({
  type: payload.status === 'OK' ? PUT_TRACE_SUCCESS : PUT_TRACE_FAILURE,
  payload,
});

export const actionPostExportFinished = (payload) => ({
  type: payload.status === 'OK' ? POST_EXPORT_SUCCESS : POST_EXPORT_FAILURE,
  payload,
});

export const actionPostReportFinished = (payload) => ({
  type: payload.status === 'OK' ? POST_REPORT_SUCCESS : POST_REPORT_FAILURE,
  payload,
});

export const actionGetExportsFinished = (payload) => ({
  type: payload.status === 'OK' ? GET_EXPORTS_SUCCESS : GET_EXPORTS_FAILURE,
  payload,
});

export const actionGetReportsFinished = (payload) => ({
  type: payload.status === 'OK' ? GET_REPORTS_SUCCESS : GET_REPORTS_FAILURE,
  payload,
});

export const setCurrentTraceFilterAction = (data) => ({
  type: SET_CURRENT_TRACE_FILTER,
  payload: data,
});

export const actionSetCommonNetworkFailure = (payload) => ({
  type: SET_COMMON_NETWORK_FAILURE,
  payload,
});

export const actionUnsetCommonNetworkFailure = (payload) => ({
  type: UNSET_COMMON_NETWORK_FAILURE,
  payload,
});

function setCurrentTraceFilter(event, state) {
  return {
    ...state,
    filter: event.payload,
  };
}

function handlePostExportSuccess(event, state) {
  return {
    ...state,
    exports: [...state.exports, event.payload.newExport],
  };
}

function handlePostReportSuccess(event, state) {
  handleSuccess('Success creating report.');
  return {
    ...state,
    reports: [...state.reports, event.payload.newReport],
  };
}

function handleGetExportsSuccess(event, state) {
  return {
    ...state,
    exports: event.payload.allExports,
  };
}

function handleGetReportsSuccess(event, state) {
  return {
    ...state,
    reports: event.payload.reports,
  };
}

function handleSetCommonNetworkFailure(event, state) {
  return {
    ...state,
    commonNetworkFailure: event.payload,
  };
}

function handleUnsetCommonNetworkFailure(event, state) {
  return {
    ...state,
    commonNetworkFailure: null,
  };
}

const eventHandlers = {
  [POST_TRACE_FAILURE]: handleError('Error while creating new trace.'),
  [POST_EXPORT_SUCCESS]: handlePostExportSuccess,
  [POST_EXPORT_FAILURE]: handleError('Error while creating new export.'),
  [POST_REPORT_SUCCESS]: handlePostReportSuccess,
  [POST_REPORT_FAILURE]: handleError(),
  [GET_REPORTS_SUCCESS]: handleGetReportsSuccess,
  [GET_REPORTS_FAILURE]: handleError(),
  [GET_TRACES_FAILURE]: handleError('Error while fetching traces.'),
  [GET_EXPORTS_SUCCESS]: handleGetExportsSuccess,
  [GET_EXPORTS_FAILURE]: handleError('Error while fetching exports.'),
  [SET_CURRENT_TRACE_FILTER]: setCurrentTraceFilter,
  [SET_COMMON_NETWORK_FAILURE]: handleSetCommonNetworkFailure,
  [UNSET_COMMON_NETWORK_FAILURE]: handleUnsetCommonNetworkFailure,
};

const initialState = {
  traces: [],
  exports: [],
  reports: [],
  commonNetworkFailure: null,
};

export default function networkEventsReducer(state = initialState, event) {
  const handler = eventHandlers[event.type];
  return handler ? handler(event, state) : state;
}
