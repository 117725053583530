import { cutLongText, getTextWidth } from './textHandlers';
import { convertToRGB } from 'lib/convertToRGB';
import { colors, font } from 'App/styleVariables';
import { NODE_HEIGHT, NODE_WIDTH } from '../constants';
import { createNodeTextContent } from './createNodeTextContent';

export const createGraphElements = (svg, graph, colorTheme, graph_scheme) => {
  // Create an arrow marker
  svg
    .append('svg:defs')
    .append('svg:marker')
    .attr('id', 'arrow')
    .attr('viewBox', '0 -5 10 10')
    .style('fill', colors.gray)
    .attr('refX', 90)
    .attr('markerWidth', 7)
    .attr('markerHeight', 7)
    .attr('orient', 'auto')
    .append('svg:path')
    .attr('d', 'M0,-5L10,0L0,5');

  // Create arrows between node blocks
  const link = svg
    .append('g')
    .selectAll('g')
    .data(graph.links)
    .enter()
    .append('g')
    .attr('class', 'links');

  const lines = link
    .append('line')
    .style('stroke', colors.gray)
    .attr('marker-end', () => 'url(#arrow)')
    .style('stroke-width', 2);

  // Create a background underlay for the arrow text
  const textUnderlay = link
    .append('rect')
    .attr('width', (d) => getTextWidth(d.type) + 6)
    .attr('height', 4)
    .attr('fill', 'white');

  // Create a label for the arrow
  const text = link
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('font-size', '11px')
    .style('font-family', font)
    .style('font-weight', 500)
    .style('fill', 'black')
    .text((d) => d.type);

  // Create node blocks
  const node = svg
    .append('g')
    .selectAll('g')
    .data(graph.nodes)
    .enter()
    .append('g')
    .attr('class', 'nodes');

  // Create a node underlayer
  node
    .append('rect')
    .attr('width', NODE_WIDTH)
    .attr('height', NODE_HEIGHT)
    .attr('rx', 14)
    .attr('ry', 14)
    .attr('fill', 'white')
    .attr('x', -NODE_WIDTH / 2)
    .attr('y', -NODE_HEIGHT / 2 + 5);

  // Create a node base layer
  node
    .append('rect')
    .attr('width', NODE_WIDTH)
    .attr('height', NODE_HEIGHT)
    .attr('rx', 14)
    .attr('ry', 14)
    .attr('fill', (d) => {
      const hexColor = colorTheme[d.data?.traceType];
      const rgbColor = convertToRGB(hexColor);
      return rgbColor
        ? `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, 0.1)`
        : 'white';
    })
    .attr('stroke', (d) => colorTheme[d.data?.traceType])
    .attr('stroke-width', 2)
    .attr('x', -NODE_WIDTH / 2)
    .attr('y', -NODE_HEIGHT / 2 + 5);

  // Create a node trace type badge
  node
    .append('rect')
    .attr('width', (d) => cutLongText(d.data.traceType, 21).length * 6.8 + 14)
    .attr('height', 20)
    .attr('fill', (d) => colorTheme[d.data?.traceType])
    .attr('rx', 10)
    .attr('ry', 10)
    .attr('x', -NODE_WIDTH / 2 + 10)
    .attr('y', -NODE_HEIGHT / 2 - 5);

  node
    .append('text')
    .attr('font-size', '11px')
    .attr('x', -NODE_WIDTH / 2 + 16)
    .attr('y', -NODE_HEIGHT / 2 + 8)
    .style('font-family', font)
    .style('font-weight', 500)
    .style('fill', 'white')
    .html((d) => cutLongText(d.data.traceType, 21));

  // Create text content for the node
  node
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('font-size', '11px')
    .style('font-family', font)
    .attr('x', 0)
    .style('fill', 'black')
    .html((d) => createNodeTextContent(d, graph_scheme))
    .attr('y', (_d, i, nodes) => {
      // Place the text content exactly in the center of the node along the Y axis
      // but not higher than y = -47
      const textHeight = nodes[i].getBBox().height;
      const y = -textHeight / 2;
      return y < -47 ? -47 : y;
    });

  return { node, lines, text, textUnderlay };
};
