import React from "react";
import CardWrapper from "../../../components/CardWrapper";
import MudTable from "./containers/MudTable";
import MudModal from "./containers/MudModal";
import withCommonNetworkFailure from "components/HOC/withCommonNetworkFailure";

const BactMudAnalysis = ({ pageName }) => (
  <CardWrapper pageName={pageName}>
    <MudTable search />
    <MudModal />
  </CardWrapper>
);

export default withCommonNetworkFailure(BactMudAnalysis);
