import { cutLongText, getText } from './textHandlers';

export const createNodeTextContent = (nodeElement, graph_scheme) => {
  const traceType = nodeElement.data.traceType;
  let field_to_display = graph_scheme[nodeElement.data.traceType].Fields;
  let display_text = '';

  if (field_to_display === undefined) {
    field_to_display = [graph_scheme[nodeElement.data.traceType]];
  }

  if (!Array.isArray(field_to_display)) field_to_display = [field_to_display];

  if (graph_scheme[traceType].Label) {
    display_text += getText(graph_scheme[traceType].Label);
  }

  field_to_display.forEach(function (element) {
    if (/(^\|\|)(.+)(\|\|$)/.test(element)) {
      const variable_name = element.match(/#\{(.+)\}/)[1];
      const before_text = element.match(/\|\|(.+)#/)[1];
      const after_text = element.match(/\}(.+)\|/)[1].replace('|', '');
      const label_text = `${before_text}${nodeElement.data[variable_name]}${after_text}`;

      display_text += getText(label_text);
    } else {
      const label_text =
        !nodeElement.data[element] && nodeElement.data.traceId
          ? cutLongText(nodeElement.data.traceId, 10)
          : nodeElement.data[element];
      display_text += getText(label_text);
    }
  });

  return display_text;
};
