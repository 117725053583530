import AbstractResourceFactory from './abstract-resource-factory';
import table_presenter from '../../../../../lib/presenters/TablePresenter';
import client from '../../../../../lib/apiClient';
import { Table } from '../../../types';
import { removeGaps } from '../../../../../lib/util';
import { resetAllDataAction, store } from '../../../../../store/nextStore';
import { actionGoTo } from '../../../../SideBar/reducer';
import formErrorHandler from "../../formErrorHandler";

class UserResourceFactory implements AbstractResourceFactory {
  async getAll(): Promise<Table> {
    const data = table_presenter.present_data(await client.getUsers(), 'users');
    const schema = await client.getUsersTable();
    return {
      data,
      schema,
      total: data.length,
    };
  }

  async add({ data, actions }: any) {
    try {
      await client.postUser(removeGaps(data));
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }

  async edit({ data, actions, user }: any) {
    try {
      const response = table_presenter.present_data(
        [await client.putUser(removeGaps(data))],
        'users'
      );
      actions.setSubmitting(false);

      if (user?.id === data?.id && data?.password) {
        // @ts-ignore
        store.dispatch(resetAllDataAction());
        store.dispatch(actionGoTo('/sign_in'));

        return { force_relogin: true };
      }

      return response[0];
    } catch (e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }
}

export default UserResourceFactory;
