import FilterHandler from '../../lib/helpers/FilterHandler';
import EncodePresenter from '../../lib/presenters/EncodePresenter';

class FilterBuilder {
  constructor() {
    this._custom_filters = {};
    this._default_page = 1;
    this.filter_handler = new FilterHandler();
    this.encode_presenter = new EncodePresenter();
  }
  get_filter = ({
    filters: column_filters,
    sorter: { field },
    filter: { sorting: prev_sorting },
    filter,
  }) => {
    const { created_at, send_date, answer_date, DateReception, IssueDate, DateEmission } = column_filters;

    if (this._not_empty(created_at)) this._get_custom_filters({ created_at });
    if (this._not_empty(send_date)) this._get_custom_filters({ send_date });
    if (this._not_empty(answer_date)) this._get_custom_filters({ answer_date });
    if (this._not_empty(DateReception)) this._get_custom_filters({ DateReception });
    if (this._not_empty(IssueDate)) this._get_custom_filters({ IssueDate });
    if (this._not_empty(DateEmission)) this._get_custom_filters({ DateEmission });

    const sorting = this._define_sorting({ field, prev_sorting });
    const filters = this._define_filters({ column_filters, type: 'filters' });

    this._reset();

    return {
      ...filter,
      page_number: this._default_page,
      sorting,
      filters,
    };
  };

  _get_custom_filters = (date) => {
    const [from, to] = Object.values(date)[0];
    this._custom_filters = {
      ...this._custom_filters,
      [Object.keys(date)[0]]: this.filter_handler.get_range({
        from,
        to,
      }),
    };
  };

  _define_sorting = ({ field, prev_sorting }) => {
    const sort = this.filter_handler.get_sorting(field);

    const sorting = sort && this.encode_presenter.encode(sort);

    return sorting || prev_sorting;
  };

  _define_filters = ({ column_filters, type }) =>
    this.encode_presenter.encode(
      { ...column_filters, ...this._custom_filters },
      type
    );

  _not_empty = (v) => v && v.length;

  _reset = () => (this._custom_filters = {});
}

export default FilterBuilder;
