import { FC, useEffect, useRef, useState } from 'react';
import {
  Button,
  Flex,
  Form,
  Modal,
  Spin,
  Tooltip,
  Typography,
  message,
  Empty,
} from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { blocks } from '../../common-dashboard';
import * as customFilters from './components';
import errorHandler from '../../../../lib/errorHandler';
import { fetchData } from '../../fetchData';
import StyledWrapper from './styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { InfoBadge } from 'components/library';

const CustomFilter: FC = ({
  businessName,
  schema,
  styles,
  subItems,
  searchButtonText,
  filtersList,
  title,
  subtitle,
  displayInfoModal,
  infoModalContent,
  infoBadgeColor,
  infoModalWidth,
  extraInfo,
  id,
  submitError,
  pageSchema,
  urlParams,
  componentName,
  downloadUploadFile,
  downloadUploadFileUrl,
  exportButtonText,
}: any) => {
  const [data, setData] = useState<any>();
  const [filtersData, setFiltersData] = useState<Array<any>>([]);
  const [filterValues, setFilterValues] = useState<object>();
  const [extra, setExtra] = useState(extraInfo);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [submittable, setSubmittable] = useState<boolean>(true);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [schemaSlice, setSchemaSlice] = useState<any>();

  const [form] = Form.useForm();
  const prevFilterValueRef = useRef<object>();

  const values = Form.useWatch([], form);

  const generateFilters = () => {
    let filters = [];

    if (!filtersList) {
      return null;
    }

    filtersList
      .filter((filter) => filter?.type)
      .forEach((filter, index) => {
        const rules = [
          { required: filter?.required },
          filter?.rule?.type &&
            (({ getFieldValue }) => ({
              validator(_, value) {
                const fieldNamesByRule = filtersList
                  .filter(({ rule }) => rule?.type === 'one_of_several')
                  .map(({ name }) => name);
                if (fieldNamesByRule.some((name) => getFieldValue(name))) {
                  return Promise.resolve();
                }
                return Promise.reject();
              },
            })),
        ].filter((rule) => !!rule);

        const options = filter?.sortOptions
          ? filter?.options
              ?.filter((option) => option.label)
              .sort((a, b) => {
                return a.label.localeCompare(b.label, 'en-US-u-kf-upper', {
                  ignorePunctuation: true,
                  numeric: true,
                });
              })
          : filter?.options;

        filters.push({
          ...filter,
          rules,
          ...(filter?.options && { options }),
        });
      });

    setFiltersData(filters);
  };

  useEffect(() => {
    generateFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (schema) {
      let subItemsData = {};
      subItems.forEach((item) => (subItemsData[item] = schema[item]));
      setData(subItemsData);
      setSchemaSlice(schema);
      setIsLoading(false);
    }
  }, [schema, subItems]);

  const refreshContent = () => {
    setIsLoading(true);

    fetchData(businessName, urlParams, filterValues, pageSchema, id)
      .then((response: any) => {
        if (response?.schema) {
          let subItemsData = {};
          subItems.forEach(
            (item) => (subItemsData[item] = response.schema[item])
          );
          setExtra(response?.schema[componentName]?.extraInfo);
          setSchemaSlice(response?.schema);
          setData(subItemsData);
        } else {
          // TODO: display popup with error message (?)
        }
        setIsLoading(false);
      })
      .catch((err: Error[]) => {
        errorHandler(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!_.isEqual(filterValues, prevFilterValueRef.current)) {
      refreshContent();

      prevFilterValueRef.current = filterValues;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  const onSubmit = (values: any) => {
    let formattedValues = values;
    const isDayjsObj = (current: any) => dayjs.isDayjs(current);

    Object.entries(values).forEach(
      ([key, value]: [key: string, value: any]) => {
        switch (true) {
          case isDayjsObj(value):
            formattedValues = {
              ...values,
              [key]: dayjs(value as Dayjs).format('DD-MM-YYYY'),
            };
            break;
          case Array.isArray(value) && value.every(isDayjsObj):
            formattedValues = {
              ...values,
              [key]: value?.map((item: Dayjs) =>
                dayjs(item).format('DD-MM-YYYY')
              ),
            };
            break;
          default:
            break;
        }
      }
    );

    setFilterValues(formattedValues);
  };

  const content =
    data &&
    Object.keys(data).length > 0 &&
    Object.keys(data)?.map((componentName: string, index: number) => {
      const name =
        data[componentName]?.component === 'Table' || componentName === 'Table'
          ? 'FrontendPaginatedTable'
          : data[componentName]?.component || componentName;

      const Component = blocks[name];
      const props = {
        ...data[componentName],
        businessName,
        schema: schemaSlice,
        refetchContent: refreshContent,
      };

      return <Component {...props} key={`subItem-${componentName}-${index}`} />;
    });

  const filters =
    filtersData.length > 0
      ? filtersData.map((filter, index) => {
          return (
            <Tooltip
              id="indicatorTooltip"
              title={filter?.tooltipTitle}
              color={'#ffffff'}
              key={`filter-${index}`}
            >
              <Form.Item
                name={filter?.name}
                className={'filter'}
                rules={filter?.rules}
              >
                {customFilters[filter?.type]({
                  placeholder: filter?.placeholderText,
                  options: filter?.options,
                  dateFormat: filter?.dateFormat,
                  disabled: filter?.disabled,
                })}
              </Form.Item>
            </Tooltip>
          );
        })
      : null;

  const infoModal = infoModalContent?.map(
    (componentName: string, index: number) => {
      const Component = schema[componentName]?.component
        ? blocks[schema[componentName]?.component]
        : blocks[componentName];
      const props = {
        ...schema[componentName],
        businessName,
      };

      return <Component {...props} key={`modal-${componentName}-${index}`} />;
    }
  );

  const onFieldsChange = (changedFields, allFields) => {
    const changedFieldName = changedFields[0]?.name[0];
    const fieldNamesByRule = filtersList
      .filter(({ rule }) => rule?.type === 'one_of_several')
      .map(({ name }) => name);

    if (fieldNamesByRule.includes(changedFieldName)) {
      const notChangedFields = fieldNamesByRule.filter(
        (name) => name !== changedFieldName
      );
      form.resetFields(notChangedFields);
    }
  };

  const getInitialValues = () => {
    let initialValues = {};

    filtersList
      .filter((filter) => filter?.type)
      .forEach((item) => {
        if (item.type === 'DatePicker' && item.defaultValue) {
          initialValues[item.name] = dayjs(item.defaultValue, item.dateFormat);
        } else {
          initialValues[item.name] = item.defaultValue;
        }
      });

    return initialValues;
  };

  const handleDownload = (url) => {
    try {
      window.open(url, '_blank');
    } catch (e) {
      console.log('Error: ', e);
      message.error(`${e.message || 'Error exporting file'}`);
    }
  };

  return (
    <StyledWrapper styles={styles}>
      {title && (
        <div className="title-wrapper">
          <Typography.Title level={3} className={'title'}>
            {title}
          </Typography.Title>
          {subtitle && (
            <Typography.Paragraph className={'subtitle'}>
              {subtitle}
              {displayInfoModal && (
                <Button
                  onClick={() => setIsInfoModalOpen(true)}
                  className="info-button"
                >
                  <InfoBadge color={infoBadgeColor} />
                </Button>
              )}
            </Typography.Paragraph>
          )}
        </div>
      )}
      <Form
        form={form}
        onFinish={onSubmit}
        onFieldsChange={onFieldsChange}
        className={'filter-section'}
        initialValues={getInitialValues()}
      >
        <div className={'filters'}>{filters}</div>
        <Form.Item>
          <Tooltip
            id="indicatorTooltip"
            title={!submittable && submitError}
            color={'#ffffff'}
          >
            <Button htmlType="submit" className={'submit-btn'}>
              <SearchOutlined />
              {searchButtonText && <span>{searchButtonText}</span>}
            </Button>
          </Tooltip>
        </Form.Item>
        {extra && (
          <div className={'extra-info'}>
            {extra.map(({ label, text }) => (
              <p>
                <span className={'label'}>{label}</span>
                <span className={'text'}>{text}</span>
              </p>
            ))}
          </div>
        )}
      </Form>
      <Spin spinning={isLoading} size={'large'}>
        {content ? (
          <div className={'content'}>{content}</div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
      {downloadUploadFile && downloadUploadFileUrl && (
        <Flex justify="flex-end" style={{ marginTop: '20px' }}>
          <Button
            onClick={() => handleDownload(downloadUploadFileUrl)}
            className="export-button"
          >
            <DownloadOutlined />
            {exportButtonText && <span>{exportButtonText}</span>}
          </Button>
        </Flex>
      )}
      <Modal
        open={isInfoModalOpen}
        onCancel={() => setIsInfoModalOpen(false)}
        footer={[
          <Button
            type="primary"
            onClick={() => setIsInfoModalOpen(false)}
            className="platform-button primary"
          >
            OK
          </Button>,
        ]}
        width={infoModalWidth}
        maskClosable={false}
      >
        {infoModal}
      </Modal>
    </StyledWrapper>
  );
};

export default CustomFilter;
