import React from 'react';
import { connect } from 'react-redux';
import DataTable from '../../../../../components/DataTable';
import { onSearchAction } from '../../../../../containers/SearchData/reducer';
import {
  get_data_action,
  get_current_action,
  validate_data_action,
  validate_all_action,
  set_filter_action,
  set_initial_state_action,
} from './reducer';
import { openModalAction } from '../StockingModal/reducer';

const headerActionsSchema = [
  {
    label: 'Valider cohérentes',
    action: 'validAll',
    icon: 'check',
  },
];

const coherence = { oui: true };
const header_action = { validAll: 'validee' };
const actions = { invalidate: 'invalidee', validate: 'validee' };
const trace_actions = { trace_pb: true };
const modal_action = { edit: true, show: true };

class StockingTable extends React.Component {
  componentDidMount() {
    const { get_data } = this.props;
    get_data();
  }

  componentWillUnmount() {
    const { set_initial_state } = this.props;
    set_initial_state();
  }

  handleTableChange = (pagination, filters, sorter, current_sort) => {
    const { set_filter } = this.props;
    const { currentDataSource } = current_sort;
    set_filter(currentDataSource);
  };

  handleClickOnTableRowAction = async ({ record, action }) => {
    const { openModal, validate_data, get_current, user } = this.props;
    const { traceId, traceType } = record;
    const trace = await get_current({ traceId });
    const { data, blockNumber, txHash, displayTraceType } = trace;
    const trace_data = { ...JSON.parse(data), traceId, blockNumber, txHash };

    if (modal_action[action]) {
      openModal({
        [action]: true,
        formType: traceType,
        data: action === 'show' ? trace_data : JSON.parse(trace.data),
        traceId,
        displayTraceType,
      });
    }

    if (trace_actions[action]) openModal({ [action]: true, traceId });

    if (actions[action]) {
      const trace = await get_current({ traceId });
      validate_data({
        traceId,
        trace,
        status: actions[action],
        validation: user.attributes.company.kind === 'Admin',
      });
    }
  };

  handleClickOnHeaderButton = async (action) => {
    const { validate_all, data } = this.props;
    if (header_action[action])
      await validate_all({ data, status: header_action[action], coherence });
  };

  render() {
    const { data, table_scheme, onSearch, user, loading } = this.props;

    return (
      <DataTable
        isBrokenTitle
        prefixId="StockingTable"
        user={user}
        onSearch={onSearch}
        loading={loading}
        getRowKey={(user) => user.email}
        data={data}
        toggleArrow={1900}
        columnsSchema={table_scheme}
        headerActionsSchema={headerActionsSchema}
        handleClickOnTableRowAction={this.handleClickOnTableRowAction}
        handleClickOnHeaderButton={this.handleClickOnHeaderButton}
        handleTableChange={this.handleTableChange}
      />
    );
  }
}

const mapStateToProps = ({
  stockingTable: { data, table_scheme, loading },
  searchData: { isSearchActive, searchResult, table },
  auth: { currentUser },
}) => ({
  data: isSearchActive && table === 'StockingTable' ? searchResult : data,
  user: currentUser,
  table_scheme,
  loading,
});

const mapDispatchToProps = {
  get_data: get_data_action,
  get_current: get_current_action,
  validate_data: validate_data_action,
  validate_all: validate_all_action,
  set_filter: set_filter_action,
  set_initial_state: set_initial_state_action,
  openModal: openModalAction,
  onSearch: (q) =>
    onSearchAction(
      q,
      (globalState) => globalState.stockingTable.data,
      'StockingTable'
    ),
};

export default connect(mapStateToProps, mapDispatchToProps)(StockingTable);
