// Table Names
export const USERS_TABLE = 'Users';
export const COMPANIES_TABLE = 'Companies';
export const CUSTOMLIST_TABLE = 'CustomList';
export const PRODUCTS_TABLE = 'Products';
export const SMS_TABLE = 'Sms';
export const TRACES_TABLE = 'Traces';
export const CHAINS_TABLE = 'Chains';
export const UPLOAD_TABLE = 'FileUpload';
export const SMSCOMFIRMATION_TABLE = 'SmsConfirmation';
export const HISTORY_DETAILS_TABLE = 'HistoryDetails';
export const SLUDGEANALYSIS_TABLE = 'SludgeAnalysis';
export const COMPANALYSIS_TABLE = 'CompAnalysis';
export const SOILANALYSIS_TABLE = 'SoilAnalysis';
export const DASHBOARD_BACKEND_TABLE = 'DashboardBackendTable';

// Modal Names
export const showModal = 'SHOW_ITEM_MODAL';
export const downloadTraceModal = 'HOTKEY_SHOW_TRACE_MODAL';
export const formWorkerModal = 'FORM_WORKER_MODAL';
export const chainsModal = 'SHOW_CHAINS';
export const qrCodeModal = 'QRCODE_PREVIEW_MODAL';
export const fileUploadModal = 'FILE_UPLOAD_MODAL';
export const smsDetailsModal = 'SMS_DETAILS_MODAL';
export const addFileGalleryModal = 'ADD_FILE_GALLERY_MODAL';
export const historyTableModal = 'HISTORY_TABLE_MODAL';

export const CUSTOM_MODAL_WIDTH = 1000;

// Modal Titles
export const USERS_ADD_TITLE = 'Add User :';
export const USERS_EDIT_TITLE = 'Edit User :';
export const COMPANIES_ADD_TITLE = 'Add Organization :';
export const COMPANIES_EDIT_TITLE = 'Edit Organization :';
export const CUSTOMLIST_SHOW_TITLE = 'Show Item :';
export const CUSTOMLIST_EDIT_TITLE = 'Edit Item :';
export const CUSTOMLIST_ADD_TITLE = 'Add Item :';
export const UPLOADS_ADD_TITLE = 'Add Upload :';
export const UPLOADS_SHOW_TITLE = 'Show Upload Details :';
export const UPLOADS_EDIT_TITLE = 'Edit Upload :';
export const TRACES_ADD_TITLE = 'Add Trace :';
export const TRACES_SHOW_TITLE = 'Show Trace :';
export const TRACES_EDIT_TITLE = 'Edit Trace :';
export const TRACES_HISTORY_TITLE = 'History details :';
export const TRACES_SHOW_VERSION_TITLE = 'Edited :';
export const PRODUCTS_ADD_TITLE = 'Add Product :';
export const PRODUCTS_EDIT_TITLE = 'Edit Product :';
export const CHAIN_DETAILS_TITLE = 'Chain :';
export const QR_CODE_TITLE = 'QR Code';
export const ANALYSIS_SHOW_TITLE = 'Voir :';
export const SMSCOMFIRMATION_SHOW_TITLE = 'View User';
