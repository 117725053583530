import React from 'react';
import { connect } from 'react-redux';
import DataTable from '../../../../../components/DataTable';
import { onSearchAction } from '../../../../../containers/SearchData/reducer';
import {
  getInvalidationValue,
  getValidationValue,
  getCoherence,
  getValidateAllButtonLabel,
} from '../../../../../lib/validations/util';

import {
  get_data_action,
  get_current_action,
  validate_data_action,
  validate_all_action,
  set_filter_action,
  set_initial_state_action,
} from './reducer';
import { openModalAction } from '../MudModal/reducer';

var headerActionsSchema = [
  {
    label: 'Valider cohérentes',
    action: 'validAll',
    icon: 'check',
  },
];

var coherence = { oui: true };
var header_action = { validAll: 'validee' };
var actions = { invalidate: 'invalidee', validate: 'validee' };
const modal_actions = { edit: true, show: true };

class MudTable extends React.Component {
  componentDidMount() {
    const { get_data } = this.props;
    get_data();
  }

  componentWillUnmount() {
    const { set_initial_state } = this.props;
    set_initial_state();
  }

  handleTableChange = (pagination, filters, sorter, current_sort) => {
    const { set_filter } = this.props;
    const { currentDataSource } = current_sort;
    set_filter(currentDataSource);
  };

  handleClickOnTableRowAction = async ({ record, action }) => {
    const { openModal, validate_data, get_current, user } = this.props;
    const { traceId, traceType } = record;
    const trace = await get_current({ traceId });
    const { data, blockNumber, txHash, displayTraceType } = trace;
    const trace_data = { ...JSON.parse(data), traceId, blockNumber, txHash };

    actions = {
      invalidate: getInvalidationValue(
        this.props.user.attributes.business.name
      ),
      validate: getValidationValue(this.props.user.attributes.business.name),
    };
    if (modal_actions[action] && trace)
      openModal({
        [action]: true,
        formType: traceType,
        data: action === 'show' ? trace_data : JSON.parse(trace.data),
        traceId,
        displayTraceType,
      });
    if (actions[action])
      validate_data({
        traceId,
        trace,
        status: actions[action],
        validation: user.attributes.company.kind === 'Admin',
      });
  };

  handleClickOnHeaderButton = async (action) => {
    const { validate_all, data } = this.props;
    header_action = {
      validAll: getValidationValue(this.props.user.attributes.business.name),
    };
    if (header_action[action])
      coherence = getCoherence(this.props.user.attributes.business.name);
    await validate_all({ data, status: header_action[action], coherence });
  };

  render() {
    const { data, table_scheme, onSearch, user, loading } = this.props;
    headerActionsSchema = [
      {
        label: getValidateAllButtonLabel(
          this.props.user.attributes.business.name
        ),
        action: 'validAll',
        icon: 'check',
      },
    ];
    return (
      <DataTable
        prefixId="MudTable"
        user={user}
        onSearch={onSearch}
        loading={loading}
        getRowKey={(user) => user.email}
        data={data}
        columnsSchema={table_scheme}
        headerActionsSchema={headerActionsSchema}
        handleClickOnTableRowAction={this.handleClickOnTableRowAction}
        handleClickOnHeaderButton={this.handleClickOnHeaderButton}
        handleTableChange={this.handleTableChange}
      />
    );
  }
}

const mapStateToProps = ({
  mudTable: { data, table_scheme, loading },
  searchData: { isSearchActive, searchResult, table },
  auth: { currentUser },
}) => ({
  data: isSearchActive && table === 'MudTable' ? searchResult : data,
  user: currentUser,
  table_scheme,
  loading,
});

const mapDispatchToProps = {
  get_data: get_data_action,
  get_current: get_current_action,
  validate_data: validate_data_action,
  validate_all: validate_all_action,
  set_filter: set_filter_action,
  set_initial_state: set_initial_state_action,
  openModal: openModalAction,
  onSearch: (q) =>
    onSearchAction(q, (globalState) => globalState.mudTable.data, 'MudTable'),
};

export default connect(mapStateToProps, mapDispatchToProps)(MudTable);
