import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HeaderTextPair from '../HeaderTextPair';
import FilePreview from '../FilePreview';
import { FileInfoContainer } from '../../containers/gallery/styles';

const sortingArr = ['file_name', 'company', 'comment', 'file_url', 'file_hash'];

const FileTechnicalInfo = ({ item, business }) => {
  if (!item) {
    return null;
  }

  return (
    <FileInfoContainer>
      <FilePreview fileUrl={item?.file_url_href} />
      {Object.keys(item)
        .slice()
        .sort(function (a, b) {
          return sortingArr.indexOf(a) - sortingArr.indexOf(b);
        })
        .map((key) => (
          <HeaderTextPair
            key={key}
            text={item[key]}
            header={key}
            businessId={business?.id}
          />
          )
        )}
    </FileInfoContainer>
  );
};

FileTechnicalInfo.propTypes = {
  item: PropTypes.object,
  business: PropTypes.object,
};

const mapStateToProps = ({ auth }) => ({
  business: auth.currentUser.attributes.business
});

const ConnectedFileTechnicalInfo = connect(mapStateToProps)(FileTechnicalInfo)

export default ConnectedFileTechnicalInfo;
