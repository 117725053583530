import React from 'react';
import { Input as AInput } from 'antd';

const Input = ({ placeholder, disabled }: any) => {
  return (
    <AInput
      placeholder={placeholder}
      allowClear
      disabled={disabled}
    />
  )
};

export default Input;
