import React from 'react';
import { Col, Flex } from 'antd';
import { SSplitScreen, SBackgroundBlock } from './styled-components';

import background from 'images/crystalchain_background.png';
import crystalchain from 'images/crystalchain_logo_white.png';

const SplitScreenWrapper = ({
  children,
  backgroundImage = background,
}: any) => {
  return (
    <SSplitScreen>
      <Col span={14}>
        <Flex justify={'center'} className={'content-container'}>
          {children}
        </Flex>
      </Col>
      <Col span={10}>
        <SBackgroundBlock image={backgroundImage}>
          <img src={crystalchain} className={'logo'} alt="Crystalchain" />
        </SBackgroundBlock>
      </Col>
    </SSplitScreen>
  );
};

export default SplitScreenWrapper;
