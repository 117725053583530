import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';

const StyledDiv = styled.div`
  display: ${(props) => props.display || 'block'};
  font-weight: ${(props) => (props.name ? 'bold' : 'normal')};
  margin-left: ${(props) => props.marginLeft || '0'};
`;

const SmsDetails = ({ data }) => {
  return (
    <div>
      {data
        ? Object.entries(data)
            .filter((item) => item[0] !== 'searchable_index')
            .map(([label, value]) => (
              <StyledDiv key={label} display="flex">
                <StyledDiv name={label}>{label}: </StyledDiv>
                <StyledDiv marginLeft="5px">
                  {value === null
                    ? 'N/A'
                    : value instanceof Date && dayjs(new Date(value)).isValid()
                    ? dayjs(new Date(value)).format('HH:mm:ss DD-MM-YYYY')
                    : value}
                </StyledDiv>
              </StyledDiv>
            ))
        : ''}
    </div>
  );
};

SmsDetails.propTypes = {
  /**
   * SMS data
   */
  data: PropTypes.object,
};

export default SmsDetails;
