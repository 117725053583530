class TracePresenter {
  constructor() {
    this._white_list = new Set([0, false]);
  }

  present_data = (data, type, ...rest) => this[`_present_${type}`](data, rest);

  _present_show_list = data => {
    const all = this._pick_data(data);
    const filtered = this._filter_data(all);
    const no_empty = all.length === filtered.length;

    return { all, filtered, no_empty };
  };

  _pick_data = data =>
    Object.entries(data).map(([title, content]) => ({
      title,
      content: Array.isArray(content)
        ? content.map(n => this._pick_data(n))
        : content
    }));

  _filter_data = data =>
    data.filter(({ content }) => content || this._white_list.has(content));
}

export default new TracePresenter();
