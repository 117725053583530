import React from 'react';
import { Config } from 'config.js';

class YieldBoard extends React.PureComponent {
  componentDidMount() {
    const { API_URL } = Config;
    const DASHBOARD_URL = `${API_URL}/api/v2/restitution/business/ccm`;
    fetch(DASHBOARD_URL).then(response => response.json());
  }

  render() {
    return (
      <div style={{ background: '#ffffff' }}>
        {process.env.REACT_APP_CCM_YIELD_URL && (
          <iframe
            title="Yield"
            src={process.env.REACT_APP_CCM_YIELD_URL}
            width="100%"
            height="1000"
            frameBorder="0"
          />
        )}
      </div>
    );
  }
}

export default YieldBoard;
