import React from 'react';
import StyledBlock from './styled-component';
import ButtonDownload from '../ButtonDownload';
import CharacteristicBlock from './CharacteristicBlock';

interface Props {
  companyName: string;
  key: string;
  projectName: string | undefined;
  projectId: string | number | undefined;
  projectLogo: string;
  creditingProjectId: string;
  description?: string | undefined;
  businessId: number;
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
  characteristics: { label: string; text?: string; link?: string }[];
  files: { label: string; file: string }[];
  sponsors_file: string;
  ceo_file: string;
}

const DashboardDescription = ({
  companyName,
  key,
  projectName,
  projectId,
  projectLogo,
  creditingProjectId,
  description,
  styles,
  characteristics,
  files,
 businessId,
}: Props) => {
  return (
    <StyledBlock key={key} styles={styles}>
      <div className={'header-wrapper'}>
        <img src="/images/noah-logo.jpg" className={'circle-logo'} alt="Logo" />
        <div className={'header-titles'}>
          <p className={'title'}> {companyName} </p>
          <div className={''}>
            {projectId && (
              <p className={'subtitle'}> Project ID {projectId} </p>
            )}
            {projectName && <p className={'subtitle'}> {projectName} </p>}
          </div>
          {creditingProjectId && (
            <p className={'subtitle'}>
              Crediting project ID {creditingProjectId}
            </p>
          )}
        </div>
        <img src={projectLogo} className={'circle-logo'} alt="Logo" />
      </div>

      {description && (
        <div className={'description-wrapper'}>
          <p className={'section-name'}> Description </p>
          <p className={'description'}> {description} </p>
        </div>
      )}

      <div className={'content-wrapper'}>
        <div className={'fields-wrapper'}>
          {characteristics &&
            characteristics.map((item, index) => (
              <CharacteristicBlock
                key={index}
                title={item?.label}
                text={item?.text}
                link={item?.link}
              />
            ))}
        </div>

        <div className={'files-wrapper'}>
          {files &&
            files.map(({ file, label }, index) => (
              <ButtonDownload key={index} file={file} label={label} businessId={businessId}/>
            ))}
        </div>
      </div>
    </StyledBlock>
  );
};

export default DashboardDescription;
