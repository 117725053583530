import client from '../../../../lib/apiClient';
import { removeGaps } from '../../../../lib/util';

const SET_ERRORS = 'DETAILS_FORM::SET_ERRORS';
const RESET_ERRORS = 'DETAILS_FORM::RESET_ERRORS';

export const handleSubmitAction = ({
  traceId,
  data,
  actions,
  onSuccess,
}) => async (dispatch, getState) => {
  try {
    data = removeGaps(data);

    onSuccess();

    const requestData = JSON.stringify(data);

    await client.putTraces({
      data: requestData,
      traceId,
    });
    onSuccess();
  } catch (e) {
    // onSuccess();
    // eslint-disable-next-line no-console
    console.error('err in TraceFrom -> reducer', e);
    actions.setStatus({
      [e.name]: e.message,
    });
  }
};

export const resetErrUserAction = () => (dispatch) => {
  dispatch({ type: RESET_ERRORS });
};

const handleSetErrors = (state, data) => ({ errors: data });
const handleResetErrors = () => ({ errors: {} });

const handlers = {
  [SET_ERRORS]: handleSetErrors,
  [RESET_ERRORS]: handleResetErrors,
};

export default (state = { errors: {} }, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action.payload) : state;
};
