import { FC } from 'react';
import { Button } from 'antd';
import { SContentWrapper } from './styled-components';
import DotLegend from '../DotLegend';

interface PopoverContentProps {
  content: any;
  closeFn: () => void;
  type?: 'dot_legend';
  displayClose: boolean;
}

const PopoverContent: FC<PopoverContentProps> = ({
  content,
  type,
  closeFn,
  displayClose,
}) => {
  const getContent = () => {
    switch (type) {
      case 'dot_legend':
        return <DotLegend data={content} />;
      default:
        return content;
    }
  };

  return (
    <SContentWrapper>
      <div className="content">{getContent()}</div>
      {displayClose && (
        <Button onClick={closeFn} className="close-button">
          Close
        </Button>
      )}
    </SContentWrapper>
  );
};

export default PopoverContent;
