import React, { useEffect, useRef, useState } from 'react';
import { Input as InputField } from 'antd';
import s, { Wrapper, Confirm, Reset } from './style';

type Props = {
  setSelectedKeys: Function;
  clearFilters: Function;
  confirm: Function;
  name: string;
};

const Input = ({ setSelectedKeys, clearFilters, confirm, name }: Props) => {
  const [value, setValue] = useState<string | string[]>('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const handleChange = ({ target: { value } }) => {
    setSelectedKeys([value]);
    setValue([value]);
  };

  const handleReset = () => {
    clearFilters();
    confirm();
    setValue('');
  };

  return (
    <Wrapper>
      <InputField
        value={value}
        name={name}
        style={s.filter}
        onChange={handleChange}
        ref={inputRef}
      />
      <Confirm onClick={() => confirm()}>Ok</Confirm>
      <Reset onClick={handleReset}>Reset</Reset>
    </Wrapper>
  );
};

export default (props: Props) => <Input {...props} />;
