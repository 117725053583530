import * as yup from 'yup';
import generateValidationObject from './validateGenerator';

//* * */ I add a custom for the yup library. it will check the value and replace the comma with a dot to pass validation
yup.addMethod(yup.number, 'delocalize', function () {
  return this.transform((currentValue, originalValue) =>
    /^-?[0-9]*[.,]?[0-9]+$/.test(originalValue)
      ? parseFloat(originalValue.replace(',', '.'))
      : false
  );
});

const validationOptions = (
  ruleName,
  validationObject,
  fieldName,
  schema,
  isEdit
) => {
  const isRequired =
    schema[fieldName].rules.required && schema[fieldName].disabled !== true;
  const forbiddenChars = schema[fieldName]?.rules?.forbidden_chars;
  const hasForbiddenChars =
    Array.isArray(forbiddenChars) && forbiddenChars.length > 0;

  switch (ruleName) {
    case 'required':
      const isNumber = schema[fieldName].rules.number;
      const isEditingDisabled = schema[fieldName].isEditingDisabled;
      const isUploadInput = schema[fieldName].type === 'UploadInput';
      const isEmailInput = schema[fieldName].type === 'EmailInput';

      if (
        (fieldName === 'password' || fieldName === 'password_confirmation') &&
        (isEdit || !isRequired)
      ) {
        validationObject[fieldName] =
          !(isEditingDisabled && isEdit) &&
          validationObject[fieldName]
            .string()
            .nullable()
            .test('len', `must be `, (val = '') => {
              // ugly solution
              if (fieldName === 'password') {
                localStorage.setItem('val', val);
              }
              return true;
            });
      } else if (isNumber && isRequired) {
        validationObject[fieldName] = validationObject[fieldName]
          .number()
          .delocalize()
          .typeError('invalid number')
          .required('required field');
      } else if (isNumber && !isRequired) {
        validationObject[fieldName] = validationObject[fieldName]
          .number()
          .delocalize()
          .nullable()
          .transform((value, originalValue) => {
            return originalValue === null ? null : value;
          })
          .typeError('invalid number');
      } else if (isUploadInput && isRequired) {
        validationObject[fieldName] =
          !(isEditingDisabled && isEdit) &&
          validationObject[fieldName]
            .mixed()
            .required('required field')
            .nullable();
      } else if (isEmailInput && !isRequired) {
        validationObject[fieldName] =
          !(isEditingDisabled && isEdit) &&
          validationObject[fieldName].string().nullable();
      } else if (
        (hasForbiddenChars && !isRequired) ||
        (hasForbiddenChars && isRequired && isEditingDisabled && isEdit)
      ) {
        validationObject[fieldName] = validationObject[fieldName].string();
      } else {
        validationObject[fieldName] =
          isRequired &&
          !(isEditingDisabled && isEdit) &&
          validationObject[fieldName]
            .string()
            .required('required field')
            .nullable();
      }
      break;
    case 'isEmail':
      validationObject[fieldName] = validationObject[fieldName]
        .min(3, 'too short')
        .max(255)
        .email('email must be valid');
      break;
    case 'match':
      const matchField = schema[fieldName].rules.match;

      if ((isEdit || !isRequired) && fieldName === 'password_confirmation') {
        validationObject[fieldName] = validationObject[fieldName].test(
          'len',
          `does not match with ${matchField}`,
          (val = '') => {
            // ugly solution
            const pass = localStorage.getItem('val') || '';
            return val === pass;
          }
        );
      } else {
        validationObject[fieldName] = validationObject[fieldName]
          .oneOf([yup.ref(matchField)], `does not match`)
          .required(`does not match`);
      }

      break;
    case 'exact':
      const exactValue = schema[fieldName].rules.exact;

      validationObject[fieldName] = validationObject[fieldName].test(
        'len',
        `must be exactly ${exactValue} characters`,
        (val = '') => val.length === exactValue
      );
      break;
    case 'min_length':
      // if (fieldName === 'password' && isEdit) break;

      const minValue = schema[fieldName].rules.min_length;

      validationObject[fieldName] = validationObject[fieldName].min(
        minValue,
        `${minValue} characters min.`
      );
      break;
    case 'forbidden_chars':
      validationObject[fieldName] = hasForbiddenChars
        ? validationObject[fieldName].matches(
            new RegExp(`^[^${forbiddenChars.join('')}]*$`),
            `Field cannot contain ${forbiddenChars.join(', ')}`
          )
        : validationObject[fieldName];
      break;
    default:
      break;
  }
};

const getValidationSchema = (schema, isEdit) =>
  yup
    .object()
    .shape(generateValidationObject(schema, validationOptions, isEdit));

export default getValidationSchema;
