import React, { useState } from 'react';
import { Wrapper } from './styled-components';
import { Spin } from 'antd';

const SupportForm = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleOnLoad = () => {
    setIsLoading(false);
  };

  return (
    <Wrapper>
      <Spin spinning={isLoading} size={'large'}>
        <iframe
          id={'zsfeedbackFrame'}
          name={'zsfeedbackFrame'}
          title={'Support form'}
          className={'support-iframe'}
          onLoad={handleOnLoad}
          src={'https://desk.zoho.eu/support/fbw?formType=AdvancedWebForm&fbwId=edbsn254c574e52df2227cf686b4ce5158ee33879bce20233eb7aadc3564585beabeb&xnQsjsdp=edbsn31ba75ddc11d6020b55afa7dc8684b72&mode=showNewWidget&displayType=iframe'}
        />
      </Spin>
    </Wrapper>
  );
};

export default SupportForm;
