import React, { useEffect, useState } from 'react';
import {
  createSearchParams,
  NavigateFunction,
  Link,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import { message, Spin } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import SplitScreenWrapper from '../../containers/SplitScreenWrapper';
import client from '../../lib/apiClient';
import { SWrapper, SResult, SLink } from './styled-components';

import crystaltrace from 'images/crystaltrace_logo.png';

interface ResponseInterface {
  success: boolean;
  email?: string;
  reset_password_token?: string;
  errors?: any;
}

const ConfirmEmail = () => {
  const [error, setError] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response: ResponseInterface = await client.confirmEmail({
          token,
        });

        if (response.success) {
          message.success('Email successfully confirmed');

          if (response.email && response.reset_password_token) {
            const params = {
              email: response.email,
              token: response.reset_password_token,
            };
            navigate({
              pathname: '/reset_password',
              search: `?${createSearchParams(params)}`,
            });
          } else {
            navigate('/sign_in');
          }
        } else {
          message.error(response.errors[0]);
          navigate('/sign_in');
        }
      } catch (e) {
        console.log('Err:', e);
        setError(true);
      }
    };

    confirmEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <SplitScreenWrapper>
      <SWrapper>
        <img src={crystaltrace} className={'trace-logo'} alt="CrystalTrace" />
        {!error ? (
          <>
            <h1> Email Confirmation </h1>
            <Spin size={'large'} />
          </>
        ) : (
          <SResult>
            <ExclamationCircleFilled />
            <div>
              <p>Something went wrong. Please contact our administrator.</p>
              <SLink>
                <Link to={'/sign_in'}>Back to login</Link>
              </SLink>
            </div>
          </SResult>
        )}
      </SWrapper>
    </SplitScreenWrapper>
  );
};

export default ConfirmEmail;
