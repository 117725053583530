export const onTick = (lines, text, node, textUnderlay) => {
  lines
    .attr(
      'd',
      (d) => `M${d.source.x},${d.source.y}, ${d.target.x},${d.target.y}`
    )
    .attr('x1', (d) => d.source.x)
    .attr('y1', (d) => d.source.y)
    .attr('x2', (d) => d.target.x)
    .attr('y2', (d) => d.target.y);

  text.attr('transform', (d) => {
    const angle =
      Math.atan2(d.target.y - d.source.y, d.target.x - d.source.x) *
      (180 / Math.PI);
    const readableAngle = angle >= 90 || angle <= -90 ? angle + 180 : angle; // to avoid upside down text

    const x = (d.source.x + d.target.x) / 2;
    const y = (d.source.y + d.target.y) / 2;

    return `translate(${x},${y}) rotate(${readableAngle}) translate(0, 3)`;
  });

  textUnderlay.attr('transform', (d, i, nodes) => {
    const angle =
      Math.atan2(d.target.y - d.source.y, d.target.x - d.source.x) *
      (180 / Math.PI);
    const x = (d.source.x + d.target.x) / 2;
    const y = (d.source.y + d.target.y) / 2;

    const rectangleCenterX = nodes[i].getBBox().width / 2;

    return `translate(${x},${y}) rotate(${angle}) translate(${-rectangleCenterX}, -2)`;
  });

  node.attr('transform', (d) => `translate(${d.x}, ${d.y})`);
};