import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TraceList from './components/TraceList';
import client from '../../lib/apiClient';
import trace_presenter from '../../lib/presenters/TracePresenter';
import { Spin } from 'antd';
import errorHandler from "../../lib/errorHandler";

class ShowTraceList extends React.PureComponent {
  state = { is_all: false, no_empty: true };
  
  async componentDidMount() {
    const { data, fetchTrace, traceId } = this.props;
    let dynamicTraceData;

    if (fetchTrace) {
      try {
        const r = await client.getTraceById(traceId);
        dynamicTraceData = { ...JSON.parse(r.data), traceId: r.traceId, updated_at: r.displayDate }
      } catch (e) {
        console.log(e);
        errorHandler(e);
      }
    }
    this.setState(trace_presenter.present_data(data || dynamicTraceData, 'show_list'));
  }

  handleToggle = () => this.setState({ is_all: !this.state.is_all });

  render() {
    const { modalWidth, business } = this.props;
    const { all, filtered, no_empty, is_all } = this.state;

    return filtered || all ? (
      <TraceList
        data={(is_all && all) || filtered}
        modalWidth={modalWidth}
        no_empty={no_empty}
        is_all={is_all}
        handleToggle={this.handleToggle}
        business={business}
      />
    ) : (
      <Spin size="large" style={{ display: 'block' }} />
    );
  }
}

ShowTraceList.propTypes = {
  /**
   * Trace data : all trace information that you want to see on the screen
   */
  data: PropTypes.object,
  /**
   * Property that sets the dimensions for the label field and the value field in the row
   */
  modalWidth: PropTypes.any,
  /**
   * Property that specifies whether to fetch trace data when the component is mounted
   */
  fetchTrace: PropTypes.bool,
  /**
   * Trace id
   */
  traceId: PropTypes.string,
};

const mapStateToProps = ({ auth }) => ({
  business: auth.currentUser.attributes.business
});

const ConnectedShowTraceList = connect(mapStateToProps)(ShowTraceList);

export default ConnectedShowTraceList;
