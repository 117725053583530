import React from 'react';
import PopoverWrapper from '../PopoverWrapper';

export const CsrdPopoverContent = () => (
  <PopoverWrapper>
    <>
      <p className={'popover-title'}> CSRD </p>
      <p>
        Les catégories présentées sont celles liées à la double matérialité de
        la directive européenne CSRD.
      </p>
      <p>
        Pour chaque catégorie est indiqué son niveau de risque au niveau de la
        matérialité d’impact (vision “Inside-out”) et au niveau de la
        matérialité financière (vision “Outside-in”) :
      </p>
      <p className={'color-description'}>
        <span className={'color-dot critical'}></span>
        <span>Critique</span>
      </p>
      <p className={'color-description'}>
        <span className={'color-dot significant'}></span>
        <span>Significatif</span>
      </p>
      <p className={'color-description'}>
        <span className={'color-dot important'}></span>
        <span>Important</span>
      </p>
      <p className={'color-description'}>
        <span className={'color-dot informative'}></span>
        <span>Informatif</span>
      </p>
      <p className={'color-description'}>
        <span className={'color-dot minimal'}></span>
        <span>Minimal</span>
      </p>
    </>
  </PopoverWrapper>
);
