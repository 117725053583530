import React from 'react';

type Props = {
  url: string;
};

const Mockup = ({ url }: Props) => {
  return (
    <div className="temp-wrapper">
      <div className="px">
        <div className="px__body">
          <div className="px__body__cut" />
          <div className="px__body__speaker" />
          <div className="px__body__sensor" />

          <div className="px__body__mute" />
          <div className="px__body__up" />
          <div className="px__body__down" />
          <div className="px__body__right" />
        </div>
        <div className="px__screen">
          <div className="px__screen__">
            <div className="px__screen__frame">
              <iframe title="QR Preview" className="device-content" src={url} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mockup;
