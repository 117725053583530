import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface Block {
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      },
      row: {
        start: number;
        end: number;
      }
    }
  };
}

const StyledWrapperButton = styled.div<Block>`
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  box-sizing: border-box;
`;

const StyledButton = styled(NavLink)`
  display: block;
  width: 250px;
  margin: 0 auto;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  background-color: #0a1f6a;
  padding: 15px;
  border-radius: 35px;
  color: #ffffff;
  text-align: center;
  transition: background-color 0.3s;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    color: #ffffff;
    background-color: #0e3096;
  }
`;

export { StyledWrapperButton, StyledButton };