import React from 'react';
import DetailsTable from './containers/DetailsTable';
import DetailsModal from './containers/DetailsModal';

const TraceDetails = props => (
  <div>
    <DetailsTable {...props} />
    <DetailsModal />
  </div>
);

export default TraceDetails;
