import styled from 'styled-components';

export const SImagePreviewWrapper = styled.div`
  text-align: center;

  .ant-image {
    width: 250px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .placeholder-loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    background-color: ${({ theme }) => theme.body} !important;
  }

  .file-preview-image {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;

    &.file {
      max-height: 130px;
    }
  }
`;
