import React from 'react';
import { SRow, SIndicatorContainer } from './styled-components';
import Badge from '../../../../components/Badge';
import { CSRD_ICONS } from '../../constants';

const CSRDirective = ({ styles, bordered, categories }: any) => {
  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <p className={'indicator-title'}>Analyse de double matérialité</p>
      <div className={'indicator-wrapper'}>
        <SRow>
          <div></div>
          <div className={'cell header-title'}>Catégorie</div>
          <div className={'cell header-title'}>Impact</div>
          <div className={'cell header-title'}>Financier</div>
        </SRow>
        {categories?.map(({ title, icon, subcategories }) => {
          return (
            <SRow key={title} childrenLength={subcategories.length}>
              <div className={'category-title'}>
                {icon && <img src={CSRD_ICONS[icon]} alt={title} />}
                <p>{title}</p>
              </div>
              {subcategories?.map(({ name, impact_risk, financial_risk }) => {
                return (
                  <React.Fragment key={name}>
                    <div className={'cell'}>{name}</div>
                    <div className={'cell badge-cell'}>
                      <Badge
                        title={impact_risk.title}
                        type={impact_risk.type}
                      />
                    </div>
                    <div className={'cell badge-cell'}>
                      <Badge
                        title={financial_risk.title}
                        type={financial_risk.type}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
            </SRow>
          );
        })}
      </div>
    </SIndicatorContainer>
  );
};

export default CSRDirective;
