import styled from 'styled-components';
import { Button } from 'antd';
import { colors, font } from 'App/styleVariables';

const SDetailsList = styled.div`
  margin-top: 20px;

  .ant-list-item {
    margin-bottom: 8px !important;
    box-sizing: border-box;
    overflow: auto;
  }
  .array-data {
    max-height: 140px;
    padding: 1px 0px 5px 0px;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  .array-data div {
    display: block;
    margin-bottom: 5px;
  }
  .array-data div div {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
  }
  ant-row {
    display: flex;
    align-items: center;
  }
  span.title {
    font-weight: bold;
    margin-right: 8px;
  }
  .trace-list-wrapper {
    display: flex;
    align-items: start;
  }

  .trace-list-title {
    display: flex;
    justify-content: flex-start;
    width: ${(props) =>
      props.modalWidth.left ? props.modalWidth.left : '30%'};
    color: #1b1b1b;
    font-family: ${(props) => props.theme.showTrace?.family || font}, sans-serif;
    font-size: ${(props) => props.theme.showTrace?.size || '12px'};
    line-height: 24px;
  }

  .trace-list-desc {
    display: inline-block;
    width: ${(props) =>
      props.modalWidth.right ? props.modalWidth.right : '70%'};
    color: #7a7a7a;
    font-family: ${(props) => props.theme.showTrace?.family || font}, sans-serif;
    font-size: ${(props) => props.theme.showTrace?.size || '12px'};
    line-height: 24px;

    a {
      color: ${({ theme }) => theme.showTrace?.link?.colorPassive || colors.secondary} !important;

      &:hover {
        color: ${({ theme }) => theme.showTrace?.link?.colorActive || colors.primary} !important;
      }
    }
  }
`;

const SButton = styled(Button)`
  font-size: ${(props) => props.theme.form.backButton.size} !important;
  font-weight: ${(props) => props.theme.form.backButton.weight} !important;
  font-family: ${(props) => props.theme.form.backButton.family} !important;
  color: ${({ theme }) => theme.form.backButton.colorPassive} !important;
  border: ${({ theme }) => theme.form.backButton.borderPassive} !important;

  &:hover {
    color: ${({ theme }) => theme.form.backButton.colorActive || colors.secondary} !important;
    border: ${({ theme }) => theme.form.backButton.borderActive || `1px solid ${colors.secondary}`} !important;
  }
`;

const s = {
  compos: {
    paddingTop: 30,
    paddingBottom: 20,
    borderTop: '1px solid #ede9e4',
    borderBottom: '1px solid #ede9e4',
  },
  grid: {
    column: 1,
  },
};

export { SDetailsList, SButton, s };

export default {};
