import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import InputWrapper from '../../../components/HOC/InputWrapper';

const roles = { User: true, Admin: true };
const excludedFieldsForClear = ['role', 'deactivated'];

const { Option } = Select;

class SelectComponent extends React.PureComponent {
  static defaultProps = { unique_id: `${Math.random()}` };

  render() {
    const {
      name,
      placeholder,
      value,
      setFieldTouched,
      setFieldValue,
      handleRoleChange,
      options,
      disabled,
      isError,
    } = this.props;

    const unique_id = `select_${name}`;

    return (
      <Select
        id={unique_id}
        notFoundContent="no results"
        showSearch
        allowClear={!disabled && !excludedFieldsForClear.includes(name.toLowerCase())}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        value={value || undefined}
        onChange={(value) => {
          setFieldValue(name, value || '');
          if (roles[value]) {
            handleRoleChange(value);
            setFieldValue('steps', []);
          }
        }}
        onBlur={() => setFieldTouched(name, true)}
        onClear={() => setFieldTouched(name, true)}
        className={isError ? 'has-error' : ''}
        autoComplete="off"
        getPopupContainer={triggerNode => triggerNode.parentElement}
        data-name={name}
      >
        {options &&
          options.map(({ value, text }) => (
            <Option key={value} value={value}>
              {text}
            </Option>
          ))}
      </Select>
    );
  }
}

SelectComponent.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleRoleChange: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  isError: PropTypes.string,
};

export default (props) => InputWrapper(props, SelectComponent);
