import React from 'react';

type Props = {
  title: string;
  text?: string;
  link?: string;
};

const CharacteristicBlock = ({ title, text, link }: Props) => {
  return (
    <div className={'field border-gradient'}>
      <p className={'section-name'}> {title} </p>
      {text && (<p className={'section-text'}> {text} </p>)}
      {link && (
        <a
          href={link}
          target={'_blank'}
          rel="noopener noreferrer"
          className={'link-text'}
        >
          click here
        </a>
      )}
    </div>
  );
};

export default CharacteristicBlock;
