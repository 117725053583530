import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import getValidationSchema from '../../FormValidator';

import * as inputTypes from '../../components';
import { InfoBadge, InfoPopover } from 'components/library';
import { getFormScheme } from '../../actions';
import { renameKeys } from 'lib/util';
import UniqIdsGenerator from 'lib/uniqIdsGenerator';

import s, {
  StyledButton,
  StyledBackButton,
  StyledSpin,
  StyledFForm,
} from './style';

const id_generator = new UniqIdsGenerator('form-filling');

const without_back = {
  product: true,
  custom_list: true,
  document: true,
  file: true,
  suppliers_dashboard: true,
  conformity_dashboard: true,
};
const replace = '#replace#';

const getWrapItemStyle = (type) => {
  const widths = {
    user: '270px',
    company: '270px',
    upload: '200px',
    trace: '46%',
    product: '250px',
    custom_list: '46%',
    file: '40%',
    suppliers_dashboard: '40%',
  };
  return {
    marginRight: '0',
    textAlign: 'right',
    marginBottom: '0px',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '10px',
    width: widths[type],
  };
};

const FieldWrapper = ({
  children,
  label,
  isError,
  wrapItem,
  isHidden,
  help,
}) => {
  return (
    <div style={s.wrap} className={isHidden ? 'hidden-field' : ''}>
      <div style={wrapItem}>
        <div style={s.labelWrapper}>
          <span style={s.label}>
            {label && label}
            {isError ? <span style={s.star}>*</span> : null}
            {help && (
              <InfoPopover
                triggerElement={
                  <span style={{ marginLeft: '2px' }}>
                    <InfoBadge color={'rgba(0, 0, 0, 0.65)'} />
                  </span>
                }
                content={help}
              />
            )}
          </span>
        </div>
      </div>
      <div style={s.input}>{children && children}</div>
    </div>
  );
};

const defineFormWidth = (formSchema) => {
  // const compos = formSchema.composition ? formSchema.composition.fields : []
  // const keys = [...Object.keys(formSchema), ...Object.keys(compos)]
  // console.log(keys)
};

const types = { company: true, trace: true, user: true };
const labels = { Author: true };

const getExluded = (obj) =>
  Object.keys(obj).filter((key) => obj[key].type === 'TextArea');

const getInitalValues = (formSchema, userValues, user, type, step, isEdit) => {
  userValues = renameKeys(userValues, '.', replace);
  const initialValues = {};
  Object.keys(formSchema).forEach((fieldName) => {
    // const dateRegExp = new RegExp('date', 'i')
    if (
      userValues &&
      userValues.steps &&
      formSchema[fieldName].type === 'MultiSelect' &&
      type === 'user' &&
      step > 0
    ) {
      userValues.steps = [];
    }

    if (userValues && userValues[fieldName]) {
      initialValues[fieldName] = userValues[fieldName];
    } else if (
      formSchema[fieldName].type === 'Select' &&
      formSchema[fieldName].defaultValue &&
      formSchema[fieldName].disabled
    ) {
      initialValues[fieldName] = formSchema[fieldName].defaultValue;
    } else if (
      !userValues &&
      (type === 'trace' || type === 'upload') &&
      (fieldName === 'author' || fieldName === 'created_by')
    ) {
      initialValues[fieldName] = `${user.first_name} ${user.last_name}`;
    } else if (
      !userValues &&
      (type === 'trace' || type === 'upload') &&
      fieldName === 'location'
    ) {
      initialValues[fieldName] = user.city;
    } else if (!userValues && formSchema[fieldName].type === 'DatePicker') {
      initialValues[fieldName] = formSchema[fieldName].defaultValue
        ? dayjs(formSchema[fieldName].defaultValue, 'YYYY/MM/DD')
        : '';
    } else if (formSchema[fieldName].type === 'FieldArray') {
      initialValues[fieldName] = [{}];
      Object.keys(formSchema[fieldName].fields).forEach(
        (compositionFieldName) => {
          initialValues[fieldName][0][compositionFieldName] =
            formSchema[fieldName].fields[compositionFieldName].defaultValue ||
            '';
        }
      );
    } else {
      initialValues[fieldName] =
        (!isEdit && formSchema[fieldName].defaultValue) || '';
    }
  });

  return initialValues;
};

class FormContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      formSchema: '',
      currentRole: null,
      count: 0,
    };
  }

  UNSAFE_componentWillMount() {
    const { handleSubTitle, kinds, formType, userCompany, type, isEdit } =
      this.props;
    if (handleSubTitle) {
      const user_type = (kinds && kinds.get(formType)) || userCompany;
      let current;
      if (type === 'user' || type === 'company') current = user_type;
      else if (!isEdit && (type === 'trace' || type === 'upload')) {
        current = kinds.get(formType);
      } else current = formType;
      handleSubTitle(current);
    }
  }

  async componentDidMount() {
    const { formType, type, listName } = this.props;
    const scheme = await getFormScheme({
      type,
      kind: formType,
      listName,
    });

    const formSchema = scheme && renameKeys(scheme, '.', replace);

    if (formSchema) {
      defineFormWidth(formSchema);
      const currentRole =
        formSchema.role && formSchema.role.defaultValue
          ? formSchema.role.defaultValue
          : null;
      const noTrimming = getExluded(formSchema);
      let noTrimmingCompos = [];
      if (formSchema.Composition) {
        noTrimmingCompos = Object.keys(formSchema.Composition.fields).filter(
          (key) =>
            formSchema.Composition.fields[key].type === 'TextArea' &&
            !noTrimming.includes(key)
        );
      }
      localStorage.removeItem('noTrimming');
      localStorage.removeItem('role');
      localStorage.setItem(
        'noTrimming',
        JSON.stringify([...noTrimming, ...noTrimmingCompos])
      );

      this.setState({ formSchema, currentRole });
    }
  }

  componentWillUnmount() {
    const { handleSubTitle } = this.props;
    if (handleSubTitle) {
      handleSubTitle('');
    }
    localStorage.removeItem('noTrimming');
    localStorage.removeItem('role');
  }

  handleMoveBack = () => {
    const { onFormTypeSelect, formType, step } = this.props;
    onFormTypeSelect({
      formStep: 'formSelection',
      formType,
      step: step + 1,
    });
  };

  handleRoleChange = (role) => {
    this.setState({ currentRole: role, count: this.state.count + 1 });
    localStorage.setItem('role', role);
  };

  render() {
    const {
      isEdit,
      styles,
      formType,
      userValues,
      extraKeyName,
      handleSubmit,
      extraKeyValue,
      type,
      user,
      step,
      isLoading,
      title,
      submitText = 'OK',
    } = this.props;

    const { formSchema, currentRole, count } = this.state;
    const initialValues = getInitalValues(
      formSchema,
      userValues,
      user,
      type,
      step,
      isEdit
    );
    if (initialValues.role && count === 0) {
      localStorage.setItem('role', initialValues.role);
    }
    const editRole = localStorage.getItem('role');
    return formSchema ? (
      <div id={'form-filling-form-wrapper'} style={s.wrapper}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            handleSubmit(
              {
                ...renameKeys(values, replace, '.'),
                ...(extraKeyName
                  ? { [extraKeyName]: extraKeyValue || formType }
                  : null),
              },
              actions
            );
          }}
          validationSchema={getValidationSchema(formSchema, isEdit)}
          render={(props) =>
            FormContent({
              ...props,
              editRole,
              currentRole,
              formSchema,
              styles,
              isEdit,
              formType,
              handleMoveBack: this.handleMoveBack,
              type,
              handleRoleChange: this.handleRoleChange,
              isLoading,
              submitText,
              title,
            })
          }
        />
      </div>
    ) : (
      <Spin style={s.spin} size="large" />
    );
  }
}

const FormContent = (props) => {
  const {
    formSchema,
    values,
    touched,
    errors,
    status,
    isEdit,
    extraKeyName,
    handleChange,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    handleMoveBack,
    handleRoleChange,
    currentRole,
    type,
    editRole,
    submitText,
    title,
    isLoading = false,
    isSubmitting,
    submitCount,
  } = props;

  let content;

  useEffect(() => {
    if (submitCount > 0 && (status || Object.keys(errors).length)) {
      const errorElements = document.getElementsByClassName('has-error');
      const firstEl = errorElements[0];
      firstEl?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [status, submitCount, errors]);

  if (without_back[type] || (types[type] && isEdit)) {
    content = null;
  } else {
    content = (
      <StyledBackButton
        id={id_generator.get_id('btn-back')}
        // disabled={isEdit && type === 'user'}
        type="default"
        style={{ marginRight: '10px' }}
        onClick={() => {
          handleMoveBack();
        }}
      >
        Back
      </StyledBackButton>
    );
  }
  const wrapItem = getWrapItemStyle(type);

  return (
    <StyledFForm>
      <div id={'form-filling-form-content'} style={{ width: '100%' }}>
        {Object.keys(formSchema).map((itemName) => {
          if (
            itemName === 'Composition' ||
            formSchema[itemName]?.type === 'FieldArray'
          ) {
            return (
              <React.Fragment key={itemName}>
                {inputTypes[formSchema[itemName].type]({
                  name: itemName,
                  value: values[itemName] || '',
                  placeholder: formSchema[itemName].label,
                  options: formSchema[itemName].options,
                  disabled:
                    (isEdit &&
                      (formSchema[itemName].isEditingDisabled ||
                        formSchema[itemName].disabled ||
                        labels[formSchema[itemName].label])) ||
                    formSchema[itemName].disabled,
                  extraKeyName,
                  handleChange,
                  handleBlur,
                  setFieldTouched,
                  setFieldValue,
                  formSchema,
                  touched,
                  errors,
                  handleRoleChange,
                  currentRole,
                  editRole,
                  wrapItem,
                  compositionName: formSchema[itemName].label,
                  endpoint: formSchema[itemName].endpoint || null,
                  isEdit,
                })}
              </React.Fragment>
            );
          }
          // if (itemName === 'FileSelect') {
          //   return (
          //     <FileSelect
          //       file={{
          //         DocumentFilename: values.DocumentFilename,
          //         DocumentHash: values.DocumentHash,
          //         DocumentUrl: values.DocumentUrl
          //       }}
          //       setFieldValue={setFieldValue}
          //     />
          //   );
          // }
          return (
            <FieldWrapper
              wrapItem={wrapItem}
              key={itemName}
              label={formSchema[itemName].label}
              isError={
                (formSchema[itemName].rules.required ||
                  formSchema[itemName].rules.exact) &&
                ((itemName === 'password' && !isEdit) ||
                  itemName !== 'password') &&
                ((itemName === 'password_confirmation' && !isEdit) ||
                  itemName !== 'password_confirmation')
              }
              isHidden={formSchema[itemName].rules.hidden}
              help={formSchema[itemName].help}
            >
              <div>
                <div>
                  {inputTypes[formSchema[itemName].type]({
                    name: itemName,
                    value: values[itemName] || '',
                    placeholder: formSchema[itemName].label,
                    options: formSchema[itemName].options,
                    disabled:
                      (isEdit &&
                        (formSchema[itemName].isEditingDisabled ||
                          formSchema[itemName].disabled ||
                          labels[formSchema[itemName].label])) ||
                      formSchema[itemName].disabled,
                    extraKeyName,
                    handleChange,
                    handleBlur,
                    setFieldTouched,
                    setFieldValue,
                    formSchema,
                    touched,
                    errors,
                    handleRoleChange,
                    currentRole,
                    editRole,
                    type,
                    rules: formSchema[itemName].rules || {},
                    isError:
                      touched[itemName] &&
                      !Array.isArray(touched[itemName]) &&
                      (errors[itemName] || (status && status[itemName]))
                        ? 'error'
                        : '',
                    title,
                    endpoint: formSchema[itemName].endpoint || null,
                  })}
                  <span style={s.composError}>
                    {touched[itemName] &&
                    !Array.isArray(touched[itemName]) &&
                    (errors[itemName] || (status && status[itemName]))
                      ? errors[itemName] || status[itemName]
                      : ''}
                  </span>
                </div>
              </div>
            </FieldWrapper>
          );
        })}

        <FieldWrapper wrapItem={wrapItem}>
          {!isSubmitting && content}
          {(!!isLoading || isSubmitting) && <StyledSpin size="default" />}
          {!isLoading && !isSubmitting && (
            <StyledButton
              id={id_generator.get_id('btn-submit')}
              type="primary"
              htmlType="submit"
              style={without_back[type] ? { marginRight: '0px' } : null}
              disabled={!!isLoading || isSubmitting}
            >
              {submitText}
            </StyledButton>
          )}
        </FieldWrapper>
      </div>
    </StyledFForm>
  );
};

FieldWrapper.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  isError: PropTypes.bool,
  wrapItem: PropTypes.any,
};

FormContainer.propTypes = {
  handleSubTitle: PropTypes.func,
  kinds: PropTypes.object,
  formType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userCompany: PropTypes.any,
  type: PropTypes.string,
  isEdit: PropTypes.bool,
  listName: PropTypes.string,
  onFormTypeSelect: PropTypes.func,
  step: PropTypes.number,
  styles: PropTypes.object,
  userValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  extraKeyName: PropTypes.string,
  extraKeyValue: PropTypes.any,
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  submitText: PropTypes.string,
  formSchema: PropTypes.object,
};

FormContent.propTypes = {
  formSchema: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  status: PropTypes.object,
  isEdit: PropTypes.bool,
  extraKeyName: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleMoveBack: PropTypes.func,
  handleRoleChange: PropTypes.func,
  currentRole: PropTypes.any,
  type: PropTypes.string,
  editRole: PropTypes.string,
  submitText: PropTypes.string,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default React.createFactory(FormContainer);
