import styled from 'styled-components';
import { BellOutlined } from '@ant-design/icons';

const StyledRoot = styled.section`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 16px;
  position: relative;

  //:hover {
  //  background-color: #f5f5f5;
  //}
`;

const StyledBell = styled(BellOutlined)`
  font-size: 16px;
  margin-right: 16px;
`;

const StyledDate = styled.header`
  color: #f5222d;
`;

export { StyledRoot, StyledBell, StyledDate };
