import styled from 'styled-components';
import { colors, font } from 'App/styleVariables';

interface Block {
  styles: {
    padding?: string;
    alignSelf?: string;
    border?: string;
    boxShadow?: string;
    title?: {
      color?: string;
      fontSize?: string;
      fontWeight?: string;
      textAlign?: string;
      textTransform?: string;
    };
    descriptions?: {
      justifyContent?: string;
      label?: {
        fontSize?: string;
        fontWeight?: string;
        color?: string;
        flex?: string | number;
      };
      content?: {
        fontSize?: string;
        fontWeight?: string;
        color?: string;
        flex?: string | number;
      };
    };
    totals?: {
      color?: string;
      fontSize?: string;
      fontWeight?: string;
      textTransform?: string;
    };
    text?: {
      color?: string;
      fontSize?: string;
      fontWeight?: string;
      textTransform?: string;
    };
    footerNote?: {
      color?: string;
      fontSize?: string;
      fontWeight?: string;
      textAlign?: string;
    };
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

export const SCardWrapper = styled.div<Block>`
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  background: ${({ theme }) =>
    theme?.dashboard?.block?.background || '#ffffff'};
  box-shadow: ${({ styles }) =>
    styles?.boxShadow || '0 0 5px 5px rgba(0, 0, 0, 0.03)'};
  border-radius: ${({ theme }) =>
    theme?.dashboard?.block?.borderRadius || '10px'};
  border: ${({ styles }) => styles?.border};
  padding: ${({ theme, styles }) =>
    styles?.padding || theme?.dashboard?.block?.padding || '20px 15px'};
  box-sizing: border-box;
  font-family: ${({ theme }) =>
    theme?.dashboard?.fontFamily || font} !important;
  position: relative;
  align-self: ${({ styles }) => styles?.alignSelf};

  .card-container {
    font-family: inherit;
    height: 100%;
  }

  .card-header {
    margin-bottom: 15px;
  }

  .title {
    color: ${({ styles }) => styles?.title?.color || '#000000'};
    font-size: ${({ styles }) => styles?.title?.fontSize || '18px'};
    font-weight: ${({ styles }) => styles?.title?.fontWeight || '600'};
    text-align: ${({ styles }) => styles?.title?.textAlign || 'left'};
    text-transform: ${({ styles }) => styles?.title?.textTransform || 'none'};
    margin-bottom: 0;

    @media(max-width: 1370px) {
        font-size: 16px;
    }
  }

  .icon {
    width: 75px;
    height: 75px;
    object-fit: contain;
    margin-right: 10px;

    @media (max-width: 1550px) {
      width: 50px;
      height: 50px;
    }
  }

  .totals {
    font-size: ${({ styles }) => styles?.totals?.fontSize || '20px'};
    font-weight: ${({ styles }) => styles?.totals?.fontWeight || '500'};
    color: ${({ styles }) => styles?.totals?.color};
    text-transform: ${({ styles }) => styles?.totals?.textTransform};
    margin-bottom: 10px;
    text-align: center;

    @media(max-width: 1370px) {
        font-size: 18px;
    }
  }

  .card-descriptions {
    font-family: ${({ theme }) =>
      theme?.dashboard?.fontFamily || font} !important;

    .ant-descriptions-row {
      & > td {
        padding-bottom: 8px;
      }

      &:last-child {
        & > td {
          padding-bottom: 0;
        }
      }
    }

    .ant-descriptions-item-container {
      justify-content: ${({ styles }) => styles?.descriptions?.justifyContent};
    }

    .ant-descriptions-item-label {
      font-size: ${({ styles }) => styles?.descriptions?.label?.fontSize};
      font-weight: ${({ styles }) => styles?.descriptions?.label?.fontWeight};
      color: ${({ styles }) => styles?.descriptions?.label?.color};
      flex: ${({ styles }) => styles?.descriptions?.label?.flex};
    }

    .ant-descriptions-item-content {
      font-size: ${({ styles }) => styles?.descriptions?.content?.fontSize};
      font-weight: ${({ styles }) => styles?.descriptions?.content?.fontWeight};
      color: ${({ styles }) => styles?.descriptions?.content?.color};
      flex: ${({ styles }) => styles?.descriptions?.content?.flex};
    }
  }

  .simple-text {
    margin-bottom: 10px;
    font-size: ${({ styles }) => styles?.text?.fontSize || '14px'};
    font-weight: ${({ styles }) => styles?.text?.fontWeight};
    color: ${({ styles }) => styles?.text?.color};
  }

  .footer-note {
    font-size: ${({ styles }) => styles?.footerNote?.fontSize || '12px'};
    font-weight: ${({ styles }) => styles?.footerNote?.fontWeight};
    color: ${({ styles }) => styles?.footerNote?.color || colors.gray};
    margin-bottom: 0;
    text-align: ${({ styles }) => styles?.footerNote?.textAlign || 'right'};
  }
`;
