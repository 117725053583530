// import { Popconfirm } from 'antd';
import React from 'react';
import { Message } from '../../containers/bell/reducer';
import { StyledRoot, StyledBell, StyledDate } from './style';
import dayjs from 'dayjs';

type Props = {
  notification: Message;
  markAsRead: Function;
};

const Notification = ({ notification, markAsRead }: Props) => {
  const message = (
    <StyledRoot
    // style={{ cursor: !notification.read ? 'pointer' : 'default' }}
    >
      <StyledBell
        style={{ color: notification.read ? '#d9d9d9' : '#f5222d' }}
      />
      <div>
        <>
          <StyledDate>
            {dayjs(notification.created_at).format('DD/MM/YYYY')}
          </StyledDate>{' '}
          {notification.message}
        </>
      </div>
    </StyledRoot>
  );
  // const confirmMessage = (
  //   <Popconfirm
  //     title="Are you sure delete this notification?"
  //     onConfirm={() => markAsRead(notification.id)}
  //     onCancel={() => {}}
  //     okText="Yes"
  //     cancelText="No"
  //     placement="bottomLeft"
  //   >
  //     {message}
  //   </Popconfirm>
  // );
  return (
    <>
      {/*
        {!notification.read && (
          <Tooltip placement="topLeft" title="Click to mark as read">
            {confirmMessage}
          </Tooltip>
        )}
        {notification.read && confirmMessage}
      */}
      {message}
    </>
  );
};

export default Notification;
