import AbstractResourceFactory from './abstract-resource-factory';
import { Table } from '../../../types';
import client from '../../../../../lib/apiClient';

class HistoryDetailsResourceFactory implements AbstractResourceFactory {
  isSearchEnabled = false;
  async getAll(
    page: number,
    searchField: any,
    filter: object,
    path: string,
    filter_params: any,
    pageSchema: object,
    traceId: string
  ): Promise<Table> {
    const [{ data, table_scheme }] = await Promise.all([
      client.getTracesHistory(traceId),
    ]);

    return {
      data: data?.map((t: any) => ({ ...t, data: JSON.parse(t.data) })),
      schema: table_scheme,
      total: data.length,
    };
  }
}

export default HistoryDetailsResourceFactory;

