import React from "react";
import CardWrapper from "components/CardWrapper";
import TrackProductTable from "./containers/TrackProductTable";
import TrackProductModal from "./containers/TrackProductModal";
import withCommonNetworkFailure from "components/HOC/withCommonNetworkFailure";

const TrackProductSection = () => (
  <CardWrapper title="Track Product">
    <TrackProductTable />
    <TrackProductModal />
  </CardWrapper>
);

export default withCommonNetworkFailure(TrackProductSection);
