import styled from 'styled-components';
import { font } from '../../../../App/styleVariables';

interface Chart {
  styles: {
    height?: string;
    padding?: string;
    boxShadow?: string;
    border?: string;
    title?: {
      color?: string;
      fontSize?: string;
      fontWeight?: string;
      textTransform?: string;
    };
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledChartWrapper = styled.div<Chart>`
  background: ${({ theme }) =>
    theme?.dashboard?.block?.background || '#ffffff'};
  grid-column-start: ${({ styles }) => styles?.size?.column?.start};
  grid-column-end: ${({ styles }) => styles?.size?.column?.end};
  grid-row-start: ${({ styles }) => styles?.size?.row?.start};
  grid-row-end: ${({ styles }) => styles?.size?.row?.end};
  font-family: ${({ theme }) => theme?.dashboard?.fontFamily || font};
  box-sizing: border-box;
  padding: ${({ theme, styles }) =>
    styles?.padding || theme?.dashboard?.block?.padding || '20px 15px'};
  box-shadow: ${({ styles }) =>
    styles?.boxShadow || '0 0 5px 5px rgba(0, 0, 0, 0.03)'};
  border: ${({ styles }) => styles?.border};
  border-radius: ${({ theme }) =>
    theme?.dashboard?.block?.borderRadius || '10px'};

  .title {
    color: ${({ styles }) => styles?.title?.color || '#000000'};
    font-size: ${({ styles }) => styles?.title?.fontSize || '20px'};
    font-weight: ${({ styles }) => styles?.title?.fontWeight || '500'};
    text-transform: ${({ styles }) =>
      styles?.title?.textTransform || 'initial'};
    margin-bottom: 15px;
  }

  .chart-container {
    height: ${({ styles }) => styles?.height};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .recharts-label-list {
    .recharts-label {
      font-weight: 600;
    }
  }
`;

const StyledCustomTooltip = styled.div`
  padding: 8px;
  background: #ffffff;
  border: 1px solid #d9d9d9;

  .label {
    text-align: center;
  }
`;

export { StyledChartWrapper, StyledCustomTooltip };
