export const getCoordinatesFromTransform = (
  node: Element
): { x: number; y: number } => {
  const transformAttr = node.getAttribute('transform');
  const matchValue = transformAttr?.match(/translate\(([-\d.]+),\s*([-.\d]+)\)/);
  const xValue = matchValue ? parseFloat(matchValue[1]) : 0;
  const yValue = matchValue ? parseFloat(matchValue[2]) : 0;

  return {
    x: xValue,
    y: yValue,
  };
};
