import React from 'react';
import * as constants from '../../constants';
import { ScrollableListInterface } from '../../types';
import { SIndicatorContainer } from './styled-components';

const ScrollableList = ({
  styles,
  bordered,
  title,
  icon,
  list,
}: ScrollableListInterface) => {
  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <div className={'header'}>
        <img
          src={constants[icon]}
          className={'indicator-icon'}
          alt="Indicator icon"
        />
        <p className={'indicator-title'}>{title}</p>
      </div>
      <div className={'content'}>
        <div className={'wrapper'}>
          {list.map((listItem, index) => (
            <p key={`${listItem}-${index}`}>{listItem}</p>
          ))}
        </div>
      </div>
    </SIndicatorContainer>
  );
};

export default ScrollableList;
