import React, { useEffect } from 'react';
import {
  NavigateFunction, useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin, Flex, notification } from 'antd';
import { getBusinessData, SIGNIN_REQUEST_SUCCEEDDED } from '../reducer';
import { resetAllDataAction } from '../../../store/nextStore';
import { SWrapper } from './styled-components';
import SplitScreenWrapper from '../../../containers/SplitScreenWrapper';
import crystaltrace from '../../../images/crystaltrace_logo.png';

const headerFields: string[] = [
  'uid',
  'access-token',
  'client',
  'expiry',
  'token-type',
];
const getHeadersFromQueryString = (searchParams: URLSearchParams): object => {
  const headersObj = {};

  headerFields.forEach((item: string): void => {
    headersObj[item] = searchParams.get(item);
  });

  return headersObj;
};

interface Props {
  getBusinessData: Function;
  resetAllData: Function;
}

const LoginCallbackPage = ({ getBusinessData, resetAllData }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const { state }: any = useLocation();
  const [searchParams] = useSearchParams();
  const headers: object = getHeadersFromQueryString(searchParams);
  const error: string = searchParams.get('error');
  const user_message: string = searchParams.get('user_message');

  useEffect(() => {
    if (error !== 'true') {
      Object.entries(headers).forEach(
        ([key, value]: [key: string, value: string]) => {
          sessionStorage.setItem(key, value);
        }
      );
      getBusinessData()
        .then((res) => {
          const resData = res?.business_list ? { business_list: res?.business_list } : null;
          navigate('/sign_in', { state: { ...state, ...resData } });
          if (res?.type === SIGNIN_REQUEST_SUCCEEDDED) {
            navigate(res?.forceReset ? '/reset_password' : state?.path || '/');
          }
        })
        .catch(() => {
          resetAllData();
          navigate('/');
        });
    } else {
      notification.error({
        placement: 'top',
        message: 'Error',
        description: user_message,
        duration: 30,
      });
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SplitScreenWrapper>
      <SWrapper>
        <img src={crystaltrace} className={'trace-logo'} alt="CrystalTrace" />
        <Flex align={'center'} justify={'center'}>
          <Spin size={'large'} />
        </Flex>
      </SWrapper>
    </SplitScreenWrapper>
  );
};

const mapDispatchToProps = {
  getBusinessData: getBusinessData,
  resetAllData: resetAllDataAction,
};

export default connect(null, mapDispatchToProps)(LoginCallbackPage);
