import styled from 'styled-components';

const LinkWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-width: 172px;

  .link-icon {
    fill: #214a7a;
    transition: color 0.5s ease;
  }

  .link {
    font-size: 20px;
    color: #214a7a;
    font-weight: bold;
    line-height: 1.2;
    margin-left: 12px;
    transition: color 0.5s ease;
    text-decoration: none;
  }

  &:hover {
    .link {
      color: #112530;
    }

    .link-icon {
      fill: #112530;
    }
  }
`;

export { LinkWrapper };

export default {};
