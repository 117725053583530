import React from 'react';
import { Badge } from 'antd';
import { BellOutlined, LoginOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { capitalizeFirstLetter } from '../../../../lib/util';
import { SRadioCard } from './styled-components';
import { BusinessItemInterface } from '../../../../sections/Login/types';

const BusinessCard = ({
  id,
  name,
  description,
  logo,
  logo_bg,
  last_logged_in,
  alerts_quantity,
  background_image,
  focusedValue,
  onKeyDown,
  onFocus,
  onBlur,
}: BusinessItemInterface) => {
  return (
    <SRadioCard
      value={id}
      logo_bg={logo_bg}
      logo={logo}
      background_image={background_image}
      alerts={Number(alerts_quantity)}
      tabIndex={0}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      className={focusedValue === id ? 'focused' : ''}
    >
      <div className={'business-card-container'}>
        <div className={'business-background'} />
        <div className={'info-wrapper'}>
          {logo && (
            <div className={'logo-container'}>
              <div className={'logo'} />
            </div>
          )}
          <p className={'title'}>{description || capitalizeFirstLetter(name)}</p>
          <div className={'icons-row'}>
            <div className={'last-logged-in'}>
              <LoginOutlined />
              <p>
                last logged in: {dayjs(last_logged_in).format('DD MMM YYYY HH:mm:ss')}
              </p>
            </div>
            <div className={'alerts'}>
              <Badge count={Number(alerts_quantity)} showZero overflowCount={10}>
                <BellOutlined />
              </Badge>
            </div>
          </div>
        </div>
      </div>
    </SRadioCard>
  );
};

export default BusinessCard;
