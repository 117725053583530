import styled from 'styled-components';
import { Alert } from 'antd';

export const SWarning = styled(Alert)`
  position: fixed;
  bottom: 20px;
  left: 10px;

  .ant-alert-message {
    font-weight: 600;
  }

  .ant-alert-description {
    p {
      margin-bottom: 0;
    }

    .platform {
      font-weight: 500;
    }
  }
`;
