import React from 'react';
import Badge from '../../../../components/Badge';
import * as constants from '../../constants';
import { InfoCardInterface } from '../../types';
import { SIndicatorContainer } from './styled-components';

const InfoCard = ({
  styles,
  bordered,
  icon,
  title,
  info,
  badge,
}: InfoCardInterface) => {
  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <img
        src={constants[icon]}
        className={'indicator-icon'}
        alt="Indicator icon"
      />
      <div className={'content'}>
        <h3 className={'indicator-title'}>{title}</h3>
        <div className={'info-container'}>
          {info && <p className={'indicator-info'}>{info}</p>}
          {badge && (
            <Badge
              title={badge.title}
              type={badge.type}
              displayIcon={badge.displayBadgeIcon}
              customColor={badge.customColors}
            />
          )}
        </div>
      </div>
    </SIndicatorContainer>
  );
};

export default InfoCard;