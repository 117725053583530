import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ children, isSignedIn }) => {
  const location = useLocation();

  return isSignedIn ? (
    children
  ) : (
    <Navigate to={'/sign_in'} replace state={{ path: location?.pathname }} />
  );
};

const mapStateToProps = ({ auth: { currentUser } }) => ({
  isSignedIn: currentUser?.isSignedIn,
});

export default connect(mapStateToProps)(PrivateRoute);
