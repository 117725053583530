import client from '../../lib/apiClient';
import * as responses from './response';

const STUBBED_COCKPITS = ['financial_cockpit', 'carbone_cockpit'];

export const fetchData = async (
  businessName: string,
  pathName: string,
  param?: any,
) => {
  if (STUBBED_COCKPITS.includes(pathName)) {
    return responses[pathName].render(param?.lot_number);
  }

  const data = await client.getDashboardData({
    business: businessName,
    dashboard_name: businessName,
    params: {
      ...param,
    },
  });

  if (data.error) {
    throw new Error(data.error);
  }

  return data;
};
