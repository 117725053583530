import styled from 'styled-components';
import { SIndicator } from '../../styled-components';

export const SIndicatorContainer = styled(SIndicator)`
  .ant-card-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
  .indicator-title {
    display: flex;
    align-items: center;

    @media (max-width: 1600px) {
      width: 60%;
      justify-content: center;
    }

    span {
      font-size: 36px;
      margin-right: 10px;
      cursor: pointer;
      font-weight: 600;

      @media (max-width: 1600px) {
        font-size: 30px;
        margin-right: 5px;
      }
      @media (max-width: 1600px) {
        font-size: 26px;
      }
    }
  }
  .progress-wrapper {
    margin-top: 6px;
    min-width: 160px;

    @media (max-width: 1440px) {
      width: 35%;
    }

    .ant-progress {
      margin-bottom: 0;
    }
  }
`;
