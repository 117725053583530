import styled from 'styled-components';
import { Button } from 'antd';
import { colors } from 'App/styleVariables';

export const ButtonLink = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  border: 1px solid ${colors.primary};
  border-radius: 8px;
  text-align: center;
  padding: 5px 15px;
  color: ${colors.primary};
  i {
    margin-right: 8px;
  }
`;