import styled from 'styled-components';

interface BlockWrapper {
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      },
      row: {
        start: number;
        end: number;
      }
    }
  };
}
interface Item {
  background: string;
}

const StyledCarouselWrapper = styled.div<BlockWrapper>`
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  box-sizing: border-box;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
`;

const StyledItem = styled.div<Item>`
  height: ${({ theme }) => theme?.dashboard?.carousel?.height || "150px"};
  background-image: ${(props) => `url(${props.background})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export { StyledCarouselWrapper, StyledItem };