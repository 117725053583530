import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip as ReTooltip } from 'recharts';
import { Tooltip } from 'antd';
import { isExist } from '../../util';
import * as constants from '../../constants';
import { RecencyInterface } from '../../types';
import { SIndicatorContainer, SLegendItem } from './styled-components';

const Recency = ({
  styles,
  bordered,
  data,
  title,
  displayTitleTooltip = true,
  displayHeader,
  icon,
  headerTitle,
  total,
  customLegend = false,
  legendItems,
  customColors,
  displayChartTooltip = false,
  legendLayout = 'vertical',
  legendVerticalAlign = 'middle',
  legendAlign = 'right',
  legendHeight,
  legendIconSize,
}: RecencyInterface) => {
  if (!isExist(data)) {
    return null;
  }

  const colorPalette = customColors?.length ? customColors : constants.COLORS;

  const renderLegend = ({ payload }) => {
    return (
      <ul className={'custom-legend'}>
        {payload.map((entry, index) => (
          <SLegendItem key={`item-${index}`} color={entry.color}>
            <span className={'legend-circle'}></span>
            {entry.value}
            {legendItems && (
              <img
                src={constants[legendItems[entry.value]]}
                className={'legend-icon'}
                alt={`${entry.value} icon`}
              />
            )}
          </SLegendItem>
        ))}
      </ul>
    );
  };

  const tooltipFormatter = (value, _name, props) => {
    const { payload } = props;
    return `${value} ${payload.payload?.unit ? payload.payload?.unit : ''}`;
  };

  return (
    <SIndicatorContainer styles={styles} bordered={bordered}>
      <Tooltip
        id="indicatorTooltip"
        title={
          displayTitleTooltip &&
          'Répartition des entités par rapport au dernier événement ajouté sur la plateforme'
        }
        color={'#ffffff'}
      >
        <p className={'indicator-title'}>
          {title || 'Récence de la donnée (par entité)'}
        </p>
      </Tooltip>
      {displayHeader && (
        <div className={'header'}>
          <img
            src={constants[icon]}
            className={'indicator-icon'}
            alt="Indicator icon"
          />
          <p className={'indicator-title'}>
            <span> {total} </span>
            {headerTitle}
          </p>
        </div>
      )}
      <div className={'pie-chart'}>
        <ResponsiveContainer>
          <PieChart>
            <Legend
              align={legendAlign}
              verticalAlign={legendVerticalAlign}
              layout={legendLayout}
              iconType="circle"
              iconSize={legendIconSize}
              height={legendHeight}
              content={customLegend && renderLegend}
            />
            <Pie
              data={data}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius="100%"
              innerRadius="60%"
              fill="#8884d8"
            >
              {data?.map((entry, index) => (
                <Cell
                  key={`cell-${entry}`}
                  fill={colorPalette[index % colorPalette.length]}
                />
              ))}
            </Pie>
            {displayChartTooltip && <ReTooltip formatter={tooltipFormatter} />}
          </PieChart>
        </ResponsiveContainer>
      </div>
    </SIndicatorContainer>
  );
};

export default Recency;
