import styled from 'styled-components';
import { SIndicator } from '../../styled-components';

export const SIndicatorContainer = styled(SIndicator)`
  .ant-card-body {
    height: 350px;
    display: flex;
    flex-direction: column;
  }
  .chart {
    width: 100%;
    height: 290px;

    .recharts-label {
      font-weight: 500;
      fill: #464a53;
      font-size: 16px;
    }
    .recharts-legend-wrapper {
      top: 5px !important;
    }
  }
  .indicator-title {
    position: relative;
    align-self: flex-start;
  }
`;
