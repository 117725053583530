import styled from 'styled-components';
import { Image } from 'antd';

interface Block {
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledImageWrapper = styled.div<Block>`
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-self: center;
  
  .ant-image {
    width: 150px;
    height: 150px;
    
    @media(max-width: 1470px) {
      width: 130px;
      height: 130px;
    }
  }
  .ant-image-mask {
    border-radius: 50%;
  }
`;

const StyledImage = styled(Image)`
  border-radius: 50%;
  border: ${({ theme }) =>
    `4px solid ${theme?.dashboard?.circleImage?.borderColor || '#000000'}`};
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
`;
export { StyledImageWrapper, StyledImage };
