import styled from 'styled-components';
import { Image } from 'antd';

interface ImageStyles {
    styles: {
        marginBottom?: string;
    }
}

const SImage = styled(Image)<ImageStyles>`
    margin-bottom: ${({ styles }) => styles?.marginBottom};
`;

export default SImage;
