import React from "react";
import CardWrapper from "../../../components/CardWrapper";
import StockingTable from "./containers/StockingTable";
import StockingModal from "./containers/StockingModal";
import withCommonNetworkFailure from "components/HOC/withCommonNetworkFailure";

const Stocking = ({ pageName }) => (
  <CardWrapper pageName={pageName}>
    <StockingTable search />
    <StockingModal />
  </CardWrapper>
);

export default withCommonNetworkFailure(Stocking);
