// const GET_FILE_UPLOAD_SUCCESS = 'QUALITY::';

const initial_state = {};

const handlers = {};

export default (state = initial_state, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action.payload) : state;
};
