import { FC } from 'react';
import { SButton } from './styled-components';

interface PlatformTourButtonProps {
  label: string;
}

const JimoPlatformTourButton: FC<PlatformTourButtonProps> = ({ label }) => {
  return <SButton className="jimo-platform-tour-btn">{label}</SButton>;
};

export default JimoPlatformTourButton;
