import React, { useState } from 'react';
import { QueryObserverResult, useQuery, useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Spin } from 'antd';

import { BusinessData, MutationArguments, PageSchema, Table } from './types';
import { TRACES_TABLE, UPLOAD_TABLE, CUSTOMLIST_TABLE } from './constants';
import CardWrapper from 'components/CardWrapper';
import withCommonNetworkFailure from '../../components/HOC/withCommonNetworkFailure';
import {
  closeGenericModalAction,
  openGenericModalAction,
} from '../../containers/GenericModal/reducer';
import FilterBuilder from '../../lib/builders/FilterBuilder';
import AbstractResourceFactory from './helpers/factories/resource/abstract-resource-factory';
import getResourceFactory from './helpers/factories/resource/get-resource-factory';
import actionHandler from './helpers/actionHandler';
import checkTableIsApiPaginated from './helpers/checkTableIsApiPaginated';
import handleSubTitle from './helpers/handleSubTitle';
import getHeaderAction from './helpers/getHeaderAction';
import {
  useEditMutation,
  useAddMutation,
  useResendOneMutation,
  useResendAllMutation,
  useValidateOneMutation,
  useValidateAllMutation,
} from './helpers/mutations';
import mutationGeneration from './helpers/mutationGeneration';
import qrParametersGeneration from './helpers/qrParametersGeneration';
import { to_query } from '../../lib/util';
import errorHandler from '../../lib/errorHandler';

const FrontEndTableComponent = React.lazy(
  () => import('./table/front-end-paginated-table')
);
const BackEndTableComponent = React.lazy(
  () => import('./table/back-end-paginated-table')
);

type Props = {
  pageName: string;
  user: any;
  pageSchema: PageSchema;
  filter_params: any;
  business: BusinessData;
  traceId: string;
  withWrapper?: boolean;
  isSearchBarEnabled?: boolean;
  searchPlaceholder?: string;
  searchButtonText?: boolean;
};

const GenericTablePage: React.FC<Props> = ({
  pageName,
  user,
  pageSchema,
  filter_params,
  business,
  traceId,
  withWrapper = true,
  isSearchBarEnabled = true,
  searchPlaceholder,
  searchButtonText,
}: Props) => {
  const resourceFactory: AbstractResourceFactory = getResourceFactory(
    pageSchema?.component || pageName,
    { pageSchema }
  );

  const filter_builder = new FilterBuilder();
  const queryClient = useQueryClient();

  const [page, setPage] = useState<number>(1);
  const [searchField, setSearchField] = useState<string>('');
  const [filter, setFilter] = useState<object | any>(
    resourceFactory.defaultFilterState || {}
  );

  const path = pageSchema?.path?.split('/')[1];
  const toggleArrow = resourceFactory.toggleArrow || 1550;
  const isSearchEnabled = resourceFactory?.hasOwnProperty('isSearchEnabled')
    ? resourceFactory?.isSearchEnabled
    : isSearchBarEnabled;
  const apiPaginatedTable = checkTableIsApiPaginated(pageName, pageSchema);
  const uploadType = filter_params?.upload_type || null;

  const { data, isFetching }: QueryObserverResult<Table, any> = useQuery(
    [pageName, page, searchField, filter],
    () => {
      return resourceFactory.getAll(
        page,
        searchField,
        filter,
        path,
        filter_params,
        pageSchema,
        traceId,
        business,
      );
    },
    {
      cacheTime: 300,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => errorHandler(error),
    }
  );

  const refreshFunc = async () =>
    await queryClient.refetchQueries([pageName, page, searchField, filter]);

  const handleAccessCreation = (tableName, user, uploadType) => {
    const { steps, role, object_permissions, business } = user;

    if (role === 'Admin') return true;

    switch (tableName) {
      case TRACES_TABLE:
        return steps.length > 0;
      case UPLOAD_TABLE:
        return uploadType === 'object'
          ? (!business.object_permissions_enabled || object_permissions.length > 0)
          : steps.length > 0;
      case CUSTOMLIST_TABLE:
        return data?.allow_creation;
      default:
        return true;
    }
  };

  const mutationArguments: MutationArguments = {
    factory: resourceFactory,
    apiPaginatedTable,
    client: queryClient,
    name: pageName,
    page,
    searchField,
    filter,
  };

  // Mutations List
  const { mutate: editMutation } = useEditMutation(mutationArguments);
  const { mutate: addMutation } = useAddMutation(mutationArguments);
  const { mutate: resendOneMutation } = useResendOneMutation(mutationArguments);
  const { mutate: resendAllMutation } = useResendAllMutation(mutationArguments);
  const { mutate: validateOneMutation } = useValidateOneMutation(mutationArguments);
  const { mutate: validateAllMutation } = useValidateAllMutation(mutationArguments);

  const mutations = {
    addMutation,
    editMutation,
    resendOneMutation,
    resendAllMutation,
    validateOneMutation,
    validateAllMutation,
  };

  const actionGeneration = (action: string, record: object) => {
    const mutation = mutationGeneration(action, mutations);

    return actionHandler(action, mutation, record, {
      path,
      filter_params,
      user,
      handleSubTitle,
      filters: filter,
      searchField,
      url: qrParametersGeneration(record, action, business as BusinessData).url,
      imageName: qrParametersGeneration(record, action, business as BusinessData).imageName,
      allData: data?.data,
      objectType: data?.object_type,
      chainTableName: pageSchema?.options?.name || '',
    });
  };

  let Table: any;
  if (apiPaginatedTable) {
    Table = BackEndTableComponent;
  } else {
    Table = FrontEndTableComponent;
  }

  const table = (
    <React.Suspense
      fallback={<Spin style={{ display: 'block' }} size="large" />}
    >
      {data ? (
        <Table
          dataSource={data?.data}
          columns={data?.schema}
          isAllowed={handleAccessCreation(
            pageSchema?.component || pageName,
            user,
            uploadType
          )}
          pagination={!apiPaginatedTable ? { showSizeChanger: false } : false}
          handleTableChange={(pagination, filters, sorter) => {
            const { field, order } = sorter;
            setPage(1);
            const allFilters =
              pageName === UPLOAD_TABLE ||
              pageSchema?.component === UPLOAD_TABLE
                ? {
                    ...filters,
                    upload_type: filter_params?.upload_type || 'trace',
                  }
                : filters;
            if (pageName !== TRACES_TABLE) {
              setFilter(
                filter_builder.get_filter({
                  filters: allFilters,
                  sorter,
                  filter,
                })
              );
            } else {
              setFilter({
                sortingField: field || filter.sortingField,
                sortDirection: order || filter.sortDirection,
                filters: to_query(filters),
              });
            }
          }}
          isLoading={isFetching}
          getRowKey={(record: any) =>
            `${
              record.traceRecordId || record.traceId || record.id
            }_${Math.random().toFixed(5)}`
          }
          onRowAction={({ record, action }: { record: object; action: string }) =>
            actionGeneration(action, record)
          }
          actions={getHeaderAction(pageName, pageSchema, business?.name)}
          actionHandler={(action: string) => actionGeneration(action, {})}
          toggleArrow={toggleArrow}
          setSearchField={setSearchField}
          page={page}
          setPage={setPage}
          isSearchEnabled={isSearchEnabled}
          searchPlaceholder={searchPlaceholder}
          searchButtonText={searchButtonText}
          apiPaginatedTable={apiPaginatedTable}
          total={data?.total}
          list_name={path}
        />
      ) : (
        <Spin style={{ display: 'block' }} size="large" />
      )}
    </React.Suspense>
  );

  return withWrapper ? (
    <CardWrapper pageName={pageName} refreshFunc={refreshFunc}>
      {table}
    </CardWrapper>
  ) : (
    <>{table}</>
  );
};

const mapStateToProps = ({
  auth: {
    currentUser: { attributes },
  },
}) => ({ user: attributes, business: attributes.business });

const mapDispatchToProps = {
  openGenericModal: openGenericModalAction,
  closeGenericModal: closeGenericModalAction,
};

const composeResult = compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  withCommonNetworkFailure
)(GenericTablePage);

export default composeResult;
