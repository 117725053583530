import React from 'react';
import PropTypes from 'prop-types';
import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

const getContent = (reader) =>
  new Promise((resolve, reject) => {
    reader.onload = (e) => resolve(window.btoa(e.target.result));
  });

class UploadInput extends React.Component {
  constructor(props) {
    super(props);

    const { accept } = props.rules;
    const stateBuilder = {};
    if (props.type === 'document') {
      stateBuilder.accept = accept || '.pdf,.csv';
    } else {
      stateBuilder.accept = accept || '.csv';
    }
    stateBuilder.maxFilesLength = -1;
    stateBuilder.multipleSelectionWasHandled = false;
    this.state = stateBuilder;
  }

  handleOnChange = async (info) => {
    const { setFieldValue, name } = this.props;
    const { accept } = this.state;

    if (info.file.status === 'done') {
      if (info.fileList.length > 1) {
        info.fileList.splice(0, 1);
      }

      let reader = new FileReader();
      reader.readAsBinaryString(info.file.originFileObj);

      getContent(reader).then((response) => {
        const data = `data:${info.file.type};base64,${response}`;
        if (
          accept.includes('csv') &&
          info.file.type === 'application/vnd.ms-excel'
        ) {
          /* TODO This case is for Chanel business and need to be fixed */
          setFieldValue(name, { ...info.file, data });
        } else if (accept.includes(info.file.type.slice(-3))) {
          setFieldValue(name, { ...info.file, data });
        } else {
          message.error(`Incorrect file type`);
        }
      });
    } else if (info.file.status === 'error') {
      if (info.fileList.length > 1) {
        info.fileList.splice(0, 1);
      }
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  handleOnChangeMultiple = async (info) => {
    const { setFieldValue, name } = this.props;
    const { accept, maxFilesLength, multipleSelectionWasHandled } = this.state;

    if (info?.file.status === 'uploading') {
      this.setState((state) => ({
        maxFilesLength: Math.max(state?.maxFilesLength, info?.fileList?.length),
      }));
    }

    // Check if ant.d finished files handling and files have not uploaded to API from our side yet, and that we reach all selected files by user
    if (
      info?.file?.status === 'done' &&
      !multipleSelectionWasHandled &&
      info?.fileList?.length === maxFilesLength
    ) {
      this.setState({ multipleSelectionWasHandled: true });

      const files = await Promise.all(
        info.fileList.map(async (file) => {
          let reader = new FileReader();
          reader.readAsBinaryString(file.originFileObj);

          const response = await getContent(reader);

          const data = `data:${file.type};base64,${response}`;
          if (
            accept.includes('csv') &&
            file.type === 'application/vnd.ms-excel'
          ) {
            /* TODO This case is for Chanel business and need to be fixed */
            return { ...file, data };
          } else if (accept.includes(file.type.slice(-3))) {
            return { ...file, data };
          } else {
            message.error(`Incorrect file type`);
          }
        })
      );
      setFieldValue(name, files);
    }
  };

  render() {
    const {
      name,
      placeholder,
      disabled,
      title,
      rules: { enableMultipleSelection },
    } = this.props;
    const { accept } = this.state;

    return (
      <Upload
        id={'upload'}
        customRequest={dummyRequest}
        name={name}
        placeholder={placeholder}
        onChange={
          enableMultipleSelection
            ? this.handleOnChangeMultiple
            : this.handleOnChange
        }
        disabled={disabled}
        accept={accept}
        multiple={enableMultipleSelection}
        data-name={name}
      >
        <Button id={'upload-select-btn'} disabled={disabled}>
          <UploadOutlined /> {title || `Select ${accept}`}
        </Button>
      </Upload>
    );
  }
}

UploadInput.propTypes = {
  rules: PropTypes.object.isRequired,
  type: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

export default React.createFactory(UploadInput);
