import React from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import ExcelJS from 'exceljs';
import { downloadFile } from 'lib/util';

interface ExportToXLSXButtonInterface {
  exportData: { [key: string]: object[] }[];
  fileName?: string;
  btnLabel?: string;
  styleProperties?: {
    sheet?: {
      colorTheme: { [key: string]: string };
    };
  };
}

const ExportToXLSXButton: React.FC<ExportToXLSXButtonInterface> = ({
  exportData,
  fileName,
  btnLabel = 'Export',
  styleProperties,
}) => {
  const getTabColor = (
    colors: { [key: string]: string },
    tabName: string
  ): null | { argb: string } => {
    if (!colors) return null;

    return { argb: `ff${colors[tabName].replace('#', '')}` };
  };
  const exportXLSXFile = async () => {
    const workbook = new ExcelJS.Workbook();

    exportData.forEach((sheet) => {
      const sheetName = Object.keys(sheet)[0];
      const sheetData = sheet[sheetName];
      const sheetStyleProps = styleProperties?.sheet;
      const tabColor = getTabColor(sheetStyleProps?.colorTheme, sheetName);

      const worksheet = workbook.addWorksheet(sheetName, {
        properties: {
          defaultColWidth: 25,
          tabColor: tabColor,
        },
      });

      worksheet.views = [
        {
          state: 'frozen',
          xSplit: 0,
          ySplit: 1,
          activeCell: 'A2',
        },
      ];

      const headerRow = worksheet.addRow(Object.keys(sheetData[0]));
      headerRow.font = {
        name: 'Calibri',
        family: 2,
        size: 12,
        bold: true,
      };

      sheetData.forEach((item: object) => {
        const row = worksheet.addRow(Object.values(item));
        row.font = {
          name: 'Calibri',
          family: 2,
        };
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();

    downloadFile(
      buffer,
      fileName || 'chain',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  };

  return (
    <Button icon={<DownloadOutlined />} onClick={() => exportXLSXFile()}>
      {btnLabel}
    </Button>
  );
};

export default ExportToXLSXButton;
