import React from 'react';
import { SBadge } from './styled-components';
import {
  CheckOutlined,
  CloseOutlined,
  EllipsisOutlined,
  MonitorOutlined,
  HourglassOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { OnboardingIcon } from 'components/library/Icons';


export type BadgeType =
  | 'success'
  | 'rejected'
  | 'error'
  | 'queued'
  | 'checking'
  | 'pending'
  | 'in_progress'
  | 'interrupted'
  | 'minimal'
  | 'informative'
  | 'important'
  | 'significant'
  | 'critical'
  | 'invited'
  | 'onboarding';

interface BadgeInterface {
  title?: string;
  type?: BadgeType;
  icon?: string;
  customColor?: {
    background: string;
    text: string;
  };
  displayIcon?: boolean;
  displayStar?: boolean;
}

const ICONS = {
  success: CheckOutlined,
  rejected: CloseOutlined,
  error: CloseOutlined,
  queued: EllipsisOutlined,
  checking: MonitorOutlined,
  pending: HourglassOutlined,
  in_progress: LoadingOutlined,
  interrupted: ExclamationCircleOutlined,
  invited: MailOutlined,
  onboarding: OnboardingIcon,
};

const Badge = ({
  title,
  type,
  icon,
  customColor,
  displayIcon = true,
  displayStar = false,
}: BadgeInterface) => {
  const Icon = ICONS[icon] || ICONS[type];

  return (
    <SBadge
      type={type}
      className={'badge'}
      $customColor={customColor}
      $star={displayStar}
    >
      {displayIcon && Icon && <Icon className="badge-icon" />}
      {title}
      {displayStar && <span className="badge-star">*</span>}
    </SBadge>
  );
};

export default Badge;