import React from 'react';
import { connect } from 'react-redux';
import {} from './reducer';

class QualityBoard extends React.PureComponent {
  componentDidMount() {}

  render() {
    return (
      <div style={{ background: '#ffffff' }}>
        {process.env.REACT_APP_BK_QUALITY_URL && (
          <iframe
            title="Qualité"
            src={process.env.REACT_APP_BK_QUALITY_URL}
            width="100%"
            height="1000"
            frameBorder="0"
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {};

export default connect(mapDispatchToProps)(QualityBoard);
