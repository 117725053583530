import styled from 'styled-components';
import { colors, font } from 'App/styleVariables';

export const SWrapper = styled.div`
  margin-top: 20px;
  font-family: ${font};

  .description {
    margin-bottom: 15px;
    font-family: ${font};

    .ant-descriptions-header {
      margin-bottom: 10px;
    }

    .ant-descriptions-item {
      padding-bottom: 8px;
    }

    .ant-descriptions-item-label {
      font-weight: 500;
      color: #101010;
      width: 30%;
    }

    .email-link {
      color: #101010;
      text-decoration: underline;
    }
  }

  .form-section {
    margin-bottom: 15px;
    font-family: ${font};
  }

  .form-section-header {
    font-weight: 600;
    color: #101010;
    margin-bottom: 10px;
    font-size: 16px;
  }

  .form-item-container {
    .ant-form-item-label {
      font-weight: 500;
      color: #101010;
      text-align: start;
      width: 30%;
    }

    .ant-select-selector {
      font-family: ${font};
    }

    .ant-picker {
      width: 100%;
    }
  }

  .cancel-button {
    font-size: ${(props) => props.theme.form.backButton.size} !important;
    font-weight: ${(props) => props.theme.form.backButton.weight} !important;
    font-family: ${(props) => props.theme.form.backButton.family} !important;
    color: ${({ theme }) => theme.form.backButton.colorPassive} !important;
    border: ${({ theme }) => theme.form.backButton.borderPassive} !important;

    &:hover {
      color: ${({ theme }) =>
        theme.form.backButton.colorActive || colors.secondary} !important;
      border: ${({ theme }) =>
        theme.form.backButton.borderActive ||
        `1px solid ${colors.secondary}`} !important;
    }
  }

  .submit-button {
    font-size: ${(props) => props.theme.form.submitButton?.size} !important;
    font-weight: ${(props) => props.theme.form.submitButton?.weight} !important;
    font-family: ${(props) => props.theme.form.submitButton?.family} !important;
    color: ${(props) => props.theme.form.submitButton?.color} !important;
    border: none !important;
    background: ${(props) => props.theme.form.submitButton?.passive} !important;

    &:hover {
      color: ${(props) => props.theme.form.submitButton?.color} !important;
      background: ${(props) =>
        props.theme.form.submitButton?.active} !important;
    }
  }
`;
