import styled from 'styled-components';

const WrappedFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 51px;
  padding-right: 32px;
  background: ${props => props.theme.footer.background};
  color: ${props => props.theme.footer.background};

  span {
    color: ${props => props.theme.footer.color};
    font-size: ${props => props.theme.footer.size};
    font-family: ${props => props.theme.footer.family};
    font-weight: ${props => props.theme.footer.weight};
    opacity: ${props => props.theme.footer.opacity};
  }
`;

export { WrappedFooter };

export default {};
