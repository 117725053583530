import { SLegendItem } from './styled-components';

const LegendItem = ({ label, color }: any) => {
  return (
    <SLegendItem color={color} className={'legend-item'}>
      <span className="legend-item-dot"></span>
      {label}
    </SLegendItem>
  );
};

export default LegendItem;
