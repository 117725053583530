import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { CardStyles } from '../../types';

interface CategoryStyles extends CardStyles {
  childrenLength?: number;
}

export const SIndicatorContainer = styled(SIndicator)`
  .indicator-wrapper {
    width: 100%;
  }
`;

export const SRow = styled.div<CategoryStyles>`
  display: grid;
  //grid-template-columns: repeat(11, 1fr);
  grid-template-columns: minmax(50px, 170px) 3fr 2fr 2fr;
  grid-auto-rows: minmax(10px, auto);
  border-bottom: 3px solid #e6e6e6;
  color: #636363;
  &:last-child {
    border-bottom: none;
  }
  .header-title {
    display: flex;
    justify-content: center;
    color: #464A53;
    font-weight: 500;
    font-size: 18px;
    
    @media(max-width: 1400px) {
      font-size: 16px;
    }
  }
  .category-title {
    grid-row-start: 1;
    grid-row-end: ${(props) => props?.childrenLength + 1};
    align-self: center;
    justify-self: center;
    text-align: center;
    padding: 5px;
    
    img {
      width: 45px;
      @media(max-width: 1400px) {
        width: 40px;
      }
    }
    p {
      font-size: 18px;
      font-weight: 500;
      @media(max-width: 1400px) {
        font-size: 16px;
      }
    }
  }
  .cell {
    padding: 5px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    
    &:nth-last-child(-n+3) {
      border-bottom:  none;
    }
  }
  .badge-cell {
    display: flex;
    justify-content: center;
  }
`;
