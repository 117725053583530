import client from "lib/apiClient";
import { updateData } from '../../../../containers/SearchData/reducer';
import errorHandler from "../../../../lib/errorHandler";

const moduleName = "DOCUMENTS";
const CHANGE_LOADING_STATUS = `${moduleName}::CHANGE_LOADING_STATUS`;
const GET_DOCUMENTS_SUCCESS = `${moduleName}::GET_DOCUMENTS_SUCCESS`;
const SET_INITIAL_STATE = `${moduleName}::SET_INITIAL_STATE`;

export const initial_state = {
  data: [],
  table_scheme: [],
  loading: false
};

export const get_documents = () => async dispatch => {
  try {
    dispatch({ type: CHANGE_LOADING_STATUS });

    const [data, table_scheme] = await Promise.all([
      client.getDocuments(),
      client.getDocumentsScheme()
    ]);

    dispatch({
      type: GET_DOCUMENTS_SUCCESS,
      payload: {
        data,
        table_scheme
      }
    });

    dispatch(updateData({ data, table: 'DocumentsTable'}));

  } catch (e) {
    console.log(e);
    errorHandler(e);
  }
};

const handle_loading = state => ({ ...state, loading: true });

export const set_initial_state_action = () => ({ type: SET_INITIAL_STATE });

const handle_documents_success = (state, payload) => ({
  ...payload,
  loading: false
});

const handle_initial_state = () => initial_state;

export const handlers = {
  [GET_DOCUMENTS_SUCCESS]: handle_documents_success,
  [SET_INITIAL_STATE]: handle_initial_state,
  [CHANGE_LOADING_STATUS]: handle_loading
};

export default (state = initial_state, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action.payload) : state;
};
