import _ from 'lodash';

class TablePresenter {
  present_data(data, table_type, ...rest) {
    return this[`_present_${table_type}`](data, rest);
  }

  _present_users = data =>
    data.map(user => {
      const {
        first_name,
        last_name,
        company: { name, kind }
      } = user;

      return {
        ...user,
        full_name: `${first_name} ${last_name}`,
        company_name: name,
        company_kind: kind
      };
    });

  _present_uploads = data => _.orderBy(data, 'created_at', 'desc');

  _present_chains = data =>
    data.map(trace => {
      const [label, trace_values] = Object.entries(trace).flat();

      return { label, ...trace_values };
    });

  _present_track_product = (data, [code]) =>
    data
      .map(trace => {
        const [label, trace_values] = Object.entries(trace).flat();

        return { label, ...trace_values };
      })
      .filter(chain => Object.values(chain).includes(code)); //  why should I have to make a filtering in here by code?

  _present_greasy_wool = data =>
    data.map(
      ({
        CombingMill,
        detail: {
          GreasyWoolLotNumber,
          total,
          details: { reception, blending, audit }
        }
      }) => ({
        CombingMill,
        GreasyWoolLotNumber,
        total,
        reception,
        blending,
        audit
      })
    );

  _present_wool_sales = data =>
    data.map(
      ({
        CombingMill,
        detail: {
          WoolTopCombingNumber,
          total,
          details: { production, recombing, superwash, audit = 0, sales }
        }
      }) => ({
        CombingMill,
        WoolTopCombingNumber,
        total, // for corectly req exp work we convert valuse to string
        production,
        recombing,
        superwash,
        audit,
        sales
      })
    );

  _present_wool_top = data =>
    data.map(
      ({
        SpinningMill,
        detail: {
          WoolTopLotNumber,
          total,
          details: { creation, production, audit }
        }
      }) => ({
        SpinningMill,
        WoolTopLotNumber,
        total,
        creation,
        production,
        audit
      })
    );

  _present_wool_yarn = data =>
    data.map(
      ({
        SpinningMill,
        detail: {
          YarnLotNumber,
          total,
          details: { audit = 0, production, sales }
        }
      }) => ({
        SpinningMill,
        YarnLotNumber,
        total,
        audit,
        production,
        sales
      })
    );
}

export default new TablePresenter();
