import { colors } from '../../App/styleVariables';

import factory from 'images/cockpit/factory.svg';
import farmer from 'images/cockpit/farmer.svg';
import delivery from 'images/cockpit/delivery.svg';
import control from 'images/cockpit/control.svg';
import filters from 'images/cockpit/filters.svg';
import weavers from 'images/cockpit/weavers.svg';
import manufacturers from 'images/cockpit/manufacturers.svg';

import climate_change from 'images/cockpit/climate_change.svg';
import resource_use from 'images/cockpit/resource_use.svg';
import local_biodiversity from 'images/cockpit/local_biodiversity.svg';
import water_use from 'images/cockpit/water_use.svg';
import eutrophication_of_water from 'images/cockpit/eutrophication_of_water.svg';
import carbone from 'images/cockpit/carbone.svg';
import money from 'images/cockpit/money.svg';
import people from 'images/cockpit/people.svg';
import collection from 'images/cockpit/collection.svg';
import calendar from 'images/cockpit/calendar.png';
import date_waiting from 'images/cockpit/date_waiting.png';
import warn from 'images/cockpit/warn.svg';
import distribution from 'images/cockpit/distribution.svg';
import cotton from 'images/cockpit/cotton.svg';

import quality from 'images/cockpit/high-quality.png';
import transparency from 'images/cockpit/transparency.png';
import environmental_protection from 'images/cockpit/environmental-protection.png';
import equity from 'images/cockpit/equity.png';
import social_responsibility from 'images/cockpit/social-responsibility.png';

import csrd_environment from 'images/cockpit/csrd-environment.png';
import csrd_social from 'images/cockpit/csrd-social.png';
import csrd_governance from 'images/cockpit/csrd-governance.png';

const COLORS = ['#00CEAA', '#FFB800', '#EB2C02', '#D9D9D9'];
const STOCKS_COLORS = [colors.informative, '#534DCA', colors.success];
const ANALYTICS_COLORS = {
  success: colors.success,
  info: colors.success,
};

const ANALYTICS_DATE = '2023-09-01';
const DATE_INPUT = {
  date_format: 'D MMM YYYY',
  period_labels: ['JOUR', 'SEMAINE', 'MOIS', 'AN'],
};
const METRICS = [
  {
    name: 'nb_visits',
    label: 'Nombre de visites',
    yaxis_title: 'Nombre de visiteurs',
  },
  {
    name: 'avg_time_spent',
    label: 'Durée moy visite (sec)',
    yaxis_title: 'Durée (en secondes)',
  },
  {
    name: 'nb_pageviews',
    label: 'Pages vues',
    yaxis_title: 'Nombre de pages',
  },
  {
    name: 'nb_bottom_reached',
    label: 'Bas de pages atteint',
    yaxis_title: "Nombre d'évènements",
  },
  {
    name: 'penetration_rate',
    label: 'Taux de pénétration',
    yaxis_title: 'Taux (en %)',
  },
];

const ENVIRONMENT_ICONS = {
  'climate_change': climate_change,
  'resource_use': resource_use,
  'local_biodiversity': local_biodiversity,
  'water_use': water_use,
  'eutrophication_of_water': eutrophication_of_water,
  'total_emissions': carbone,
  'total_emissions_turnover': money,
  'total_emissions_employee': people,
};

const ENTITY_ICONS = {
  'USINES D’ALIMENTS': factory,
  'ELEVEURS': farmer,
  'TRANSFORMATEURS': delivery,
  'CONTROLEURS': control,
  'FILATEURS': filters,
  'TISSEURS': weavers,
  'FABRICANTS': manufacturers,
};

const RISK_ICONS = {
  quality,
  transparency,
  environmental_protection,
  equity,
  social_responsibility
};

const CSRD_ICONS = {
  csrd_environment,
  csrd_social,
  csrd_governance,
}

export {
  COLORS,
  STOCKS_COLORS,
  ANALYTICS_COLORS,
  factory,
  farmer,
  delivery,
  control,
  filters,
  weavers,
  manufacturers,
  RISK_ICONS,
  CSRD_ICONS,
  ANALYTICS_DATE,
  DATE_INPUT,
  METRICS,
  ENVIRONMENT_ICONS,
  ENTITY_ICONS,
  collection,
  calendar,
  date_waiting,
  warn,
  distribution,
  cotton,
};
