import styled from 'styled-components';
import { font } from '../../../../App/styleVariables';

interface Block {
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledProgressWrapper = styled.div<Block>`
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  box-sizing: border-box;
  font-family: ${font};
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  background-color: ${({ theme }) =>
    theme?.dashboard?.block?.background || '#ffffff'};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-end;
  padding: 15px;
  border-radius: 10px;

  .progress-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${({ theme }) => theme?.dashboard?.block?.color || '#000000'};
    width: 29%;
    margin-bottom: 15px;
    &:nth-last-of-type(-n + 3) {
      margin-bottom: 0;
    }
  }
  .ant-progress-inner {
    width: 90px !important;
    height: 90px !important;

    @media (max-width: 1440px) {
      width: 80px !important;
      height: 80px !important;
    }
  }
  .ant-progress-text {
    color: ${({ theme }) =>
      theme?.dashboard?.block?.progressText || '#ffffff'} !important;
  }
  .progress-name {
    text-align: center;
    text-transform: ${({ theme }) =>
      theme?.dashboard?.block?.sectionTextTransform || 'initial'};
    letter-spacing: 2px;
    color: ${({ theme }) =>
      theme?.dashboard?.block?.sectionTextColor || '#8c8c8c'};
    font-size: 16px;
    line-height: 1.2;
    margin: 0 10px 10px 10px;

    //@media(max-width: 1440px) {
    //  margin: 0 10px 5px 10px;
    //}
  }
`;

export default StyledProgressWrapper;
