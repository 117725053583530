import { removeGaps } from "lib/util";
import client from "lib/apiClient";

const moduleName = `CUSTOM_LIST_TABLE`;

const SET_ERRORS = `${moduleName}::SET_ERRORS`;
const RESET_ERRORS = `${moduleName}::RESET_ERRORS`;

export const handleSubmitAction = ({
  list_item_id,
  data,
  actions,
  isEdit,
  onSuccess,
  list_name
}) => async (dispatch, getState) => {
  data = removeGaps(data);
  try {
    if (isEdit) {
      await client.putCustomListItem({ list_name, id: list_item_id })(data);
      onSuccess();
    } else {
      await client.postCustomListItem({ list_name })(data);

      onSuccess();
    }
  } catch (e) {
    actions.setStatus({
      [e.name]: e.message
    });
  }
};

const handleSetErrors = (state, data) => ({ errors: data });
const handleResetErrors = () => ({ errors: {} });

const handlers = {
  [SET_ERRORS]: handleSetErrors,
  [RESET_ERRORS]: handleResetErrors
};

export default (state = { errors: {} }, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action.payload) : state;
};
