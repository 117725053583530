import styled from 'styled-components';
import { font } from 'App/styleVariables';

interface LegendItemStyles {
  color: string;
}

export const SLegendItem = styled.div<LegendItemStyles>`
  .text {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    line-height: 1.2;
    font-family: ${font};
    font-size: 14px;

    &::before {
      content: '';
      display: ${({ color }) => (color ? 'block' : 'none')};
      width: 16px;
      height: 16px;
      background-color: ${({ color }) => color};
      margin-right: 8px;
      border-radius: 50%;
    }
  }

  &:last-child {
    .text {
      margin-bottom: 0;
    }
  }
`;
