import _ from 'lodash';
import { renameTraceProperty, transformCollections } from 'lib/util';

export const getTransformedNodesData = (nodesData, excludedFields) => {
  const nodes = _.cloneDeep(nodesData);

  let objectData = {};

  nodes.forEach((node) => {
    const type = Object.keys(node)[0];
    let traceDataArr = [node[type]];

    traceDataArr.forEach((obj) => {
      excludedFields?.forEach((field) => {
        delete obj[field];
      });
    });

    if (traceDataArr[0].hasOwnProperty('Composition')) {
      renameTraceProperty(traceDataArr[0], 'Composition', 'C');

      traceDataArr = traceDataArr.flatMap((trace) => {
        return trace['C'].map((compositionObj) => {
          let newItem = { ...trace };

          for (let key in compositionObj) {
            newItem[`C.${key}`] = compositionObj[key];
          }

          delete newItem['C'];

          return newItem;
        });
      });
    }

    traceDataArr.forEach((trace) => transformCollections(trace));

    if (!objectData[type]) {
      objectData[type] = traceDataArr;
    } else {
      objectData[type].push(...traceDataArr);
    }
  });

  return Object.keys(objectData).map((traceType) => ({
    [objectData[traceType][0].traceType]: objectData[traceType],
  }));
};
