import { MutationFunction, useMutation } from 'react-query';

import { MutationArguments } from '../../types';
import { store } from '../../../../store/nextStore';
import { formWorkerModal } from '../../constants';
import { closeGenericModalAction } from '../../../../containers/GenericModal/reducer';
import errorHandler from '../../../../lib/errorHandler';

const useAddMutation = ({
  factory,
  client,
  name,
  page,
  searchField,
  filter,
}: MutationArguments) => {
  return useMutation(factory?.add as MutationFunction, {
    onSuccess: (data) => {
      client.refetchQueries([name, page, searchField, filter]);
      store.dispatch(closeGenericModalAction({ name: formWorkerModal }));
    },
    onError: (error) => {
      if (error) {
        errorHandler(error);
        client.refetchQueries([name, page, searchField, filter]);
        store.dispatch(closeGenericModalAction({ name: formWorkerModal }));
      }
    },
  });
};

export default useAddMutation;
