import React from 'react';
import PopoverWrapper from '../PopoverWrapper';

export const EnvironmentPopoverContent = () => (
  <PopoverWrapper>
    <>
      <p className={'popover-title'}> Environnement </p>
      <p>
        <i> Source des équivalents : </i>
      </p>
      <ul>
        <li>Changement climatique : 1 tonne de CO2 = 90 € (depuis 2022)</li>
        <li>Utilisation des ressources fossiles : 1 GigaJoule = 32 €</li>
        <li>
          Utilisation de ressources en eau : 1 m3 = 3 € (en moyenne, en 2023)
        </li>
      </ul>

      <p>
        <i>Périmètre</i> : les coût et impacts sont déterminés sur toute la chaîne
        de production.{' '}
      </p>
      <p>
        <i>Méthodologie utilisée</i> : les impacts sont calculés via Ecobalyse. Ce
        sont les trois impacts principaux.
      </p>
    </>
  </PopoverWrapper>
);
