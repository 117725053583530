import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import { Form } from './components';
import BusinessPicker from '../../../containers/BusinessPicker';
import SplitScreenWrapper from '../../../containers/SplitScreenWrapper';
import { SAuthPageWrapper } from '../../../App/global-styled-components';

import crystaltrace from 'images/crystaltrace_logo.png';

const LoginPage = () => {
  const [displayBusinessPicker, setDisplayBusinessPicker] =
    useState<boolean>(false);
  const [pickerData, setPickerData] = useState([]);
  const { state }: any = useLocation();

  useEffect(() => {
    if (state?.business_list) {
      setPickerData(state?.business_list);
      setDisplayBusinessPicker(true);
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (localStorage.getItem('redirectUrl')) {
    localStorage.removeItem('redirectUrl');
    return (
      <div style={{ width: '100%', paddingTop: '150px' }}>
        <Spin style={{ display: 'block' }} size="large" />
      </div>
    );
  }

  return (
    <SplitScreenWrapper>
      <SAuthPageWrapper vertical align={'center'}>
        <img src={crystaltrace} className={'trace-logo'} alt="CrystalTrace" />
        {!displayBusinessPicker ? (
          <>
            <h1> Welcome to the Traceability platform </h1>
            <Form
              setDisplayBusinessPicker={setDisplayBusinessPicker}
              setPickerData={setPickerData}
            />
          </>
        ) : (
          <>
            <h1 className={'picker-title'}> Pick an option </h1>
            <BusinessPicker data={pickerData} />
          </>
        )}
      </SAuthPageWrapper>
    </SplitScreenWrapper>
  );
};

export default LoginPage;
