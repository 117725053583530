import styled from 'styled-components';
import { Button } from 'antd';
import { colors } from '../../../../App/styleVariables';

const Wrapper = styled.div`
  padding: 8px;

  .ant-input:not(.ant-input-disabled) {
    &:hover,
    &:focus {
      border: ${({ theme }) => theme.table.filter?.input?.borderActive} !important;
    }

    &:focus {
      box-shadow: ${({ theme }) => `0 0 0 2px ${theme.table.filter?.input?.boxShadowActiveColor}`} !important;
    }
  }
`;

const Confirm = styled(Button)`
  width: 90px !important;
  margin-right: 8px !important;
  color: ${({ theme }) => theme.table.filter?.button?.passiveColor || colors.secondary} !important;
  border: none !important;

  &:hover {
    color: ${({ theme }) => theme.table.filter?.button?.activeColor || colors.primary} !important;
  }
`;

const Reset = styled(Button)`
  width: 90px !important;
  color: ${({ theme }) => theme.table.filter?.button?.passiveColor || colors.secondary} !important;
  border: none !important;

  &:hover {
    color: ${({ theme }) => theme.table.filter?.button?.activeColor || colors.primary} !important;
  }
`;

const s = {
  filter: { width: 188, marginBottom: 8, display: 'block' },
};

export { Wrapper, Confirm, Reset };

export default s;
