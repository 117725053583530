import styled from 'styled-components';

const StyledSectionsWrapper = styled.div`
  margin: 0 !important;
  background: #f9f9f9 !important;
`;

export { StyledSectionsWrapper };

export default {};
