import fileExtension from 'file-extension';
import pdf from './resource/pdf.png';
import csv from './resource/csv.png';
import paper from './resource/paper.png';

export const getFileExtensionFromUrl = (url) => {
  const extension = fileExtension(url);
  const checkCharacterIndex = extension.split('').indexOf('?');
  const modifiedExtension =
    checkCharacterIndex !== -1
      ? extension.slice(0, checkCharacterIndex)
      : extension;

  return modifiedExtension;
};

export const getImageUrl = (fileExtension, fileUrl) => {
  let src = paper;
  switch (fileExtension) {
    case 'pdf':
      src = pdf;
      break;
    case 'csv':
      src = csv;
      break;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      src = fileUrl;
      break;
    default:
  }

  return src;
};
