import React from 'react';
import PropTypes from 'prop-types';
import * as formSteps from './FormSteps';

class FormWorker extends React.PureComponent {
  constructor(props) {
    super(props);

    const { formStep, formType } = this.props;

    this.state = {
      step: 0,
      formStep,
      formType,
    };
  }

  onFormTypeSelect = (formType) => {
    this.setState({ ...formType });
  };

  render() {
    const { onFormTypeSelect, state, props } = this;

    return formSteps[state.formStep]({
      ...props,
      ...state,
      onFormTypeSelect,
    });
  }
}

FormWorker.propTypes = {
  formStep: PropTypes.string,
  formType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FormWorker;
