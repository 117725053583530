import {INVALIDATE_DATA_ACTION, VALIDATE_ALL_ACTION, VALIDATE_DATA_ACTION} from '../actions';
import {
  getValidationValue,
  getInvalidationValue,
} from '../../../lib/validations/util';

const validationStatusHandler = (action: string, businessName: string) => {
  const actions: any = {
    [VALIDATE_DATA_ACTION]: getValidationValue(businessName),
    [INVALIDATE_DATA_ACTION]: getInvalidationValue(businessName),
    [VALIDATE_ALL_ACTION]: getValidationValue(businessName),
  };

  return actions[action];
};

export default validationStatusHandler;
