import React from "react";
import YieldBoard from "./containers/YieldBoard";

const Yield = () => (
  <React.Fragment>
    <YieldBoard />
  </React.Fragment>
);

export default Yield;
