import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import withWindowSize from '../../../../../components/HOC/withWindowSize';
import { SForm, SInput, SButton, SArrowUp, SSearch, Wrapper, Buttons } from './style';

const remove_gaps = obj => {
  for (const [k, v] of Object.entries(obj)) obj[k] = v.trim();
  return obj;
};

const initial_values = { numerolot: '' };
const err_msg = 'Saisie non valide';

const validation_schema = yup.object().shape({
  numerolot: yup
    .string()
    .min(3, err_msg)
    .max(100, err_msg)
    .required(err_msg)
});

const TraceUpForm = ({
  handleType,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  ws,
  error,
  handleErrors
}) => {
  return (
    <SForm>
      <div
        className={
          (touched.numerolot && errors.numerolot) || error.is_error
            ? 'input-wrapper has-error'
            : 'input-wrapper'
        }
      >
        <SInput
          name="numerolot"
          placeholder="Numéro de Lot"
          value={values.numerolot}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleErrors}
          autoComplete="off"
        />
        <span className="error-msg">
          {(touched.numerolot && errors.numerolot) ||
            (error.is_error && error.msg) ||
            ''}
        </span>
      </div>

      <Buttons>
        <SButton
          type="primary"
          htmlType="submit"
          ws={ws}
          onClick={handleType('json')}
        >
          TRACE UP
          <SArrowUp ws={ws} />
        </SButton>
        <SButton
          type="primary"
          htmlType="submit"
          ws={ws}
          onClick={handleType('pdf')}
        >
          RAPPORT COMPLET <SSearch ws={ws} />
        </SButton>
      </Buttons>
    </SForm>
  );
};

const ControlPanel = ({
  handleData,
  handleType,
  handleErrors,
  windowSize: ws,
  error
}) => (
  <Wrapper>
    <Formik
      initialValues={initial_values}
      validationSchema={validation_schema}
      onSubmit={values => handleData(remove_gaps({ ...values }))}
      render={props =>
        TraceUpForm({ ...props, handleType, ws, error, handleErrors })
      }
    />
  </Wrapper>
);

export default withWindowSize(ControlPanel);
