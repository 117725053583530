import React from 'react';
import { Outlet } from 'react-router-dom';
import { history } from '../../store/nextStore';
import sections from '../../sections';
import ThemeWrapper from '../ThemeWrapper';
import Footer from '../../App/components/Footer';
import { StyledSectionsWrapper } from '../../App/style';

const { TopBar, SideBar } = sections;

const PageWrapper = () => {
  return (
    <div className="page-wrapper">
      <ThemeWrapper>
        <TopBar />
      </ThemeWrapper>
      <div className="Side-bar-wrapper">
        <div className="Side-bar-panel-wrapper">
          <ThemeWrapper>
            <SideBar history={history} />
          </ThemeWrapper>
        </div>
        <StyledSectionsWrapper className="Side-bar-items-wrapper">
          <Outlet />
        </StyledSectionsWrapper>
      </div>
      <ThemeWrapper>
        <Footer />
      </ThemeWrapper>
    </div>
  );
};

export default PageWrapper;
