import AbstractResourceFactory from '../abstract-resource-factory';
import client from '../../../../../../lib/apiClient';
import table_presenter from "../../../../../../lib/presenters/TablePresenter";

import { Table } from '../../../../types';


class SpinningSalesResourceFactory implements AbstractResourceFactory {
  toggleArrow = 1650;

  async getAll(): Promise<Table> {
    const { data, table_scheme } = await client.getStocks('yarn');
    const presentedData = table_presenter.present_data(data, 'wool_yarn');

    return {
      data: presentedData,
      schema: table_scheme,
      total: presentedData?.length,
    }
  }
}

export default SpinningSalesResourceFactory;