import React from 'react';
import { connect } from 'react-redux';
import FormWorker from '../../../../../containers/FormWorker';
import { handleSubmitAction } from './reducer';
import { get_data_action } from '../MudTable/reducer';

const MudForm = ({
  traceId,
  userValues,
  handleSubmit,
  handleSubTitle,
  get_data,
  closeModal,
  formType,
  isEdit
}) => {
  const onSuccess = () => {
    closeModal();
    get_data();
  };

  return (
    <FormWorker
      type="trace"
      isEdit
      userValues={userValues}
      formStep="formFilling"
      formType={formType}
      extraKeyName="traceType"
      handleSubTitle={handleSubTitle}
      handleSubmit={(data, actions) =>
        handleSubmit({
          traceId: traceId && traceId,
          data,
          actions,
          isEdit,
          onSuccess,
          userValues
        })
      }
    />
  );
};

const mapStateToProps = ({ mudModal }) => ({
  userValues: mudModal.data,
  traceId: mudModal.traceId,
  userData: mudModal.userData,
  isEdit: mudModal.isEdit,
  formType: mudModal.formType
});

const mapDispatchToProps = {
  get_data: get_data_action,
  handleSubmit: handleSubmitAction
};

const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(MudForm);

export default ConnectedForm;
