import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Space, Spin, Switch, Tooltip } from 'antd';
import {
  AimOutlined,
  ExpandOutlined,
  MinusOutlined,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import * as d3 from 'd3';
import { store } from 'store/nextStore';
import { openGenericModalAction } from '../../../GenericModal/reducer';
import ExportToXLSXButton from 'components/ExportToXLSXButton';
import { InfoBadge } from 'components/library';
import show_width_handler from 'lib/helpers/width_handlers/ShowWidthHandler';
import UniqIdsGenerator from 'lib/uniqIdsGenerator';
import { define_width } from 'lib/util';
import {
  createGraphElements,
  getTraceTypeColors,
  getTransformedNodesData,
  handleZoomAll,
  navigateToRoot,
  onTick,
} from './helpers';
import { showModal } from 'sections/GenericTablePage/constants';
import { StyledGraph } from './styled-components';
import { colors } from 'App/styleVariables';
import {
  INIT_SCALE_FACTOR,
  MAX_SCALE_FACTOR,
  MIN_SCALE_FACTOR,
  EXCLUDED_FIELDS,
} from './constants';

class ChainGraph extends Component {
  state = {
    visible: false,
    data: null,
    finishedSimulation: false,
    activatedPinMode: false,
  };
  uniqId = new UniqIdsGenerator('ChainGraph');
  handleZoomIn;
  handleZoomOut;
  handleZoomInit;

  handleWidths = (data) => {
    const modalWidth = data && show_width_handler.get_widths({ data });
    const current_width = modalWidth && define_width({ target: modalWidth });
    return { modalWidth, current_width };
  };

  handlePinMode = (value) => {
    this.setState({ activatedPinMode: value });
  };

  createGraph = (graph_scheme) => {
    let svg = d3.select(this.svg);
    const svgSizes = svg.node().getBoundingClientRect();
    const width = svgSizes.width;
    const height = svgSizes.height;

    const colorTheme = getTraceTypeColors(graph_scheme);
    this.setState({ colorTheme });

    const sorted_array = Object.keys(graph_scheme).reduce((new_arr, key) => {
      const { nodes } = this.props.data;
      // eslint-disable-next-line
      nodes.map((node) => {
        const node_key = Object.keys(node)[0];
        if (node[node_key].traceType === key) new_arr.push(node);
      });
      return new_arr;
    }, []);

    const nodes_array =
      sorted_array.length === 2 &&
      graph_scheme.graph_options &&
      graph_scheme.graph_options.is_reversed
        ? sorted_array.reverse()
        : sorted_array;
    const first_node = nodes_array[0];
    const last_node = nodes_array[nodes_array.length - 1];
    const first_node_name = Object.getOwnPropertyNames(first_node)[0];
    const last_node_name = Object.getOwnPropertyNames(last_node)[0];
    const classup = [first_node_name];
    const classbottom = [last_node_name];

    function idIndex(a, id) {
      for (let i = 0; i < a.length; i++) {
        if (a[i].id === id) return i;
      }
      return null;
    }

    let nodes = [],
      links = [];
    const chainDetails = Array.isArray(this.props.data)
      ? this.props.data[0]
      : this.props.data;
    const graph_scheme_json = this.props.data.graph_scheme;

    chainDetails.nodes.forEach((n) => {
      const label = Object.keys(n)[0];
      if (idIndex(nodes, n[label].neo_id) == null) {
        nodes.push({
          id: n[label].neo_id,
          label,
          data: n[label],
        });
      }
    });

    links = links.concat(
      chainDetails.relationships.map((r) => ({
        source: r.start_node_id,
        target: r.end_node_id,
        value: 1,
        type: r.type,
      }))
    );

    const graph = { nodes, links };

    const simulation = d3
      .forceSimulation()
      .force(
        'link',
        d3
          .forceLink()
          .id((d) => d.id)
          .distance(300)
      )
      .force('charge', d3.forceManyBody().strength(-2500))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('collision', d3.forceCollide(150))
      .alphaDecay(0.03)
      .alphaMin(0.1);

    const { node, lines, text, textUnderlay } = createGraphElements(
      svg,
      graph,
      colorTheme,
      graph_scheme_json
    );

    node.on('click', (event, d) => this.onClick(event, d));

    const zoomed = (zoomEvent) => {
      svg.selectAll('svg > g').attr(
        'transform',
        `translate(${zoomEvent.transform.x}, ${zoomEvent.transform.y})
          scale(${zoomEvent.transform.k})`
      );
    };

    const zoom = d3
      .zoom()
      .scaleExtent([MIN_SCALE_FACTOR, MAX_SCALE_FACTOR])
      .on('zoom', zoomed);

    svg.call(zoom);

    const transition = (zoomLevel) => {
      svg.transition().call(zoom.scaleBy, zoomLevel);
    };

    this.handleZoomIn = () => {
      transition(1.2); // increase on 0.2 each time
    };

    this.handleZoomOut = () => {
      transition(0.6); // increase on 0.2 each time
    };

    this.handleZoomInit = () => {
      svg
        .transition()
        .delay(100)
        .duration(700)
        .call(zoom.scaleTo, INIT_SCALE_FACTOR);
    };

    this.handleZoomAll = () => handleZoomAll(svg, zoom);

    this.navigateToRoot = () =>
      navigateToRoot(svg, graph, zoom, this.props.traceId);

    simulation
      .nodes(graph.nodes)
      .on('tick', () => onTick(lines, text, node, textUnderlay))
      .on('end', () => {
        if (!this.state.finishedSimulation) {
          this.setState({ finishedSimulation: true });
          this.navigateToRoot();
          simulation.alphaMin(0.001);
        }
      });

    simulation.force('link').links(graph.links);

    this.arrangementinit = (nodes, classup, classbottom) => {
      function appartient(family, element) {
        let bool = false;
        for (const idfam in family) {
          if (family[idfam] === element) {
            bool = true;
          }
        }
        return bool;
      }

      for (const idnodes in nodes) {
        // const idbis = nodes.length - idnodes - 1;
        if (appartient(classup, nodes[idnodes].label)) {
          nodes[idnodes].y = -1100;
        } else if (appartient(classbottom, nodes[idnodes].label)) {
          nodes[idnodes].y = 2500;
        }
      }
    };
    this.arrangementinit(nodes, classup, classbottom);

    const dragstarted = (event, d) => {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    };

    const dragged = (event, d) => {
      d.fx = event.x;
      d.fy = event.y;
    };

    const dragended = (event, d) => {
      const { activatedPinMode } = this.state;

      if (!event.active) simulation.alphaTarget(0);

      if (!activatedPinMode) {
        d.fx = null;
        d.fy = null;
      }
    };

    svg.selectAll('g').call(
      d3
        .drag()
        .on('start', (event, d) => dragstarted(event, d))
        .on('drag', (event, d) => dragged(event, d))
        .on('end', (event, d) => dragended(event, d))
    );

    ChainGraph.simulation = simulation;
  };

  onClick = (_e, data) => {
    const traceData = data?.data;
    const { modalWidth, current_width } = this.handleWidths(traceData);
    store.dispatch(
      openGenericModalAction({
        name: showModal,
        configuration: {
          title: `Trace Details: ${traceData && traceData.traceType}`,
          width: current_width,
        },
        data: {
          data: traceData,
          modalWidth: modalWidth,
        },
      })
    );
  };

  getExportFilename = () => {
    const { data, traceId } = this.props;
    const { nodes } = data;

    const rootTraceObject = nodes.find(
      (node) => Object.values(node)[0].traceId === traceId
    );
    const rootTrace = Object.values(rootTraceObject)[0];

    return `Chain-${rootTrace.traceType}-${rootTrace.traceId}`;
  };

  componentDidMount() {
    const { graph_scheme } = this.props.data;

    this.createGraph(graph_scheme);
  }

  render() {
    const { data } = this.props;
    const { finishedSimulation, colorTheme, activatedPinMode } =
      this.state;

    return (
      <StyledGraph id={this.uniqId.getGraphDivWrapper()}>
        <Spin
          tip="Building a chain..."
          size="large"
          spinning={!finishedSimulation}
        >
          <svg
            ref={(svg) => (this.svg = svg)}
            id={'chain-graph'}
            width="100%"
            height="75vh"
          />
          <div id="button-group">
            <Space className="settings">
              <Button
                icon={<PlusOutlined />}
                id="zoom_in"
                onClick={() => this.handleZoomIn()}
              />
              <Button
                icon={<MinusOutlined />}
                id="zoom_out"
                onClick={() => this.handleZoomOut()}
              />
              <Button
                icon={<SyncOutlined />}
                id="zoom_init"
                onClick={() => this.handleZoomInit()}
              />
              <Divider type="vertical" />
              <Button
                icon={<ExpandOutlined />}
                id="zoom_all"
                onClick={() => this.handleZoomAll()}
              >
                All
              </Button>
              <Button
                icon={<AimOutlined />}
                id="navigate_root"
                onClick={() => this.navigateToRoot()}
              >
                Root
              </Button>
              <Divider type="vertical" />
              <ExportToXLSXButton
                exportData={getTransformedNodesData(
                  data.nodes,
                  EXCLUDED_FIELDS
                )}
                fileName={this.getExportFilename()}
                styleProperties={{ sheet: { colorTheme } }}
              />
              <Divider type="vertical" />
              <p className="setting-label">
                Pin Mode
                <Tooltip
                  id="indicatorTooltip"
                  title="If Pin Mode is On, dragging a node will pin it in its new position."
                  color={'#ffffff'}
                >
                  <span>
                    <InfoBadge color={colors.gray} />
                  </span>
                </Tooltip>
              </p>
              <Switch
                onChange={this.handlePinMode}
                value={activatedPinMode}
                checkedChildren="On"
                unCheckedChildren="Off"
              />
            </Space>
          </div>
        </Spin>
      </StyledGraph>
    );
  }
}

ChainGraph.propTypes = {
  data: PropTypes.any,
  traceId: PropTypes.string,
};

export default React.createFactory(ChainGraph);
