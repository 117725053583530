import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import InputWrapper from '../../../components/HOC/InputWrapper';
import styled from 'styled-components';

const StyledMultiSelect = styled(Select)`
  li {
    height: auto !important;
    margin-bottom: 0px;

    div {
      white-space: normal !important;
      word-break: normal !important;
    }
  }
`;

const Option = Select.Option;
const roles = { Admin: true };

class MultiSelectComponent extends React.PureComponent {
  render() {
    const {
      name,
      placeholder,
      value,
      setFieldTouched,
      setFieldValue,
      options,
      currentRole,
      editRole,
      isError,
    } = this.props;
    let isAdmin = roles[editRole] || roles[currentRole];
    return (
      <StyledMultiSelect
        disabled={isAdmin}
        name={name}
        value={value || []}
        placeholder={isAdmin ? 'All' : placeholder}
        mode={'multiple'}
        onChange={(value) => setFieldValue(name, value)}
        onBlur={() => setFieldTouched(name, true)}
        className={isError ? 'has-error' : ''}
        data-name={name}
      >
        {options.map(({ value, text }) => (
          <Option key={value} value={value}>
            {text}
          </Option>
        ))}
      </StyledMultiSelect>
    );
  }
}

MultiSelectComponent.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  value: PropTypes.array,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  options: PropTypes.array,
  currentRole: PropTypes.string,
  editRole: PropTypes.string,
  isError: PropTypes.string,
};

export default (props) => InputWrapper(props, MultiSelectComponent);
