import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PrivateRoute from './PrivateRoute';

const checkForAccess = ({
  accessCompanyKinds,
  excludedCompanyKinds,
  isAdminOnly,
  isAdmin,
  kind,
}) => {
  return (
    (accessCompanyKinds.includes(kind) &&
      !excludedCompanyKinds.includes(kind) &&
      !isAdminOnly) ||
    isAdmin
  );
};

const RouteWrapper = ({
  component: Component,
  pageSchema,
  pageName,
  isAdmin,
  kind,
}) => {
  const {
    accessCompanyKinds,
    excludedCompanyKinds,
    isAdminOnly,
    defaultValues,
    filter_params,
    options,
    ...rest
  } = pageSchema;

  return (
    <PrivateRoute>
      {checkForAccess({
        accessCompanyKinds,
        excludedCompanyKinds,
        isAdminOnly,
        isAdmin,
        kind,
      }) ? (
        <Component
          key={pageName}
          pageName={pageName || ''}
          {...rest}
          pageSchema={pageSchema}
          defaultValues={defaultValues}
          filter_params={filter_params}
          {...options}
        />
      ) : (
        <Navigate to="/track_product" />
      )}
    </PrivateRoute>
  );
};

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  pageSchema: PropTypes.object,
  pageName: PropTypes.string,
  isAdmin: PropTypes.bool,
  kind: PropTypes.string,
};

export default RouteWrapper;
