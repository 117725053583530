import { parseInt } from 'lodash';
import WidthHandler from './Base';

class ShowWidthHandler extends WidthHandler {
  get_widths(data) {
    const max_widths = this._find_max_widths(data);

    const widths = this._define_widths(max_widths);

    return widths;
  }

  _find_max_widths({ data, data: { Composition: composition } }) {
    const max_key = this.get_max(Object.keys(data));
    let max_value;

    if (Array.isArray(composition)) {
      const arr = [];
      for (const n of composition) {
        for (const [k, v] of Object.entries(n)) arr.push(`${k} ${v}`);
      }

      max_value = this.get_max([...arr, ...Object.values(data)]);
    } else {
      max_value = this.get_max(Object.values(data));
    }

    return { max_key, max_value };
  }

  _define_widths({ max_key, max_value }) {
    const { default_left, default_right } = this.default_settings;
    const common = max_key + max_value;
    const is_default =
      parseInt(this.to_percent(max_key, common)) < default_left;
    const left = this._get_width(is_default, default_left, max_key, common);
    const right = this._get_width(is_default, default_right, max_value, common);

    return { left, right, common };
  }

  _get_width(is_default, default_setting, max_current, common) {
    return (
      (is_default && `${default_setting}%`) ||
      this.to_percent(max_current, common)
    );
  }
}

export default new ShowWidthHandler({ default_left: 23, default_right: 77 });
