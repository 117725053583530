import { FC } from 'react';
import { Flex, Tabs, Typography } from 'antd';
import { blocks } from '../../common-dashboard';
import { STabsCardWrapper } from './styled-components';
import TabLabel from './components/TabLabel';

interface TabsCardProps {
  title: string;
  titleImage?: string;
  content: any;
  bordered?: boolean;
  schema: any;
  businessName: string;
  refetchContent?: () => void;
  styles?: any;
}

const TabsCard: FC<TabsCardProps> = ({
  styles,
  title,
  titleImage,
  content,
  bordered = false,
  schema,
  businessName,
  refetchContent,
}) => {
  const titleImageSrc = `${process.env.REACT_APP_BUCKET_NAME}/${businessName}/public/images/${titleImage}`;

  const tabItems = content?.map(
    ({ labelTitle, labelNumber, key, children }: any) => {
      const componentSchema = schema[children];
      const name =
      componentSchema?.component === 'Table' ||
      children === 'Table'
            ? 'FrontendPaginatedTable'
            : componentSchema?.component || children;

      const Component = blocks[name];
      const props = { ...componentSchema, refetchContent };

      return {
        key,
        label: <TabLabel title={labelTitle} number={labelNumber} />,
        children: Component ? (
          <Component {...props} key={`tabItem-${key}`} />
        ) : null,
      };
    }
  );

  return (
    <STabsCardWrapper styles={styles} $bordered={bordered}>
      <Flex align="center" className="section-title-wrapper">
        {titleImage && (
          <img
            src={titleImageSrc}
            className={'title-icon'}
            alt="Section icon"
          />
        )}
        <Typography.Title level={3} className="title">
          {title}
        </Typography.Title>
      </Flex>
      <Tabs
        tabPosition="left"
        type="card"
        items={tabItems}
        className="tabs-wrapper"
      />
    </STabsCardWrapper>
  );
};

export default TabsCard;
