import styled from 'styled-components';
import { font } from '../../../../App/styleVariables';

interface Block {
  styles: {
    selectList?: {
      label?: {
        textTransform?: string;
        fontSize?: string;
        lineHeight?: number | string;
      };
      selectItem?: {
        fontSize?: string;
        lineHeight?: number | string;
      };
      selectOptions?: {
        fontSize?: string;
        lineHeight?: number | string;
        fontFamily?: string;
      };
    };
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledBlock = styled.div<Block>`
  background: ${({ theme }) =>
    theme?.dashboard?.block?.background || '#ffffff'};
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  font-family: ${({ theme }) => theme?.dashboard?.fontFamily || font};
  box-sizing: border-box;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: ${({ theme }) =>
    theme?.dashboard?.block?.borderRadius || '10px'};
  padding: ${({ theme }) => theme?.dashboard?.block?.padding || '20px 15px'};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  color: ${({ theme }) => theme?.dashboard?.block?.color || '#000000'};
  font-size: ${({ theme }) => theme?.dashboard?.block?.fontSize || '14px'};

  .select-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .select-label {
    margin: 0;
    padding: 0;
    text-transform: ${({ styles }) =>
      styles.selectList?.label?.textTransform || 'none'};
    line-height: ${({ styles }) => styles.selectList?.label?.lineHeight || 1};
    width: calc(100% - 130px);
    font-size: ${({ styles }) =>
      styles.selectList?.label?.fontSize || 'inherit'};
  }
  .select-field {
    .ant-select-selector {
      height: 28px;
      .ant-select-selection-item {
        line-height: ${({ styles }) =>
          styles.selectList?.selectItem?.lineHeight || '26px'};
        font-size: ${({ styles }) =>
          styles.selectList?.selectItem?.fontSize || 'inherit'};
        font-family: ${({ theme }) => theme?.dashboard?.fontFamily || font};
      }
    }
  }
  .select-list-wrapper {
    width: 46%;

    @media (max-width: 1650px) {
      width: 49%;
    }
  }
  .top-section-wrapper {
    width: 49%;
  }
  .main-section-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
`;

export default StyledBlock;
