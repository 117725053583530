import { useState } from 'react';
import { Button, Tooltip } from 'antd';
import {
  MoreOutlined,
  CheckCircleOutlined,
  MailOutlined,
  FileDoneOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import FrontendPaginatedTable from '../Table/FrontendPaginatedTable';
import { OnboardingIcon } from 'components/library/Icons';
import { StyledWrapper, StyledStatisticModal } from './styled-component';

interface Props {
  value: number | string;
  label: string;
  labelIcon?: string;
  labelIconColor?: string;
  postfix?: string;
  tooltipText?: string;
  modal?: boolean;
  key: number;
  modalData?: any;
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const ICONS = {
  active: CheckCircleOutlined,
  email: MailOutlined,
  onboarding: OnboardingIcon,
  onboard: FileDoneOutlined,
  close: CloseOutlined,
  question: QuestionCircleOutlined,
};

const CustomStatistic = ({
  value,
  label,
  labelIcon,
  labelIconColor,
  postfix,
  tooltipText,
  modal,
  styles,
  modalData,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const Icon = ICONS[labelIcon];

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <StyledWrapper styles={styles} modal={modal}>
      <div className={'statistic-container'}>
        {label && (
          <div className="statistic-text-wrapper">
            {Icon && (
              <Icon color={labelIconColor} className="statistic-text-icon" />
            )}
            <p className={'statistic-text'}>{label}</p>
          </div>
        )}
        {modal && (
          <>
            <Button onClick={showModal} className={'more-modal-btn'}>
              <MoreOutlined />
            </Button>
            <StyledStatisticModal
              open={isModalOpen}
              onCancel={handleCancel}
              footer={null}
              className={'more-modal'}
            >
              <FrontendPaginatedTable {...modalData?.Table} />
            </StyledStatisticModal>
          </>
        )}
        <Tooltip id="indicatorTooltip" title={tooltipText} color={'#ffffff'}>
          {value && <p className={'statistic-value'}>{value}</p>}
        </Tooltip>
        {postfix && <p className={'statistic-text'}>{postfix}</p>}
      </div>
    </StyledWrapper>
  );
};

export default CustomStatistic;