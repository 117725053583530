import { FC } from 'react';
import { Image } from 'antd';
import { getFileExtensionFromUrl, getImageUrl } from 'containers/Gallery/util';
import { LoadingOutlined } from '@ant-design/icons';
import { SImagePreviewWrapper } from './style-components';

import paper from 'containers/Gallery/resource/paper.png';

const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif'];

interface FilePreviewProps {
  fileUrl: string;
}

const FilePreview: FC<FilePreviewProps> = ({ fileUrl }) => {

  const fileExtension = getFileExtensionFromUrl(fileUrl);
  const src = getImageUrl(fileExtension, fileUrl);
  const isImage = IMAGE_EXTENSIONS.indexOf(fileExtension) !== -1;

  const image = (
    <Image
      src={src}
      placeholder={<div className='placeholder-loader'><LoadingOutlined /></div>}
      fallback={paper}
      alt="File preview"
      preview={isImage}
      className={`file-preview-image ${!isImage ? 'file' : ''}`}
    />
  );

  return (
    <SImagePreviewWrapper>
      {isImage ? (
        image
      ) : (
        <a href={fileUrl} target={'_blank'} rel="noopener noreferrer">
          {image}
        </a>
      )}
    </SImagePreviewWrapper>
  );
};

export default FilePreview;
