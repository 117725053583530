import React, { Component } from 'react';
import DocumentListTable from './containers/DocumentListTable';
import CardWrapper from 'components/CardWrapper';
import DocumentsListModal from './containers/DocumentsListModal';
import Gallery from '../../containers/Gallery';
import TableViewIcon from './components/TableViewIcon';
import { connect } from 'react-redux';
import { ViewsFilter } from './styled-components';

const VIEW_CONST = {
  table: 'table',
  gallery: 'gallery',
};

export const documentsAdditionalMenu = (view, setView) => (
  <ViewsFilter>
    <TableViewIcon
      type={'bars'}
      isSelected={view === VIEW_CONST.table}
      onClick={() => setView(VIEW_CONST.table)}
    />
    <TableViewIcon
      onClick={() => setView(VIEW_CONST.gallery)}
      type={'appstore'}
      isSelected={view === VIEW_CONST.gallery}
    />
  </ViewsFilter>
);

class Documents extends Component {
  constructor() {
    super();
    this.state = {
      view: VIEW_CONST.gallery,
    };
  }

  componentDidMount() {
    const { schema, pageName } = this.props;
    const mode = schema[pageName].options?.mode;
    this.setState({ view: mode || VIEW_CONST.gallery });
  }

  setView = (view) => this.setState({ view });

  render() {
    const { view } = this.state;

    let { pageName, schema } = this.props;

    const pageSchema = schema[pageName];
    const displayAddButton = pageSchema?.options?.displayAddButton;

    const headerActionsSchema = (typeof displayAddButton === 'undefined' ||
      displayAddButton) && [
      {
        label: pageSchema?.addButton || 'Add Item',
        action: 'addItem',
        icon: 'plus-circle',
      },
    ];

    return (
      <CardWrapper pageName={pageName}>
        {view === VIEW_CONST.gallery && (
          <Gallery
            canUploadFile
            canEditFile
            additionalMenu={documentsAdditionalMenu(view, this.setView)}
            headerActionsSchema={headerActionsSchema}
          />
        )}
        {view === VIEW_CONST.table && <DocumentsListModal />}
        {view === VIEW_CONST.table && (
          <DocumentListTable
            additionalMenu={documentsAdditionalMenu(view, this.setView)}
            headerActionsSchema={headerActionsSchema}
          />
        )}
      </CardWrapper>
    );
  }
}

export default connect(
  ({
    auth: {
      currentUser: {
        attributes: { role, company, pages_scheme },
        isSignedIn,
      },
    },
  }) => ({ schema: pages_scheme })
)(Documents);
