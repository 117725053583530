import styled from 'styled-components';

interface Block {
  styles: {
    textAlign?: string;
    color?: string;
    fontFamily?: string;
    fontSize?: string;
    fontWeight?: string;
    textTransform?: string;
    background?: string;
    padding?: string;
    boxShadow?: string;
    borderRadius?: string;
    whiteSpace?: string;
    marginBottom?: string;
    marginTop?: string;
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledSectionWrapper = styled.div<Block>`
  grid-column-start: ${({ styles }) => styles?.size?.column?.start};
  grid-column-end: ${({ styles }) => styles?.size?.column?.end};
  grid-row-start: ${({ styles }) => styles?.size?.row?.start};
  grid-row-end: ${({ styles }) => styles?.size?.row?.end};
  font-family: ${({ styles, theme }) =>
    styles?.fontFamily || theme?.dashboard?.fontFamily};
  box-sizing: border-box;
  color: ${({ styles, theme }) =>
    styles?.color || theme?.dashboard?.sectionText?.color || '#000000'};
  font-size: ${({ styles, theme }) =>
    styles?.fontSize || theme?.dashboard?.sectionText?.fontSize || '22px'};
  font-weight: ${({ styles, theme }) =>
    styles?.fontWeight || theme?.dashboard?.sectionText?.fontWeight || '400'};
  text-transform: ${({ styles, theme }) =>
    styles?.textTransform ||
    theme?.dashboard?.sectionText?.textTransform ||
    'none'};
  text-align: ${({ styles }) => styles?.textAlign};
  background: ${({ styles }) => styles?.background};
  padding: ${({ styles }) => styles?.padding};
  box-shadow: ${({ styles }) => styles?.boxShadow};
  border-radius: ${({ styles }) => styles?.borderRadius};
  white-space: ${({ styles }) => styles?.whiteSpace};
  margin-top: ${({ styles }) => styles?.marginTop};
  margin-bottom: ${({ styles }) => styles?.marginBottom};
  line-height: 1.2;
`;

export default StyledSectionWrapper;
