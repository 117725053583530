import React from 'react';
import PopoverWrapper from '../PopoverWrapper';

export const TraceabilityPopoverContent = () => (
  <PopoverWrapper>
    <>
      <p className={'popover-title'}> Traçabilité </p>
      <p>
        La <i>couverture de traçabilité</i> représente la part de chiffre
        d’affaires tracé pour les filateurs, les tisseurs et les fabricants sur
        la part de chiffre d’affaires tracé et non tracé.
      </p>
      <p>
        Le <i>chiffre d’affaires tracé</i> est défini pour chaque catégorie de
        produits. La survaleur représente le surplus de chiffre d'affaires
        réalisé grâce à un prix de vente plus élevé au travers de la traçabilité
        réalisée sur les produits. Elle représente 5% du chiffre d’affaire de
        produits tracés.
      </p>
    </>
  </PopoverWrapper>
);
