import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import InputWrapper from '../../../components/HOC/InputWrapper';

class TextArea extends React.PureComponent {
  render() {
    const {
      name,
      value,
      disabled,
      placeholder,
      handleChange,
      handleBlur,
      isError,
    } = this.props;
    return (
      <Input.TextArea
        name={name}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        className={isError ? 'has-error' : ''}
        data-name={name}
      />
    );
  }
}

TextArea.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  isError: PropTypes.string,
};

export default (props) => InputWrapper(props, TextArea);
