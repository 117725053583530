import React, { Component } from 'react';
import ErrorHandler from '../ErrorHandler/error-handler';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
class ErrorCatcher extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.log(`catched error: ${error}`);
    this.setState({ error, errorInfo });
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorHandler
          fixed={() =>
            this.setState({ hasError: false, error: null, errorInfo: null })
          }
          error={error.toString()}
        />
      );
    }

    return children;
  }
}

ErrorCatcher.propTypes = {
  children: PropTypes.any,
};

export default ErrorCatcher;
