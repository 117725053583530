import React from 'react';
import PopoverWrapper from '../PopoverWrapper';

export const CostsEvolutionPopoverContent = () => (
  <PopoverWrapper>
    <>
      <p className={'popover-title'}> Evolution des coûts des matières </p>
      <p>
        L’<i>évolution des coût</i> est déterminée sur une année. Pour le fil et
        le tissu, le prix € au kg est pris en compte. Pour le produit fini,
        c’est le prix à l’unité qui est représenté.
      </p>
    </>
  </PopoverWrapper>
);
