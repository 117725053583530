import styled from 'styled-components';
import { font } from '../../../../App/styleVariables';

interface Block {
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledBlock = styled.div<Block>`
  background: ${({ theme }) =>
    theme?.dashboard?.block?.background || '#ffffff'};
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  color: ${({ theme }) => theme?.dashboard?.block?.color || '#000000'};
  font-family: ${font} !important;
  font-size: 16px;
  letter-spacing: 3px;
  @media (max-width: 1340px) {
    font-size: 16px;
  }

  p {
    margin-bottom: 0;
    line-height: 1.2;
  }
  .bold {
    font-weight: bold;
  }
  .row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
  }
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .title {
    text-transform: ${({ theme }) =>
      theme?.dashboard?.block?.titleTextTransform || 'initial'};
    font-weight: bold;
    text-align: center;
    font-size: 28px;

    @media (max-width: 1440px) {
      font-size: 24px;
    }
  }
  .subtitle {
    text-align: center;
    text-transform: ${({ theme }) =>
      theme?.dashboard?.block?.subTitleTextTransform || 'initial'};
    font-size: 22px;

    @media (max-width: 1440px) {
      font-size: 20px;
    }
  }
  .section-name {
    color: ${({ theme }) =>
      theme?.dashboard?.block?.sectionTextColor || '#8c8c8c'};
    text-transform: ${({ theme }) =>
      theme?.dashboard?.block?.sectionTextTransform || 'initial'};
    text-align: center;
  }
  .section-text {
    text-align: center;
    text-transform: ${({ theme }) =>
      theme?.dashboard?.block?.sectionTextTransform || 'initial'};
  }
  .circle-logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    @media (max-width: 1340px) {
      width: 75px;
      height: 75px;
    }
  }
  .description-wrapper {
    margin-top: 5px;
    text-align: center;
  }
  .description {
    font-size: 16px;
    padding: 0 15px;
    letter-spacing: 2px;
    @media (max-width: 1440px) {
      font-size: 14px;
    }
  }
  .content-wrapper {
    margin-top: 25px;
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: stretch;
  }
  .link-text {
    text-transform: none;
    color: ${({ theme }) => theme?.dashboard?.block?.linkColor || '#000000'};
    outline: none;
    text-decoration: underline;
    font-weight: bold;
    line-height: 1;
    transition: color 0.3s;

    &:hover {
      color: ${({ theme }) =>
        theme?.dashboard?.block?.hoverLinkColor || '#e6e6e6'};
    }
  }
  .fields-wrapper {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    width: 60%;

    .field {
      width: 46%;
      margin-bottom: 25px;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
      &:last-child:nth-child(2n + 1) {
        margin-right: auto;
      }
    }
  }
  .border-gradient {
    &::before {
      content: '';
      border-top: 4px solid;
      border-image: linear-gradient(to right, #1ba7e0, #22375e) 1;
      display: block;
      width: 90px;
      margin: 0 auto;
      padding-bottom: 5px;
    }
  }
  .files-wrapper {
    width: 36%;

    & > * {
      margin-bottom: 10px;
    }
  }
`;

export default StyledBlock;
