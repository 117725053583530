import { Flex, Result } from 'antd';
import { colors, font } from 'App/styleVariables';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 20px;

  .table-wrapper {
    box-shadow: none;

    .ant-table {
      border: 1px solid ${colors.lightGray};
      border-radius: 8px;
    }
  }

  .anticon {
    color: ${colors.gray}
  }

  .ant-spin {
    padding: 32px;
  }
`;

const Header = styled(Flex)`
  margin-bottom: 25px;
`;

const Title = styled.p`
  padding: 0;
  margin: 0 0 0 6px;
  font-size: 16px;
  font-weight: 400;
  font-family: ${font};
`;

const SResult = styled(Result)`
  font-family: ${font};
  padding: 32px;

  .ant-result-title {
    font-size: 18px;
  }

  .ant-result-icon > .anticon {
    font-size: 50px;
    color: ${colors.significant};
  }
`;

export { Title, Wrapper, Header, SResult };