import React from 'react';
import PopoverWrapper from '../PopoverWrapper';

export const MapPopoverContent = () => (
  <PopoverWrapper>
    <>
      <p className={'popover-title'}> Cartographie </p>
      <p>
        La <i>cartographie</i> représente le risque en fonction des zones
        d’approvisionnement. La taille des puces dépend du volume réalisé dans
        chaque pays.
      </p>
      <p>
        Le risque de chaque zone est déterminé grâce à la cartographie des
        risques faite par Allianz Trade chaque année.
      </p>
    </>
  </PopoverWrapper>
);
