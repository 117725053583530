import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { font } from '../../../../App/styleVariables';

export const SIndicatorContainer = styled(SIndicator)`
  .indicator-title {
    margin-bottom: 25px;
  }
  .ant-picker,
  .ant-select {
    margin-right: 20px;
    border-radius: 8px;
    background-color: #fbfbfb;
    font-family: ${({ theme }) =>
      `${theme?.dashboard?.fontFamily}, sans-serif` || font};

    .ant-select-selector {
      font-family: ${({ theme }) =>
        `${theme?.dashboard?.fontFamily}, sans-serif` || font};
    }
  }
  .ant-tabs {
    margin-top: 20px;

    &.ant-tabs-card {
      .ant-tabs-tab:not(.ant-tabs-tab-active) {
        border-right: 1px solid #bdbdbd;
      }
      .ant-tabs-tab-active {
        border-top: 1px solid #bdbdbd !important;
        border-bottom: 1px solid #bdbdbd !important;
        border-left: 1px solid #bdbdbd !important;
      }
      .ant-statistic {
        font-family: ${({ theme }) =>
          `${theme?.dashboard?.fontFamily}, sans-serif` || font};

        .ant-statistic-title {
          font-size: 16px;
          color: #808080;
        }
        .ant-statistic-content {
          font-size: 20px;
          font-weight: 600;
          color: #050505;
          text-align: left;

          .anticon {
            margin-right: 6px;
          }
        }
      }
    }
    .ant-tabs-content {
      border: 1px solid #bdbdbd;
      border-left: none;
      border-radius: 0 8px 8px 0;
    }
  }
`;
