class UniqIdsGenerator {
  constructor(prefixId = '') {
    this.prefixId = prefixId;
  }

  get_id() {
    return Array.from(arguments).reduce(
      (acc, arg) => acc + `-${arg.toString().toLowerCase()}`,
      `${this.prefixId}`
    );
  }
  // Table section
  getTableTheadTr(index) {
    return `tr_thead_${index}_${this.prefixId}`;
  }

  getTableTheadTh(index) {
    return `th_thead_${index}_${this.prefixId}`;
  }

  getTableTbodyTr(index) {
    return `tr_tbody_${index}_${this.prefixId}`;
  }

  getTableTbodyTd(index) {
    return `td_tbody_${index}_${this.prefixId}`;
  }

  getTableDivWrapper() {
    return `div_table_wrapper_${this.prefixId}`;
  }

  getTableRowAction(type) {
    return `span_action_${type}_${this.prefixId}`;
  }

  getTableRoot(text) {
    return `${this.prefixId}_${text}`;
  }

  // Modal window
  getModalContentDivWrapper() {
    return `div_modal_content_wrapper_${this.prefixId}`;
  }

  // Header section
  getHeaderDiv() {
    return `div_header_${this.prefixId}`;
  }

  getHeaderInput() {
    return `input_header_${this.prefixId}`;
  }

  getHeaderButton(index) {
    return `button_header_${index}_${this.prefixId}`;
  }

  // Component section
  getComponentDivWrapper() {
    return `div_component_wrapper_${this.prefixId}`;
  }

  // SideBar
  getSideBarUl() {
    return `ul_${this.prefixId}`;
  }

  getSideBarLi(type) {
    return `li_${type}_${this.prefixId}`;
  }

  getSideBarLiCollapse(type) {
    return `li_collapse_${type}_${this.prefixId}`;
  }

  getSideBarUlCollapse(type) {
    return `ul_collapse_${type}_${this.prefixId}`;
  }

  getSideBarLiDiv(type) {
    return `div_li_${type}_${this.prefixId}`;
  }

  //TopBar
  getTopBarButton(type) {
    return `button_${type}_${this.prefixId}`;
  }

  getMenuItem(type) {
    return `li_menu_${type}_${this.prefixId}`;
  }

  //Other
  getListDivWrapper() {
    return `div_list_wrapper_${this.prefixId}`;
  }

  getGraphDivWrapper() {
    return `div_graph_wrapper_${this.prefixId}`;
  }

  getTrimmedString(text) {
    return `${this.prefixId}_${text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '_')}`;
  }

  getComposition(text) {
    return `${this.prefixId}_${text}`.replace(/\s+/g, '_');
  }
}

export default UniqIdsGenerator;
