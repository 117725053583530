const OPEN_MODAL = 'GENERIC_MODAL::OPEN';
const CLOSE_MODAL = 'GENERIC_MODAL::CLOSE';
const CHANGE_TITLE = 'GENERIC_MODAL::CHANGE_TITLE';

export const openGenericModalAction = ({ data, name, configuration }) => ({
  type: OPEN_MODAL,
  payload: { data, name, configuration },
});

export const closeGenericModalAction = ({ name }) => {
  return {
    type: CLOSE_MODAL,
    payload: { isModalOpen: false, productData: {}, isCommonItem: false, name },
  };
};

export const changeModalTitleAction = ({ name, title }) => {
  return {
    type: CHANGE_TITLE,
    payload: { name, title },
  };
};

const changeModalTitleHandler = (state, { payload }) => {
  return {
    ...state,
    [payload?.name]: {
      ...state[payload.name],
      configuration: {
        ...state[payload.name]?.configuration,
        title: payload?.title,
      },
    },
  };
};

const openModalHandler = (state, { payload }) => ({
  ...state,
  [payload.name]: {
    isModalOpen: true,
    data: payload.data,
    configuration: payload.configuration || {},
  },
});

const closeModalHandler = (state, { payload }) => ({
  ...state,
  [payload.name]: {
    isModalOpen: false,
    data: null,
    configuration: null,
  },
});

const handlers = {
  [OPEN_MODAL]: openModalHandler,
  [CLOSE_MODAL]: closeModalHandler,
  [CHANGE_TITLE]: changeModalTitleHandler,
};

export default (state = {}, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};
