import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Input, Spin } from 'antd';
import { Field, Form as FForm } from 'formik';
import UniqIdsGenerator from '../../../../../lib/uniqIdsGenerator';
import { SButton, SForgotLink, SFormItem } from './styled-components';

const id_generator = new UniqIdsGenerator('form-content');

interface FormContentInterface {
  isSubmitting: boolean;
  isSsoLogin: boolean;
  values: any;
  setFieldValue: Function;
}

const FormContent = ({
  isSsoLogin,
  isSubmitting,
  values,
  setFieldValue,
}: FormContentInterface) => {
  const passwordReference = useRef(null);

  useEffect(() => {
    if (!isSsoLogin) {
      passwordReference.current.focus();
    }
  }, [isSsoLogin]);

  return (
    <FForm id={id_generator.get_id('formik')}>
      <Field name={'email'}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { touched, errors, handleChange, handleBlur },
        }) => {
          const trimInput = ({ target }) => {
            const { value } = target;
            const trimmedValue = value.trim();
            setFieldValue(field.name, trimmedValue);
          };

          return (
            <SFormItem
              label="Email or Username"
              id={id_generator.get_id('helper-email')}
              help={touched.email && errors.email ? errors.email : ''}
              validateStatus={
                touched.email && errors.email ? 'error' : undefined
              }
            >
              <Input
                id={id_generator.get_id('field-email')}
                autoComplete="on"
                {...field}
                onChange={trimInput}
              />
            </SFormItem>
          );
        }}
      </Field>
      {!isSsoLogin && (
        <Field name={'password'}>
          {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors, handleChange, handleBlur },
          }) => {
            return (
              <SFormItem
                label="Password"
                id={id_generator.get_id('helper-password')}
                help={
                  touched.password && errors.password ? errors.password : ''
                }
                validateStatus={
                  touched.password && errors.password ? 'error' : undefined
                }
              >
                <Input.Password
                  id={id_generator.get_id('field-password')}
                  autoComplete="on"
                  {...field}
                  ref={passwordReference}
                />
              </SFormItem>
            );
          }}
        </Field>
      )}
      {!isSsoLogin && (
        <SForgotLink>
          <Link to={'/forgot_password'} state={{ email: values?.email }}>
            Forgot Password?
          </Link>
        </SForgotLink>
      )}
      {isSubmitting && <Spin size="default" />}
      {!isSubmitting && (
        <SButton
          id={id_generator.get_id('btn-submit')}
          htmlType="submit"
          className={'login-form-button'}
        >
          Login
        </SButton>
      )}
    </FForm>
  );
};

export default FormContent;
