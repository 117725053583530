import EnvironmentPopoverContent from './EnvironmentPopoverContent';
import TraceabilityPopoverContent from './TraceabilityPopoverContent';
import CostsEvolutionPopoverContent from './CostsEvolutionPopoverContent';
import MapPopoverContent from './MapPopoverContent';
import ProtectionPopoverContent from './ProtectionPopoverContent';
import CsrdPopoverContent from './CSRDPopoverContent';

export default {
  EnvironmentPopoverContent,
  TraceabilityPopoverContent,
  CostsEvolutionPopoverContent,
  MapPopoverContent,
  ProtectionPopoverContent,
  CsrdPopoverContent,
};
