import styled from 'styled-components';
import { Button } from 'antd';
import { colors } from 'App/styleVariables';

export const SButton = styled(Button)`
  border: ${({ theme }) =>
    theme.button?.default?.passiveBorder || `1px solid ${colors.primary}`};
  color: ${({ theme }) =>
    theme.button?.default?.passiveColor || colors.secondary};
  background: ${({ theme }) => theme.button?.default?.passiveBackground};
  height: 36px;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    color: ${({ theme }) =>
      theme.button?.default?.activeColor || colors.secondary} !important;
    background: ${({ theme }) =>
      theme.button?.default?.activeBackground} !important;
    border: ${({ theme }) =>
      theme.button?.default?.activeBorder ||
      `1px solid ${colors.secondary}`} !important;
  }
`;
