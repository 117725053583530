import React from 'react';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';
import _ from 'lodash';
import DataTable from '../../../../../components/DataTable';
import {
  get_chains_action,
  get_layout_action,
  set_filter_action,
  set_initial_state_action,
} from './reducer';
import { openModalAction } from '../TrackProductModal/reducer';
import CodeEntryBlock from './components/CodeEntryBlock';
import { Spin } from 'antd';

class TrackProductTable extends React.Component {
  componentDidMount() {
    const { get_layout } = this.props;
    get_layout();
  }

  componentWillUnmount() {
    const { set_initial_state } = this.props;
    set_initial_state();
  }

  handleCodeSubmit = ({ code }) => {
    const { get_chains } = this.props;
    get_chains({ code });
  };

  handleTableChange = (pagination, filters, sorter, current_sort) => {
    const { set_filter } = this.props;
    const { currentDataSource } = current_sort;

    if (_.isEmpty(Object.values(filters))) set_filter(currentDataSource);
  };

  handleClickOnTableRowAction = ({ record, action }) => {
    const { openModal } = this.props;
    const { traceType, traceId } = record;
    openModal({ mode: action, traceType, traceId });
  };

  handleParser = (html = '') => Parser(html);

  render() {
    const { data, table_scheme, loading, error, footer, header } = this.props;
    if (!header || !footer) {
      return <Spin />;
    }
    const html_header = this.handleParser(header);
    const html_footer = this.handleParser(footer);

    return (
      <React.Fragment>
        {html_header}
        <CodeEntryBlock
          onCodeSubmit={this.handleCodeSubmit}
          loading={loading}
          error={error}
        />
        {data && !!data.length && (
          <React.Fragment>
            <DataTable
              prefixId="TrackProductTable"
              isSearchDisabled
              getRowKey={(d) => d.traceId}
              data={data}
              columnsSchema={table_scheme}
              handleClickOnTableRowAction={this.handleClickOnTableRowAction}
              handleTableChange={this.handleTableChange}
            />
            {html_footer}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  track_product_table: {
    data,
    table_scheme,
    loading,
    error,
    layout: { footer, header },
  },
}) => ({
  data,
  table_scheme,
  loading,
  error,
  header,
  footer,
});

const mapDespatchToProps = {
  get_chains: get_chains_action,
  get_layout: get_layout_action,
  set_filter: set_filter_action,
  set_initial_state: set_initial_state_action,
  openModal: openModalAction,
};

export default connect(mapStateToProps, mapDespatchToProps)(TrackProductTable);
