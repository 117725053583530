import { FC } from 'react';
import StyledSectionWrapper from './styled-components';

interface SectionTextProps {
  text: string;
  styles?: any;
}

const SectionText: FC<SectionTextProps> = ({ text, styles }) => {
  if (!text) {
    return null;
  }
  return <StyledSectionWrapper styles={styles}>{text}</StyledSectionWrapper>;
};

export default SectionText;
