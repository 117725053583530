/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import CommonNetworkFailure from '../CommonNetworkFailure';
import { actionUnsetCommonNetworkFailure } from 'reducers/network_events';

export default (WrappedComponent) => {
  class withCommonNetworkError extends React.Component {
    render() {
      const {
        commonNetworkFailure,
        actionUnsetCommonNetworkFailure,
      } = this.props;
      return commonNetworkFailure &&
        commonNetworkFailure.error &&
        commonNetworkFailure.error.name === 'maintenance' ? (
        <CommonNetworkFailure
          data={commonNetworkFailure}
          cleanup={actionUnsetCommonNetworkFailure}
        />
      ) : (
        <WrappedComponent {...this.props} />
      );
    }
  }

  return connect(
    ({ networkEvents }) => ({
      commonNetworkFailure: networkEvents.commonNetworkFailure,
    }),
    { actionUnsetCommonNetworkFailure: actionUnsetCommonNetworkFailure }
  )(withCommonNetworkError);
};
