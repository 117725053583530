import styled from 'styled-components';
import { Form } from 'formik';
import { Input, Button } from 'antd';
import { ArrowUpOutlined, SearchOutlined } from '@ant-design/icons';
import { font } from "../../../../../App/styleVariables";

const SForm = styled(Form)`
  display: flex !important;
  justify-content: space-between !important;
  padding-right: 0.6% !important;
  width: 100% !important;

  .input-wrapper {
    position: relative;
    display: flex;
    min-width: 150px;
    margin-right: 8px;
    width: 16% !important;

    .error-msg {
      position: absolute;
      left: 0px;
      top: 65px;
      display: block !important;
      min-width: 400px !important;
      color: red;
    }
  }
`;

const SInput = styled(Input)`
  display: flex !important;
  padding-left: 10% !important;
  height: 60px !important;
  font-size: 16px !important;
  font-family: ${font} !important;
  font-weight: 600 !important;
  color: #102530 !important;

  &::placeholder {
    font-size: 16px !important;
    font-family: ${font} !important;
    font-weight: 600 !important;
  }
`;

const SButton = styled(Button)`
  height: 60px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 49.2% !important;
  max-width: 250px !important;
  font-size: ${props => props.theme.form.submitButton?.size} !important;
  font-weight: ${props => props.theme.form.submitButton?.weight} !important;
  font-family: ${props => props.theme.form.submitButton?.family} !important;
  color: ${props => props.theme.form.submitButton?.color} !important;
  border: none !important;
  background: ${props => props.theme.form.submitButton?.passive} !important;

  &:hover {
    color: white;
    background: ${props => props.theme.searchButton?.active} !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: 10px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between !important;
  width: 84% !important;
`;

const SArrowUp = styled(ArrowUpOutlined)`
  font-size: ${props => (props.ws.width < 1280 ? '20px' : '30px;')};
`;

const SSearch = styled(SearchOutlined)`
  font-size: ${props => (props.ws.width < 1280 ? '20px' : '30px;')};
`;

export { SForm, SInput, SButton, SArrowUp, SSearch, Wrapper, Buttons };

export default {};
