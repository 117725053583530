import React from 'react';
import { QuestionMarkPopoverInterface } from '../../types';
import { Popover } from 'antd';
import components from './components';
import question from '../../../../images/cockpit/question.svg';
import { SQuestionMark } from './styled-component';

const QuestionMarkPopover = ({ popoverName }: QuestionMarkPopoverInterface) => {
  return (
    <Popover content={components[popoverName]}>
      <SQuestionMark src={question} alt="?" className={'question-mark'} />
    </Popover>
  );
};

export default QuestionMarkPopover;
