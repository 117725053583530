import React from 'react';
import PropTypes from 'prop-types';
import { StyledTooltip } from './style';

class TableElement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { is_tooltip: false, is_ellipsis: false };
    this.ref = React.createRef();
    this.is_active = false;
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('keydown', this.handleKeyDown);

    this.updateDimensions();
  }

  updateDimensions = () => {
    if (!this.ref || !this.ref.current) return;

    const { scrollWidth, clientWidth } = this.ref.current;

    this.setState({ is_ellipsis: scrollWidth >= clientWidth });
  };

  handleKeyDown = (e) => {
    const keys = { 123: true };
    if (keys[e.keyCode]) setTimeout(this.updateDimensions, 400);
  };

  handleMouseOver = () => {
    const { scrollWidth, clientWidth } = this.ref.current;
    this.is_active = true;

    if (scrollWidth > clientWidth) this.setState({ is_tooltip: true });
  };

  handleMouseLeave = () => {
    this.is_active = false;
    this.setState({ is_tooltip: false });
  };

  render() {
    const { text } = this.props;
    const { is_ellipsis, is_tooltip } = this.state;

    const styleBuilder = {};

    styleBuilder.wordBreak = 'break-all';
    styleBuilder.whiteSpace = 'normal';
    if (is_ellipsis) {
      styleBuilder.textOverflow = 'ellipsis';
      styleBuilder.whiteSpace = 'nowrap';
    }

    return (
      <div
        style={styleBuilder}
        ref={this.ref}
        onMouseOver={this.is_active ? null : this.handleMouseOver}
        onFocus={this.is_active ? null : this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      >
        {(is_tooltip && is_tooltip && (
          <StyledTooltip
            placement="bottomLeft"
            title={text}
            overlayClassName="custom-tooltip"
            mouseEnterDelay={300}
            mouseLeaveDelay={0}
            open={is_tooltip && is_tooltip}
          >
            {text}
          </StyledTooltip>
        )) ||
          text}
      </div>
    );
  }
}

TableElement.propTypes = {
  text: PropTypes.string,
};

export default TableElement;
