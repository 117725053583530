import React from 'react';
import './styles.css';
import _ from 'lodash';
import PropTypes from 'prop-types';
import fileExtension from 'file-extension';
import { Popover } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import UniqIdsGenerator from '../../../../lib/uniqIdsGenerator';
import { getPreview } from './get-file-preview';

const idGenerator = new UniqIdsGenerator('files_');

const FILE_NAME_MAX_SIZE = {
  small: 8,
  medium: 14,
  large: 20,
};

const FileViewMiniature = ({ item, onClick, isSelected, size = 'medium' }) => {
  const extension = fileExtension(item.file_url_href);
  const checkCharacterIndex = extension.split('').indexOf('?');
  const modifiedExtension =
    checkCharacterIndex !== -1
      ? extension.slice(0, checkCharacterIndex)
      : extension;

  const pdfStyle = {
    backgroundImage: 'url(./pdf_simple.png)',
    backgroundSize: '75%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  };

  return (
    <Popover
      content={
        <div className="file-preview-popover-content">{item.file_name}</div>
      }
      placement="bottom"
    >
      <div
        className={`file-preview-root ${
          isSelected ? 'file-preview-root-selected' : ''
        } file-preview-root-${size}`}
        onClick={onClick}
        id={idGenerator.getTableRoot(item.file_name)}
        // onDoubleClick={ondblclick}
        style={modifiedExtension === 'pdf' ? pdfStyle : {}}
      >
        {item?.protected && (
          <div className={'protected-icon-wrapper'}>
            <LockOutlined />
          </div>
        )}
        {modifiedExtension !== 'pdf' &&
          getPreview(item.file_url_href, size, modifiedExtension !== 'pdf')}
        <header className={`file-preview-name file-preview-name-${size}`}>
          {modifiedExtension !== 'pdf'
            ? _.truncate(item.file_name, { length: FILE_NAME_MAX_SIZE[size] })
            : item.file_name}
        </header>
      </div>
    </Popover>
  );
};

FileViewMiniature.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  onDoubleClick: PropTypes.func,
  size: PropTypes.any,
};

export default FileViewMiniature;
