import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { CardStyles } from '../../types';

interface EnvironmentStyles extends CardStyles {
  withDescription?: boolean;
}

export const SIndicatorContainer = styled(SIndicator)<EnvironmentStyles>`
  .header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: ${(props) => props?.styles?.header?.marginBottom || '24px'};
    

    @media (max-width: 1440px) {
      margin-bottom: 8px;
    }

    .indicator-icon {
      display: block;
      margin-right: 15px;

      @media (max-width: 1600px) {
        max-width: 70px;
      }
      @media (max-width: 1440px) {
        max-width: 60px;
        margin-right: 5px;
      }
      @media (max-width: 1280px) {
        max-width: 50px;
      }
    }
    .indicator-title {
      font-family: inherit;
      font-size: 20px;
      font-weight: 500;

      @media (max-width: 1920px) {
        max-width: 60%;
      }
      @media (max-width: 1700px) {
        max-width: 100%;
      }
      @media (max-width: 1600px) {
        font-size: 18px;
      }
      @media (max-width: 1280px) {
        font-size: 16px;
      }
    }
  }
  .total {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    
    span {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
  }
  .impact-wrapper {
    margin-bottom: ${(props) => props?.withDescription ? '15px' : ''};
  }
  .impact-row {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12px;

    @media (max-width: 1440px) {
      font-size: 12px;
    }

    span {
      font-size: 32px;

      @media (max-width: 1600px) {
        font-size: 26px;
      }
      @media (max-width: 1440px) {
        font-size: 22px;
        margin-bottom: 8px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
      }
    }
  }
  .impact-row.with-description {
    font-weight: 400;
    margin-bottom: 20px;

    span {
      font-size: 16px;
      font-weight: 600;

      @media (max-width: 1600px) {
        font-size: 14px;
      }
    }
    @media (max-width: 1440px) {
      margin-bottom: 10px;
    }
  }
  .growth-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: ${(props) => props?.styles?.growthRow?.alignItems || 'flex-end'};

    .percentage {
      line-height: 1.2;

      .ant-statistic-content {
        font-size: 16px;
        display: ${(props) => props?.styles?.percentage?.display || 'block'};

        @media (max-width: 1440px) {
          font-size: 12px;
        }
      }
    }
    .text {
      color: #667085;
      margin-left: 6px;
      line-height: 1.2;

      @media (max-width: 1440px) {
        font-size: 12px;
      }
    }
  }
  .graph-container {
    width: 40%;
  }
  .area-chart {
    width: 100%;
    height: ${(props) => props?.styles?.chart?.height || '80px'};
    margin-bottom: ${(props) => props?.styles?.chart?.marginBottom};

    @media (max-width: 1600px) {
      height: 70px;
    }
  }
  .graph-description {
    font-size: 10px;
    text-align: right;
    color: #667085;
    line-height: 1.1;
  }
`;
