import styled from 'styled-components';
import { SIndicator } from '../../styled-components';

export const SIndicatorContainer = styled(SIndicator)`
  .brand-value-row {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: #525357;
    
    .brand-value {
      margin-left: 10px;
      font-weight: 600;
      font-size: 24px;
      color: #101828;
    }
  }
  .categories-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    row-gap: 20px;
    width: 100%;
    margin-top: 20px;
  }
  .category-element {
    width: 47%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    max-width: 435px;
    @media(max-width: 1700px) {
      width: 48%;
    }
    
    .category-name-wrapper {
      display: flex;
      align-items: center;
    }
    .category-name {
      font-size: 18px;
      text-transform: uppercase;
      color: #525357;
      
      @media(max-width: 1700px) {
        font-size: 15px;
      }
      @media(max-width: 1400px) {
        font-size: 13px;
      }
    }
    .category-icon {
      width: 35px;
      height: 35px;
      margin-right: 15px;
      @media(max-width: 1400px) {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }
  }
`;
