import * as d3 from 'd3';

export const navigateToRoot = (svg, graph, zoom, rootTraceId) => {
  const svgSizes = svg.node().getBoundingClientRect();
  const svgWidth = svgSizes.width;
  const svgHeight = svgSizes.height;

  // const rootTraceId = this.props.traceId;
  const { nodes } = graph;
  const rootNode = nodes.filter(
    (node) => node.data?.traceId === rootTraceId
  )[0];

  const centerX = svgWidth / 2 - rootNode.x;
  const centerY = svgHeight / 2 - rootNode.y;

  const zoomTransition = d3.transition().delay(100).duration(700);
  svg
    .transition(zoomTransition)
    .call(zoom.transform, d3.zoomIdentity.translate(centerX, centerY).scale(1));
};
