import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomModal from '../../../../components/Modal';
import ShowTraceList from '../../../../components/ShowTraceList';
import DetailsForm from '../DetailsForm';
import { closeModalAction } from './reducer';

const actions = { edit: true, show: true };
const titles = { edit: 'Editer Trace', show: 'Voir Trace' };
const isEdit = { edit: true };

class DetailsModal extends React.PureComponent {
  handleOnClose = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  render() {
    const { action, isModalOpen, formType, data } = this.props;

    return (
      <CustomModal
        title={actions[action] ? `${titles[action]} : ${formType}` : null}
        closeModal={this.handleOnClose}
        modalVisible={isModalOpen}
        width={isEdit[action] ? 1100 : 750}
      >
        {isModalOpen && isEdit[action] ? <DetailsForm {...this.props} /> : null}
        {isModalOpen && !isEdit[action] ? <ShowTraceList data={data} /> : null}
      </CustomModal>
    );
  }
}

DetailsModal.propTypes = {
  closeModal: PropTypes.func,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formType: PropTypes.string,
  isModalOpen: PropTypes.bool,
  data: PropTypes.any,
};

const ConnectedModal = connect(
  ({ detailsModal }) => ({
    isModalOpen: detailsModal.isModalOpen,
    action: detailsModal.action,
    data: detailsModal.data,
    formType: detailsModal.formType,
  }),
  {
    closeModal: closeModalAction,
  }
)(DetailsModal);

export default ConnectedModal;
