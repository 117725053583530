import React, { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import flow from 'images/cockpit/flow.svg';
import { fetchData } from '../../fetchData';
import errorHandler from '../../../../lib/errorHandler';
import { SelectOption, MapInterface } from '../../types';
import { SMapWrapper, SLegendItem } from './styled-components';
import QuestionMarkPopover from '../QuestionMarkPopover';
import { Template ,Circle, IEntitySettings } from "@amcharts/amcharts5";

const FlowMap = ({
  styles,
  bordered,
  data,
  businessName,
  pathName,
  title,
  questionMark,
  questionMarkPopover,
  legendTitle,
  showArrowInLegend = true,
  withBubbles = false,
  zoomLevel,
  selectPlaceholderText,
}: MapInterface) => {
  const [mapData, setMapData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let root = am5.Root.new('mapdiv');
    root.setThemes([am5themes_Animated.new(root)]);

    // Create the map chart
    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: 'translateX',
        panY: 'translateY',
        projection: am5map.geoMercator(),
      })
    );
    chart.children.push(
      am5.Container.new(root, {
        layout: root.horizontalLayout,
        x: 20,
        y: 40,
      })
    );

    // Create main polygon series for countries
    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
      })
    );

    // Create line series for trajectory lines
    let lineSeries = chart.series.push(am5map.MapLineSeries.new(root, {}));
    lineSeries.mapLines.template.setAll({
      stroke: root.interfaceColors.get('alternativeBackground'),
      strokeOpacity: 0.6,
    });

    // Arrow series
    let arrowSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));
    arrowSeries.bullets.push(function () {
      let arrow = am5.Graphics.new(root, {
        fill: am5.color(0x000000),
        stroke: am5.color(0x000000),
        draw: function (display) {
          display.moveTo(0, -3);
          display.lineTo(8, 0);
          display.lineTo(0, 3);
          display.lineTo(0, -3);
        },
      });

      return am5.Bullet.new(root, {
        sprite: arrow,
      });
    });

    // Markers series
    mapData?.data.forEach((item, index) => {
      Object.keys(item).forEach((type) => {
        let entitySeries = chart.series.push(
          am5map.MapPointSeries.new(root, {
            calculateAggregates: true,
            valueField: "value",
            polygonIdField: "id"
          })
        );
        const circleTemplate = am5.Template.new({
          tooltipText: "{title}"
        } as IEntitySettings);

        entitySeries.bullets.push(function () {
          let circle = am5.Circle.new(root, {
            radius: 5,
            // tooltipText: '{title}',
            tooltipY: 0,
            fill: am5.color(mapData?.colors[type]),
            ...(!withBubbles && { stroke: root.interfaceColors.get('background')}),
            strokeWidth: 2,
            templateField: "circleTemplate"
          }, circleTemplate as Template<Circle>);

          return am5.Bullet.new(root, {
            sprite: circle,
          });
        });

        entitySeries.set("heatRules", [{
          target: circleTemplate,
          min: 5,
          max: 10,
          key: "radius",
          dataField: "value"
        }]);

        entitySeries.data.setAll(mapData?.data[index][type]);
      });
    });

    // Destination series
    am5.array.each(
      mapData?.arrows,
      function (did: { origin: []; destinations: [] }) {
        let lineDataItem = lineSeries.pushDataItem({
          geometry: {
            type: 'LineString',
            coordinates: [did?.origin, did?.destinations],
          },
        });
        arrowSeries.pushDataItem({
          lineDataItem: lineDataItem,
          positionOnLine: 0.5,
          autoRotate: true,
        });
      }
    );

    // Add zoom control
    chart.set('zoomControl', am5map.ZoomControl.new(root, {}));

    const centerCoordinates =
      mapData?.arrows.length > 0
        ? {
            longitude: mapData?.arrows[0].origin[0],
            latitude: mapData?.arrows[0].origin[1],
          }
        : {
            longitude: 8.674706,
            latitude: 55.411029,
          };

    polygonSeries.events.on('datavalidated', function () {
      chart.zoomToGeoPoint(centerCoordinates, zoomLevel || 8);
    });

    // Make stuff animate on load
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapData]);

  const handleChange = (value) => {
    setIsLoading(true);
    fetchData(businessName, pathName, { lot_number: value })
      .then((data) => {
        setMapData(data?.map?.data);
        setIsLoading(false);
      })
      .catch((err: Error[] | Error) => {
        errorHandler(err);
      });
  };

  const legend =
    mapData?.colors &&
    Object.entries(mapData?.colors).map(([type, color]) => {
      const label = type === 'AUTRES' ? 'CLIENTS AVAL' : type;
      return (
        <SLegendItem color={color} key={type}>
          {label}
        </SLegendItem>
      );
    });

  return (
    <SMapWrapper styles={styles} bordered={bordered}>
      <p className={'indicator-title'}>
        {title || 'Carte des flux'}
        {questionMark && (
          <QuestionMarkPopover popoverName={questionMarkPopover} />
        )}
      </p>
      <div className={'wrapper'}>
        <div className={'content'}>
          <div className={'filter-section'}>
            <Select
              showSearch
              allowClear
              placeholder={selectPlaceholderText || 'Sélectionnez un numéro de lot'}
              filterOption={(input, option: SelectOption) =>
                option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(filter) => handleChange(filter)}
              options={mapData?.filter}
              className={'filter'}
              suffixIcon={<SearchOutlined />}
            />
          </div>
          <div className={'map-legend'}>
            {legendTitle && <p className={'legend-title'}>{legendTitle}</p>}
            {legend}
            {showArrowInLegend && (
              <SLegendItem icon={flow}>Flux de produit</SLegendItem>
            )}
          </div>
        </div>
        <div className={'map-container'}>
          <div id="mapdiv"></div>
          {isLoading && (
            <div className={'loader'}>
              <Spin size={'large'} />
            </div>
          )}
        </div>
      </div>
    </SMapWrapper>
  );
};

export default FlowMap;
