const formErrorHandler = (e: Error | Error[]): string | object => {
  if(e instanceof Error) {
    return `${e?.name || ''} ${e?.message}`
  }

  let errors;
  Object.values(e).forEach((error: Error) => {
    if(error?.name === 'error') {
      return errors = error?.message;
    } else {
      errors = {
        ...errors,
        [error.name]: error.message
      }
    }
  })
  return errors;
};

export default formErrorHandler;