import { message } from 'antd';
import {
  closeGenericModalAction,
  openGenericModalAction,
} from 'containers/GenericModal/reducer';
import { store } from 'store/nextStore';
import { actionGoTo } from 'sections/SideBar/reducer';
import {
  downloadTraceModal,
  formWorkerModal,
} from 'sections/GenericTablePage/constants';
import {
  conformityFormModal,
  contactSupplierModal,
  industrialProcessModal,
  sendEmailFormModal,
} from 'components/Modal/constants';

const tableActionHandler = (
  action: string,
  record: object | any | undefined,
  businessName: string,
) => {
  switch (action) {
    case 'openCleanSolutionDash':
      store.dispatch(
        actionGoTo(`/cleansolution_projects/${record?.ProjectId}`)
      );
      break;
    case 'openSovereignCCDash':
      store.dispatch(actionGoTo(`/sovereign_cc_use/${record?.Country}`));
      break;
    case 'openProjectFundingDash':
      store.dispatch(actionGoTo(`/project_funding/${record?.ProjectId}`));
      break;
    case 'openFundCompositionDash':
      store.dispatch(actionGoTo(`/fund_composition/${record?.FundName}`));
      break;
    case 'showTrace':
      store.dispatch(
        openGenericModalAction({
          name: downloadTraceModal,
          configuration: {
            title: 'Supplier information',
            width: '650px',
          },
          data: {
            data: record,
            isEdit: false,
            isShowTrace: true,
          },
        })
      );
      break;
    case 'followUp':
      store.dispatch(
        openGenericModalAction({
          name: sendEmailFormModal,
          configuration: {
            title: record.Supplier,
            width: '800px',
          },
          data: {
            data: record,
            businessName,
          },
        })
      );
      break;
    case 'editSupplier':
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: `Edit :`,
            width: '750px',
          },
          data: {
            userValues: record,
            isEdit: true,
            isShowTrace: false,
            formStep: 'formFilling',
            type: 'suppliers_dashboard',
            extraKeyName: 'traceType',
            handleSubmit: (data: any, actions: any) => {
              setTimeout(() => {
                message.success('Saved');
                store.dispatch(
                  closeGenericModalAction({ name: formWorkerModal })
                );
              }, 1500);
            },
          },
        })
      );
      break;
    case 'showProduct':
      store.dispatch(
        openGenericModalAction({
          name: conformityFormModal,
          configuration: {
            title: `View :`,
            width: '850px',
          },
          data: {
            userValues: record,
            isEdit: false,
            type: 'conformity_dashboard',
          },
        })
      );
      break;
    case 'editProduct':
      store.dispatch(
        openGenericModalAction({
          name: conformityFormModal,
          configuration: {
            title: `Edit :`,
            width: '850px',
          },
          data: {
            userValues: record,
            isEdit: true,
            type: 'conformity_dashboard',
          },
        })
      );
      break;
    case 'contactSupplier':
      store.dispatch(
        openGenericModalAction({
          name: contactSupplierModal,
          configuration: {
            title: record.Company,
            width: '700px',
          },
          data: {
            data: record,
          },
        })
      );
      break;
    case 'showIndustry':
      store.dispatch(
        openGenericModalAction({
          name: industrialProcessModal,
          configuration: {
            title: record.IndustrialProcess,
            width: '750px',
          },
          data: {
            userValues: record,
            isEdit: false,
          },
        })
      );
      break;
    case 'editIndustry':
      store.dispatch(
        openGenericModalAction({
          name: industrialProcessModal,
          configuration: {
            title: record.IndustrialProcess,
            width: '750px',
          },
          data: {
            userValues: record,
            isEdit: true,
          },
        })
      );
      break;
    default:
      console.log(`Action ${action} not found`);
      break;
  }
};

export default tableActionHandler;