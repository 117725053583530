import { FC } from 'react';
import { Typography, Flex, Descriptions } from 'antd';
import { SCardWrapper } from './styled-components';

const DescriptionCard: FC = ({
  imageName,
  title,
  totals,
  descriptions,
  text,
  footerNote,
  businessName,
  styles,
}: any) => {
  const imageSrc = `${process.env.REACT_APP_BUCKET_NAME}/${businessName}/public/images/${imageName}`;

  return (
    <SCardWrapper styles={styles}>
      <Flex vertical justify="space-between" className="card-container">
        <Flex align="center" className="card-header">
          {imageName && (
            <img src={imageSrc} className="icon" alt="Icon" />
          )}
          <Typography.Title level={3} className="title">
            {title}
          </Typography.Title>
        </Flex>
        {totals && <p className="totals"> {totals} </p>}
        {descriptions && (
          <Descriptions
            items={descriptions}
            column={1}
            colon={false}
            className="card-descriptions"
          />
        )}
        {text && <p className="simple-text"> {text} </p>}
        {footerNote && <p className="footer-note"> {footerNote} </p>}
      </Flex>
    </SCardWrapper>
  );
};

export default DescriptionCard;
