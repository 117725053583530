import styled from 'styled-components';
import { colors, font, transition } from '../../App/styleVariables';

const SWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  font-family: ${font};

  .trace-logo {
    max-width: 265px;
    margin: 10vh 0;
  }

  h1 {
    font-size: 28px;
    font-weight: 600;
    margin: 30px 0 20vh;
  }
`;

const SResult = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  margin-top: 40px;

  p {
    margin: 15px 0;
    text-align: justify;
  }
  .anticon {
    color: ${colors.primary};
    margin-right: 15px;

    svg {
      width: 45px;
      height: 45px;
    }
  }
`;

const SLink = styled.div`
  margin-top: 25px;
  align-self: flex-start;

  a {
    color: #1d1d1b;
    font-weight: 500;
    transition: ${transition};
    text-decoration: underline;

    &:hover {
      color: ${colors.secondary};
    }
  }
`;

export { SWrapper, SResult, SLink };