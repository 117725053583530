import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import {
  CalculatedDataInterface,
  UnitType,
  StubbedDataInterface,
} from './type';

/**
 * Function to calculate analytics data from the stubbed data
 * @param data - stubbed data
 * @param dateArray - dates based on which calculations will be made
 * @param period - period of time
 */
export const calculateStubbedData = (
  data: StubbedDataInterface[],
  dateArray: string[],
  period: UnitType
): CalculatedDataInterface => {
  /**
   * Function to get the end of a unit of time
   * @param date
   * @param unit
   */
  const getEndDate = (date: string, unit: OpUnitType) => {
    return dayjs(date).endOf(unit);
  };

  /**
   * Function to get data in the time period between two dates
   * @param start - period start date
   * @param end - period end date
   */
  const getRangeData = (start: string, end: Dayjs | string) => {
    return data?.filter((obj) => {
      const date = dayjs(obj.date);
      return date >= dayjs(start) && date <= dayjs(end);
    });
  };

  /**
   * Function that calculates the sum of an array
   * @param values - values the sum of which the function calculates
   */
  const sum = (values: number[]) => {
    return values.reduce((acc, curr) => {
      return acc + curr;
    }, 0);
  };

  /**
   * Function that calculates the arithmetic mean
   * @param values - values whose arithmetic mean is calculated by the function
   */
  const arithmeticMean = (values: number[]) => {
    const sumValues = sum(values);
    const mean = sumValues / values.length;
    return Math.round(mean);
  };

  /**
   * Function that finds and returns metric values from a data
   * @param name - metric name
   * @param data - array of data
   */
  const getMetricValues = (name: string, data: StubbedDataInterface[]) => {
    return data.map((item) => {
      if (typeof item[name] === 'string' && item[name].includes('%')) {
        return Number(item[name].replace('%', ''));
      }
      return item[name];
    });
  };

  switch (period) {
    case 'day':
      const startDate = dateArray[0];
      const endDate = dateArray[4];
      const periodData = getRangeData(startDate, endDate);

      return {
        nb_visits: getMetricValues('nb_visits', periodData),
        avg_time_spent: getMetricValues('avg_time_spent', periodData),
        nb_pageviews: getMetricValues('nb_pageviews', periodData),
        nb_bottom_reached: getMetricValues('nb_bottom_reached', periodData),
        penetration_rate: getMetricValues('penetration_rate', periodData).map(
          (d) => `${d}%`
        ),
      };
    default:
      const startDates = dateArray;
      const endDates = dateArray.map((date) => getEndDate(date, period));

      const res = startDates.map((date, index) => {
        const periodData = getRangeData(date, endDates[index]);
        const nbVisits = getMetricValues('nb_visits', periodData);
        const avgTimeSpent = getMetricValues('avg_time_spent', periodData);
        const nbPageviews = getMetricValues('nb_pageviews', periodData);
        const nbBottomReached = getMetricValues(
          'nb_bottom_reached',
          periodData
        );
        const penetrationRate = getMetricValues('penetration_rate', periodData);

        return {
          nb_visits: sum(nbVisits),
          avg_time_spent: sum(avgTimeSpent),
          nb_pageviews: sum(nbPageviews),
          nb_bottom_reached: arithmeticMean(nbBottomReached),
          penetration_rate: `${arithmeticMean(penetrationRate)}%`,
        };
      });

      return {
        nb_visits: res.map((i) => i.nb_visits),
        avg_time_spent: res.map((i) => i.avg_time_spent),
        nb_pageviews: res.map((i) => i.nb_pageviews),
        nb_bottom_reached: res.map((i) => i.nb_bottom_reached),
        penetration_rate: res.map((i) => i.penetration_rate),
      };
  }
};
