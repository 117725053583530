import styled from 'styled-components';

interface Block {
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledVideoWrapper = styled.div<Block>`
  grid-column-start: ${(props) => props.styles?.size?.column?.start};
  grid-column-end: ${(props) => props.styles?.size?.column?.end};
  grid-row-start: ${(props) => props.styles?.size?.row?.start};
  grid-row-end: ${(props) => props.styles?.size?.row?.end};
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-self: center;
`;

const StyledVideo = styled.a`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: ${({ theme }) =>
    `4px solid ${theme?.dashboard?.circleVideo?.borderColor || '#000000'}`};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #c0c5c6;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);

  @media (max-width: 1470px) {
    width: 130px;
    height: 130px;
  }

  .play {
    font-size: 45px;
    color: #ffffff;
  }
`;

export { StyledVideoWrapper, StyledVideo };
