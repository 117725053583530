import { notification } from 'antd';

type Error = {
  name: string;
  message: string;
};

const displayNotification = (
  type: string,
  description: string,
  message?: string,
  duration: number = 10,
) => {
  notification[type]({
    placement: 'top',
    message: message,
    description: description,
    duration: duration,
    className: 'login-error'
  });
};

export const errorAuthAlertManager = (
  err: Error | Error[]
) => {
  const error = err[0] || err;
  const message = error?.message;

  switch (true) {
    case message === 'Invalid login credentials. Please try again.':
    case message === 'Invalid credentials':
      displayNotification('error', 'Invalid Credentials. Please try again.', 'Error');
      break;
    case message === 'Failed to fetch':
      displayNotification('error', null,'Network Error');
      break;
    case message === 'Your account has been locked due to an excessive number of unsuccessful sign in attempts.':
      displayNotification('error', 'Your account has been locked for 5 minutes due to an excessive number of unsuccessful sign in attempts.', 'Error', 30);
      break;
    case message === 'You need to sign in or sign up before continuing.':
      displayNotification('error', 'You need to sign in or sign up before continuing.', 'Error');
      break;
    case message === 'login disabled':
      displayNotification('error', 'Login disabled', 'Error');
      break;
    case message === 'Unauthorized':
      displayNotification('error', 'Unauthorized', 'Error');
      break;
    case typeof message === 'string' && message?.includes('A confirmation email was sent to your account at'):
      displayNotification('info', message, 'Notification', 30);
      break;
    default:
      displayNotification('error', message, 'Error');
  }
};