import {
  ADD_ITEM_ACTION,
  ADD_ORGANIZATION_ACTION,
  ADD_PRODUCT_ACTION,
  ADD_TRACE_ACTION,
  ADD_UPLOAD_ACTION,
  ADD_USER_ACTION,
  EDIT_COMPANY_ACTION,
  EDIT_ITEM_ACTION,
  EDIT_OBJECT_ACTION,
  EDIT_PRODUCT_ACTION,
  EDIT_TRACE_ACTION,
  EDIT_UPLOAD_ACTION,
  EDIT_USER_ACTION,
  RESEND_ALL_SMS_CONFIRMATION_ACTION,
  RESEND_SMS_CONFIRMATION_ACTION,
  VALIDATE_DATA_ACTION,
  INVALIDATE_DATA_ACTION,
  VALIDATE_ALL_ACTION,
} from '../actions';
import { Mutations } from "../types";

const mutationGeneration = (action: string, mutations: Mutations) => {
  const {
    addMutation,
    editMutation,
    resendOneMutation,
    resendAllMutation,
    validateOneMutation,
    validateAllMutation,
  } = mutations;

  switch (action) {
    case ADD_ORGANIZATION_ACTION:
    case ADD_USER_ACTION:
    case ADD_ITEM_ACTION:
    case ADD_PRODUCT_ACTION:
    case ADD_TRACE_ACTION:
    case ADD_UPLOAD_ACTION:
      return addMutation;
    case EDIT_USER_ACTION:
    case EDIT_COMPANY_ACTION:
    case EDIT_OBJECT_ACTION:
    case EDIT_ITEM_ACTION:
    case EDIT_PRODUCT_ACTION:
    case EDIT_TRACE_ACTION:
    case EDIT_UPLOAD_ACTION:
      return editMutation;
    case RESEND_SMS_CONFIRMATION_ACTION:
      return resendOneMutation;
    case RESEND_ALL_SMS_CONFIRMATION_ACTION:
      return resendAllMutation;
    case VALIDATE_DATA_ACTION:
    case INVALIDATE_DATA_ACTION:
      return validateOneMutation;
    case VALIDATE_ALL_ACTION:
      return validateAllMutation;
  }
};

export default mutationGeneration;
