import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { CardStyles } from '../../types';

export const SIndicatorContainer = styled(SIndicator)<CardStyles>`
  color: #544d56;
  box-shadow: none !important;
  background: transparent;

  .ant-card-body {
    padding: 0 !important;
    display: ${(props) => props?.styles?.display};
    align-items: ${(props) => props?.styles?.alignItems};
  }
  .title {
    text-transform: uppercase;
    font-size: 30px;
    line-height: ${(props) => props?.styles?.title?.lineHeight || '1.2'};
    font-weight: 600;
    margin-bottom: ${(props) => props?.styles?.title?.marginBottom || '5px'};
    position: relative;
    text-align: ${(props) => props?.styles?.title?.textAlign};
    
    @media(max-width: 1400px) {
      font-size: 24px;
    }
  }
  .sub-title {
    font-style: italic;
    font-size: ${(props) => props?.styles?.subtitle?.fontSize || '18px'};
    text-transform: ${(props) => props?.styles?.subtitle?.textTransform};
    font-weight: 300;
    margin-bottom: 0;
    margin-left: ${(props) => props?.styles?.subtitle?.marginLeft};

    @media (max-width: 1440px) {
      font-size: 16px;
    }
  }
`;
