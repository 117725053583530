import React from 'react';
import { Tooltip } from 'antd';
import { getTooltipTitle } from '../../../../util';
import { EntityElementInterface } from '../../../../types';
import { SEntity } from '../../styled-components';

import active from 'images/cockpit/active.svg';
import onboarded from 'images/cockpit/onboarded.svg';

const EntityElement = ({
  src,
  type,
  total,
  onboarded_qty,
  active_qty,
  tracedVersion,
  traced_qty,
  traced_nontraced_qty,
}: EntityElementInterface) => (
  <SEntity tracedVersion={tracedVersion}>
    <div className={'total-container'}>
      <img src={src} className={'entity-icon'} alt="Entity icon" />
      {total && (
        <Tooltip
          id="indicatorTooltip"
          title={getTooltipTitle(type)['total']}
          color={'#ffffff'}
        >
          <p className={'total-number'}> {total} </p>
        </Tooltip>
      )}
    </div>
    <div className={'info-container'}>
      <p className={'title'}> {type} </p>
      <div className={'entities-numbers'}>
        <Tooltip
          id="indicatorTooltip"
          title={getTooltipTitle(type)[tracedVersion ? 'traced' : 'onboarded']}
          color={'#ffffff'}
        >
          <div className={`qty ${tracedVersion ? 'traced' : ''}`}>
            {!tracedVersion && <img src={onboarded} alt="Onboarded" />}
            {tracedVersion ? traced_qty : onboarded_qty}
          </div>
        </Tooltip>
        {tracedVersion && <span className={'devider'}>/</span>}
        <Tooltip
          id="indicatorTooltip"
          title={
            getTooltipTitle(type)[tracedVersion ? 'tracedTotal' : 'active']
          }
          color={'#ffffff'}
        >
          <div className={`qty ${tracedVersion ? 'traced total' : ''}`}>
            {!tracedVersion && <img src={active} alt="Active" />}
            {tracedVersion ? traced_nontraced_qty : active_qty}
          </div>
        </Tooltip>
      </div>
    </div>
  </SEntity>
);

export default EntityElement;
