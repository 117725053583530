import styled from 'styled-components';

const StyledTopBar = styled.div`
  background: ${props => props.theme.header.background};
  box-shadow: 0 0 4px 4px rgba(0,0,0,0.08);
  position: relative;
  z-index: 1;
  
  .topbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 0 30px;
    height: 83px;
  }

  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo-inner {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
  }
  
  .logo-icon {
    max-height: 75px;
  }

  .platform-type,
  .platform-by {
    color: ${props => props.theme.header.subText.color};
    font-size: ${props => props.theme.header.subText.size};
    font-family: ${props => props.theme.header.subText.family};
    font-weight: ${props => props.theme.header.subText.weight};
    opacity: ${props => props.theme.header.subText.opacity};
  }

  .platform-by {
    margin-right: 7px;
  }


  .platform-name {
    color: ${props => props.theme.header.crystal.color};
    font-size: ${props => props.theme.header.crystal.size};
    font-family: ${props => props.theme.header.crystal.family};
    font-weight: ${props => props.theme.header.crystal.weight};
  }

  .company-name {
    margin: auto;
    color: ${props => props.theme.header.business.color};
    font-size: ${props => props.theme.header.business.size};
    font-family: ${props => props.theme.header.business.family};
    font-weight: ${props => props.theme.header.business.weight};
  }

  .right-box-wrapper {
    display: flex;
    flex-direction: column;
  }

  .profile-wrapper {
    display: flex;
    color: ${props => props.theme.header.subText.color};
    font-size: ${props => props.theme.header.subText.size};
    font-family: ${props => props.theme.header.subText.family};
    font-weight: ${props => props.theme.header.subText.weight};
    justify-content: center;
    align-items: center;

  }

  .logout-btn {
    margin-left: 10px;
    color: ${props => props.theme.header.logout.passive};
    font-size: ${props => props.theme.header.logout.size};
    font-family: ${props => props.theme.header.logout.family};
    font-weight: ${props => props.theme.header.logout.weight};
    cursor: pointer;
    transition: all 0.3s cubic-bezier(.645,.045,.355,1);

    &:hover {
      color: ${props => props.theme.header.logout.active};
    }
  }
  .support-btn {
    padding: 0 !important;
    color: ${props => props.theme.header.logout.passive};
    font-size: ${props => props.theme.header.logout.size};
    font-family: ${props => props.theme.header.logout.family};
    font-weight: ${props => props.theme.header.logout.weight};
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.header.logout.active} !important;
    }
  }

  .date {
    color: ${props => props.theme.header.date.color};
    font-size: ${props => props.theme.header.date.size};
    font-family: ${props => props.theme.header.date.family};
    font-weight: ${props => props.theme.header.date.weight};
    text-align: right;
    opacity: ${props => props.theme.header.date.opacity};
  }
`;

export { StyledTopBar }
