import React, { useState } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { signIn, SIGNIN_REQUEST_SUCCEEDDED } from '../../../reducer';
import FormContent from '../FormContent';
import {
  validationSchema,
  validationSchemaWithPassword,
  initialValues,
} from '../../helpers/loginControl';
import { InitialValuesInterface } from '../../../types';

interface Props {
  /**
   * Authorization form handler function.
   */
  signIn: Function;
  setPickerData: Function;
  setDisplayBusinessPicker: Function;
}

const Form = ({ signIn, setPickerData, setDisplayBusinessPicker }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const { state }: any = useLocation();
  const [isSsoLogin, setIsSsoLogin] = useState<boolean>(true);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(
        values: InitialValuesInterface,
        actions: FormikHelpers<InitialValuesInterface>
      ): void => {
        signIn(values)
          .then((res): void => {
            switch (true) {
              case res?.hasOwnProperty('login_by_sso') && res?.login_by_sso:
                window.open(res.url, '_self');
                break;
              case res?.hasOwnProperty('login_by_sso') && !res?.login_by_sso:
                setIsSsoLogin(false);
                break;
              case res?.hasOwnProperty('business_list'):
                setDisplayBusinessPicker(true);
                setPickerData(res?.business_list);
                break;
              case res?.forceReset:
                navigate('/reset_password');
                break;
              case res?.type === SIGNIN_REQUEST_SUCCEEDDED:
                navigate(state?.path || '/');
                break;
              default:
                break;
            }
            actions.setSubmitting(false);
          })
          .catch((e): void => {
            console.log('Error:', e);
          });
        actions.resetForm({
          values: {
            email: values.email,
            password: '',
          },
        });
      }}
      validationSchema={
        isSsoLogin ? validationSchema : validationSchemaWithPassword
      }
    >
      {(props: FormikProps<InitialValuesInterface>) => {
        return <FormContent {...props} isSsoLogin={isSsoLogin} />;
      }}
    </Formik>
  );
};

const mapDispatchToProps = {
  signIn: signIn,
};
export default connect(null, mapDispatchToProps)(Form);
