import React from 'react';
import { Input } from 'antd';
import { Formik, FormikProps, Form as FForm, Field } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { confirmPassword, resetForgotPassword } from '../../reducer';
import { validationSchema } from '../resetControl';
import { SButton, SFormItem } from '../../styled-components';

interface InitialValuesInterface {
  password: string;
  password_confirmation: string;
}

interface ResetFormInterface {
  confirmPassword: any;
  resetForgotPassword: any;
}

const initialValues = {
  password: '',
  password_confirmation: '',
};

const ResetForm = ({
  confirmPassword,
  resetForgotPassword,
}: ResetFormInterface) => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const token = searchParams.get('token');

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions, { resetForm = () => null }: any = {}) => {
        if (token && email) {
          resetForgotPassword(
            { ...values, reset_password_token: token, email },
            actions
          );
          resetForm(values);
        } else {
          confirmPassword(values, actions);
          resetForm(values);
        }
      }}
      validationSchema={validationSchema}
    >
      {(props: FormikProps<InitialValuesInterface>) => {
        return (
          <FForm>
            <Field name={'password'}>
              {({
                field, // { name, value, onChange, onBlur }
                form: { touched, errors, status, handleChange, handleBlur },
              }) => {
                return (
                  <SFormItem
                    label="Password"
                    help={
                      touched.password && errors.password ? errors.password : ''
                    }
                    validateStatus={
                      touched.password && errors.password ? 'error' : undefined
                    }
                  >
                    <Input.Password
                      autoComplete="on"
                      {...field}
                      className={status && status['password'] ? 'has-error' : ''}
                    />
                    <span className={status && status['password'] ? 'form-error' : ''}>
                      {status && status['password'] ? status['password'] : ''}
                    </span>
                  </SFormItem>
                );
              }}
            </Field>
            <Field name={'password_confirmation'}>
              {({
                field, // { name, value, onChange, onBlur }
                form: { touched, errors, handleChange, handleBlur },
              }) => {
                return (
                  <SFormItem
                    label="Password confirmation"
                    help={
                      touched.password_confirmation &&
                      errors.password_confirmation
                        ? errors.password_confirmation
                        : ''
                    }
                    validateStatus={
                      touched.password_confirmation &&
                      errors.password_confirmation
                        ? 'error'
                        : undefined
                    }
                  >
                    <Input.Password autoComplete="off" {...field} />
                  </SFormItem>
                );
              }}
            </Field>
            <SButton htmlType="submit">
              Confirm
            </SButton>
          </FForm>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = {
  confirmPassword: confirmPassword,
  resetForgotPassword: resetForgotPassword,
};

export default connect(null, mapDispatchToProps)(ResetForm);
