import { colors } from 'App/styleVariables';
import { BadgeType } from './badge';

export const getBadgeColor = (type: BadgeType) => {
  switch (type) {
    case 'success':
      return {
        background: colors.success10,
        text: colors.success,
      };
    case 'rejected':
    case 'error':
      return {
        background: colors.error10,
        text: colors.error,
      };
    case 'queued':
      return {
        background: colors.secondary10,
        text: colors.secondary,
      };
    case 'checking':
      return {
        background: colors.checking10,
        text: colors.checking,
      };
    case 'pending':
      return {
        background: colors.pending10,
        text: colors.pending,
      };
    case 'in_progress':
      return {
        background: colors.inProgress10,
        text: colors.inProgress,
      };
    case 'interrupted':
      return {
        background: colors.interrupted10,
        text: colors.interrupted,
      };
    case 'minimal':
      return {
        background: colors.success,
        text: 'white',
      };
    case 'informative':
      return {
        background: colors.informative,
        text: 'white',
      };
    case 'important':
      return {
        background: colors.important,
        text: 'white',
      };
    case 'significant':
      return {
        background: colors.significant,
        text: 'white',
      };
    case 'critical':
      return {
        background: colors.critical,
        text: 'white',
      };
    default:
      return {
        background: colors.secondary10,
        text: colors.secondary,
      };
  }
};