import { FC } from 'react';
import { Button, Flex, Descriptions, Divider } from 'antd';
import { closeGenericModalAction } from 'containers/GenericModal/reducer';
import { SWrapper } from './styled-components';
import { contactSupplierModal } from 'components/Modal/constants';
import { store } from 'store/nextStore';

const ConformityForm: FC<any> = ({ data }) => {
  const { contacts } = data;

  return (
    <SWrapper>
      {contacts?.map((contact, index) => (
        <>
          <Descriptions
            key={`contact-${index}`}
            column={1}
            title={`Contact ${index + 1}`}
            className="contact-description"
          >
            <Descriptions.Item label="First Name">
              {contact.first_name}
            </Descriptions.Item>
            <Descriptions.Item label="Last Name">
              {contact.last_name}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              <a href={`mailto:${contact.email}}`} className="email-link">
                {contact.email}
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="Phone Number">
              {contact.phone}
            </Descriptions.Item>
          </Descriptions>
          {index + 1 !== contacts.length && <Divider />}
        </>
      ))}

      <Flex justify="flex-end">
        <Button
          onClick={() =>
            store.dispatch(closeGenericModalAction({ name: contactSupplierModal }))
          }
          className="cancel-button"
        >
          Cancel
        </Button>
      </Flex>
    </SWrapper>
  );
};

export default ConformityForm;