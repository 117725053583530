import React from 'react';
import { connect } from 'react-redux';
import CustomModal from '../../../../../components/Modal';
import MudForm from '../MudForm';
import { closeModalAction } from './reducer';
import ShowTraceList from '../../../../../components/ShowTraceList';

const actions = { edit: 'Editer', show: 'Voir' };

class MudModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  handleOnClose = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  render() {
    const {
      edit,
      isModalOpen,
      formType,
      data,
      show,
      displayTraceType,
    } = this.props;

    let title;
    if (edit) title = `${actions.edit} : ${formType}`;
    if (show) title = `${actions.show}: ${displayTraceType}`;
    else title = null;

    const MudModalRef = React.forwardRef((props, ref) => (
      <CustomModal
        title={title}
        closeModal={this.handleOnClose}
        modalVisible={isModalOpen}
        width={1100}
      >
        {isModalOpen ? (
          show ? (
            <ShowTraceList data={data} />
          ) : (
            <MudForm {...this.props} />
          )
        ) : null}
      </CustomModal>
    ));

    return <MudModalRef ref={this.modalRef} />;
  }
}

const ConnectedModal = connect(
  ({ mudModal }) => ({
    isModalOpen: mudModal.isModalOpen,
    edit: mudModal.edit,
    formType: mudModal.formType,
    show: mudModal.show,
    data: mudModal.data,
    displayTraceType: mudModal.displayTraceType,
  }),
  {
    closeModal: closeModalAction,
  }
)(MudModal);

export default ConnectedModal;