import React from 'react';
import PropTypes from 'prop-types';
import { getKindsList } from '../../actions';
import Selection from './components/Selection';
import form_presenter from '../../../../lib/presenters/FormPresenter';

class FormSelection extends React.PureComponent {
  state = { kinds: '', formType: null, list_type: null };
  sorts = { radio: false, select: true };

  async componentDidMount() {
    const { type, filter_params } = this.props;
    try {
      const { data, list_type } = await getKindsList({ type, filter_params });
      const is_sorted = this.sorts[list_type];
      const { formType, kinds } = form_presenter.present_data(
        data,
        'selection',
        is_sorted
      );

      this.setState({ kinds, formType, list_type: list_type || 'select' });
    } catch (e) {
      console.log(e);
    }
  }

  handleChange = (e) => {
    if (e.target) this.setState({ formType: e.target.value });
    else this.setState({ formType: e });
  };

  handleSubmit = (e) => {
    const { onFormTypeSelect, user } = this.props;
    const { formType, kinds } = this.state;

    onFormTypeSelect({
      formStep: 'formFilling',
      formType,
      kinds,
      user,
    });

    e.preventDefault();
  };

  render() {
    const { styles, selectLabel, view } = this.props;
    const { kinds, formType, list_type } = this.state;

    return (
      <Selection
        kinds={kinds}
        formType={formType}
        styles={styles}
        view={view}
        list_type={list_type}
        selectLabel={selectLabel}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

FormSelection.propTypes = {
  type: PropTypes.string,
  filter_params: PropTypes.object,
  onFormTypeSelect: PropTypes.func,
  user: PropTypes.object,
  styles: PropTypes.object,
  view: PropTypes.object,
  selectLabel: PropTypes.string,
};

export default React.createFactory(FormSelection);
