import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataTable from '../../../../components/DataTable';
import {
  get_data_action,
  get_current_action,
  set_filter_action,
  set_initial_state_action,
} from './reducer';
import { openModalAction } from '../DetailsModal/reducer';

const modal_actions = { edit: true, show: true };

class TraceDetails extends React.Component {
  componentDidMount() {
    const { get_data, parentTraceId } = this.props;
    get_data({ traceId: parentTraceId });
  }

  componentWillUnmount() {
    const { set_initial_state } = this.props;
    set_initial_state();
  }

  handleTableChange = (pagination, filters, sorter, current_sort) => {
    const { set_filter } = this.props;
    const { currentDataSource } = current_sort;
    set_filter(currentDataSource);
  };

  handleClickOnTableRowAction = async ({ record, action }) => {
    const { openModal, get_current, parentTraceId } = this.props;
    const { traceId, traceType: formType } = record;
    const trace = await get_current({ traceId });

    if (modal_actions[action] && trace)
      openModal({
        action: [action],
        data: JSON.parse(trace.data),
        parentTraceId,
        formType,
        traceId,
      });
  };

  render() {
    const { data, table_scheme, user, loading } = this.props;
    return (
      <DataTable
        isSearchDisabled
        prefixId="ChainDetailsTable"
        user={user}
        loading={loading}
        getRowKey={(user) => user.email}
        data={data}
        columnsSchema={table_scheme}
        handleClickOnTableRowAction={this.handleClickOnTableRowAction}
        handleTableChange={this.handleTableChange}
      />
    );
  }
}

TraceDetails.propTypes = {
  get_data: PropTypes.func,
  parentTraceId: PropTypes.string,
  set_initial_state: PropTypes.func,
  set_filter: PropTypes.func,
  openModal: PropTypes.func,
  get_current: PropTypes.func,
  data: PropTypes.any,
  table_scheme: PropTypes.object,
  user: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = ({
  detailsTable: { data, table_scheme, loading },
  auth: { currentUser },
}) => ({
  user: currentUser,
  data,
  table_scheme,
  loading,
});

const mapDispatchToProps = {
  get_data: get_data_action,
  get_current: get_current_action,
  set_filter: set_filter_action,
  set_initial_state: set_initial_state_action,
  openModal: openModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TraceDetails);
