import styled from 'styled-components';
import { Card } from 'antd';
import { CardStyles } from './types';
import { font } from '../../App/styleVariables';

const SContainer = styled.div`
  padding: 32px;
  box-sizing: border-box;
  font-family: ${({ theme }) =>
    `${theme?.dashboard?.fontFamily}, sans-serif` ||
    font};

  .section {
    margin-bottom: 20px;
  }
  .section-header {
    margin-bottom: 12px;
    color: #544d56;
  }
  .title {
    text-transform: uppercase;
    font-size: 30px;
    line-height: 1.2;
    font-weight: 600;
  }
  .sub-title {
    font-style: italic;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 0;

    @media (max-width: 1440px) {
      font-size: 16px;
    }
  }
  .error-container {
    font-weight: 600;
    text-align: center;
    font-size: 20px;
  }
`;

const SWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(auto, auto);
  grid-gap: 15px 20px;
  align-items: start;

  p {
    margin: 0;
    line-height: 1.2;
  }
  & > div.ant-card {
    font-family: inherit;
    box-shadow: 0 3px 4px 3px rgba(0, 0, 0, 0.08);
    width: auto;
    align-self: stretch;

    .ant-card-body {
      padding: 16px;
    }
  }
`;

const SIndicator = styled(Card)<CardStyles>`
  grid-column-start: ${(props) => props?.styles?.size?.column?.start};
  grid-column-end: ${(props) => props?.styles?.size?.column?.end};
  grid-row-start: ${(props) => props?.styles?.size?.row?.start};
  grid-row-end: ${(props) => props?.styles?.size?.row?.end};
  color: #101828;

  .indicator-title {
    font-family: inherit;
    font-size: 20px;
    font-weight: 500;

    @media (max-width: 1440px) {
      font-size: 18px;
      max-width: 100%;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
    }
  }

  .traces-info {
    color: #808080;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    align-items: center;
    line-height: 1;

    @media (max-width: 1600px) {
      font-size: 11px;
    }

    span {
      color: #000000;
      font-weight: 600;
      font-size: 16px;
      margin-right: 4px;
      cursor: pointer;

      @media (max-width: 1600px) {
        margin-right: 2px;
        font-size: 14px;
      }
    }
  }

  .header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: ${(props) => props?.styles?.header?.marginBottom || '24px'};


    @media (max-width: 1440px) {
      margin-bottom: 8px;
    }

    .indicator-icon {
      display: block;
      margin-right: 15px;
      max-width: 80px;
      max-height: 80px;

      @media (max-width: 1600px) {
        max-width: 70px;
      }
      @media (max-width: 1440px) {
        max-width: 60px;
        margin-right: 5px;
      }
      @media (max-width: 1280px) {
        max-width: 50px;
      }
    }
    .indicator-title {
      font-family: inherit;
      font-size: 20px;
      font-weight: 500;

      @media (max-width: 1920px) {
        max-width: 60%;
      }
      @media (max-width: 1700px) {
        max-width: 100%;
      }
      @media (max-width: 1600px) {
        font-size: 18px;
      }
      @media (max-width: 1280px) {
        font-size: 16px;
      }
    }
  }
`;

export { SContainer, SWrapper, SIndicator };
