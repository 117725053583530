import styled from 'styled-components';

export const SQuestionMark = styled.img`
  position: absolute;
  width: 16px;
  height: 16px;
  top: -6px;
  right: -16px;
  cursor: pointer;
`;
