import styled from 'styled-components';
import { Button, Radio, Spin } from 'antd';
import { font } from '../../App/styleVariables';

export const FormContent = styled(Radio.Group)`
  width: 100%;
  font-family: ${font};
`;

export const SButton = styled(Button)`
  min-width: 148px;
  padding: 0 42px !important;
  height: 40px;
  font-size: ${(props) => props.theme.form.submitButton.size} !important;
  font-weight: 600 !important;
  font-family: ${(props) => props.theme.form.submitButton.family} !important;
  color: ${(props) => props.theme.form.submitButton.color} !important;
  background: ${(props) => props.theme.form.submitButton.passive} !important;
  border-color: ${(props) => props.theme.form.submitButton.passive} !important;
  margin-top: 16px;
  align-self: center;

  &:hover {
    color: ${(props) => props.theme.form.submitButton.color} !important;
    background: ${(props) => props.theme.form.submitButton.active} !important;
    border-color: ${(props) => props.theme.form.submitButton.active} !important;
  }
`;

export const PickerSpin = styled(Spin)`
  max-height: initial !important;
`;
