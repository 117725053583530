import React from 'react';
import PropTypes from 'prop-types';
import fileExtension from 'file-extension';
import { Popover } from 'antd';
import mapper from './mapper';
import { openProtectedLink } from 'lib/util';
import './style.css';

const HeaderTextPair = ({ header, text, businessId }) => {
  let article = null;

  switch (header) {
    case 'file_url_DELETE_THIS_WORDS_TO_USE':
      article = (
        <article className={'header-text-pair-text'}>
          <a href={text} target={'_blank'} rel="noopener noreferrer">
            {text}
          </a>
        </article>
      );
      const extension = fileExtension(text);
      if (['jpg', 'jpeg', 'png', 'gif'].indexOf(extension) !== -1) {
        article = (
          <Popover
            content={
              <img
                style={{ maxWidth: '50vw', maxHeight: '80vh' }}
                src={text}
                alt="Preview"
              />
            }
            trigger="click"
            placement="left"
          >
            {article}
          </Popover>
        );
      }
      break;
    case 'file_url':
      article = (
        <a
          target={'_blank'}
          rel="noreferrer"
          style={{ wordBreak: 'break-all' }}
          onClick={(e) => {
            e.preventDefault();
            openProtectedLink(text, businessId);
          }}
          href={text}
        >
          {text}
        </a>
      );
      break;
    case 'file_url_href':
      break;
    default:
      article = text ? (
        <article className={'header-text-pair-text'}>{text}</article>
      ) : null;
  }

  if (!mapper[header]) {
    return null;
  }

  return header === 'protected' && !text ? null : (
    <section className={`header-text-pair`}>
      <header className={`header-text-pair-header`}>{mapper[header]}</header>
      {article}
    </section>
  );
};

HeaderTextPair.propTypes = {
  header: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  businessId: PropTypes.number,
};

export default HeaderTextPair;
