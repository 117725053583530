import React, { useEffect } from 'react';
import { Spin } from 'antd';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5map from '@amcharts/amcharts5/map';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import { StyledMapWrapper, SLegendItem } from './styled-components';

const Map = ({
  mapData = {
    data: [],
    arrows: [],
  },
  mapCenter = [2.0517, 48.2192649],
  defaultZoom = 28,
  markerRadius = 5,
  markerStrokeWidth = 2,
  isLoading,
  title,
  styles,
  tooltipStructure,
}: any) => {
  useEffect(() => {
    let root = am5.Root.new('mapdiv');
    root.setThemes([am5themes_Animated.new(root)]);

    // Create the map chart
    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: 'translateX',
        panY: 'translateY',
        projection: am5map.geoMercator(),
        maxZoomLevel: 300,
      })
    );
    chart.children.push(
      am5.Container.new(root, {
        layout: root.horizontalLayout,
        x: 20,
        y: 40,
      })
    );

    // Create main polygon series for countries
    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
      })
    );

    // Create line series for trajectory lines
    let lineSeries = chart.series.push(am5map.MapLineSeries.new(root, {}));
    lineSeries.mapLines.template.setAll({
      stroke: root.interfaceColors.get('alternativeBackground'),
      strokeOpacity: 0.6,
    });

    // Arrow series
    let arrowSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));
    arrowSeries.bullets.push(function () {
      let arrow = am5.Graphics.new(root, {
        fill: am5.color(0x000000),
        stroke: am5.color(0x000000),
        draw: function (display) {
          display.moveTo(0, -3);
          display.lineTo(8, 0);
          display.lineTo(0, 3);
          display.lineTo(0, -3);
        },
      });

      return am5.Bullet.new(root, {
        sprite: arrow,
      });
    });

    // Markers series
    mapData?.data.forEach((item, index) => {
      Object.keys(item).forEach((type) => {
        let entitySeries = chart.series.push(
          am5map.MapPointSeries.new(root, {})
        );
        entitySeries.bullets.push(function () {
          let circle = am5.Circle.new(root, {
            radius: markerRadius,
            tooltipText: mapData?.tooltips[type],
            tooltipY: 0,
            fill: am5.color(mapData?.colors[type]),
            stroke: root.interfaceColors.get('background'),
            strokeWidth: markerStrokeWidth,
          });

          return am5.Bullet.new(root, {
            sprite: circle,
          });
        });

        entitySeries.data.setAll(mapData?.data[index][type]);
      });
    });

    // Destination series
    am5.array.each(
      mapData?.arrows,
      function (did: { origin: []; destinations: [] }) {
        let lineDataItem = lineSeries.pushDataItem({
          geometry: {
            type: 'LineString',
            coordinates: [did?.origin, did?.destinations],
          },
        });
        arrowSeries.pushDataItem({
          lineDataItem: lineDataItem,
          positionOnLine: 0.5,
          autoRotate: true,
        });
      }
    );

    // Add zoom control
    chart.set('zoomControl', am5map.ZoomControl.new(root, {}));

    const centerCoordinates =
      mapData?.arrows.length > 0
        ? {
            longitude: mapData?.arrows[0].origin[0],
            latitude: mapData?.arrows[0].origin[1],
          }
        : {
            longitude: mapCenter[0],
            latitude: mapCenter[1],
          };

    polygonSeries.events.on('datavalidated', function () {
      chart.zoomToGeoPoint(centerCoordinates, defaultZoom);
    });

    // Make stuff animate on load
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapData]);

  const legend =
    mapData?.colors &&
    Object.entries(mapData?.colors).map(([type, color]: [string, string]) => {
      return (
        <SLegendItem color={color} key={type} styles={styles?.legend}>
          {type}
        </SLegendItem>
      );
    });

  return (
    <StyledMapWrapper styles={styles}>
      {title && <p className={'title'}> {title} </p>}
      <div className={'map-wrapper'}>
        <div className={'map-container'}>
          <div id="mapdiv"></div>
          {isLoading && (
            <div className={'loader'}>
              <Spin size={'large'} />
            </div>
          )}
        </div>
        <div className={'legend-container'}>{legend}</div>
      </div>
    </StyledMapWrapper>
  );
};

export default Map;
