import client from 'lib/apiClient';
import { updateData } from '../../../../containers/SearchData/reducer';
import errorHandler from '../../../../lib/errorHandler';

const moduleName = "CUSTOM_LIST_TABLE";
const CHANGE_LOADING_STATUS = `${moduleName}::CHANGE_LOADING_STATUS`;
const GET_CUSTOM_LIST_ITEMS_SUCCESS = `${moduleName}::GET_CUSTOM_LIST_ITEMS_SUCCESS`;
const SET_INITIAL_STATE = `${moduleName}::SET_INITIAL_STATE`;

export const initial_state = {
  data: [],
  table_scheme: [],
  loading: false
};

export const get_custom_list_items = ({ list_name }) => async dispatch => {
  try {
    dispatch({ type: CHANGE_LOADING_STATUS });

    const [{ table_scheme, data }] = await Promise.all([
      client.getCustomLists({ list_name })()
    ]);

    dispatch({
      type: GET_CUSTOM_LIST_ITEMS_SUCCESS,
      payload: {
        data,
        table_scheme,
        list_name
      }
    });

    dispatch(updateData({ data, table: 'CustomListTable'}));

  } catch (e) {
    console.log(e);
    // dispatch(actionSetCommonNetworkFailure({ error: e }));
    errorHandler(e);
  }
};

const handle_loading = state => ({ ...state, loading: true });

export const set_initial_state_action = () => ({ type: SET_INITIAL_STATE });

const handle_custom_lists_items_success = (state, payload) => ({
  ...payload,
  loading: false
});

const handle_initial_state = () => initial_state;

export const handlers = {
  [GET_CUSTOM_LIST_ITEMS_SUCCESS]: handle_custom_lists_items_success,
  [SET_INITIAL_STATE]: handle_initial_state,
  [CHANGE_LOADING_STATUS]: handle_loading
};

export default (state = initial_state, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action.payload) : state;
};
