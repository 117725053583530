import React from 'react';
import { DatePicker as ADatePicker } from 'antd';

const DatePicker = ({
  dateFormat = 'DD-MM-YYYY',
  placeholder,
  disabled,
}: {
  dateFormat: string;
  placeholder?: string;
  disabled?: boolean;
}) => {
  return (
    <ADatePicker
      format={dateFormat}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default DatePicker;
