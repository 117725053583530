import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { getChainDetailsAction } from './actions';
import * as chainModes from './Modes';
import { SResult, SWrapper } from './styled-components';

class ChainDetails extends Component {
  state = { data: null, isLoading: false, isError: false };

  async componentDidMount() {
    const { traceId, traceType, name } = this.props;
    try {
      this.setState({ isLoading: true });
      const dataRaw = await getChainDetailsAction({ traceId, traceType, name });
      const graph_scheme = dataRaw.graph_scheme ? dataRaw.graph_scheme : [];
      const data = {
        ...dataRaw.data,
        table_scheme: dataRaw.table_scheme,
        graph_scheme,
      };
      this.setState({ data, isLoading: false });
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false, isError: true });
    }
  }

  render() {
    const { mode, traceId } = this.props;
    const { data, isLoading, isError } = this.state;

    return (
      <SWrapper>
        <Spin
          size="large"
          spinning={isLoading}
          tip="Fetching chain data. It can take some time."
        >
          <div className="chains-wrapper">
            {!!data && chainModes[mode]({ data, traceId })}
            {isError && (
              <SResult
                icon={<CloseCircleOutlined />}
                title="Something went wrong."
                subTitle="Please, try again later."
              />
            )}
          </div>
        </Spin>
      </SWrapper>
    );
  }
}

ChainDetails.propTypes = {
  traceId: PropTypes.string,
  traceType: PropTypes.string,
  mode: PropTypes.string,
};

export default React.createFactory(ChainDetails);
