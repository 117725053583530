import React from 'react';
import PropTypes from 'prop-types';
import { Form, Spin, Select, Radio } from 'antd';
import { StyledButton } from '../../../formFilling/style';
import { SSelect, SRadio, SForm } from './style';
import UniqIdsGenerator from '../../../../../../lib/uniqIdsGenerator';

const id_generator = new UniqIdsGenerator('form-selection');

const SelectList = ({ kinds, formType, handleChange }) => (
  <SSelect
    showSearch
    optionFilterProp="children"
    onChange={handleChange}
    defaultValue={formType}
    onSearch={() => true}
    id={'form-selection-select-list'}
  >
    {Array.from(kinds.entries()).map(([type_number, value], i) => (
      <Select.Option
        id={id_generator.get_id('btn-option', i)}
        key={type_number}
        value={type_number}
        data-name={type_number}
      >
        {value}
      </Select.Option>
    ))}
  </SSelect>
);

const RadioList = ({ kinds, formType, styles, view, handleChange, handleSubmit }) => (
  <div id={'form-selection-radio-list'} onDoubleClick={handleSubmit}>
    <Radio.Group onChange={handleChange} value={formType} style={{ width: '100%' }}>
      {Array.from(kinds.entries()).map(([type_number, value], i) => (
        <SRadio
          id={id_generator.get_id('btn-radio', i)}
          key={type_number}
          value={type_number}
          col={view?.formSelection}
        >
          {value}
        </SRadio>
      ))}
    </Radio.Group>
  </div>
);

const list_types = {
  select: SelectList,
  radio: RadioList,
};

const Selection = ({
  kinds,
  styles,
  formType,
  selectLabel,
  list_type,
  handleSubmit,
  handleChange,
  view
}) =>
  kinds ? (
    <SForm onSubmit={handleSubmit}>
      <Form.Item
        label={selectLabel || 'Select Form Type:'}
        style={{ marginBottom: '10px' }}
      />

      <Form.Item>
        {list_types[list_type]({
          kinds,
          formType,
          styles,
          handleChange,
          handleSubmit,
          view,
        })}
      </Form.Item>

      <Form.Item>
        <StyledButton
          id={id_generator.get_id('btn-next')}
          type="primary"
          htmlType="submit"
        >
          Next
        </StyledButton>
      </Form.Item>
    </SForm>
  ) : (
    <Spin style={{ display: 'block' }} size="large" />
  );

SelectList.propTypes = {
  kinds: PropTypes.object,
  formType: PropTypes.string,
  handleChange: PropTypes.func,
};

RadioList.propTypes = {
  kinds: PropTypes.object,
  formType: PropTypes.string,
  styles: PropTypes.object,
  view: PropTypes.object,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

Selection.propTypes = {
  kinds: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  formType: PropTypes.string,
  styles: PropTypes.object,
  view: PropTypes.object,
  list_type: PropTypes.string,
  selectLabel: PropTypes.string,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default Selection;
