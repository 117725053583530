import React from 'react';
import { DatePicker } from 'antd';

const { RangePicker: ARangePicker } = DatePicker;

const RangePicker = ({
  dateFormat = 'DD-MM-YYYY',
  placeholder,
  disabled,
}: {
  dateFormat: string;
  placeholder?: [string, string];
  disabled?: boolean;
}) => {
  return (
    <ARangePicker
      format={dateFormat}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default RangePicker;
