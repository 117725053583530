import React from 'react';
import { Spin } from 'antd';

const def_styles = { display: 'block', margin: 'auto' };

const Spinner = ({ s = def_styles, size = 'small' }) => (
  <Spin style={s} size={size} />
);

export default Spinner;
