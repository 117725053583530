import styled from 'styled-components';

interface Block {
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
    };
  };
}

const StyledTableWrapper = styled.div<Block>`
  height: 100%;
  width: 100%;
  padding: 35px;
  @media (max-width: 1440px) {
    padding: 25px 15px;
  }

  .ant-table {
    box-shadow: rgb(0 0 0 / 10%) 0 0 10px;
   }
`;

export default {
  StyledTableWrapper
};
