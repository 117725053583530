import reducer from 'sections/CustomList/containers/CustomListItemForm/reducer';
import { removeGaps } from 'lib/util';
import client from 'lib/apiClient';
import errorHandler from '../../../../lib/errorHandler';

export const handleSubmitAction = ({
  data,
  actions,
  isEdit,
  onSuccess,
  list_item_id,
}) => async (dispatch, getState) => {
  delete data?.undefined;
  data = removeGaps({ ...data });
  try {
    if (isEdit) {
      await client.putDocuments({ id: list_item_id })(data);
      onSuccess();
    } else {
      if (Array.isArray(data?.file)) {
        await Promise.all(
          data?.file.map(
            async (f) => await client.postDocuments({ ...data, file: f })
          )
        );
        onSuccess();
        return;
      }
      await client.postDocuments(data);
      onSuccess();
    }
  } catch (e) {
    actions.setStatus({
      [e.name]: e.message,
    });
    errorHandler(e);
  }
};

export default reducer;
