import AnalysisResourceFactory from './analysis-resource-factory';
import client from '../../../../../../lib/apiClient';

import { Table } from '../../../../types';

class SoilAnalysisResourceFactory extends AnalysisResourceFactory {
  async getAll(): Promise<Table> {
    const { data, table_scheme } = await client.getSoil();

    return {
      data,
      schema: table_scheme,
      total: data.length,
    };
  }
}

export default SoilAnalysisResourceFactory;