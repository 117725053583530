import client from '../../lib/apiClient';
import { errorAuthAlertManager } from '../../lib/auth/errorAuthAlertManager';
import { actionGoTo } from '../SideBar/reducer';
import { Dispatch } from 'redux';
import { message } from 'antd';

export const confirmPassword =
  (
    values: { password: string; password_confirmation: string },
    actions: { setStatus: Function }
  ) =>
  async (dispatch: Dispatch) => {
    try {
      await client.resetPassword(values);
      sessionStorage.clear();
      dispatch(actionGoTo('/sign_in'));
    } catch (e) {
      if (e[0]?.name === 'password') {
        actions.setStatus({
          [e[0].name]: e[0].message,
        });
      } else {
        console.log(e);
        errorAuthAlertManager(e);
      }
    }
  };

export const resetForgotPassword =
  (
    values: {
      password: string;
      password_confirmation: string;
      email: string;
      token: string;
    },
    actions: { setStatus: Function }
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await client.resetForgotPassword(values);
      sessionStorage.clear();
      message.success(`${res?.message}`);
      dispatch(actionGoTo('/sign_in'));
    } catch (e) {
      if (e[0]?.name === 'password') {
        actions.setStatus({
          [e[0].name]: e[0].message,
        });
      } else {
        console.log('err', e);
        errorAuthAlertManager(e);
      }
    }
  };
