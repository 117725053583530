import styled from 'styled-components';
import { colors, font } from 'App/styleVariables';

export const SWrapper = styled.div`
  margin-top: 20px;
  font-family: ${font};
  .contact-description {
    margin-bottom: 15px;
    font-family: ${font};
    .ant-descriptions-header {
      margin-bottom: 10px;
    }
    .ant-descriptions-item {
      padding-bottom: 8px;
    }
    .ant-descriptions-item-label {
      font-weight: 500;
      color: #101010;
    }
    .email-link {
      color: #101010;
      text-decoration: underline;
    }
  }
  .cancel-button {
    font-size: ${(props) => props.theme.form.backButton.size} !important;
    font-weight: ${(props) => props.theme.form.backButton.weight} !important;
    font-family: ${(props) => props.theme.form.backButton.family} !important;
    color: ${({ theme }) => theme.form.backButton.colorPassive} !important;
    border: ${({ theme }) => theme.form.backButton.borderPassive} !important;
    &:hover {
      color: ${({ theme }) =>
        theme.form.backButton.colorActive || colors.secondary} !important;
      border: ${({ theme }) =>
        theme.form.backButton.borderActive ||
        `1px solid ${colors.secondary}`} !important;
    }
  }
`;