import React, { useRef, useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import { Wrapper } from './style';

const { RangePicker } = DatePicker;

type Props = {
  setSelectedKeys: Function;
  confirm: Function;
  clearFilters: Function;
  name: string;
};

const Range = (props: Props) => {
  const [data, setData] = useState(null);
  const pickerRef = useRef(null);
  const { setSelectedKeys, confirm, clearFilters, name } = props;

  useEffect(() => {
    if (pickerRef.current) {
      pickerRef.current.focus();
    }
  }, [pickerRef]);

  return (
    <Wrapper>
      <RangePicker
        name={name}
        size="large"
        value={data}
        onChange={(data) => {
          if (!data) {
            clearFilters();
            setData(null);
            confirm();
            return;
          }

          setData(data);
          setSelectedKeys([{ filter: 'FRangePicker', data }]);
          confirm();
        }}
        ref={pickerRef}
      />
    </Wrapper>
  );
};

export default (props: Props) => <Range {...props} />;
