import React from 'react';
import { Progress } from 'antd';
import StyledProgressWrapper from './styled-component';

type ProgressData = {
  name: string;
  value: number;
  percent: number;
};
interface Props {
  key: number;
  data: ProgressData[];
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const ProgressCircles = ({ key, styles, data }: Props) => {
  return data && data.length !== 0 ? (
    <StyledProgressWrapper key={key} styles={styles}>
      {Array.isArray(data)
        ? data.map((item, index) => {
            const { value, name, percent } = item;
            return (
              <div className={'progress-item'} key={index}>
                <p className={'progress-name'}>{name}</p>
                <Progress
                  type="circle"
                  percent={percent}
                  format={() => `${value}`}
                  // width={90}
                  strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068',
                  }}
                  trailColor={'#e6e6e6'}
                />
              </div>
            );
          })
        : null}
    </StyledProgressWrapper>
  ) : null;
};

export default ProgressCircles;
