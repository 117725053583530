const moduleName = 'SEARCH';

const SEARCH_DATA_TABLE = `${moduleName}::GET_CHAINS_SUCCESS`;
const RESET_SEARCH = `${moduleName}::RESET_SEARCH`;
const UPDATE_SEARCH_DATA_TABLE = `${moduleName}::UPDATE_SEARCH_SUCCESS`;

const initial_state = {
  searchResult: [],
  isSearchActive: false,
  queryValue: '',
  table: '',
};

function search(query, records) {
  if (!query || !records) {
    return [];
  }

  const stringRegex = query.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

  const searchResult = records
    .map((record) => {
      const reg = new RegExp(stringRegex, 'gi');

      const results = Object.keys(record).filter(
        (k) =>
          k !== 'data' && record[k] && record[k].match && record[k].match(reg)
      );
      if (record.company) {
        const inner = record.company;
        const companyResults = Object.keys(inner).filter(
          (k) =>
            k !== 'data' && inner[k] && inner[k].match && inner[k].match(reg)
        );
        if (companyResults.length) {
          return record;
        }
      }
      return results.length ? record : null;
    })
    .filter((r) => r);
  return searchResult;
}

export const onSearchAction = (query, getData, table) => (dispatch, getProps) =>
  dispatch({
    type: SEARCH_DATA_TABLE,
    payload: { query, data: getData(getProps()), table },
  });

export const updateData = ({data, table}) =>  (dispatch, getState) =>
  dispatch ({
    type: UPDATE_SEARCH_DATA_TABLE,
    payload: {
      query: getState().searchData.queryValue,
      data,
      table
    }
  })

export const reset_search_action = () => ({ type: RESET_SEARCH });

const handle_search = (_, { query, data, table })  => ({
  table,
  searchResult: search(query, data),
  queryValue: query,
  isSearchActive: !!query,
});

const handle_search_reset = () => initial_state;

const handlers = {
  [SEARCH_DATA_TABLE]: handle_search,
  [UPDATE_SEARCH_DATA_TABLE]: handle_search,
  [RESET_SEARCH]: handle_search_reset,
};

export default (state = initial_state, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action.payload) : state;
};
