import { Input } from 'antd';
import styled from 'styled-components';
import { borderRadius, transition } from 'App/styleVariables';

const { Search } = Input;

const SearchWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`;

const StyledInputSearch = styled(Search)`
  width: 239px !important;
  
  .ant-input-group-addon {
    display: none;
  }
  
  .ant-input-wrapper {
    padding: 0;
    max-width: 239px !important;
    
    input {
      color: ${({ theme }) => theme.searchInput.color} !important;
      font-size: ${({ theme }) => theme.searchInput.size} !important;
      font-weight: ${({ theme }) => theme.searchInput.weight} !important;
      font-family: ${({ theme }) => theme.searchInput.family} !important;
      
      &::placeholder {
        padding-left: 0px !important;
        color: ${({ theme }) => theme.searchInput.color} !important;
        font-size: ${({ theme }) => theme.searchInput.size} !important;
        font-weight: ${({ theme }) => theme.searchInput.weight} !important;
        font-family: ${({ theme }) => theme.searchInput.family} !important;
      }
    }
  }
  
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    height: 36px;
    border-radius: ${({ theme }) =>
      theme.searchInput?.borderRadius || borderRadius} !important;
    border: 1px solid #d9d9d9;
    outline: none !important;
    box-shadow: none !important;
    transition: ${transition};

    &:hover {
      transition: all 0.2s;
      border-color: ${({ theme }) =>
        theme.searchInput?.borderColorActive} !important;
    }

    &:focus {
      box-shadow: ${({ theme }) =>
        theme.searchInput?.boxShadowFocus} !important;
    }
  }
  
  .ant-input-suffix {
    .anticon-search {
      color: ${({ theme }) => theme.searchInput.icon};
      font-size: ${({ theme }) => theme.searchInput.size} !important;
      font-weight: ${({ theme }) => theme.searchInput.weight} !important;
      font-family: ${({ theme }) => theme.searchInput.family} !important;
      cursor: default;
    }
  }
  
  .header-media & {
    width: 100% !important;
    max-width: 240px;
    min-width: 150px;

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
      height: 36px;
    }
  }
`;

export { StyledInputSearch, SearchWrapper };

export default {};
