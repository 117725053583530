const SET_LOGOUT_URL = 'COMMON_IFRAME::SET_LOGOUT_URL';

export const setDashboardLogoutUrlAction = (url) => ({
  type: SET_LOGOUT_URL,
  payload: { url },
});

const setDashboardLogoutUrlHandler = (state, { payload }) => ({
  ...state,
  dashboardLogoutUrl: payload?.url,
});

const handlers = {
  [SET_LOGOUT_URL]: setDashboardLogoutUrlHandler,
};

export default (state = {}, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};
