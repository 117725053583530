import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

class DynamicTime extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      time: `${dayjs.utc(new Date()).format('DD MMM YYYY HH:mm:ss')}`
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  tick() {
    this.setState({
      time: `${dayjs.utc(new Date()).format('DD MMM YYYY HH:mm:ss')}`
    });
  }

  render() {
    const { time } = this.state;
    return <div className="date">{time} GMT</div>;
  }
}

export default DynamicTime;
