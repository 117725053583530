import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { CardStyles } from '../../types';

export const SIndicatorContainer = styled(SIndicator)<CardStyles>`
  .ant-card-body {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 100%;
  }

  .indicator-icon {
    display: block;
    margin-right: 15px;
    max-width: 70px;

    @media (max-width: 1440px) {
      max-width: 60px;
      margin-right: 5px;
    }
    @media (max-width: 1280px) {
      max-width: 50px;
    }
  }
  
  .content {
    flex: 1;
    
    .indicator-title {
      text-align: center;

      @media (max-width: 1600px) {
        font-size: 18px;
      }
    }
    
    .badge {
      margin-left: 10px;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
  }
  
  .info-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 1280px) {
      flex-flow: row wrap;
    }
  }
  
  .indicator-info {
    font-size: 28px;
    font-weight: 600;

    @media (max-width: 1600px) {
      font-size: 24px;
    }

    @media (max-width: 1440px) {
      font-size: 20px;
    }
  }
`;
