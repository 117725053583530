/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { closeGenericModalAction } from '../reducer';
import CustomModal from '../../../components/Modal';

const MODAL_WIDTH = 950;

const mapStateToProps = (state) => ({
  modal: state.genericModal,
});

const mapDispatchToProps = {
  closeModal: closeGenericModalAction,
};

/**
 * WrappedComponent should fire closeModal function when job is done. Place all data you need to use in WrappedComponent
 * into data field
 * @param WrappedComponent - component name that should be showed in modal window
 * @param modalName - modal name same as in redux
 * @param argsToWrappedComponent - array of args to send to wrapped component
 * @param customCloseModal - function that is called when the close modal
 * @returns Component wrapped by CustomModal
 */
function withGenericModal(WrappedComponent, modalName, argsToWrappedComponent, customCloseModal) {
  const GenericModal = ({ closeModal, modal }) => {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    // Return nothing if information about modal with required name was not added to redux or if modal window in closed state
    if (!modal[modalName] || !modal[modalName].isModalOpen) {
      return null;
    }

    const { title, width, prefixId } = modal[modalName].configuration;

    // Return component from function arguments wrapped by modal
    return (
      <CustomModal
        title={title}
        closeModal={() => {
          if (typeof customCloseModal === 'function') {
            customCloseModal();
          }
          closeModal({ name: modalName })
        }}
        modalVisible={modal[modalName] ? modal[modalName].isModalOpen : false}
        width={width || MODAL_WIDTH}
        prefixId={prefixId || ''}
      >
        <WrappedComponent
          closeModal={() => closeModal({ name: modalName })}
          {...modal[modalName].data}
          {...argsToWrappedComponent}
          router={{ location, navigate, params }}
        />
      </CustomModal>
    );
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )((props) => <GenericModal {...props} />);
}

export default withGenericModal;
