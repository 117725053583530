import AbstractResourceFactory from './abstract-resource-factory';
import { Table, TableFilters } from '../../../types';
import client from '../../../../../lib/apiClient';
import formErrorHandler from '../../formErrorHandler';

class CustomListResourceFactory implements AbstractResourceFactory {
  defaultFilterState = {
    sorting: '%5B%5D',
    lines_per_page: 10,
  };

  async getAll(
    page = 1,
    searchField = '',
    filters: TableFilters,
    list_name: string,
    filter_params
  ): Promise<Table> {
    const [
      {
        table_scheme,
        data,
        allow_creation = true,
        object_type,
        numberOfElements,
      },
    ] = await Promise.all([
      client.getCustomListsAPI({
        list_name,
        query: searchField,
        ...filters,
        page_number: page,
      })(),
    ]);

    return {
      data,
      schema: table_scheme,
      total: numberOfElements,
      allow_creation,
      object_type,
    };
  }

  async add({ data, path, actions }: any) {
    try {
      await client.postCustomListItem({ list_name: path })(data);
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }

  async edit({ data, path, actions }: any) {
    try {
      const { id, data: newData } = await client.putCustomListItem({
        list_name: path,
        id: data.id,
      })(data);
      actions.setSubmitting(false);
      return {
        id,
        ...newData,
      };
    } catch(e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }
}

export default CustomListResourceFactory;
