import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormWorker from '../../../FormWorker';
import { handleSubmitAction } from './reducer';
import { get_data_action } from '../DetailsTable/reducer';

const DetailsForm = ({
  traceId,
  parentTraceId,
  userValues,
  handleSubmit,
  handleSubTitle,
  get_data,
  closeModal,
  formType,
  isEdit,
}) => {
  const onSuccess = () => {
    closeModal();
    get_data({ traceId: parentTraceId });
  };

  return (
    <FormWorker
      type="trace"
      isEdit
      userValues={userValues}
      formStep="formFilling"
      formType={formType}
      extraKeyName="traceType"
      handleSubTitle={handleSubTitle}
      handleSubmit={(data, actions) =>
        handleSubmit({
          traceId: traceId && traceId,
          data,
          actions,
          isEdit,
          onSuccess,
          userValues,
        })
      }
    />
  );
};

DetailsForm.propTypes = {
  traceId: PropTypes.string,
  parentTraceId: PropTypes.string,
  userValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleSubTitle: PropTypes.func,
  get_data: PropTypes.func,
  closeModal: PropTypes.func,
  formType: PropTypes.string,
  isEdit: PropTypes.bool,
};

const mapStateToProps = ({ detailsModal }) => ({
  userValues: detailsModal.data,
  traceId: detailsModal.traceId,
  parentTraceId: detailsModal.parentTraceId,
  userData: detailsModal.userData,
  isEdit: detailsModal.isEdit,
  formType: detailsModal.formType,
});
const mapDispatchToProps = {
  get_data: get_data_action,
  handleSubmit: handleSubmitAction,
};

const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(DetailsForm);

export default ConnectedForm;
