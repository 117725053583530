import { FC } from 'react';
import { SLegendItem } from './styled-components';

interface DotLegendProps {
  data: {
    color: string;
    text: string;
  }[];
}

const DotLegend: FC<DotLegendProps> = ({ data }) => {
  const items = data?.map(({ color, text }) => (
    <SLegendItem color={color}>
      <span className="text">{text}</span>
    </SLegendItem>
  ));

  return <>{items}</>;
};

export default DotLegend;
