import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import InputWrapper from '../../../components/HOC/InputWrapper';

class CheckboxComponent extends React.PureComponent {
  render() {
    const { name, value, setFieldValue, disabled, isError } = this.props;

    return (
      <Checkbox
        name={name}
        checked={value}
        onChange={(value) => setFieldValue(name, value.target.checked)}
        disabled={disabled}
        className={`form-checkbox-wrapper ${isError ? 'has-error' : ''}`}
        data-name={name}
      />
    );
  }
}

CheckboxComponent.propTypes = {
  /**
   * The name for the current DefaultInput. Must be unique in the form.
   */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Input value.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * General input change event handler.
   */
  handleChange: PropTypes.func,
  /**
   * Determine whether the DefaultInput is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Shows whether the DefaultInput has an error. If '' then there is no errors.
   */
  isError: PropTypes.string,
};

export default (props) => InputWrapper(props, CheckboxComponent);
