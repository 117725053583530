import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { font } from '../../../../App/styleVariables';

export const SMapWrapper = styled(SIndicator)`
  .ant-card-body {
    width: 100%;
    display: flex;
    flex-direction: column;

    .indicator-title {
      margin-bottom: 10px;
      position: relative;
      align-self: flex-start;
    }

    .wrapper {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }
  .content {
    width: 26%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .map-container {
    width: 74% !important;
    height: 410px;
    position: relative;

    .loader {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
  #mapdiv {
    width: 100%;
    height: 100%;
  }
  .filter-section {
    .filter {
      width: 80%;
      min-width: 150px;

      .ant-select-selector {
        border-radius: 49px;
        height: 32px;
        font-family: ${({ theme }) =>
          `${theme?.dashboard?.fontFamily}, sans-serif` || font} !important;
        background: #f5f5f5 !important;
        border: none;
        padding-left: 18px;

        .ant-select-selection-search-input {
          color: #8f8f8f;
          font-weight: 500;
          font-size: 16px;
          padding-left: 8px;
        }
        .ant-select-selection-placeholder {
          font-size: 16px !important;
          color: #8f8f8f;
        }
      }
      .ant-select-arrow {
        color: #808080;
        font-size: 18px;

        svg[data-icon='down'] {
          display: none;
        }
      }
      .ant-select-clear {
        top: 40%;
        font-size: 16px;
        width: 16px;
        height: 16px;
        background: inherit;
        right: 35px;
      }
    }
  }
  .map-legend {
    color: #808080;
    font-size: 14px;

    .legend-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
      color: #101828;
    }
  }
`;

export const SLegendItem = styled.div<any>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 10px;
  text-transform: uppercase;

  &:before {
    content: ${({ icon }) => `url(${icon})` || ''};
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${({ color }) => color};
    margin-right: 15px;
  }
`;
