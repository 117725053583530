import React from 'react';
import { Button } from 'antd';
import { openProtectedLink } from '../../../../lib/util';
import StyledLinkContainer from './styled-component';

interface Props {
  file: string;
  label?: string;
  businessId: number;
  styles?: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const ButtonDownload = ({ file, label, styles, businessId }: Props) => {
  const dashboard = true;

  return (
    <StyledLinkContainer styles={styles}>
      {label && <p className={'section-name link-label'}> {label} </p>}
      <Button
        type="link"
        className={'link-button'}
        onClick={(e) => {
          e.preventDefault();
          openProtectedLink(file, businessId, dashboard);
        }}
      >
        <img src="/images/file-download.png" alt="Download icon" className={'link-icon'}/>
      </Button>
    </StyledLinkContainer>
  );
};

export default ButtonDownload;
