import { Radio, Select } from 'antd';
import styled from 'styled-components';

const SSelect = styled(Select)`
  width: 450px !important;
`;

const SRadio = styled(Radio)`
  width: ${(props) => {
    return `${
      (props.col?.columns === 1 && '100%') ||
      (props.col?.columns === 2 && '47%') ||
      '290px'
    } !important`;
  }};
  margin-bottom: 17px;

  &:not(.ant-radio-wrapper-checked) {
    &:hover {
      .ant-radio-inner {
        border: ${({ theme }) => theme.form.item?.input?.borderActive} !important;
      }
    } 
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) =>
      props.theme.form.submitButton?.passive} !important;
    background: transparent;
  }
  .ant-radio-inner::after {
    background-color: ${(props) =>
      props.theme.form.submitButton?.passive} !important;
  }
`;

const SForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 15px;

  .ant-form-item {
    .ant-form-item-label label,
    label.ant-radio-wrapper {
      font-family: ${(props) => props.theme.form.item?.label?.family};
      color: ${(props) => props.theme.form.item?.label?.color}
    }
  }
`;

export { SSelect, SRadio, SForm };

export default {};
