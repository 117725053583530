import AbstractResourceFactory from './abstract-resource-factory';
import { Table } from '../../../types';
import client from '../../../../../lib/apiClient';
import formErrorHandler from '../../formErrorHandler';

class ProductResourceFactory implements AbstractResourceFactory {
  async add({ data, actions }: any) {
    try {
      await client.postProduct(data);
      actions.setSubmitting(false);
    } catch(e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }

  async edit({ data, actions }: any) {
    try {
      const newData = await client.putProduct(data);
      actions.setSubmitting(false);
      return newData;
    } catch (e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }

  async getAll(...args: any[]): Promise<Table> {
    const [products, table_scheme] = await Promise.all([
      client.getProducts(),
      client.getProductsTable(),
    ]);
    return {
      data: products,
      schema: table_scheme,
      total: products.length,
    };
  }
}

export default ProductResourceFactory;
