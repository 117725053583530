import styled from 'styled-components';

const StyledTab = styled.div`
  background-color: white;

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;  
  }
  
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    border-top: 2px solid;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-top: 2px solid #f0f0f0;
    flex: 1;
    &:hover {
      border-top: 2px solid #40a9ff;
    }

    @media (max-width: 1200px) {
      padding: 6px 10px;
    }
  }

  .ant-statistic-title {
    font-size: 14px;
    color: #333;
    
    @media (max-width: 1200px) {
      white-space: initial;
    }
  }

  .ant-tabs-nav-wrap {
    background: #f9f9f9;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-bottom: 0 !important;
  }
`;

export default StyledTab;
