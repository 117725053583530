import React from 'react';
import { StyledImage, StyledImageWrapper } from './styled-component';

interface Props {
  key: number;
  data: { img: string }[];
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const CircleImagePreview = ({ key, styles, data }: Props) => {
  return data && Array.isArray(data) && data.length !== 0 ? (
    <StyledImageWrapper key={key} styles={styles}>
      {data.map((item, index) => {
        return (
          <StyledImage src={item?.img} key={index} />
        )
      })}
    </StyledImageWrapper>
  ) : null;
};

export default CircleImagePreview;