import client from '../../lib/apiClient';

const GET_HOME_PAGE_SUCCESS = 'GET_HOME_PAGE_SUCCESS';

const initial_state = {
  page: null,
  homepage_schema: null,
};

export const getHomePage = () => async (dispatch) => {
  try {
    const page = await client.getPage('homepage')();
    dispatch({
      type: GET_HOME_PAGE_SUCCESS,
      payload: {
        page,
      },
    });
  } catch (e) {}
};

const set_home_page = (state, payload) => ({
  ...state,
  page: payload.page.data,
  homepage_schema: payload.page.homepage_schema,
});

const handlers = {
  [GET_HOME_PAGE_SUCCESS]: set_home_page,
};

export default (state = initial_state, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action.payload) : state;
};
