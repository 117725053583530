import React from 'react';
import QRCode from 'qrcode.react';
import {
  FlexWrapper,
  QRWrapper,
  DownloadButton,
  URLRoot,
  URLPreview,
  MockupWrapper,
  QRCodeRootBlock,
  QRCodeNotation,
} from './styled';
import { Divider } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import './styles.css';
import { renderToString } from 'react-dom/server';
import Mockup from './mockup';

const PREVIEW_QR_ID = 'PREVIEW_QR_ID';

function downloadString(text: string, fileType: string, fileName: string) {
  var blob = new Blob([text], { type: fileType });

  var a = document.createElement('a');
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// const downloadQR = (imageName) => {
//   const canvas = document.getElementById(PREVIEW_QR_ID);
//   const pngUrl = canvas
//     .toDataURL('image/png')
//     .replace('image/png', 'image/octet-stream');
//   let downloadLink = document.createElement('a');
//   downloadLink.href = pngUrl;
//   downloadLink.download = imageName;
//   document.body.appendChild(downloadLink);
//   downloadLink.click();
//   document.body.removeChild(downloadLink);
// };

type Props = {
  url: string;
  imageName: string;
};

const QrCodePreview = ({ url, imageName }: Props) => {
  const size = 196;
  const qr = (
    <QRCode
      bgColor={'transparent'}
      id={PREVIEW_QR_ID}
      size={size}
      value={url}
      renderAs={'svg'}
    />
  );

  return (
    <FlexWrapper>
      <QRCodeRootBlock>
        <QRCodeNotation>
          Scan :
        </QRCodeNotation>
        <QRWrapper id={'QRWrapper'}>{qr}</QRWrapper>
        <DownloadButton
          onClick={() =>
            downloadString(
              renderToString(qr)
                .replace(
                  'svg',
                  'svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"'
                )
                .replace(`height="${size}"`, '')
                .replace(`width="${size}"`, ''),
              'image/svg+xml',
              `${imageName}.svg`
            )
          }
        >
          <DownloadOutlined /> <Divider type="vertical" /> SVG
        </DownloadButton>
        <URLRoot>
          <URLPreview href={url} target={'_blank'}>
            {url}
          </URLPreview>
        </URLRoot>
      </QRCodeRootBlock>
      <MockupWrapper>
        <Mockup url={url} />
      </MockupWrapper>
    </FlexWrapper>
  );
};

export default QrCodePreview;
