import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

const TimePicker = (props) => {
  const {
    name,
    value,
    disabled,
    placeholder,
    setFieldTouched,
    setFieldValue,
    isError,
  } = props;

  const dateValue =
    value && typeof value !== 'object' ? dayjs.utc(value) : value;

  return (
    <DatePicker
      placeholder={placeholder}
      name={name}
      style={{ width: '100%' }}
      value={!dateValue ? null : dateValue}
      onChange={(date) => setFieldValue(name, date)}
      onBlur={() => setFieldTouched(name, true)}
      disabled={disabled}
      className={isError ? 'has-error' : ''}
      showTime
      data-name={name}
    />
  );
};

TimePicker.propTypes = {
  /**
   * The name for the current TimePicker. Must be unique in the form.
   */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Date value.
   */
  value: PropTypes.string,
  /**
   * Determine whether the TimePicker is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The placeholder of date input.
   */
  placeholder: PropTypes.string,
  /**
   * Touch state monitoring function.
   */
  setFieldTouched: PropTypes.func,
  /**
   * Value setting function.
   */
  setFieldValue: PropTypes.func,
  /**
   * Shows whether the TimePicker has an error. If '' then there is no errors.
   */
  isError: PropTypes.string,
};

export default TimePicker;
