import React from 'react';
import { Config } from '../../../config';
import { grabToken } from '../../../lib/util';
import { SIframe, SH1 } from './style';

const { API_URL } = Config;
const traceup_url = `${API_URL}/api/v2/trace_up`;
const msg = 'Lot inexistant';

class Details extends React.PureComponent {
  static defaultProps = {
    details: localStorage.getItem('details'),
    token: grabToken()
  };

  render() {
    const {
      details,
      token,
      history: { length: step }
    } = this.props;
    const src = `${traceup_url}/${details}?${token}`;
    let content;

    if (step === 1) {
      content = <SIframe frameBorder="0" src={src || null} />;
      document.body.style.overflow = 'hidden';
    } else content = <SH1>{msg}</SH1>;

    return content;
  }
}

export default Details;
