import { createGlobalStyle } from 'styled-components';

const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'TradeGothicLTStdExtended';
    src: url("/fonts/TradeGothicLTStd-Extended.otf") format('opentype'),
         url("/fonts/TradeGothicLTStd-Extended.otf") format('woff');
    font-weight: 400;
  }
  @font-face {
    font-family: 'TradeGothicLTStdExtended';
    src: url("/fonts/TradeGothicLTStd-BoldExt.otf") format('opentype'),
    url("/fonts/TradeGothicLTStd-BoldExt.otf") format('woff');
    font-weight: 700;
  }
`;

export default FontStyles;
