import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { CardStyles } from '../../types';

export const SIndicatorContainer = styled(SIndicator)<CardStyles>`
  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;

    .indicator-title {
      text-transform: ${(props) => props?.styles?.title?.textTransform};
      margin-bottom: ${(props) => props?.styles?.title?.marginBottom};
    }

    .header {
      margin-bottom: 5px;

      .indicator-title {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        text-transform: none;

        span {
          font-size: 36px;
          margin-right: 10px;
          cursor: pointer;
          font-weight: 600;

          @media (max-width: 1600px) {
            font-size: 30px;
          }
        }
      }

      .indicator-icon {
        max-width: 50px !important;
      }
    }
  }
  .pie-chart {
    width: ${(props) => props?.styles?.chart?.widthXl || '100%'};
    height: ${(props) => props?.styles?.chart?.heightXl || '160px'};
    margin: 0 auto;

    @media (max-width: 1600px) {
      width: ${(props) => props?.styles?.chart?.widthL};
      height: ${(props) => props?.styles?.chart?.heightL || '140px'};
    }

    @media (max-width: 1440px) {
      width: ${(props) => props?.styles?.chart?.widthM};
    }

    .recharts-default-legend {
      li {
        margin-right: 0 !important;
        margin-left: ${(props) => props?.styles?.legend?.item?.marginLeft || '8px'};
        display: ${(props) => props?.styles?.legend?.item?.display} !important;
        justify-content: ${(props) => props?.styles?.legend?.item?.justifyContent};
        align-items: ${(props) => props?.styles?.legend?.item?.alignItems}
      }
    }
  }

  .recharts-responsive-container {
    margin: 0 auto;
  }

  .recharts-legend-wrapper {
    .recharts-default-legend {
      padding-top: ${(props) =>
        props?.styles?.legend?.paddingTop || 0} !important;
    }
  }

  .custom-legend {
    list-style-type: none;
    margin-bottom: 0;
  }
`;

export const SLegendItem = styled.li<{ color: string }>`
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .legend-circle {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-right: 4px;
  }

  .legend-icon {
    width: 20px;
    margin-left: 4px;
  }
`;
