import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { CardStyles } from '../../types';

export const SIndicatorContainer = styled(SIndicator)<CardStyles>`
  .content {
    .filler {
      .label {
        font-size: 12px;
      }
    }
  }

  .legend {
    margin-top: 20px;
  }
`;

export const SStatusLegendItem = styled.div`
  display: flex;
  color: ${(props) => props.color};
  margin-bottom: 10px;

  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-right: 5px;
  }

  span {
    width: calc(100% - 25px);
    line-height: 1;
  }
`;
