import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import components from './components';
import { fetchData } from './fetchData';
import errorHandler from '../../lib/errorHandler';
import { CockpitPropsInterface } from './types';
import { SWrapper, SContainer } from './styled-components';
import ErrorPage from '../CommonDashboard/error-page';
import ProgressBar from '../../components/ProgressBar';

const Cockpit = ({ businessName, pageSchema }: CockpitPropsInterface) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const pathName = pageSchema?.path?.split('/')[1];

  const indicators = { ...components, ProgressBar };

  useEffect(() => {
    fetchData(businessName, pathName)
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        errorHandler(err, 'CommonDashboard');
        setErrorMessage(err[0]?.message || err?.message);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <SContainer>
        <Spin style={{ display: 'block' }} size="large" />
      </SContainer>
    );
  }

  if (errorMessage) {
    return (
      <SContainer>
        <div className={'error-container'}>{errorMessage}</div>
      </SContainer>
    );
  }

  const content =
    data && Object.keys(data).length > 0 ? (
      Object.keys(data)?.map((componentName: string, index: number) => {
        const Component = data[componentName]?.component
          ? indicators[data[componentName]?.component]
          : indicators[componentName];
        const props = {
          ...data[componentName],
          indicatorName: componentName,
          businessName,
          pathName,
        };

        return <Component key={`${componentName}-${index}`} {...props} />;
      })
    ) : (
      <ErrorPage message={errorMessage} />
    );

  return (
    <SContainer>
      <SWrapper>{content}</SWrapper>
    </SContainer>
  );
};

const mapStateToProps = ({
  auth: {
    currentUser: {
      attributes: {
        business: { name },
      },
    },
  },
}: {
  auth: { currentUser: { attributes: { business: { name: string } } } };
}) => ({
  businessName: name,
});

export default connect(mapStateToProps)(Cockpit);
